<section class="bg-white">
  <app-user-header></app-user-header>
</section>

<nav class="container-fluid pt-3 pb-3 bd-navbar sub-header">
  <div class="row">
    <div class="col">
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb" style="cursor: pointer">
          <li class="breadcrumb-item active" aria-current="page">
            <a>{{pageTitle}}</a>
          </li>
        </ol>
      </nav>
    </div>


  </div>
</nav>

<div class="container-fluid main-div user-list-top">
  <div>
    <form [formGroup]="blogForm" novalidate>
      <div class="row m-3">
        <div class="col-md-3">
          <h5>Title <span style="color: red">*</span></h5>
        </div>
        <div class="col-md-8 form-group float-label-control">
          <input type="text" class="form-control" placeholder="Enter Title" formControlName="title" required>
        </div>
      </div>
      <div class="row m-3">
        <div class="col-md-3">
          <h5>SubTitle <span style="color: red">*</span></h5>
        </div>
        <div class="col-md-8 form-group float-label-control">
          <input type="text" class="form-control" placeholder="Enter SubTitle" formControlName="subTitle" required>
        </div>
      </div>

      <div class="row m-3">
        <div class="col-md-3">
          <h5>Blog Url <span style="color: red">*</span></h5>
        </div>
        <div class="col-md-8 form-group float-label-control">
          <input type="text" class="form-control" placeholder="Enter Blog Slug" formControlName="blogSlug" required>
        </div>
      </div>
      <div class="row m-3">
        <div class="col-md-3">
          <h5>Category Name <span style="color: red">*</span></h5>
        </div>
        <div class="col-md-8 form-group float-label-control">
          <input type="text" class="form-control" placeholder="Enter CategoryName" formControlName="category" required>
          <!-- <select class="form-control" formControlName="selectedCategory" name="selectedTeamSize" required>
            <option value="" [disabled]="true">Select Category Name</option>
            <option *ngFor="let category of categories " [value]=category.name>
              {{category.name}}
            </option>
          </select> -->
        </div>
      </div>
      <div class="row m-3">
        <div class="col-md-3">
          <h5>Image</h5>
        </div>
        <!--            <div class="col-md-3">-->
        <!--              <input type="file" placeholder="Upload file" name="fileUpload" id="uploadFile"-->
        <!--                     (change)="fileChangeEvent($event)" required><br><i>(Supported extensions: .webp)</i>-->
        <!--              <p class="text-danger">{{fileError}}</p>-->
        <!--            </div>-->
        <div class="col-md-5" style="text-align: left;">
          <input type="file" placeholder="Upload file" name="fileUpload" id="uploadFile2"
            (change)="fileChangeEventJpg($event)" required><br><i>(Supported extensions: .jpg,
            .png,.jpeg)</i>
          <p class="text-danger">{{fileUploadError}}</p>
        </div>
        <div class="col-md-3 offset-3" *ngIf="primaryImage != ''">
          <img src="{{primaryImage}}" class="img-fluid" width="100%" alt="" style="max-width: 100%;">
        </div>
      </div>
      <div class="row m-3">
        <div class="col-md-3">
          <h5>Body <span style="color: red">*</span></h5>
        </div>
        <div class="col-md-8">
          <!--              <angular-editor [placeholder]="'Enter text here...'" [(ngModel)]="htmlContent"></angular-editor>-->
          <angular-editor formControlName="subjectBody" [config]="config"></angular-editor>
        </div>

      </div>
      <div class="row m-3">
        <div class="col-md-3">
          <h5>Author <span style="color: red">*</span></h5>
        </div>
        <div class="col-md-8 form-group float-label-control">
          <input type="text" class="form-control" placeholder="CreatedBy" formControlName="author" required>
        </div>
      </div>
      <div class="row m-3" >
        <div class="col-11 text-right">
          <button class="btn btn-outline" (click)="gotoBlogListScreen()">Cancel</button>
          <button *ngIf="!isEditBlog" class="btn btn-primary" [disabled]=" !blogForm.valid" (click)="createBlog()">Save</button>
          <button *ngIf="isEditBlog" class="btn btn-primary" [disabled]="!blogForm.valid" (click)="updateBlog()">Update</button>
        </div>
      </div>
      <div class="text-danger text-center mt-4"> {{errorMsgUnApproved}} </div>
    </form>
  </div>
</div>
