import { Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { AssessmentService } from "../assessment.service";
import { ToastrService } from "ngx-toastr";
import { Location } from "@angular/common";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { JobService } from "../../jobs/jobs.service";
import { IMyDpOptions } from "mydatepicker";
import { ListOfCandidatesComponent } from "../list-of-candidates/list-of-candidates.component";
import { NgxUiLoaderService } from "ngx-ui-loader";

declare let myExtObject: any;
declare let $: any;

@Component({
  selector: "app-assessment-details",
  templateUrl: "./assessment-details.component.html",
  styleUrls: ["./assessment-details.component.css"],
  providers: [AssessmentService, JobService],
})
export class AssessmentDetailsComponent implements OnInit, OnDestroy {
  selectedAssigneeDetails: any;
  availableAssignees: any = [];
  linkId: any;
  centerMngrAssignedTo: string = "";
  setCenterMangerFlag: boolean;
  noCenterManagerFlag: boolean = false;
  selAssigneeId = "";
  fillInTheBlanksQuesn = '';
  hideSelectAssigneeOption = false;
  assigneeRole = { evaluate: false, proctor: false, centerManager: false };
  batchReferenceId: any;
  selectedBatch: any;
  assignEvaluatorConfirmationMessage = "";
  unlinkAssigneeConfirmationMessage = "";
  removeAssigneeActionType = "";
  userRole: any;
  userDetails: any;
  assessmentDescriptionDash: any;
  assessmentDetails: any;
  companyId: any;
  accessToken: any;
  showSectionQuestion: Boolean = false;
  showCandidatelinkName: Boolean = true;
  questionAnswerSave: any = [];
  showAssessemt: Boolean;
  assessmentList1: Boolean;
  assessmentData: Boolean = true;
  assessmentConfigurationDash: Boolean = false;
  assessmentListOfCandidates: Boolean = false;
  assessmentCandidatesDetails: Boolean = false;
  asseementQuestion: Boolean = false;
  sectionIndex: any;
  questionAnswer: any;
  errorMsg: any;
  assessmentNumQuestion: any = [];
  assessmentQuestionType: any = [];
  sectionWiseQuestionDetail: any = [];
  createdassessmentId: String = "";
  assessmentSection: any = [];
  candidateSearchText: string = "";
  QuestionType: any;
  trackGeoLocation = {
    startLocation: {
      latitude: "",
      location: "",
      longitude: "",
      reverseGeo: "",
    },
    endLocation: {
      latitude: "",
      location: "",
      longitude: "",
      reverseGeo: "",
    },
  };
  listOfLinkForAssessment: Boolean;
  isRequestInProcess: Boolean = false;
  skipforLinkList: any;
  listofLinkDetails: any;
  publicLinkcreateion = "";
  listMessage: Boolean;
  listErrMessage: Boolean = false;
  assessmentTypeList = [];
  tagsForLink: any;
  typeOfQuestion: any;
  levelOfQuestion: any;
  assessmentTypeForLoadMore = "";
  isDetails: Boolean = true;
  companyType: any;
  proctor: any;
  proctorLinkId: any;
  editId: any;
  subCatDetails = [{ categories: [] }];
  publicSTARTDate: any;
  publicENDDate: any;
  publicLinkCreation: Boolean;
  privateLinkCreationStep1: Boolean;
  privateLinkCreationStep2: Boolean;
  Testcode = "";
  linkIdDeleted = "";
  option1 = "1";
  option2 = "2";
  option3 = "3";
  option4 = "4";
  CandidatelinkName = "";
  CandidatelinkID = "";
  CandidatelinkType = "";
  CandidateTrackLocation: boolean;
  candidateListValue;
  CandidateSnap: Boolean = false;
  CandidateStartDT;
  CandidateEndDT;
  candidateLocation = "";
  candidateListForLink: boolean;
  skipforCan = 0;
  addLinkID = "";
  candidateReportResponse = [];
  CandidateReportDetails: Boolean = false;
  assessmentIdDelete: any;
  instructForTest: Boolean = true;
  instructForTestValue = "Instructions not included";
  afterTestMessage: Boolean = true;
  afterTestMessageValue = "Thank you for taking assessment";
  imageUploadForLink: boolean;
  publicStartDate = "";
  publicStartTime = "";
  publicEndDate = "";
  publicEndTime = "";
  publicerrorMsg = "";
  mobileNum: string;
  countryCodeNum: string;
  modalResetCandidate: FormGroup;
  selectedRowMobileNumber: string;
  selectedRowCountryCode: string;
  selectRow = 0;
  isUploadingCandImg: Boolean = false;
  uploadedFileErrMsg: string;
  afterSelect = false;
  base64textString = "";
  responseUrlImage = "";
  lessChar: boolean = false;
  date = new Date();
  currentYear = this.date.getFullYear();
  currentMonth = this.date.getMonth() + 1;
  currentDate = this.date.getDate();
  candidateSearchTextTag: string = "";
  searchTextTag: string = "";
  candidateSearchText1: string = "";
  searchFlag: boolean = false;

  public myDatePickerOptions: IMyDpOptions = {
    disableUntil: {
      year: this.currentYear,
      month: this.currentMonth,
      day: this.currentDate - 1,
    },
    editableDateField: false,
    dateFormat: "yyyy-mm-dd",
  };
  CandidateReportData = [];
  CandidateData;
  candidateCaputerdSnapshot = [];
  candidateAttendanceSnapshot = "";
  @ViewChild("gmapstart", { static: false }) gmapElementstart: any;
  @ViewChild("gmapstart1", { static: false }) gmapElementstart1: any;
  @ViewChild("gmapend", { static: false }) gmapElementend: any;
  marker: any;
  location: string;
  startLocation: string;
  endLocation: string;
  data: any;
  totalNumQuestion: any;
  nodeValue: any = [];
  previewOfAssessmentList: any = [];
  easy = "EASY";
  medium = "MEDIUM";
  hard = "HARD";
  reviewDetails: any = [];
  descriptiveMarks: any = [];
  isReviewLoading: Boolean = false;

  linkData: any = {};
  countryCode: any;
  reviewId: any;
  isEnabled: Boolean = false;
  fromList: Boolean = false;
  maxLength = 4;
  assessment_name: any;
  candidateSuspiciousCount: any;
  candidateFaceViolationCount: any;
  candidateTimeSpentForAssessment: any;
  candidateName: any;
  suspicionReason: any;
  markBtnLabel: any = "Evaluate";
  candidateResponseDesc: any = [];
  emptyArray: any = [];
  formattedAns: any = [];
  statusLabel: any = "";
  reviewComment: any = "";
  remarkDetails: any = [];
  commentedBy: any = "";
  showCommentSecion: Boolean = false;
  showDescriptiveIcon: any;
  startTimeMap: any = "";
  endTimeMap: any = "";
  candidateIndex = 0;
  screenOptions = {
    position: 0,
  };
  onlinecompiler: boolean;
  onlinequestionCount: any;
  questionViewPreferences: string;
  getDoSelectEdit: any;
  onlineSection: any = [];
  onlinecomp: any;
  forOnlineCompiler: boolean = false;
  onlinetestNameDe: any;
  onlineSectionName: any;
  Psychometer: boolean = false;
  totalProblems: any;
  problemType: any;

  optionDetails: any = [];
  lhsArrDetails: any = [];
  rhsArrDetails: any = [];
  newlhsArrDetails: any;
  newrhsArrDetails: any;
  assignedTo: any = [];
  roleManagementError = "";
  hasDescriptiveQuestions: boolean = false;
  masterQBAccess: boolean = false;
  editFlag: boolean = true;
  showCenterManagerChkBx: boolean = true;
  searchText: string = "";
  searchText1: string = "";
  noDataFoundFlag: boolean = false;
  noCandidateFound: boolean = false;
  inviteErr: string = "";
  CandidateErr: string = "";
  appendAccessCodeFlag: boolean = false;
  dispPublicLinkCreation:any;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private assessmentService: AssessmentService,
    private jobService: JobService,
    private toastrService: ToastrService,
    private _location: Location,
    private listOfCan: ListOfCandidatesComponent,
    private ngxService: NgxUiLoaderService
  ) {
    this.accessToken = localStorage.getItem("accessToken");
    this.companyId = localStorage.getItem("companyId");
    this.companyType = localStorage.getItem("companyType");
    this.proctor = localStorage.getItem("proctor");
    this.getRouteParams();
  }

  ngOnInit() {

    this.assementType();
    this.getLevelOfAssess();
    this.getQuestionType();
    this.getTagsForLink();
    localStorage.removeItem("hasDescriptiveQuestions");
    this.modalResetCandidate = new FormGroup({
      reset: new FormControl("", Validators.required),
      resetReason: new FormControl("", Validators.required),
    });
    if (localStorage.getItem("fromInviteComp") === "FromInviteComponent") {
      this.listofInvitationLink(true);
      if (localStorage.getItem("createdLinkId") !== null) {
        const linkId = localStorage.getItem("createdLinkId");
        const payload = { linkId: linkId };
        this.assessmentService
          .candidatesLinkIDDetails(payload, this.companyId, this.accessToken)
          .subscribe(
            (response) => {
              this.ViewAccessCode(response.message, true);
            },
            (error) => {
              this.errorMsg = error.error.message;
            }
          );
      }
    }
    window.onbeforeunload = function () {
      localStorage.removeItem("fromInviteComp");
      return "";
    };
    this.userDetails = JSON.parse(localStorage.getItem("userInfo"));
    this.userRole = this.userDetails.Companies[0].RoleId;

    var qbAccess = localStorage.getItem("masterQBAccess");
    if (qbAccess == "allowed") {
      this.masterQBAccess = true;
    } else {
      this.masterQBAccess = false;
    }

    var assessmentType = localStorage.getItem("_assessmentType");
    if (assessmentType == "Aptitude" || assessmentType == "Psychometer") {
      if (!this.masterQBAccess) {
        this.editFlag = false;
      }
    } else {
      this.editFlag = true;
    }
    this.ngxService.stop();
  }

  ngOnDestroy() {
    localStorage.removeItem("fromInviteComp");
  }

  getRouteParams() {
    this.route.params.subscribe(
      (params) => {
        if (params.id) {
          this.getAssessmentDetails(params["id"]);
          this.createdassessmentId = params["id"];
        }
      },
      (error) => console.log(error)
    );
  }

  assessmentDashboard() {
    this.router.navigate(["assessment/list"]);
  }

  getAssessmentDetails(id: any) {
    const payload = { assessmentId: id };
    this.assessmentService
      .getAssessmentById(payload, this.companyId, this.accessToken)
      .subscribe(
        (res) => {
          this.assessmentDetails = res.message[0];
          this.editId = this.assessmentDetails._id;
          this.assessmentTypeForLoadMore =
            this.assessmentDetails.assessmentType;
          localStorage.setItem(
            "assessmentTypeFromDetails",
            this.assessmentTypeForLoadMore
          );
          if (this.proctor === "proctor") {
            this.listofInvitationLink(true);
            this.candidatesLinkIDDetails();
          }
          this.assessmentSection = this.assessmentDetails.sections;
          this.onlinetestNameDe =
            this.assessmentDetails.onlineCompiler.testName;
          localStorage.setItem("SelectedonlinetestName", this.onlinetestNameDe);
          this.afterTestMessage =
            this.assessmentDetails.configuration.addMessage;
          this.afterTestMessageValue =
            this.assessmentDetails.configuration.addMessageValue;
          this.instructForTest =
            this.assessmentDetails.configuration.instructionForTest;
          this.instructForTestValue =
            this.assessmentDetails.configuration.instructionForTestValue;
          this.onlinequestionCount = this.assessmentDetails.questionCount;
          this.questionViewPreferences = this.assessmentDetails.sections.map(section => section.questionViewPreference);
          for (let i = 0; i < this.assessmentSection.length; i++) {
            let sum = 0;
            let questionTypeCounting = 0;
            for (
              let j = 0;
              j < this.assessmentSection[i].categories.length;
              j++
            ) {
              if (this.assessmentSection[i].categories[j].questionType === null) {
                this.assessmentSection[i].categories[j].questionType = [];
              }
              if (this.assessmentSection[i].categories[j].questionType[0] == "Descriptive"
              ) {
                localStorage.setItem("hasDescriptiveQuestions", "yes");
                this.hasDescriptiveQuestions = true;
              }
              sum += Number(
                this.assessmentSection[i].categories[j].numberOfQuestions
              );
              if (
                this.assessmentSection[i].categories[0].questionType[0] !==
                this.assessmentSection[i].categories[j].questionType[0]
              ) {
                questionTypeCounting += 1;
              }
            }
            this.QuestionType =
              this.assessmentSection[i].categories[0].questionType[0];
            this.assessmentNumQuestion[i] = sum;
            if (questionTypeCounting !== 0) {
              this.assessmentQuestionType[i] =
                "" + this.QuestionType + " +" + questionTypeCounting + " more";
            } else {
              this.assessmentQuestionType[i] = "" + this.QuestionType;
            }
          }
        },
        (err) => {
          console.log(err);
        }
      );
  }

  candidatesLinkIDDetails() {
    this.proctorLinkId = localStorage.getItem("linkedId");
    const payload = { linkId: this.proctorLinkId };
    this.assessmentService
      .candidatesLinkIDDetails(payload, this.companyId, this.accessToken)
      .subscribe(
        (response) => {
          this.candidateReportList(response.message, true);
        },
        (error) => {
          this.errorMsg = error.error.message;
        }
      );
  }

  sectionWiseQuestion(data, idx) {
    this.assessmentDescriptionDash = true;
    this.questionAnswerSave = [];
    this.showAssessemt = false;
    this.assessmentList1 = false;
    this.assessmentData = false;
    this.sectionIndex = idx;
    data.categories.forEach((ele) => {
      if (!ele.categoryId) {
        ele.categoryId = ele.parentCategoryId;
      }
    });

    if (localStorage.getItem("assessmentTypeFromDetails") === "Psychometer") {
      this.Psychometer = true;
    }

    if (
      localStorage.getItem("assessmentTypeFromDetails") === "Online Compiler"
    ) {
      this.forOnlineCompiler = true;

      this.assessmentService
        .getTest(this.companyId, this.accessToken)
        .subscribe(
          (res: any) => {
            this.getDoSelectEdit = res.message.objects;
            this.showSectionQuestion = true;
            this.asseementQuestion = false;
            let sampletest = [];
            for (let i = 0; i < this.getDoSelectEdit.length; i++) {
              if (
                this.getDoSelectEdit[i].name ==
                localStorage.getItem("SelectedonlinetestName")
              ) {
                sampletest.push(this.getDoSelectEdit[i]);
                this.onlineSection = sampletest[0].sections[0];
                this.onlineSectionName = sampletest[0].sections[0].name;
                this.totalProblems = sampletest[0].sections[0].num_problems;
                this.problemType =
                  sampletest[0].sections[0].problems[0].problem_type;
              }
            }
          },

          (error) => {
            console.log(error.error.message);
          }
        );
    }
    const payload = { sections: [data] };
    this.assessmentService
      .sectionWiseQuestion(payload, this.companyId, this.accessToken)
      .subscribe(
        (response) => {
          this.sectionWiseQuestionDetail = response;
          this.asseementQuestion = false;
          this.showSectionQuestion = true;
          if (response && response[0] && response[0].questions) {
            
            this.questionAnswer = response[0].questions;
            for (let i = 0; i < this.questionAnswer.length; i++) {
              
              if (this.questionAnswer[i].questionType !== 'Reading Comprehension') {
                let obj = {};
                if (
                  this.questionAnswer[i].questionType !== "Fill In The Blanks" &&
                  this.questionAnswer[i].questionType !== "Descriptive" &&
                  this.questionAnswer[i].questionType !== "Match The Following"
                ) {
                  this.questionAnswerSave[i] =
                    this.questionAnswer[i].answer.split(",");
                  obj = {
                    a: this.questionAnswerSave[i][0],
                    b: this.questionAnswerSave[i][1],
                    c: this.questionAnswerSave[i][2],
                    d: this.questionAnswerSave[i][3],
                  };
                  

                  this.questionAnswer[i].answer = obj;
                }

                // view for match the following
                if (
                  this.questionAnswer[i].questionType === "Match The Following"
                ) {
                  this.lhsArrDetails = this.questionAnswer[i].LHSOptions;
                  this.rhsArrDetails = this.questionAnswer[i].RHSOptions;
                  this.optionDetails = this.questionAnswer[i].answer;
                  this.newlhsArrDetails = this.lhsArrDetails.map((it, index) => {
                    let key = Object.keys(it);
                    let firstKey = key[0];
                    let tempAns = this.optionDetails[index];
                    return Object.assign({
                      question: it[firstKey],
                      answer: tempAns,
                    });
                  });

                  this.newrhsArrDetails = this.rhsArrDetails.map((it) => {
                    let key = Object.keys(it);
                    let firstKey = key[0];
                    return Object.assign({ val: firstKey, name: it[firstKey] });
                  });
                }

                if (
                  this.questionAnswer[i].questionType === "Fill In The Blanks" &&
                  Object.keys(this.questionAnswer[i].answer).length !== 0
                ) {
                  const regex = /#blank#/gi;
                  const arr = this.questionAnswer[i].question.split(regex);
                  Object.keys(this.questionAnswer[i].answer).forEach((key, j) => {
                    if (this.questionAnswer[i].answer[key].length > 1) {
                      this.questionAnswer[i].answer[key] =
                        this.questionAnswer[i].answer[key].join(" / ");
                    }
                    arr[j] =
                      arr[j] +
                      '<span class="blank-ans">' +
                      this.questionAnswer[i].answer[key] +
                      ' <img alt="right-tick" src="/assets/img/Upload completed.png"> </span>';
                  });
                  this.questionAnswer[i].question = arr
                    .join("")
                    .split("?")
                    .join(".");
                }
              }
              else {
                
                if (this.questionAnswer[i].subQuestions) {
                  this.questionAnswer[i].subQuestions.forEach((question, i) => {
                    const type = question.questionType;
                    
                    // Handle standard question types (not "Fill In The Blanks", "Descriptive", or "Match The Following")
                    if (type !== "Fill In The Blanks" && type !== "Descriptive" && type !== "Match The Following") {
                      // Split the answer string into an array
                      this.questionAnswerSave[i] = question.answer.split(",");

                      // Map the array values to an object with keys a, b, c, d
                      const obj = {
                        a: this.questionAnswerSave[i][0],
                        b: this.questionAnswerSave[i][1],
                        c: this.questionAnswerSave[i][2],
                        d: this.questionAnswerSave[i][3],
                      };

                      // Replace the original answer with the object
                      question.answer = obj;
                    }

                    

                    // Handle "Fill In The Blanks" type
                    if (type === "Fill In The Blanks" && Object.keys(question.answer).length !== 0) {
                      let tempArray = [];
                      
                      tempArray = question.answer;
                    
                      this.fillInTheBlanksQuesn = '';
                      this.fillInTheBlanksQuesn = question.question;
                      let arr = [];
                      arr = this.fillInTheBlanksQuesn.split(/#blank#/g);
                    
                      // Process each key-value pair in tempArray
                      Object.keys(tempArray).forEach((key, j) => {
                        const valueArray = tempArray[key].Value; // Access the Value array
                    
                        if (valueArray.length > 1) {
                          tempArray[key].Value = valueArray.join(' / ');
                        } else {
                          tempArray[key].Value = valueArray[0]; // Single element in the array
                        }
                    
                        arr[j] = arr[j] + `<span class="blank-ans">${tempArray[key].Value} <img alt="right-tick" src="/assets/img/Upload completed.png"></span>`;
                      });
                    
                      this.fillInTheBlanksQuesn = arr.join('').split('?').join('.');
                    }
                    
                  });
                }

              }
            }
          } else {
          }
        },
        (error) => {
          this.errorMsg = error.error.message;
        }
      );
  }

  getOptionText(optionObj, optionType) {
    return myExtObject.getMTFOptionText(optionObj, optionType);
  }

  getOptionKey(optionObj, optionType) {
    return myExtObject.getMTFOptionKey(optionObj, optionType);
  }

  getReviewDetails(data: any) {
    this.isReviewLoading = false;
    if (this.isReviewLoading) {
      return;
    }
    if (typeof data === "boolean") {
      this.fromList = data;
    }
    if (data === true) {
      this.reviewId =
        this.candidateReportResponse[
          this.candidateIndex
        ].candidateReviewAssessmentDetails[0].assessmentId;
      this.countryCode = this.countryCodeNum;
    } else {
      this.reviewId = data.candidateReviewAssessmentDetails[0].assessmentId;
      this.mobileNum = data.mobile;
      this.countryCode = data.countryCode;
    }
    const payload = {
      assessmentId: this.reviewId,
      linkId: this.addLinkID,
      mobile: this.mobileNum,
      countryCode: this.countryCode,
    };
    this.reviewDetails = [];
    this.descriptiveMarks = [];
    this.emptyArray = [];
    this.remarkDetails = [];
    this.commentedBy = "";
    this.reviewComment = "";
    if (this.reviewId !== "") {
      this.assessmentService
        .getDescriptiveQuestions(payload, this.companyId, this.accessToken)
        .subscribe(
          (response) => {
            if (response.descriptiveQuestions !== null) {
              if (
                response.remarkedDetails &&
                response.remarkedDetails.length > 0
              ) {
                this.remarkDetails = response.remarkedDetails;
                this.remarkDetails = this.remarkDetails.reverse();
                this.commentedBy = response.remarkedBy;
              }

              this.reviewDetails = response.descriptiveQuestions;
              this.formatAnswer(this.reviewDetails);

              this.reviewDetails.forEach((val, j) => {
                if (val.answer === "") {
                  this.descriptiveMarks[j] = {
                    questionId: val.questionId,
                    marks: val.adminScore,
                  };

                  this.emptyArray.push(val);
                } else {
                  this.descriptiveMarks[j] = {
                    questionId: val.questionId,
                    marks: val.adminScore,
                  };
                  if (val.adminScore >= 0) {
                    this.descriptiveMarks[j] = {
                      questionId: val.questionId,
                      marks: val.adminScore,
                    };
                  }
                  if (val.adminScore === "") {
                    this.isEnabled = true;
                  }
                }
              });

              this.checkForLabel(this.mobileNum);
              $("#reviewModal").modal("show");
              this.isReviewLoading = true;
            } else {
              $("#reviewModal").modal("hide");
            }
          },
          (error) => {
            this.errorMsg = error.error.message;
            $("#reviewModal").modal("hide");
          }
        );
    }
  }

  formatAnswer(data) {
    this.formattedAns = [];
    if (data !== undefined) {
      data.forEach((str) => {
        if (str.answer !== null) {
          const formatStr = str.answer
            .split("\n")
            .join("<br>")
            .split(" ")
            .join("&nbsp;");
          this.formattedAns.push(formatStr);
        }
      });
    }
  }

  marksScored(e, idx) {
    if (e.target.value !== "" && e.target.value !== " ") {
      this.descriptiveMarks[idx]["marks"] = e.target.value;
    }
  }

  validateNumber(e, idx) {
    if (
      $.inArray(e.keyCode, [46, 8, 9, 27, 13, 110]) !== -1 ||
      (e.keyCode === 65 && e.ctrlKey === true) ||
      (e.keyCode >= 35 && e.keyCode <= 39)
    ) {
      return;
    }
    // Ensure that it is a number and stop the keypress
    if (
      ((e.shiftKey || e.keyCode < 48 || e.keyCode > 57) && e.keyCode < 96) ||
      (e.keyCode !== 190 && e.keyCode > 105) ||
      (e.keyCode > 105 && e.shiftKey)
    ) {
      e.preventDefault();
    }
  }

  restrictMax(e, idx) {
    const num = e.target.value.split(".");
    if (num[0] === "") {
      this.maxLength = 3;
    } else if (num[0].length > 1) {
      this.maxLength = 5;
    } else {
      this.maxLength = 4;
    }
    if (e.target.value === "") {
      this.isEnabled = true;
    } else {
      this.isEnabled = false;
      this.checkForEmpty();
    }

    if (e.target.value > Number(this.reviewDetails[idx].questionScore)) {
      e.target.value = "";
      this.descriptiveMarks[idx]["marks"] = "";
      this.isEnabled = true;
      this.checkForEmpty();
    }
  }

  checkForEmpty() {
    this.descriptiveMarks.forEach((val, k) => {
      if (this.reviewDetails[k].answer !== "" && val.marks === "") {
        this.isEnabled = true;
      }
    });
  }

  publishResult() {
    this.isReviewLoading = false;
    if (this.isReviewLoading) {
      return;
    }
    const payload = {
      linkId: this.addLinkID,
      mobile: this.mobileNum,
      countryCode: this.countryCode,
      assessmentId: this.reviewId,
      adminScore: this.descriptiveMarks,
      isMarkAsEvaluated: true,
      status: this.statusLabel,
      remark: this.reviewComment,
    };
    this.assessmentService
      .saveDescriptiveScore(payload, this.companyId, this.accessToken)
      .subscribe(
        (response) => {
          $("#reviewModal").modal("hide");
          this.toastrService.success(response.Response);
          this.isReviewLoading = true;
          if (!this.fromList) {
            this.candidateReportList(this.linkData, true);
          }
          this.reviewComment = "";
        },
        (error) => {
          this.errorMsg = error.error.message;
          $("#reviewModal").modal("hide");
          this.reviewComment = "";
        }
      );
  }

  reviewCancel() {
    $("#reviewModal").modal("hide");
    this.descriptiveMarks = [];
    this.reviewDetails = [];
  }

  sectionDetails() {
    window.scroll(0, 0);
    if (document.getElementById("Question") != null) {
      document.getElementById("Question").style.color = "#0ec2d0";
      document.getElementById("invites").style.color = "";
      document.getElementById("Details").style.color = "";
      document.getElementById("config").style.color = "";
      document.getElementById("Details").style.borderBottom = "";
      document.getElementById("invites").style.borderBottom = "";
      document.getElementById("Question").style.borderBottom = "2px solid";
      document.getElementById("config").style.borderBottom = "";
    }
    this.assessmentDescriptionDash = true;
    this.assessmentData = false;
    this.assessmentCandidatesDetails = false;
    this.CandidateReportDetails = false;
    this.showAssessemt = false;
    this.assessmentList1 = false;
    this.asseementQuestion = true;
    this.showSectionQuestion = false;
    this.assessmentConfigurationDash = false;
    this.assessmentListOfCandidates = false;
    localStorage.removeItem("fromInviteComp");
  }

  assessmentDetailsHeader() {
    window.scroll(0, 0);
    if (document.getElementById("Details") != null) {
      document.getElementById("Details").style.color = "#0ec2d0";
      document.getElementById("invites").style.color = "";
      document.getElementById("Question").style.color = "";
      document.getElementById("config").style.color = "";
      document.getElementById("Details").style.borderBottom = "2px solid";
      document.getElementById("invites").style.borderBottom = "";
      document.getElementById("Question").style.borderBottom = "";
      document.getElementById("config").style.borderBottom = "";
    }
    this.assessmentDescriptionDash = true;
    this.assessmentCandidatesDetails = false;
    this.CandidateReportDetails = false;
    this.showAssessemt = false;
    this.assessmentList1 = false;
    this.assessmentData = true;
    this.asseementQuestion = false;
    this.showSectionQuestion = false;
    this.assessmentConfigurationDash = false;
    this.assessmentListOfCandidates = false;
    localStorage.removeItem("fromInviteComp");
  }

  aseessmentConfigHeader() {
    window.scroll(0, 0);
    if (document.getElementById("config") != null) {
      document.getElementById("config").style.color = "#0ec2d0";
      document.getElementById("invites").style.color = "";
      document.getElementById("Details").style.color = "";
      document.getElementById("Question").style.color = "";
      document.getElementById("Details").style.borderBottom = "";
      document.getElementById("invites").style.borderBottom = "";
      document.getElementById("Question").style.borderBottom = "";
      document.getElementById("config").style.borderBottom = "2px solid";
    }
    this.assessmentDescriptionDash = true;
    this.assessmentCandidatesDetails = false;
    this.showAssessemt = false;
    this.assessmentList1 = false;
    this.assessmentData = false;
    this.asseementQuestion = false;
    this.showSectionQuestion = false;
    this.assessmentListOfCandidates = false;
    this.CandidateReportDetails = false;
    this.assessmentConfigurationDash = true;
    localStorage.removeItem("fromInviteComp");
  }

  listofInvitationLink(check, searchFlag?: boolean) {

    this.ngxService.start();
    if (!(searchFlag === undefined)) {
      this.searchFlag = searchFlag;
    }


    // to reset the  value of search and search tags in both invite list and candidate list
    if (!this.searchFlag) {
      this.searchText = "";
      this.searchText1 = "";
      this.searchTextTag = "";
      this.candidateSearchText = "";
      this.candidateSearchText1 = "";
      this.candidateSearchTextTag = "";
      this.assessmentService.setCandidateSearchText(this.candidateSearchText);
    }
    //***************************************************/

    if (this.isRequestInProcess) {
      return;
    }
    if (check === false) {
      this.skipforLinkList++;
    } else {
      this.skipforLinkList = 0;
      this.listofLinkDetails = [];
    }
    if (localStorage.getItem("fromInviteComp") === "FromInviteComponent") {
      setTimeout(() => {
        if (document.getElementById("invites") != null) {
          document.getElementById("Details").style.color = "";
          document.getElementById("Details").style.borderBottom = "";
          document.getElementById("invites").style.color = "#0ec2d0";
          document.getElementById("invites").style.borderBottom = "2px solid";
        }
      }, 2000);
    }
    if (document.getElementById("invites") != null) {
      document.getElementById("invites").style.color = "#0ec2d0";
      document.getElementById("invites").style.borderBottom = "2px solid";
      document.getElementById("Details").style.color = "";
      document.getElementById("Details").style.borderBottom = "";
      document.getElementById("Question").style.color = "";
      document.getElementById("config").style.color = "";
      document.getElementById("Question").style.borderBottom = "";
      document.getElementById("config").style.borderBottom = "";
    }
    this.assessmentDescriptionDash = true;
    this.assessmentCandidatesDetails = false;
    this.CandidateReportDetails = false;
    this.assessmentData = false;
    this.showAssessemt = false;
    this.assessmentList1 = false;
    this.asseementQuestion = false;
    this.showSectionQuestion = false;
    this.assessmentConfigurationDash = false;
    this.assessmentListOfCandidates = true;
    const payload = {
      referenceId: this.createdassessmentId,
      searchText: this.searchText,
    };
    this.isRequestInProcess = true;

    this.assessmentService
      .listofInvitationLink(
        payload,
        this.companyId,
        this.accessToken,
        this.skipforLinkList
      )
      .subscribe(
        (response) => {

          this.isRequestInProcess = false;

          this.listOfLinkForAssessment = response.isMoreList;
          let listofLink = [];
          listofLink = response.message;

          localStorage.setItem("IsDescriptive", listofLink[0].isDescriptive);
          this.listofLinkDetails = this.listofLinkDetails.concat(listofLink);

          this.publicLinkcreateion = response.linkUrl;
          this.listMessage = true;
          // this.skipforLinkList++;
          this.listErrMessage = false;
          this.selectInviteSection();
          this.ngxService.stop();
        },
        (error) => {
          this.ngxService.stop();
          this.listofLinkDetails = [];
          this.isRequestInProcess = false;
          this.errorMsg = error.error.message;

          if (
            error.error["message"] === "No matching batch found" &&
            this.searchText != ""
          ) {
            this.noDataFoundFlag = true;
            // this.assessmentNumber=0;
            this.listOfLinkForAssessment = false;
            // this.totalCount=''
            this.inviteErr = error.error.message;
            return;
          } else if (
            error.error["message"] ===
            "Text should not be less than 3 characters." &&
            this.searchText != ""
          ) {
            this.lessChar = true;
            // this.assessmentNumber=0;
            this.listOfLinkForAssessment = false;
            // this.totalCount=''
            return;
          } else {
            this.listMessage = false;
            this.listErrMessage = true;
          }
        }
      );
  }

  resetSearch() {
    this.searchTextTag = "";
    this.searchText = "";
    this.listofLinkDetails = [];
    this.listofInvitationLink(true);
    this.noDataFoundFlag = false;
    this.lessChar = false;
    this.searchFlag = false;
  }

  async resetCandidateSearch() {
    this.candidateSearchTextTag = "";
    this.candidateSearchText = "";
    this.candidateReportResponse = [];
    this.assessmentService.setCandidateSearchText(this.candidateSearchText);

    await this.candidateReportList(this.linkData, true);
    this.noDataFoundFlag = false;
    this.searchFlag = false;
    this.listOfCan.ngOnInit();
  }

  copyLink() {
    const copyText = document.getElementById("linkGener") as HTMLInputElement;
    copyText.select();
    document.execCommand("copy");
  }

  selectInviteSection() {
    if (document.getElementById("invites") != null) {
      document.getElementById("invites").style.color = "#0ec2d0";
      document.getElementById("Details").style.color = "";
      document.getElementById("Question").style.color = "";
      document.getElementById("config").style.color = "";
      document.getElementById("Details").style.borderBottom = "";
      document.getElementById("invites").style.borderBottom = "2px solid";
      document.getElementById("Question").style.borderBottom = "";
      document.getElementById("config").style.borderBottom = "";
    }
  }

  listAssessment(assType) {
    const type = assType.toLowerCase();
    this.router.navigate(["assessment/list/" + type]);
  }

  assementType() {
    const payload = {
      type: "assessmentType",
      assessmentType: "",
    };
    this.assessmentService
      .getLevelOfAssess(payload, this.companyId, this.accessToken)
      .subscribe(
        (response) => {
          this.assessmentTypeList = response;
        },
        (error) => {
          console.log(error.error.message);
        }
      );
  }

  getLevelOfAssess() {
    const payload = {
      type: "level",
      assessmentType: "",
    };
    this.assessmentService
      .getLevelOfAssess(payload, this.companyId, this.accessToken)
      .subscribe(
        (response) => {
          this.levelOfQuestion = response;
        },
        (error) => {
          console.log(error.error.message);
        }
      );
  }

  getTagsForLink() {
    const payload = {
      type: "tagsForLink",
      assessmentType: "",
    };
    this.assessmentService
      .getLevelOfAssess(payload, this.companyId, this.accessToken)
      .subscribe(
        (response) => {
          this.tagsForLink = response;
        },
        (error) => {
          console.log(error.error.message);
        }
      );
  }

  getQuestionType() {
    const payload = {
      type: "questionType",
      assessmentType: "",
    };
    this.assessmentService
      .getLevelOfAssess(payload, this.companyId, this.accessToken)
      .subscribe(
        (response) => {
          this.typeOfQuestion = response;
        },
        (error) => {
          // console.log(error.error.message);
        }
      );
  }

  editAssessment(id: any) {

    if (this.assessmentDetails.quickAssessmentTemplateId) {
      this.router.navigate(["assessment/update-quick-assessment/" + id]);
    }
    else {
      this.router.navigate(["assessment/update/" + id]);
    }
  }

  backLocation() {
    this._location.back();
  }

  deleteAssessment(assId: any, check) {
    $("#deleteSingleAssessmentData").modal("show");
    this.assessmentIdDelete = assId;
  }

  deleteAssessmentDATA(check) {
    const payload = { arrayData: [this.assessmentIdDelete] };
    this.assessmentService
      .assessmentDelete(payload, this.companyId, this.accessToken)
      .subscribe(
        (response) => {
          this.assessmentDescriptionDash = false;
          this.assessmentIdDelete = "";
          this.router.navigate(["/assessment/list"]);
          if (check === true) {
            this.toastrService.success("Assessment deleted successfully", "", {
              timeOut: 5000,
            });
          } else {
            this.toastrService.success("Assessment archived successfully", "", {
              timeOut: 5000,
            });
          }
        },
        (error) => {
          this.errorMsg = error.error.message;
        }
      );
  }

  addPreviewCandidates() {
    this.router.navigate([
      "assessment/publish-link/" + this.createdassessmentId,
    ]);
    localStorage.setItem("isAddCandidate", "AddNewCandidate");
  }

  downloadLocation() {
    const payload = { linkId: this.CandidatelinkID };
    this.assessmentService
      .exportCandidateExcel(payload, this.companyId, this.accessToken)
      .subscribe(
        (response) => {
          this.candidateLocation = response.location;
          localStorage.setItem("location", "this.candidateLocation");
          window.open(this.candidateLocation, "_blank");
        },
        (error) => {
          this.errorMsg = error.error.message;
        }
      );
  }

  searchBatch() {


    if (!this.searchText1.replace(/\s/g, '').length) {
      this.searchText1 = "";
      return;
    }
    if (this.searchText1.charAt(0) == " " || this.searchText1.charAt(this.searchText1.length - 1) == " ") {
      this.searchText1 = this.searchText1.trim();

    }
    if (this.searchText1.length < 3) {
      return;
    }

    this.searchText = this.searchText1;
    this.searchTextTag = this.searchText;
    this.searchFlag = true;
    this.noDataFoundFlag = false;

    this.listofInvitationLink(true);
    this.lessChar = false;
    this.searchText1 = "";
  }

  noInvitaionForAssesment() {
    this.router.navigate([
      "assessment/publish-link/" + this.createdassessmentId,
    ]);
  }

  createNewAssessment() {
    var assessmentType = localStorage.getItem("_assessmentType");

    if (assessmentType == "Aptitude" || assessmentType == "Psychometer") {
      if (!this.masterQBAccess) {
        $("#AccessDenied").modal("show");
      } else {
        this.router.navigate(["assessment/create"]);
      }
    } else if (assessmentType == "Role Based" || assessmentType == "Skill Based" || assessmentType == "POSH") {
      this.router.navigate(["/skill-based-assessment"])
    } else {
      // localStorage.setItem("_assessmentType", params.type);
      this.router.navigate(["assessment/create"]);
    }

    // this.router.navigate(['assessment/create/']);
  }

  testCodeGeneration() {
    this.assessmentService
      .testCodeGenerate(this.companyId, this.accessToken)
      .subscribe(
        (response) => {
          console.log(response.message);
          this.Testcode = response.message;
        },
        (error) => {
          this.errorMsg = error.error.message;
        }
      );
  }

  ViewAccessCode(data, flag: boolean) {
    this.appendAccessCodeFlag = flag;
    this.Testcode = data.testCode;
    this.dispPublicLinkCreation= this.publicLinkcreateion +'&batchtoken='+this.Testcode

    if (localStorage.getItem("createdLinkId") !== null) {
      $("#publicLink").modal("show");
    }
    $("#publicLink").modal("show");

    // this.listofInvitationLink(true);
    localStorage.removeItem("createdLinkId");
   
  }

  deleteLink(linkID) {
    $("#deleteLink").modal("show");
    this.linkIdDeleted = linkID;
  }

  deleteLinkMessage() {
    const payload = { linkId: this.linkIdDeleted };
    this.assessmentService
      .deleteLink(payload, this.companyId, this.accessToken)
      .subscribe(
        (response) => {
          this.listofInvitationLink(true);
          this.toastrService.success("Link archived successfully", "", {
            timeOut: 5000,
          });
          this.linkIdDeleted = "";
        },
        (error) => {
          this.errorMsg = error.error.message;
        }
      );
  }

  checkForLabel(mobile: any) {
    this.candidateResponseDesc.forEach(
      (val: { mobile: any; candidateReviewAssessmentDetails: any[] }) => {
        if (
          mobile === val.mobile &&
          val.candidateReviewAssessmentDetails != null
        ) {
          val.candidateReviewAssessmentDetails.forEach((review) => {
            if (this.reviewDetails.length === this.emptyArray.length) {
              this.markBtnLabel = "Mark As Evaluated";
              this.statusLabel = "";
              this.showCommentSecion = false;
              if (review.isReviewAnswer === "false") {
                this.isEnabled = true;
              } else {
                this.isEnabled = false;
              }
            } else if (review.isReviewAnswer === "true") {
              this.markBtnLabel = "Evaluate";
              this.statusLabel = "";
              this.showCommentSecion = false;
              this.isEnabled = false;
              this.checkForEmpty();
            } else {
              this.markBtnLabel = "Re-Evaluate";
              this.statusLabel = "Re-Evaluate";
              this.showCommentSecion = true;
              this.isEnabled = true;
            }
          });
        }
      }
    );
  }

  async candidateReportList(data, check) {
    // this.listOfCan.isFetchingCandidateList=true;
    // this.listOfCan.ngOnInit();
    this.ngxService.start();
    this.linkData = data;

    this.assessmentService.setConfigurationData(data.configuration);
    if (this.isRequestInProcess) {
      return;
    }
    this.candidateListValue = data;
    this.CandidatelinkName = data.linkName;
    this.CandidatelinkID = data._id;
    this.addLinkID = data._id;
    this.CandidatelinkType = data.linkType;
    this.CandidateTrackLocation = data.configuration.trackGeoLocation;
    this.CandidateSnap = data.configuration.captureCandidateSnapshot;
    this.CandidateStartDT = data.configuration.startDate;
    this.CandidateEndDT = data.configuration.endDate;

    this.assessmentService.setLinkId(this.CandidatelinkID);

    if (check === false) {
      this.skipforCan++;
    } else {
      this.skipforCan = 0;
      this.candidateReportResponse = [];
    }

    const payload = {
      linkId: this.CandidatelinkID,
      searchText: this.candidateSearchText,
    };
    this.isRequestInProcess = true;
    if (this.CandidatelinkType === "PRIVATE") {
      localStorage.setItem("isAddLinkId", this.addLinkID);
    }
    this.candidateResponseDesc = [];
    await this.assessmentService
      .candidateReport(
        payload,
        this.skipforCan,
        this.companyId,
        this.accessToken
      )
      .toPromise()
      .then(
        async (response) => {

          this.isRequestInProcess = false;
          this.showCandidatelinkName = true;
          this.noCandidateFound = false;
          this.candidateResponseDesc = response.results;

          this.checkForLabel(this.mobileNum);
          this.candidateLocation = response.location;
          this.candidateListForLink = await response.isMoreCandidates;

          localStorage.setItem("location", "this.candidateLocation");
          let listofcan = [];
          listofcan = response.results;

          await this.assessmentService.setBatchData(response);
          this.candidateReportResponse =
            this.candidateReportResponse.concat(listofcan);
          this.assessmentListOfCandidates = false;
          this.assessmentCandidatesDetails = true;
          this.CandidateReportDetails = false;
          this.assessmentData = false;
          this.showAssessemt = false;
          this.assessmentList1 = false;
          this.asseementQuestion = false;
          this.showSectionQuestion = false;
          this.assessmentConfigurationDash = false;
          this.privateLinkCreationStep1 = false;
          this.privateLinkCreationStep2 = false;
          this.publicLinkCreation = false;
        },
        (error) => {
          this.ngxService.stop();
          this.isRequestInProcess = false;
          this.toastrService.info(
            "No candidate found under the selected batch."
          );
          this.noCandidateFound = true;
          this.assessmentService.setBatchData(null);
          this.candidateListForLink = false;
          this.noCandidateFound = true;
          this.CandidateErr = error.error.message;

        }
      );
  }

  candidateSnapcaptured(data) {
    this.trackGeoLocation = data[0].assessmentVersions[0].trackGeo;
    const startLat = parseFloat(
      data[0].assessmentVersions[0].trackGeo.startLocation.latitude
    );
    const startLng = parseFloat(
      data[0].assessmentVersions[0].trackGeo.startLocation.longitude
    );
    myExtObject.initMap3(startLat, startLng);
    $("#popUp").modal("show");
  }

  validateTime(e) {
    if (
      $.inArray(e.keyCode, [46, 8, 9, 27, 13, 110]) !== -1 ||
      (e.keyCode === 65 && e.ctrlKey === true) ||
      (e.keyCode >= 35 && e.keyCode <= 39)
    ) {
      return;
    }
    // Ensure that it is a number and stop the keypress
    if (
      (e.shiftKey || e.keyCode < 48 || e.keyCode > 57) &&
      (e.keyCode < 96 || e.keyCode > 105)
    ) {
      e.preventDefault();
    }
  }

  showLocation(data) {

    this.startTimeMap = data[0].assessmentVersions[0].appearedOn;
    this.endTimeMap = data[0].assessmentVersions[0].endedOn;
    this.trackGeoLocation = data[0].assessmentVersions[0].trackGeo;
    const startLat = parseFloat(
      data[0].assessmentVersions[0].trackGeo.startLocation.latitude
    );
    const startLng = parseFloat(
      data[0].assessmentVersions[0].trackGeo.startLocation.longitude
    );
    const endLat = parseFloat(
      data[0].assessmentVersions[0].trackGeo.endLocation.latitude
    );
    const endLng = parseFloat(
      data[0].assessmentVersions[0].trackGeo.endLocation.longitude
    );
    myExtObject.initMap1(startLat, startLng);
    myExtObject.initMap2(endLat, endLng);
    $("#candidatesLocation").modal("show");
  }

  redirectUrl(data) {
    if (data !== "") {
      window.open(data);
    } else {
    }
  }

  backButtonForLink() {
    this.fromList = false;
    this.candidateSearchText = "";
    this.candidateSearchText1 = "";
    this.candidateSearchTextTag = "";
    if (this.assessmentCandidatesDetails) {
      this.assessmentCandidatesDetails = false;
      this.assessmentListOfCandidates = true;
    }
    if (this.CandidateReportDetails) {
      this.CandidateReportDetails = false;
      this.assessmentCandidatesDetails = true;
    }
  }

  editLink(data) {
    localStorage.setItem(
      "_createdassessmentType",
      data.assessments[0].assessmentType
    );
    // CAL-1681, Job scenario is not considered
    localStorage.setItem("_isEditLink", "true");

    // CAL-1585, impact : Edit Public Link | If not set to 'true', different 'Access Code' get displayed in the screen.
    this.router.navigate(["assessment/editlink/" + data["_id"]]);
  }

  resetDateTimeScheduling() {
    this.publicerrorMsg = "";
    this.publicStartTime = "";
    this.publicSTARTDate = "";
    this.publicENDDate = "";
    this.publicEndTime = "";
    $("#startTime").val("");
    $("#endTime").val("");
  }

  CandidateResetTest() {
    $("#candidateReset").modal("show");
  }

  CandidateRRReson(modal: any) {
    this.publicerrorMsg = "";
    const payload = {
      linkId: this.CandidatelinkID,
      mobile: this.mobileNum,
      countryCode: this.countryCodeNum,
      status: modal.reset,
      assessmentId: this.createdassessmentId,
      reason: modal.resetReason,
    };
    this.assessmentService
      .CandidateRRReson(payload, this.companyId, this.accessToken)
      .subscribe(
        (response) => {
          this.modalResetCandidate.reset();
          $("#candidateReset").modal("hide");
          this.toastrService.success(response.message, "", {
            timeOut: 5000,
          });
        },
        (error) => {
          this.publicerrorMsg = error.error.message;
        }
      );
  }

  cadidatesReports(data, status, idx) {
    if (status === "Not Taken" || status === "Expired") {
      return;
    }
    this.fromList = true;
    this.candidateIndex = idx;
    this.CandidateData = data;
    this.candidateName = data.candidateName;
    this.candidateAttendanceSnapshot = "";
    this.mobileNum = data.mobile;
    this.countryCodeNum = data.countryCode;
    if (this.candidateReportResponse.length > 0) {
      this.showDescriptiveIcon =
        this.candidateReportResponse[idx].candidateReviewAssessmentDetails[0];
    }
    const payload = {
      linkId: this.CandidatelinkID,
      mobile: this.mobileNum,
      countryCode: this.countryCodeNum,
    };
    this.assessmentService
      .cadidatesReports(payload, this.companyId, this.accessToken)
      .subscribe(
        (response) => {
          this.CandidateReportData = response.results;

          const len = this.CandidateReportData[0].assessmentVersions.length;
          this.candidateAttendanceSnapshot =
            this.CandidateReportData[0].assessmentVersions[
              len - 1
            ].attendanceSnapshot;
          if (
            this.CandidateReportData[0].assessmentVersions[len - 1]
              .candidateSnapShot == null
          ) {
            this.candidateCaputerdSnapshot = [];
          } else {
            this.candidateCaputerdSnapshot =
              this.CandidateReportData[0].assessmentVersions[
                len - 1
              ].candidateSnapShot;
          }
          this.assessment_name = this.CandidateReportData[0].assessmentName;
          this.candidateSuspiciousCount =
            this.CandidateReportData[0].assessmentVersions[0].suspiciousCount;
          this.candidateFaceViolationCount =
            this.CandidateReportData[0].assessmentVersions[0].faceViolationCount;
          this.candidateTimeSpentForAssessment =
            this.CandidateReportData[0].assessmentVersions[0].totalTimeSpentForAssessment;
          this.assessmentCandidatesDetails = false;
          this.CandidateReportDetails = true;
          this.assessmentDescriptionDash = true;
          this.assessmentData = false;
          this.showAssessemt = false;
          this.assessmentList1 = false;
          this.asseementQuestion = false;
          this.showSectionQuestion = false;
          this.assessmentConfigurationDash = false;
          this.privateLinkCreationStep1 = false;
          this.privateLinkCreationStep2 = false;
          this.publicLinkCreation = false;
        },
        (error) => {
          this.errorMsg = error.error.message;
          this.CandidateReportDetails = false;
        }
      );
  }

  bulkReportDownload() {
    const payload = {
      linkId: this.CandidatelinkID,
    };
    this.assessmentService
      .bulkReportData(payload, this.companyId, this.accessToken)
      .subscribe(
        (response: any) => {
          // window.open(response.s3Location, '_blank');
          window.location = response.s3Location;
        },
        (error) => {
          let errorMsg = "";
          if (error.status === 500) {
            errorMsg =
              "Unable to process the request at this moment. Please try again later.";
          } else {
            errorMsg = error.error.message;
          }
          this.toastrService.error(errorMsg, "Error", {
            timeOut: 5000,
          });
        }
      );
  }

  getBatchAssignmentConfirmation() {
    let selectedEvaluatorNames = [];
    if (selectedEvaluatorNames.length == 1) {
      this.assignEvaluatorConfirmationMessage =
        selectedEvaluatorNames[0] +
        " will be added as evaluator in " +
        this.selectedBatch.linkName +
        ". \nDo you want to continue?";
    } else {
      this.assignEvaluatorConfirmationMessage =
        selectedEvaluatorNames.join(", ") +
        " will be added as evaluators in " +
        this.selectedBatch.linkName +
        ". \nDo you want to continue?";
    }

    $("#assignedPersonalModal").modal("hide");
    $("#confirmationEvaluator").modal("show");
  }

  confirmActionAssigneeRemoval(removalType: string, assigneeRecord: any) {
    this.removeAssigneeActionType = removalType;
    $("#assignedPersonalModal").modal("hide");
    this.selectedAssigneeDetails = assigneeRecord;
    if (removalType == "all") {
      this.unlinkAssigneeConfirmationMessage =
        "Sure!! Do you want to unlink all assignees from " +
        this.selectedBatch.linkName +
        "?";
    } else if (removalType == "individual") {
      this.unlinkAssigneeConfirmationMessage =
        "Sure!! Do you want to unlink " +
        this.selectedAssigneeDetails.userName +
        " from " +
        this.selectedBatch.linkName +
        "?";
    } else {
      return;
    }
    $("#confirmAssigneeRemoval").modal("show");
    this.showCenterManagerChkBx = true;
  }

  cancelUnlinking() {
    $("#confirmAssigneeRemoval").modal("hide");
  }

  fetchAvailableAdministrators() {
    const payload = {
      linkId: this.selectedBatch._id,
    };

    this.assessmentService
      .availableAssigneeList(payload, this.accessToken, this.companyId)
      .subscribe(
        (response) => {
          let availableAssignees = response.user_list;
          if (availableAssignees.length == 0) {
            this.availableAssignees = [];
          } else {
            this.availableAssignees = [];
            this.selAssigneeId = "";
            this.assigneeRole.evaluate = false;
            this.assigneeRole.proctor = false;
            this.assigneeRole.centerManager = false;
            this.hideSelectAssigneeOption = false;
            for (
              let assigneeIndex = 0;
              assigneeIndex < availableAssignees.length;
              assigneeIndex++
            ) {
              this.availableAssignees.push({
                assigneeId: availableAssignees[assigneeIndex]._id,
                assigneeName:
                  availableAssignees[assigneeIndex].firstName +
                  " " +
                  availableAssignees[assigneeIndex].lastName,
              });
            }
          }
          $("#manageAssigneePrivilegeModal").modal("show");
        },
        (error) => {
          console.log(error);
        }
      );
  }

  showAssignedPersonalsModal() {
    $("#manageAssigneePrivilegeModal").modal("hide");
    this.showAssignedPersonals(this.selectedBatch);
  }
  showAssignedPersonals(linkDetails) {
    this.selectedBatch = linkDetails;
    this.batchReferenceId = linkDetails._id;

    this.assignedTo = linkDetails.assignedTo;

    // $('#ckhEvaluator').prop('checked', this.assignedTo.role.evaluate);
    // $('#chkProctor').prop('checked', this.assignedTo.role.proctor);
    // $('#chkCenterMngr').prop('checked',this.assignedTo.role.centerManager);
    this.removeAssigneeActionType = "";
    $("#assignedPersonalModal").modal("show");
    if (this.selectedBatch.configuration.enableLockUnlock) {
      for (var i = 0; i < this.selectedBatch.assignedTo.length; i++) {
        if (this.selectedBatch.assignedTo[i].role.centerManager) {
          this.showCenterManagerChkBx = false;
          break;
        } else {
          this.showCenterManagerChkBx = true;
        }
      }
    } else {
      this.showCenterManagerChkBx = false;
    }
  }

  assignEvaluator() {
    if (this.selAssigneeId == "") {
      this.roleManagementError = "Select an assignee first";
      return;
    }
    if (
      this.assigneeRole.evaluate == false &&
      this.assigneeRole.proctor == false &&
      this.assigneeRole.centerManager == false
    ) {
      this.roleManagementError =
        "Set at least one role for the selected assignee";
      return;
    }
    this.roleManagementError = "";
    if (!this.hasDescriptiveQuestions) {
      this.assigneeRole.evaluate = false;
    }
    const payload = {
      batchReference: this.batchReferenceId,
      userReferences: this.selAssigneeId,
      role: this.assigneeRole,
    };
    this.assessmentService
      .assignRoleToAssignee(payload, this.companyId, this.accessToken)
      .subscribe(
        (response: any) => {
          $("#manageAssigneePrivilegeModal").modal("hide");

          this.listofInvitationLink(true);
          this.toastrService.success(response.message, "", {
            timeOut: 5000,
          });
        },
        (error) => {
          let errorMsg = "";
          if (error.status === 500) {
            errorMsg =
              "Unable to process the request at this moment. Please try again later.";
          } else {
            errorMsg = error.error.message;
          }
          this.toastrService.error(errorMsg, "Error", {
            timeOut: 5000,
          });
        }
      );
  }
  clearDataEval() {
    $("#assignedPersonalModal").modal("hide");
  }

  executeUnlinkingAction() {
    const payload = {
      batchReference: this.batchReferenceId,
      userReference:
        this.removeAssigneeActionType == "individual"
          ? this.selectedAssigneeDetails.userId
          : "",
      removalType: this.removeAssigneeActionType,
    };

    this.assessmentService
      .unLinkAssignee(payload, this.companyId, this.accessToken)
      .subscribe(
        (response: any) => {
          this.listofInvitationLink(true);
          this.toastrService.success(response.message, "", {
            timeOut: 5000,
          });
        },
        (error) => {
          let errorMsg = "";
          if (error.status === 500) {
            errorMsg =
              "Unable to process the request at this moment. Please try again later.";
          } else {
            errorMsg = error.error.message;
          }
          this.toastrService.error(errorMsg, "Error", {
            timeOut: 5000,
          });
        }
      );
  }

  manageAssigneePrivileges() {
    $("#assignedPersonalModal").modal("hide");
    this.fetchAvailableAdministrators();
  }

  initiateAssigneePrivilegeEdit(selAssignee: any) {
    $("#assignedPersonalModal").modal("hide");
    this.availableAssignees = [];
    this.availableAssignees.push({
      assigneeId: selAssignee.userId,
      assigneeName: selAssignee.userName,
    });
    this.selAssigneeId = selAssignee.userId;
    this.assigneeRole.evaluate = selAssignee.role.evaluate;
    this.assigneeRole.proctor = selAssignee.role.proctor;
    this.assigneeRole.centerManager = selAssignee.role.centerManager;

    if (this.assigneeRole.centerManager) {
      this.showCenterManagerChkBx = true;
    }
    this.hideSelectAssigneeOption = true;
    $("#manageAssigneePrivilegeModal").modal("show");
  }

  setAssigneeRole($event: Event, role: string) {
    this.roleManagementError = "";
    if (role == "Evaluator") {
      if ((<HTMLInputElement>$event.target).checked) {
        this.assigneeRole.evaluate = true;
      } else {
        this.assigneeRole.evaluate = false;
      }
    } else if (role == "Proctor") {
      if ((<HTMLInputElement>$event.target).checked) {
        this.assigneeRole.proctor = true;
      } else {
        this.assigneeRole.proctor = false;
      }
    } else if (role == "CenterManager") {
      if ((<HTMLInputElement>$event.target).checked) {
        this.assigneeRole.centerManager = true;
      } else {
        this.assigneeRole.centerManager = false;
      }
    } else {
      return;
    }
  }

  changeBreadCrumb(value: boolean) {
    this.showCandidatelinkName = value;
  }

  checkCenterManager(assignedTo) {
    if (assignedTo.length == 0) {
      this.noCenterManagerFlag = true;
    } else {
      for (var i = 0; i < assignedTo.length; i++) {
        if (assignedTo[i].role.centerManager == true) {
          this.setCenterMangerFlag = true;
          this.centerMngrAssignedTo = assignedTo[i].userName;
          break;
        } else {
          this.setCenterMangerFlag = false;
        }
      }
      if (this.setCenterMangerFlag) {
        return true;
      } else {
        return false;
      }
    }
  }

  async searchCandidate() {

    if (!this.candidateSearchText1.replace(/\s/g, '').length) {
      this.candidateSearchText1 = "";
      return;
    }
    if (this.candidateSearchText1.charAt(0) == " " || this.candidateSearchText1.charAt(this.candidateSearchText1.length - 1) == " ") {
      this.candidateSearchText1 = this.candidateSearchText1.trim();

    }
    if (this.candidateSearchText1.length < 3) {
      return;
    }
    this.candidateSearchText = this.candidateSearchText1;
    this.candidateSearchTextTag = this.candidateSearchText;
    this.searchFlag = true;

    this.assessmentService.setCandidateSearchText(this.candidateSearchText);
    await this.candidateReportList(this.linkData, true);

    this.candidateSearchText1 = "";
    this.listOfCan.ngOnInit();
  }
}
