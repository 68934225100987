import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { catchError, map, tap } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import 'rxjs/add/observable/throw';
import {environment} from '../../environments/environment';


@Injectable()
export class ObserverService {
  private url = '' ;
  globalUrl = environment.serverUrl;

  constructor(private http: HttpClient) { }

  fetchListOfLiveBatches(companyId: string, accessToken: any): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'bearer ' + accessToken
    });
    this.url = this.globalUrl + '/business/services/candidate/live/list?companyId=' + companyId;
    return this.http.post(this.url,  {}, {headers: headers}).pipe(
      map( response => response),
      catchError(this._errorHandler)
    );
  }
  observerSnap(companyId: string, accessToken: any, payload): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'bearer ' + accessToken
    });
    this.url = this.globalUrl + '/business/services/candidate/images/live/list?companyId=' + companyId;
    return this.http.post(this.url,  payload, {headers: headers}).pipe(
      map( response => response),
      catchError(this._errorHandler)
    );
  }

  liveBatchCandidates(companyId: string, accessToken: any, payload): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'bearer ' + accessToken
    });
    this.url = this.globalUrl + '/business/services/batch/live-candidate-list?companyId=' + companyId;
    return this.http.post(this.url,  payload, {headers: headers}).pipe(
      map( response => response),
      catchError(this._errorHandler)
    );
  }

  observerCandidateDetail(companyId: string, accessToken: any, payload): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'bearer ' + accessToken
    });
    this.url = this.globalUrl + '/business/services/candidate/updated/images/list?companyId=' + companyId;
    return this.http.post(this.url,  payload, {headers: headers}).pipe(
      map( response => response),
      catchError(this._errorHandler)
    );
  }

  completedCandList(payload, companyId, accessToken): Observable<any> {

    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'bearer ' + accessToken
    });

    this.url = this.globalUrl + '/business/services/link/candidate/list?companyId=' + companyId;
    return this.http.post(this.url, payload, {headers: headers}).pipe(
      map( response => response),
      catchError(this._errorHandler)
    );

  }

  // new services

  terminateCandidate(companyId, accessToken, payload): Observable<any> {

    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'bearer ' + accessToken
    });

    this.url = this.globalUrl + '/business/services/assessment/link/candidate/notify/terminate?companyId=' +  companyId;
    return this.http.post(this.url, payload, {headers: headers}).pipe(
      map( response => response),
      catchError(this._errorHandler)
    );

  }

  listNotification(companyId: string, accessToken: any): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'bearer ' + accessToken
    });
    this.url = this.globalUrl + '/business/services/observer/notification/list?companyId=' + companyId;
    return this.http.post(this.url,  {}, {headers: headers}).pipe(
      map( response => response),
      catchError(this._errorHandler)
    );
  }
  addNotifications(companyId, accessToken, payload): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'bearer ' + accessToken
    });
    this.url = this.globalUrl + '/business/services/observer/notification/add?companyId=' +  companyId;
    return this.http.post(this.url, payload, {headers: headers}).pipe(
      map( response => response),
      catchError(this._errorHandler)
    );
  }

  editNotifications(companyId, accessToken, payload): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'bearer ' + accessToken
    });
    this.url = this.globalUrl + '/business/services/observer/notification/edit?companyId=' +  companyId;
    return this.http.post(this.url, payload, {headers: headers}).pipe(
      map( response => response),
      catchError(this._errorHandler)
    );
  }
  deleteNotification(payload, accessToken , companyId ): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'bearer ' + accessToken
    });
    this.url = this.globalUrl + '/business/services/observer/notification/delete?companyId=' +  companyId;
    return this.http.post(this.url, payload, {headers: headers}).pipe(
      map( response => response),
      catchError(this._errorHandler)
    );
  }


  _errorHandler(error: HttpErrorResponse) {
    return throwError(error || 'Server Error');

  }
}
