import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router} from '@angular/router';
import { Location } from '@angular/common';
import { ToastrService } from 'ngx-toastr';
import { AssessmentService } from '../../assessments-module/assessment.service';

@Component({
  selector: 'app-blog-list-admin',
  templateUrl: './blog-list-admin.component.html',
  styleUrls: ['./blog-list-admin.component.css'],
  providers: [ AssessmentService ]
})
export class BlogListAdminComponent implements OnInit {
  companyId: string;
  accessToken: string;
  apiCalled: boolean;
  apiCalled1: boolean;
  blogListResponse: any;
  blogListDetailsResponse: any;

  constructor(private router: Router,
              private  assessmentService: AssessmentService,
              private route: ActivatedRoute,
              private location: Location,
              private toastr: ToastrService) {
    this.companyId = localStorage.getItem('companyId');
    this.accessToken = localStorage.getItem('accessToken');}

  ngOnInit() {
    localStorage.removeItem("_selectedBlogId");
    localStorage.removeItem("_selectedBlogStatus");
    this.blogAdminList();
  }
  goToCreateBlog(){
    this.router.navigate(['/blog/create']);

  }

  blogAdminList(){
    const payload = {};
    this.assessmentService.blogListAdminApi(payload, this.companyId, this.accessToken)
      .subscribe(
        (response:any) => {
          this.apiCalled = true;
          this.blogListResponse = response.blogList;

        }
      );
  }

  gotToBlogAdminDetails(blogRecord) {
    localStorage.setItem("_selectedBlogId", blogRecord._id);
    localStorage.setItem("_selectedBlogStatus", blogRecord.status);
    this.router.navigate(['blog/' + blogRecord.blogSlug]);
  }

  blogAdminDetails(id){
    const payload = {
      blogId:id,
    }
    this.assessmentService.blogDetailsAdminApi(payload,this.companyId, this.accessToken)
      .subscribe(
        (response:any) => {
          this.apiCalled1 = true;
          this.blogListDetailsResponse = response.blogDetails;
          this.router.navigate(['/blog/create'], {queryParams: {param: JSON.stringify(this.blogListDetailsResponse[0])}})
        }
      );
  }


  publishBlog(blogId){
    const payload = {
      blogId:blogId,
    };
    this.assessmentService.publishBlog(payload,this.companyId, this.accessToken)
      .subscribe(
        (response:any) => {
          this.toastr.success(response.message);
          this.blogAdminList();
        }
      );
  }


  archiveBlog(blogId){
    const payload = {
      blogId:blogId,
    };
    this.assessmentService.archiveBlog(payload,this.companyId, this.accessToken)
      .subscribe(
        (response:any) => {
          this.toastr.success(response.message);
          this.blogAdminList();
        }
      );
  }

  editBlog(id) {
   this.blogAdminDetails(id);
  }

}
