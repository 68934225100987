import { Component, ViewChild, OnInit, AfterViewChecked, OnDestroy, ElementRef} from '@angular/core';
import { FormControl, FormGroup, Validators,ReactiveFormsModule } from '@angular/forms';
import { RegisterService } from './register.service';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { Router } from '@angular/router';
import '../externalJs/external.js';
declare let myExtObject: any;
import * as $ from 'jquery';
import {PhoneNumberComponent} from 'ngx-international-phone-number2';
import { ReCaptcha2Component} from 'ngx-captcha';
// import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-register-comp',
  templateUrl: './register-comp.component.html',
  styleUrls: ['./register-comp.component.css'],
  styles : ['input.ng-touched.ng-invalid{border: 1px solid red}'],
  providers : [RegisterService]
})

export class RegisterCompComponent implements OnInit, AfterViewChecked, OnDestroy {
  siteKey= '6Lf0LT8gAAAAAIIhOK2L0Gqy_FwmQ7UhZocxDYXQ';
  registerForm: FormGroup;
  serverErrMessage: string ;
  accessToken: string;
  companyId: string;
  mobile = '';
  countryCode = '';
  companyname = '';
  phoneData = '';
  showMinimumError: boolean;
  public captchaIsLoaded = false;
  public captchaSuccess = false;
  public captchaIsExpired = false;
  public captchaResponse?: string;
  site="6LcsKWsgAAAAAGh2byz74eqrMIQdHE0VoEVCqiVz"
   theme: 'light' | 'dark' = 'light';
   size: 'compact' | 'normal' = 'normal';
   lang = 'en';
   type: 'image' | 'audio';
  @ViewChild('app-register-comp') reaModal: ModalDirective;
  @ViewChild('myInput') phoneNumberInput: PhoneNumberComponent;
  @ViewChild('captchaElem') captchaElem: ReCaptcha2Component;
  @ViewChild('langInput') langInput: ElementRef;
 
  constructor(public router: Router, public registerService: RegisterService,) {
  }

  ngOnInit() {
    document.body.classList.add('registration_login_bg_image');
    this.registerForm = new FormGroup({
      companyName : new FormControl('', [Validators.required, Validators.minLength(3), Validators.maxLength(265), Validators.pattern('^[ A-Za-z0-9_@.+-]*$')]),
      firstName : new FormControl('', [Validators.required, Validators.minLength(1), Validators.maxLength(101),
        Validators.pattern('^[a-zA-Z\'.-]+[a-zA-Z\'. ]*$')]),
      lastName : new FormControl('', [Validators.minLength(1), Validators.maxLength(101),
        Validators.pattern('^[a-zA-Z\'.-]+[a-zA-Z\'. ]*$')]),
      contactNumber : new FormControl(''),
      email: new FormControl('', [Validators.required, Validators.pattern('^(([^<>()\\[\\]\\\\.,;:\\s@\"]+(\\.[^<>()\\[\\]\\\\.,;:\\s@\"]+)*)|(\".+\"))@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\])|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,}))$')]),
      companyUrl : new FormControl(),
      companyType: new FormControl(),
      recaptcha: new FormControl('',[Validators.required]),
    });
    // this.reCaptchaV3Service.execute(this.siteKey, 'homepage', (token) => {
    //   console.log('This is your token: ', token);
    // }, {
    //     useGlobalDomain: false
    // });

  }

   // reset(): void {
  //   this.captchaElem.resetCaptcha();
  // }
  handleSuccess(data) {
  }

  ngAfterViewChecked(): void {
    const mobileNumberField = document.getElementById('primaryPhoneInput');
    if (mobileNumberField !== null && mobileNumberField !== undefined && !mobileNumberField.classList.contains('form-control')) {
      mobileNumberField.classList.add('form-control');
    }
  }

  ngOnDestroy() {
    document.body.classList.remove('registration_login_bg_image');
  }

  get companyName() { return this.registerForm.get('companyName'); }
  get firstName() { return this.registerForm.get('firstName'); }
  get lastName() { return this.registerForm.get('lastName'); }
  get contactNumber() { return this.registerForm.get('contactNumber'); }
  get email() { return this.registerForm.get('email'); }


  public noWhitespaceValidator(control: FormControl) {
    const isWhitespace = (control.value || '').trim().length === 0;
    const isValid = !isWhitespace;
    return isValid ? null : { 'whitespace': true };
  }
  
  checkCountryCode() {
    if (this.phoneNumberInput !== undefined) {
      this.countryCode = '+' + this.phoneNumberInput.dialCode;
      this.mobile = this.phoneNumberInput.phoneNumberOnly;
    }
  }
  clearErrorMessage() {
    this.serverErrMessage = '';
  }

  submitForm(registerForm: any): void {
    this.companyname = registerForm.companyName;
    localStorage.setItem('mobile', this.mobile);
    localStorage.setItem('countryCode', this.countryCode);
    localStorage.setItem('companyname', this.companyname);

    const payload = {
      'companyName': registerForm.companyName,
      'firstName': registerForm.firstName,
      'lastName': registerForm.lastName,
      'email': registerForm.email,
      'countryCode': this.countryCode,
      'mobile': this.mobile,
      'companyType': 'corporate'
    };

    this.registerService.registerCompany(payload)
      .subscribe(
        response => {
          localStorage.setItem('companyId', response.companyId);
          this.router.navigate(['success']);
        },
        error => {
          this.serverErrMessage = error.error.message;
    });

  }



  keyprees(event) {
    return (event.charCode === 8 || event.charCode === 0) ? null : event.charCode >= 48 && event.charCode <= 57;
  }
  checkforValue(event) {
    if (event.target.value.trim() === '' && event.target.value.length !== 0) {
      this.showMinimumError = false;
    }
    if (event.target.value.trim() !== '' && event.target.value.length < 3) {
      this.showMinimumError  =  true;
    } else {
      this.showMinimumError = false;
    }
  }
  clearServerErr() {
    this.serverErrMessage = '';
  }
}

@Component({
  selector: 'app-reg-success',
  styleUrls: ['./register-comp.component.css'],
  templateUrl: './registerSuccess.component.html',
  providers: [RegisterService]
})

export class RegistrationSuccessComponent extends RegisterCompComponent implements OnInit {
  accessToken: string;
  companyId: string;
  successMSG: Boolean = false;
  message = '';

  ngOnInit() {
    const check = localStorage.getItem('changeAccount');
    if (check === 'dashboard') {
      localStorage.removeItem('changeAccount');
      localStorage.setItem('checkProfile', 'dashBoard');
      this.router.navigate(['dashboard']);
      return;
    } else if (check === 'profile') {
      localStorage.removeItem('changeAccount');
      localStorage.setItem('checkProfile', 'profile');
      this.router.navigate(['dashboard']);
      return;

    }
    this.successMSG = true;
  }
  resendActivationLink() {
    const companyName = localStorage.getItem('companyname');
    const countryCode = localStorage.getItem('countryCode');
    const mobile = localStorage.getItem('mobile');

    const payload = {
      'companyName': companyName,
      'countryCode': countryCode,
      'mobile': mobile
    };

    this.registerService.resentActivationLink(payload)
      .subscribe(
        response => {
          this.message = 'Activation link resent successfully';
          $('#message').show().delay(2000).fadeOut();
        },
        error => {
          console.log(error);
    });
  }
}
