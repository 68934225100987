<app-user-header></app-user-header>

<nav class="container-fluid pt-3 pb-3 bd-navbar sub-header">
  <div class="row">
    <div class="col">
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb" style="cursor: pointer">
          <li class="breadcrumb-item active" aria-current="page"><a>Observer</a></li>
        </ol>
      </nav>
    </div>
    <div class="col">
      <div class="btn-group float-right ">
        <button *ngIf="userRole == 'admin'" type="button" class="btn btn-primary dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          Settings
        </button>
        <div class="dropdown-menu dropdown-menu-right">
          <a class="dropdown-item" routerLink="/observer/notification">Manage Notifications</a>
        </div>
      </div>
    </div>
  </div>
</nav>

<!-- Section to show live batches -->
<div class="container-fluid main-div">
  <div *ngIf="liveBatches.length > 0 " style="margin-top: -15px; margin-left: 30px;">
    <a>{{liveBatches.length}} Assessments Live</a>
  </div>

  <div *ngIf="observerErrMessage == '' " class="col-9 mt-4" id="batchListContainer">
    <div class="row">
      <div class="col-4" *ngFor="let batch of liveBatches;">
        <a class="button" routerLink="/observer/live-batch-candidates/{{batch._id}}/{{batch.assessmentId}}">
          <img *ngIf="batch.assessmentType == 'Aptitude'" alt="Responsive image"
               class="img-fluid assessment-img" src="/assets/img/aptitude-test.png">
          <img *ngIf="batch.assessmentType == 'Technical'" alt="Responsive image"
               class="img-fluid assessment-img" src="/assets/img/technical-assessment.png">
          <img *ngIf="batch.assessmentType == 'Psychometer'" alt="Responsive image"
               class="img-fluid assessment-img" src="/assets/img/psychometric-assessment.png">
          <strong class="title text-truncate pl-0">{{batch.linkName}}</strong>
          <strong class="title text-truncate pl-0 pt-0">{{batch.assessmentName}}</strong>
          <p class="RR-14 grey">{{batch.candidateCount}} Candidates Live</p>
        </a>
      </div>
    </div>
  </div>

  <div *ngIf="observerErrMessage != '' " class="text-center">
    <span class="text-danger">{{observerErrMessage}}</span><br/><br/>
    <button class="btn-primary"  style="cursor: pointer" (click)="reloadLiveBatchList()">Reload</button>
  </div>

</div>

<img class="p-by" src="assets/img/p-by.png" alt="">
