<header (showCompanyName)="changeCompanyName();" class="bd-navbar fixed-top">

  <nav class="navbar  navbar-expand-md navbar-light navAdjust ">
    <a class="navbar-brand" style="cursor: pointer" routerLink="/dashboard" *ngIf="userRole != 'super_admin'">
      <img [src]="companyLogo" height="30" alt="">
    </a>
    <a class="navbar-brand" style="cursor: pointer" routerLink="/super_admin" *ngIf="userRole == 'super_admin'">
      <img [src]="companyLogo" height="30" alt="">
    </a>
    <button class="navbar-toggler" type="button">
      <span class="navbar-toggler-icon"></span>
    </button>

    <div class="collapse navbar-collapse" id="navbarTogglerDemo02">
      <ul class="navbar-nav mr-auto ml-5 mt-2 mt-lg-0 RR-14" id="nav">
        <li class="nav-item tablinks mr-3" *ngIf="userRole == 'admin'" >
          <a class="nav-link" routerLink="/dashboard" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}"
             (click)="dashboard();">Dashboard</a>
        </li>
        <li class="nav-item tablinks mr-3" *ngIf="userRole == 'super_admin'">
          <a class="nav-link" *ngIf="userRole == 'super_admin'" routerLink="/super_admin"  [routerLinkActiveOptions]="{exact: true}"
             routerLinkActive="active" (click)="dashboard();">Dashboard</a>
        </li>
        <li class="nav-item mr-3" *ngIf="userRole == 'super_admin'">
          <a class="nav-link" routerLink="/quick-assessment-templating" routerLinkActive="active">Quick Assessment</a>
        </li>
        <li class="nav-item mr-3" *ngIf="userRole == 'super_admin'">
          <a class="nav-link"   (click)="superAdminQB()" routerLink="/super_admin/questionbank" [routerLinkActiveOptions]="{exact: true}"
             routerLinkActive="active" >Categories & QB</a>
        </li>
        <li class="nav-item mr-3"  *ngIf="userRole == 'super_admin'">
          <a class="nav-link" routerLink="/blog/admin" [routerLinkActiveOptions]="{exact: true}"
             routerLinkActive="active" >Blog</a>
        </li>
        <li class="nav-item mr-3"  *ngIf="userRole == 'super_admin'">
          <a class="nav-link" routerLink="/super_admin/registered_companylist" [routerLinkActiveOptions]="{exact: true}"
             routerLinkActive="active" >Registered Companies</a>
        </li>
        <li class="nav-item mr-3">
          <a class="nav-link"  (click)="assesmentPage();"  routerLink="/assessment" *ngIf="userRole == 'admin' || userRole == 'evaluator'"
             routerLinkActive="active" >Assessment</a>
        </li>
        <li id="demo4" class="nav-item mr-3" *ngIf="userRole == 'admin'">
          <a class="nav-link " routerLink="/question" routerLinkActive="active"  (click)="questionPage();">Question
            Bank</a>
        </li>
        <li id="demo5" class="nav-item mr-3" *ngIf="userRole == 'admin' && biReportFlag">
          <a class="nav-link " routerLink="/bi-report" routerLinkActive="active"  (click)="biReport();">BI Report</a>
        </li>
        <li class="nav-item mr-2">
          <a class="nav-link"  *ngIf="userRole == 'external'" routerLink="/assessment/assigned-batches/evaluate" (click)="fetchData()" routerLinkActive="active">Evaluate</a>
        </li>
        <li class="nav-item mr-2">
          <a class="nav-link"  *ngIf="userRole == 'external'" routerLink="/assessment/assigned-batches/proctor" (click)="fetchData()" routerLinkActive="active">Proctor</a>
        </li>
        <li class="nav-item mr-2">
          <a class="nav-link"  *ngIf="userRole == 'external'" routerLink="/assessment/assigned-batches/centermanager" (click)="fetchData()" routerLinkActive="active">Manage</a>
        </li>

      </ul>
      <ul class="navbar-nav border-right mr-2" *ngIf="userRole == 'super_admin'">
        <li class="nav-item">
          <a style="display: block;margin-top: 8px;">{{totalLiveCandidateCount}} Live Candidates</a>
        </li>
        <li class="nav-item dropdown" style="width: auto;">
          <a class="nav-link dropdown-toggle" id="liveCandidateCountDropdown" data-toggle="dropdown" aria-haspopup="true"></a>
          <div *ngIf="totalLiveCandidateCount != 0" class="pt-0 dropdown-menu dropdown-menu-right candidate-dropdown" aria-labelledby="liveCandidateCountDropdown">
            <div class="card border-left-0 border-right-0 border-top-0">
              <div class="card-body card-thin">
                <div class="row GR-14 pt-2">
                  <div class="col col-8">Company</div>
                  <div class="col col-4 text-right">Count</div>
                </div>
              </div>
            </div>
            <div class="card card__two mt-2 border-0" *ngFor="let company of companyList;let i=index">
              <div class="card-body card-bg card-thin">
                <div class="row">
                  <div class="col col-8">{{company.companyName}}</div>
                  <div class="col col-4 text-right">{{company.liveCount}}</div>
                </div>
              </div>
            </div>
          </div>
        </li>
      </ul>

      <ul class="navbar-nav">
        <li class="nav-item">
          <a style="display: block;margin-top: 8px;">{{companyName}}</a>
        </li>
        <li class="nav-item dropdown">

          <a class="nav-link dropdown-toggle" id="navbarDropdownMenuLink" data-toggle="dropdown" aria-haspopup="true"
             aria-expanded="false">
            <img src="/assets/img/tick-logo.png" height="25" alt="profile" class="rounded-circle">
          </a>
          <div class="dropdown-menu dropdown-menu-right" aria-labelledby="navbarDropdownMenuLink">
            <a class="dropdown-item" *ngIf="userRole != 'super_admin'" routerLink="/profile">Profile</a>
            <a class="dropdown-item" data-target="#companyURL" data-toggle="modal" *ngIf="userRole != 'super_admin'">Test Link</a>
            <a class="dropdown-item" routerLink="/terms-of-use" target="_blank" *ngIf="userRole != 'super_admin'">Terms of Use</a>
            <a class="dropdown-item" *ngIf="corp_Sec_Count.isParentCompany===true && userRole == 'admin'"
               routerLink="/manage-clients">Client Management</a>
            <!--<a class="dropdown-item" *ngIf="userRole == 'admin'"  routerLink="/manage-users">User Management</a>-->
            <a class="dropdown-item" *ngIf="userRole == 'admin'" routerLink="/manage-invite">Invite Management</a>
            <div class="dropdown-divider" *ngIf="userRole != 'super_admin'"></div>
            <a class="dropdown-item text-danger" (click)="logOutUser();" style="cursor: pointer">Logout</a>
          </div>
        </li>
      </ul>

    </div>

  </nav>

  <!--<script>-->
  <!--$(function() {-->
  <!--$('#nav li a').click(function() {-->
  <!--$('#nav li').removeClass();-->
  <!--$($(this).attr('routerLink')).addClass('active');-->
  <!--});-->
  <!--});-->
  <!--</script>-->


  <!--<div *ngIf="!newAssessment" class="container-fluid">-->
  <!--<div class="row block2">-->
  <!--<div class="col">-->
  <!--<nav aria-label="breadcrumb">-->
  <!--<ol class="breadcrumb">-->
  <!--<li class="breadcrumb-item"><a >Home</a></li>-->
  <!--<li class="breadcrumb-item active" aria-current="page">{{headerTag}}</li>-->
  <!--</ol>-->
  <!--</nav>-->
  <!--</div>-->

  <!--<div *ngIf="createNewDrop" class="col">-->
  <!--<div class="btn-group float-right">-->
  <!--<button type="button" class="btn btn-login dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">-->
  <!--Create new-->
  <!--</button>-->
  <!--<div class="dropdown-menu dropdown-menu-right">-->
  <!--<button class="dropdown-item" type="button">Action</button>-->
  <!--<button class="dropdown-item" type="button">Another action</button>-->
  <!--<button class="dropdown-item" type="button">Something else here</button>-->
  <!--</div>-->
  <!--</div>-->
  <!--</div>-->

  <!--<div *ngIf="assessment">-->
  <!--<button type="button" class="btn btn-login mtop30"  aria-haspopup="true" (click)="createNewAssessment();">-->
  <!--Create Assessment-->
  <!--</button>-->

  <!--</div>-->



  <!--</div>-->
  <!--</div>-->

  <!--<div *ngIf="newAssessment" class="container-fluid">-->
  <!--<div class="row block2">-->
  <!--<div class="col">-->
  <!--<nav aria-label="breadcrumb">-->
  <!--<i class="fas fa-arrow-left"><a >Create New Assessment</a></i>-->

  <!--</nav>-->
  <!--</div>-->
  <!--</div>-->
  <!--</div>-->

</header>


<div class="modal fade" id="companyURL" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
     aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Assessment Link</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">

        <div class="row">
          <div class="col text-center">
            <img src="./assets/img/publish-assessment-link.png" alt="" class="mt-3 mb-3">

            <div class="input-group mt-3">
              <input type="text" class="form-control" id="linkGen" name="publiclink" aria-label="Recipient's username"
                     aria-describedby="basic-addon2" [value]="companyURL" [(ngModel)]="companyURL" readonly>
              <div class="input-group-append">
                <i class="fa fa-clone fa-lg green" (click)="copyLink();" tooltip="copy"
                   style=" margin-left: 7px;margin-top: 13px; cursor: pointer;"></i>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer ">
        <!--<button type="button" class="btn btn-secondary pull-right" data-dismiss="modal" (click)="privateCandidateinvitationList();">Done</button>-->
      </div>
    </div>
  </div>
</div>

<img class="p-by" src="assets/img/p-by.png">
