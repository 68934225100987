import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { Observable, BehaviorSubject} from 'rxjs';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import 'rxjs/add/observable/throw';
import {environment} from '../../environments/environment';

@Injectable()
export class AssessmentService {

  batchData:any;
  LinkId:any;
  ConfigurationData:any;
  searchText:string='';
  candidateSearchText = new BehaviorSubject("");

  setCandidateSearchText(text:string){

    this.candidateSearchText.next(text);
    this.candidateSearchText.subscribe((val:string)=>{
      this.searchText=val;
    })

  }

  getSearchText(){
    return this.searchText;
  }

  private url = '';
  private localhost = 'localhost';
  formData: FormData;
  private progress = 0;
  globalUrl = environment.serverUrl;

  // routes = {
  //   doSelectUrl: "https://api.doselect.com/platform/v1/test",
  // }

  constructor(private http: HttpClient) { }
  nativeGlobal() { return window; }
  // getTest() {
  //   const httpOptions = {
  //     headers: new HttpHeaders({
  //       'DoSelect-Api-Key': '85b15e33cf724e8aa4a1e3309dd6333f',
  //       'DoSelect-Api-Secret': "76d89d49ebe57badf57da3f10324d495f29b72aa2e7902fd0f10f13d0b9401b9"
  //     })
  //   };

  //   return this.http.get(this.routes.doSelectUrl, httpOptions);
  // }

  getTest(  companyId, accessToken): Observable<any> {

    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + accessToken
    });
    this.url = this.globalUrl + '/business/services/assessment/online-compiler/details?companyId=' + companyId;
    return this.http.post(this.url,{} , {headers: headers}).pipe(
      map( response => response),
      catchError(this._errorHandler)
    );



  }



  assessmentCreation(payload, companyId, accessToken): Observable<any> {

    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'bearer ' + accessToken
    });
    this.url = this.globalUrl + '/business/services/assessment/create?companyId=' + companyId;
    return this.http.post(this.url, payload, {headers: headers}).pipe(
      map( response => response),
      catchError(this._errorHandler)
    );



  }

  editassessment(payload, companyId, accessToken): Observable<any> {

    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'bearer ' + accessToken
    });
    this.url = this.globalUrl + '/business/services/assessment/edit?companyId=' + companyId;
    return this.http.post(this.url, payload, {headers: headers}).pipe(
      map( response => response),
      catchError(this._errorHandler)
    );

  }

  categoryList (payload, companyId, skip, accessToken): Observable<any> {

    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'bearer ' + accessToken
    });
    this.url = this.globalUrl + '/business/services/company/category/list-all?companyId=' + companyId;
    return this.http.post(this.url, payload, {headers: headers}).pipe(
      map( response => response),
      catchError(this._errorHandler)
    );

  }

  subCategoryList(payload, companyId, accessToken): Observable<any> {

    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'bearer ' + accessToken
    });
    this.url = this.globalUrl + '/business/services/company/subcategory/list?companyId=' + companyId;
    return this.http.post(this.url, payload, {headers: headers}).pipe(
      map( response => response),
      catchError(this._errorHandler)
    );

  }

  questionCountforCategory(payload, companyId, accessToken): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'bearer ' + accessToken
    });
    this.url = this.globalUrl + '/business/services/assessment/get/question/count?companyId=' + companyId;
    return this.http.post(this.url, payload, { headers: headers }).pipe(
      map( response => response),
      catchError(this._errorHandler)
    );
  }

  uniqueAssessmentList(companyId, accessToken): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'bearer ' + accessToken
    });

    this.url = this.globalUrl + '/business/services/assessment/get/unique/assessmentlist?companyId=' + companyId;
    return this.http.post(this.url, {}, {headers: headers}).pipe(
      map( response => response),
      catchError(this._errorHandler)
    );
  }

  assessmentList(payload, companyId, accessToken): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'bearer ' + accessToken
    });

    this.url = this.globalUrl + '/business/services/assessment/list?companyId=' + companyId;
    return this.http.post(this.url, payload, {headers: headers}).pipe(
      map( response => response),
      catchError(this._errorHandler)
    );
  }

  assessmentDelete(payload, companyId, accessToken): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'bearer ' + accessToken
    });
    this.url = this.globalUrl + '/business/services/assessment/delete?companyId=' + companyId;
    return this.http.post(this.url, payload, {headers: headers}).pipe(
      map( response => response),
      catchError(this._errorHandler)
    );
  }

  assessmentListById(payload, companyId, accessToken): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'bearer ' + accessToken
    });
    this.url = this.globalUrl + '/business/services/assessment/list/assessmentid?companyId=' + companyId;
    return this.http.post(this.url, payload, {headers: headers}).
    pipe(
      map( response => response),
      catchError(this._errorHandler)
    );
  }

  assessMentListofJD(payload, companyId, accessToken): Observable<any> {

    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization':  'bearer ' + accessToken
    });
    this.url = this.globalUrl + '/business/services/assessment/job/assessment/list?companyId=' + companyId;
    return this.http.post(this.url, payload, {headers: headers}).pipe(
      map( response => response),
      catchError(this._errorHandler)
    );


  }

  saveSelectedAssessmentButton(payload, companyId, accessToken): Observable<any> {

    const headers = new HttpHeaders ({
      'Content-Type': 'application/json',
      'Authorization': 'bearer ' + accessToken
    });
    this.url = this.globalUrl + '/business/services/assessment/job/assessment/add?companyId=' + companyId;
    return this.http.post(this.url, payload, {headers: headers}).
    pipe(
      map( response => response),
      catchError(this._errorHandler)
    );
  }

  assessmentDashboard(companyId, accessToken): Observable<any> {

    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'bearer ' + accessToken
    });
    this.url = this.globalUrl + '/business/services/assessment/assesstype/count?companyId=' + companyId;
    return this.http.post(this.url, {}, {headers: headers}).pipe(
      map( response => response),
      catchError(this._errorHandler)
    );

  }

  getLevelOfAssess(payload, companyId, accessToken): Observable<any> {

    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      // 'Authorization':'bearer '+accessToken
    });
    this.url = this.globalUrl + '/business/services/company/get/masterdata';
    return this.http.post(this.url, payload, {headers: headers}).pipe(
      map( response => response),
      catchError(this._errorHandler)
    );

  }

  previewOfAssessmentInBuilt(payload, companyId, accessToken): Observable<any> {

    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'bearer ' + accessToken
    });
    this.url = this.globalUrl + '/business/services/assessment/pulling/questions?companyId=' + companyId;
    return this.http.post(this.url, payload, {headers: headers}).pipe(
      map( response => response),
      catchError(this._errorHandler)
    );

  }

  uploadInvites(companyId: string, accessToken: any, file: File[]): Observable<any> {
    return Observable.create(observer => {
      const formData: FormData = new FormData(),
        xhr: XMLHttpRequest = new XMLHttpRequest();
      for (let i = 0; i < file.length; i++) {
        formData.append('fileUpload', file[i], file[i].name);
      }
      xhr.onreadystatechange = () => {
        if (xhr.readyState === 4) {
          if (xhr.status === 200) {
            observer.next(JSON.parse(xhr.response));
            observer.complete();
          } else {
            observer.error(xhr.response);
          }
        }
      };
      xhr.upload.onprogress = (event) => {
        this.progress = Math.round(event.loaded / event.total * 100);
      };
      this.url = this.globalUrl + '/business/services/assessment/link/candidate/upload?companyId=' + companyId;
      xhr.open('POST', this.url, true);
      xhr.setRequestHeader('Authorization', 'bearer ' + accessToken);
      xhr.send(formData);
    });

  }

  getassessmentList(payload, skip, companyId, accessToken): Observable<any> {

    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'bearer ' + accessToken
    });

    this.url = this.globalUrl + '/business/services/assessment/assesstype/list?companyId=' + companyId + '&skip=' + skip;
    return this.http.post(this.url, payload, { headers: headers }).pipe(
      map( response => response),
      catchError(this._errorHandler)
    );

  }

  privateLinkInvitation(payload, companyId, accessToken): Observable<any> {

    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'bearer ' + accessToken
    });
    this.url = this.globalUrl + '/business/services/assessment/job/testlink/create?companyId=' + companyId;
    return this.http.post(this.url, payload, { headers: headers }).pipe(
      map( response => response),
      catchError(this._errorHandler)
    );
  }

  privateAddEditCandidates(payload, companyId, accessToken): Observable<any> {

    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'bearer ' + accessToken
    });
    this.url = this.globalUrl + '/business/services/assessment/link/edit?companyId=' + companyId;
    return this.http.post(this.url, payload, { headers: headers }).pipe(
      map( response => response),
      catchError(this._errorHandler)
    );
  }

  candidatesLinkIDDetails(payload, companyId, accessToken): Observable<any> {

    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'bearer ' + accessToken
    });
    this.url = this.globalUrl + '/business/services/assessment/job/testlink/details?companyId=' + companyId;
    return this.http.post(this.url, payload, {headers: headers}).pipe(
      map( response => response),
      catchError(this._errorHandler)
    );

  }

  sectionWiseQuestion(payload, companyId, accessToken): Observable<any> {

    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'bearer ' + accessToken
    });
    this.url = this.globalUrl + '/business/services/assessment/pulling/questions?companyId=' + companyId;
    return this.http.post(this.url, payload, { headers: headers }).pipe(
      map( response => response),
      catchError(this._errorHandler)
    );

  }

  getPublicTemplateDetails(payload){
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    this.url = this.globalUrl + '/business/services/quick-assessment/published-template/details';
    return this.http.post(this.url, payload, {headers: headers}).pipe(
      map( response => response),
      catchError(this._errorHandler)
    );

  }
  getTemplateDetails(payload,companyId,accessToken){
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'bearer ' + accessToken
    });
    this.url = this.globalUrl + '/business/services/quick-assessment/template/details?companyId='+companyId;
    return this.http.post(this.url, payload, {headers: headers}).pipe(
      map( response => response),
      catchError(this._errorHandler)
    );

  }

  testCodeGenerate(companyId, accessToken): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'bearer ' + accessToken
    });
    this.url = this.globalUrl + '/business/services/assessment/link/testcode/generate?companyId=' + companyId;
    return this.http.post(this.url, {}, { headers: headers }).pipe(
      map( response => response),
      catchError(this._errorHandler)
    );
  }

  listofInvitationLink(payload, companyId, accessToken, skip): Observable<any> {

    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'bearer ' + accessToken
    });
    this.url = this.globalUrl + '/business/services/assessment/job/testlink/list?companyId=' + companyId + '&skip=' + skip;
    return this.http.post(this.url, payload, { headers: headers }).pipe(
      map(response => response),
      catchError(this._errorHandler)
    );
  }

  deleteLink(payload, companyId, accessToken): Observable<any> {

    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'bearer ' + accessToken
    });
    this.url = this.globalUrl + '/business/services/assessment/job/testlink/delete?companyId=' + companyId;
    return this.http.post(this.url, payload, { headers: headers }).pipe(
      map( response => response),
      catchError(this._errorHandler)
    );
  }

  editAssessment(payload, companyId, accessToken): Observable<any> {

    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'bearer ' + accessToken
    });
    this.url = this.globalUrl + '/business/services/assessment/list/assessmentid?companyId=' + companyId;
    return this.http.post(this.url, payload, { headers: headers }).pipe(
      map( response => response),
      catchError(this._errorHandler)
    );

  }

  editQuickAssessment(payload, companyId, accessToken){

    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'bearer ' + accessToken
    });
    this.url = this.globalUrl + '/business/services/quick-assessment/edit?companyId=' + companyId;
    return this.http.post(this.url, payload, { headers: headers }).pipe(
      map( response => response),
      catchError(this._errorHandler)
    );
  }

  candidateReport(payload, skip, companyId, accessToken): Observable<any> {

    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'bearer ' + accessToken
    });

    this.url = this.globalUrl + '/business/services/link/candidate/list?companyId=' + companyId + '&skip=' + skip;


    return this.http.post(this.url, payload, {headers: headers}).pipe(
      map( response => response),
      catchError(this._errorHandler)
    );

  }

  cadidatesReports(payload, companyId, accessToken): Observable<any> {

    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'bearer ' + accessToken
    });

    this.url = this.globalUrl + '/business/services/link/candidate/test/report?companyId=' + companyId;
    return this.http.post(this.url, payload, {headers: headers}).pipe(
      map( response => response),
      catchError(this._errorHandler)
    );

  }
  bulkReportData(payload , companyId , accessToken  ) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'bearer ' + accessToken
    });

    this.url = this.globalUrl + '/business/services/candidate/report/bulk-download?companyId=' + companyId;
    return this.http.post(this.url, payload, {headers: headers}).pipe(
      map( response => response),
      catchError(this._errorHandler)
    );
  }

  CandidateRRReson(payload, companyId, accessToken): Observable<any> {

    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'bearer ' + accessToken
    });

    this.url = this.globalUrl + '/business/services/assessment/link/candidate/reset/test?companyId=' + companyId;

    return this.http.post(this.url, payload, {headers: headers}).pipe(
      map( response => response),
      catchError(this._errorHandler)
    );
  }

  CandidateReschudling(payload, companyId, accessToken): Observable<any> {

    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'bearer ' + accessToken
    });

    this.url = this.globalUrl + '/business/services/assessment/testlink/candidate/reschedule?companyId=' + companyId;
    return this.http.post(this.url, payload, {headers: headers}).pipe(
      map( response => response),
      catchError(this._errorHandler)
    );

  }

  exportCandidateExcel(payload, companyId, accessToken): Observable<any> {

    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'bearer ' + accessToken
    });
    this.url = this.globalUrl + '/business/services/link/candidate/test/report/excel?companyId=' + companyId;
    return this.http.post(this.url, payload, { headers: headers }).pipe(
      map( response => response),
      catchError(this._errorHandler)
    );
  }

  getAssessmentById(payload, companyId, accessToken): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'bearer ' + accessToken
    });
    this.url = this.globalUrl + '/business/services/assessment/list/assessmentid?companyId=' + companyId;
    return  this.http.post(this.url, payload, {headers: headers}).pipe(
      map(response => response),
      catchError(this._errorHandler)
    );

  }

  getDescriptiveQuestions(payload, companyId, accessToken): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'bearer ' + accessToken
    });
    this.url = this.globalUrl + '/business/services/link/candidate/descriptive/review/questions?companyId=' + companyId;
    return  this.http.post(this.url, payload, {headers: headers}).pipe(
      map(response => response),
      catchError(this._errorHandler)
    );
  }

  saveDescriptiveScore(payload, companyId, accessToken): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'bearer ' + accessToken
    });
    this.url = this.globalUrl + '/business/services/link/candidate/descriptive/marks/calculation?companyId=' + companyId;
    return  this.http.post(this.url, payload, {headers: headers}).pipe(
      map(response => response),
      catchError(this._errorHandler)
    );
  }


  assignRoleToAssignee(payload, companyId, accessToken): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'bearer ' + accessToken
    });
    this.url = this.globalUrl + '/business/services/assessment/link/assign-administrator?companyId=' + companyId;
    return  this.http.post(this.url, payload, {headers: headers}).pipe(
      map(response => response),
      catchError(this._errorHandler)
    );
  }

  unLinkAssignee(payload, companyId, accessToken): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'bearer ' + accessToken
    });
    this.url = this.globalUrl + '/business/services/assessment/link/remove-administrator?companyId=' + companyId;
    return  this.http.post(this.url, payload, {headers: headers}).pipe(
      map(response => response),
      catchError(this._errorHandler)
    );
  }


  availableAssigneeList(payload, accessToken, companyId): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'bearer ' + accessToken
    });
    this.url = this.globalUrl + '/business/services/assessment/link/list-available-administrators?companyId=' + companyId;
    return this.http.post(this.url, payload, {headers: headers}).pipe(
      map(response => response),
      catchError(this._errorHandler)
    );
  }



//blog service
  blogListApi(payload): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    this.url = this.globalUrl + '/business/services/blog/list';
    return this.http.post(this.url, payload, {headers: headers}).pipe(
      map(response => response),
      catchError(this._errorHandler)
    );
  }


  blogDetailsApi(payload): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    this.url = this.globalUrl + '/business/services/blog/details';
    return this.http.post(this.url, payload, {headers: headers}).pipe(
      map(response => response),
      catchError(this._errorHandler)
    );
  }

  createNewBlog(payload, companyId, accessToken): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'bearer ' + accessToken
    });
    this.url = this.globalUrl + '/business/services/blog/add?companyId=' + companyId;
    return this.http.post(this.url, payload, {headers: headers}).pipe(
      map(response => response),
      catchError(this._errorHandler)
    );
  }


  publishBlog(payload, companyId, accessToken ): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'bearer ' + accessToken
    });
    this.url = this.globalUrl + '/business/services/blog/publish?companyId=' + companyId;
    return this.http.post(this.url, payload, {headers: headers}).pipe(
      map(response => response),
      catchError(this._errorHandler)
    );
  }

  updateBlog(payload, companyId,accessToken): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'bearer ' + accessToken
    });
    this.url = this.globalUrl + '/business/services/blog/edit?companyId=' + companyId;
    return this.http.post(this.url, payload, {headers: headers}).pipe(
      map(response => response),
      catchError(this._errorHandler)
    );
  }

  uploadBlogImage(companyId: string, accessToken: any, payload: any): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'bearer ' + accessToken
    });
    this.url = this.globalUrl + '/business/services/blog/upload-blog-image?companyId=' + companyId;
    return this.http.post(this.url, payload, {headers: headers}).pipe(
      map( response => response),
      catchError(this._errorHandler)
    );
  }

  archiveBlog(payload,companyId,accessToken ): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'bearer ' + accessToken
    });
    this.url = this.globalUrl + '/business/services/blog/archive?companyId=' + companyId;
    return this.http.post(this.url, payload, {headers: headers}).pipe(
      map(response => response),
      catchError(this._errorHandler)
    );
  }

  blogListAdminApi(payload, companyId, accessToken): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'bearer ' + accessToken
    });
    this.url = this.globalUrl + '/business/services/blog/administrator-list?companyId=' + companyId;
    return this.http.post(this.url, payload, {headers: headers}).pipe(
      map(response => response),
      catchError(this._errorHandler)
    );
  }

  blogDetailsAdminApi(payload,companyId , accessToken): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'bearer ' + accessToken
    });
    this.url = this.globalUrl + '/business/services/blog/administrator-detail?companyId=' + companyId;
    return this.http.post(this.url, payload, {headers: headers}).pipe(
      map(response => response),
      catchError(this._errorHandler)
    );
  }

  fetchAssignedBatches(payload, companyId, accessToken): Observable<any> {

    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'bearer ' + accessToken
    });
    this.url = this.globalUrl + '/business/services/assessment/link/list-assigned-links?companyId=' + companyId;
    return this.http.post(this.url, payload, {headers: headers}).pipe(
      map( response => response),
      catchError(this._errorHandler)
    );

  }

  setBatchData(data){
    this.batchData=data;
  }
  getBatchData(){
    return this.batchData;
  }


  _errorHandler(error: HttpErrorResponse) {
    console.error(error);
    return throwError(error || 'Server Error');

  }
  setLinkId(val:any){
    this.LinkId=val;

  }
  getLinkId(){
    return this.LinkId;
  }
  setConfigurationData(data:any){
    this.ConfigurationData=data;
  }

  getConfigurationData(){
    return this.ConfigurationData;
  }

  changeLockStatus(payload, companyId, accessToken){

    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'bearer ' + accessToken
    });

    this.url = this.globalUrl + '/business/services/assessment/link/change-lock-status?companyId='+ companyId;
    return this.http.post(this.url, payload, {headers: headers}).pipe(
      map( response => response),
      catchError(this._errorHandler)
    );

  }


  createQuickAssessment(payload, companyId, accessToken){
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'bearer ' + accessToken
    });

    this.url = this.globalUrl + '/business/services/quick-assessment/create?companyId='+ companyId;
    return this.http.post(this.url, payload, {headers: headers}).pipe(
      map( response => response),
      catchError(this._errorHandler)
    );
  }
}
