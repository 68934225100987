import {Component, OnDestroy, OnInit} from '@angular/core';
import { Router , ActivatedRoute , Params} from '@angular/router';
import {UserHeaderService} from '../header/header.service';
import { ToastrService } from 'ngx-toastr';


@Component({
  selector: 'app-log-out',
  templateUrl: './log-out.component.html',
  styleUrls: ['./log-out.component.css'],
  providers: [UserHeaderService]
})
export class LogOutComponent implements OnInit , OnDestroy {
  accessToken = '';
  companyId = '';
  constructor( private route: ActivatedRoute, private router: Router , private userheaderService: UserHeaderService , private toastrService: ToastrService ) {
    this.accessToken = localStorage.getItem('accessToken');
    this.companyId = localStorage.getItem('companyId');
  }

  ngOnInit() {
    if (this.accessToken === '' || this.accessToken === undefined || this.accessToken === null ||
      this.companyId === '' || this.companyId === undefined || this.companyId === null) {
      this.getRedirectUrl();
    } else {
      this.logoutUser();
      this.getRedirectUrl();
    }
    document.body.classList.add('registration_login_bg_image');
  }
  ngOnDestroy() {
    document.body.classList.remove('registration_login_bg_image');
  }
  getRedirectUrl() {
    this.route.queryParams.subscribe((params: Params) => {
      localStorage.clear();
      if (params['redirectTo'] !== undefined) {
        window.location.href = params['redirectTo'];
      } else {
        this.router.navigate(['/']);
      }
    });
  }
  logoutUser() {
    this.userheaderService.logOutUser()
      .subscribe(
        response => {
          this.toastrService.success(response.message, 'Success');
        },
        error => {
        });
  }}
