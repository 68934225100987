import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import { CreateAssessmentComponent } from "./create-assessment/create-assessment.component";
import { ListAssessmentComponent } from "./list-assessment/list-assessment.component";
import { Routes, RouterModule } from "@angular/router";
import { SharedModule } from "../shared/shared.module";
import { AssessmentDetailsComponent } from "./assessment-details/assessment-details.component";
import { PublishComponent } from "./publish-link/publish.component";
import { AssessmentTypeComponent } from "./assessment-type/assessment-type.component";
import { MathjaxComponent } from "./mathjax/mathjax.component";
import { ListAssignedBatchesComponent } from "./list-assigned-batches/list-assigned-batches.component";
import { ListAssignedBatchCandidatesComponent } from "./list-assigned-batch-candidates/list-assigned-batch-candidates.component";
import { ListOfCandidatesComponent } from "./list-of-candidates/list-of-candidates.component";
import { JobService } from "../jobs/jobs.service";
import { QuickAssessmentPopupComponent } from './quick-assessment-popup/quick-assessment-popup.component';
import { CreateQuickAssessmentComponent } from './create-quick-assessment/create-quick-assessment.component';
const routes: Routes = [
  { path: "", redirectTo: "list", pathMatch: "full" },
  { path: "list", component: AssessmentTypeComponent },
  { path: "list/:type", component: ListAssessmentComponent },
  { path: "create/:type", component: CreateAssessmentComponent },
  { path: "create", component: CreateAssessmentComponent },
  { path: "create-from-template/:id", component: CreateQuickAssessmentComponent },
  { path: "update-quick-assessment/:id", component: CreateQuickAssessmentComponent },

  { path: "update/:id", component: CreateAssessmentComponent },
  { path: "publish-link/:id", component: PublishComponent },
  { path: "editlink/:editId", component: PublishComponent },
  { path: "details/:id", component: AssessmentDetailsComponent },
  { path: "assigned-batches/:role", component: ListAssignedBatchesComponent },
  {
    path: "batch-candidates/:linkId",
    component: ListAssignedBatchCandidatesComponent,
  },
];
@NgModule({
  imports: [CommonModule, SharedModule, MatSlideToggleModule, RouterModule.forChild(routes)],
  declarations: [
    AssessmentTypeComponent,
    CreateAssessmentComponent,
    ListAssessmentComponent,
    AssessmentDetailsComponent,
    PublishComponent,
    MathjaxComponent,
    ListAssignedBatchesComponent,
    ListAssignedBatchCandidatesComponent,
    ListOfCandidatesComponent,
    QuickAssessmentPopupComponent,
    CreateQuickAssessmentComponent,
  ],
  providers:[
    JobService,
    ListAssignedBatchesComponent
  ]
})
export class AssessmentsModule {}
