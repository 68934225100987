<body *ngIf="successMSG" class="bg1">
<div  class="container-center">
  <div class="box-1">

    <div class="logo2" routerLink="/" style="cursor: pointer">
      <img src="../../assets/img/logo2.png" alt="logo">
    </div>

    <!--carousel -->
    <div id="carouselExampleIndicators " class="carousel slide carouselWrap" data-ride="carousel">
      <ol class="carousel-indicators ">
        <li data-target="#carouselExampleIndicators" data-slide-to="0" class="active"></li>
        <li data-target="#carouselExampleIndicators" data-slide-to="1"></li>
        <li data-target="#carouselExampleIndicators" data-slide-to="2"></li>
      </ol>
      <div class="carousel-inner carousel-height">
        <div class="carousel-item active">
          <h5>Simple to Use</h5>
        </div>
        <div class="carousel-item">
          <h5>Pay Per Use</h5>
        </div>
        <div class="carousel-item">
          <h5>Reduce Effort and Cost</h5>
        </div>
      </div>

    </div>
    <!-- carousel end-->

  </div>
  <div class="box-2">
    <div class="container">

      <form class="form-padding text-center">
        <h3 class="mtop15">Activation link successfully sent!</h3>

        <!-- <div class="success mx-auto mtop30"></div> -->
        <img class=" mtop50" src="../../assets/img/link-success.png" alt="">
        <p class="mtop50">Check your email for the activation link.</p>
        <div>
          <small id="emailHelp" class="form-text text-muted">Did not receive activation link? <a  class="text-info" (click)="resendActivationLink();" style="cursor: pointer">Resend activation link</a></small><br/>
        </div>

        <div id="message" name="message" style="display: none">
          <small style="color: green">{{message}}</small>
        </div>

      </form>
    </div>
  </div>
</div>
</body>
