import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import 'rxjs/add/observable/throw';
import { environment } from '../../environments/environment';

@Injectable()
export class LoginService {

  private headers = new HttpHeaders({
    'Content-Type': 'application/json'
  });
  private url = '' ;
  private localhost  = 'localhost';
  globalUrl = environment.serverUrl;

  constructor(private http: HttpClient) { }

  getOtpOrPassword(payLoad: any, companyId): Observable<any> {
    this.url =  this.globalUrl + '/business/services/user/validate/otporpassword';
    return this.http.post(this.url, payLoad, {headers: this.headers}).pipe(
      map( response => response),
      catchError(this._errorHandler)
    );
  }

  loginByOtp(payLoad: any, companyId): Observable<any> {
    this.url = this.globalUrl + '/business/services/user/login/otp?companyId='  + companyId;
    return this.http.post(this.url, payLoad, {headers: this.headers}).pipe(
      map( response => response),
      catchError(this._errorHandler)
    );
  }

  loginByPassword(payLoad: any, companyId): Observable<any> {
    this.url = this.globalUrl + '/business/services/user/login/password?companyId=' + companyId;
    return this.http.post(this.url, payLoad, {headers: this.headers}).pipe(
      map( response => response),
      catchError(this._errorHandler)
    );
  }

  getUserInfo(companyId: string, accessToken: any): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'bearer ' + accessToken
    });
    this.url = this.globalUrl + '/business/services/user/info?companyId=' + companyId;
    return this.http.post(this.url,  {}, {headers: headers}).pipe(
      map( response => response),
      catchError(this._errorHandler)
    );
  }

  getCompanyList(payload): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    this.url = this.globalUrl + '/business/services/user/company/list';
    return this.http.post(this.url, payload, {headers: headers}).pipe(
      map( response => response),
      catchError(this._errorHandler)
    );
  }

  _errorHandler(error: HttpErrorResponse) {
    console.error(error);
    return throwError(error || 'Server Error');
  }

}
