<app-user-header></app-user-header>
<nav class="container-fluid pt-3 pb-3 bd-navbar sub-header">
  <div class="row">
    <div class="col">
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
          <li class="breadcrumb-item">
            <i
              class="material-icons green align-middle"
              [hidden]="gobackIcon"
              style="cursor: pointer"
              (click)="landingDashBorad('goBack')"
              >keyboard_arrow_left</i
            >
            <a>Manage Categories & QB</a>
            <!--<h5>Question Bank</h5>-->
          </li>
        </ol>
      </nav>
    </div>

    <div class="col">
      <div class="btn-group float-right">
        <!-- <div [hidden]="!QBDashboard || createFlag">
          <button
            type="button"
            id="dropdownMenuButton"
            class="btn btn-outline dropdown-toggle mr-3"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            <i class="fa fa-gear mr-1"></i>Action
          </button>
          <div
            class="dropdown-menu dropdown-menu-right"
            aria-labelledby="dropdownMenuButton"
          >
            <a class="dropdown-item" (click)="redirectTosubcategory()"
              ><i class="fa fa-folder-open pr-1"></i> Subcategories</a
            >
            <a class="dropdown-item" (click)="openEditCat()"
              ><i class="fa fa-pencil pr-2"></i>Edit</a
            >
            <a class="dropdown-item" (click)="openarchiveModal('inactive')"
              ><i class="fa fa-archive pr-2"></i>Archieve</a
            >
          </div>
        </div> -->
        <div>
          <button
            type="button"
            class="btn btn-primary dropdown-toggle"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            Create New
          </button>
          <div class="dropdown-menu dropdown-menu-right">
            <a
              class="dropdown-item"
              routerlink="/super_admin/questionbank"
              (click)="openNewCatDiv()"
              >Category</a
            >
            <a
              class="dropdown-item"
              routerlink="/super_admin/questionbank"
              (click)="openSubCatDiv()"
              >Sub Category</a
            >
            <a
              class="dropdown-item"
              routerlink="/super_admin/questionbank"
              (click)="localReference()"
              >Question</a
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</nav>
<div [hidden]="QBDashboard" class="">
  <!--start for show QB Dashoboard-->
  <div class="row no-gutters">
    <div class="col-3 col-sm">
      <div class="panel-left container-fluid bg1 pb-0">
        <div class="searchSection">
          <h6 class="ml-0"><b>Search</b></h6>
          <div class="caldiv clearfix">
            <input
              class=" form-control"
              type="text"
              placeholder="Category Name"
              [(ngModel)]="searchText1"
              (keyup.enter)="searchQB()"
            />
          </div>
          <!-- <div class="caldiv clearfix">
            <input
              type="checkbox"
              id="chkArchieved"
              class="mr-1"
              value="Evaluator"
              [checked]="includeArchieved"
            />Include Archived
          </div> -->
          <!-- <div class="caldiv clearfix">
              <input type="checkbox" id="chkEvaluator" class="mr-1" value="Evaluator" [checked]="includeSubCategories"
                         >Include Subcategories
            </div> -->
          <div class="caldiv clearfix ml-0 mt-4" style="display: flex">
            <button
              type="button"
              class="btn btn-outline ml-0 btn-more side-panel-btn"
              (click)="searchQB()"
              aria-expanded="false"
              [disabled]="searchText1 == ''"
            >
              Search
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class="col-9">
      <div class="panel-right container-fluid">
        <div class="row">
          <p *ngIf="CategoryCount != 0" class="RR-14 ml-3">
            Listed - {{ CategoryCount }} / {{ totalCategoryCount }} Category(s)
          </p>
          <!-- <span *ngIf="searchTextTag.length!=0" class="RR-14   btn-sm btn-primary srchbadge">
            {{searchTextTag}} <button class=" btn  fa fa-close srchbadgebtn  pl-0" (click)="resetSearch()">
           </button>
           </span> -->
          <button
            *ngIf="searchTextTag.length!=0 && searchTextTag.length &lt; 9"
            (click)="resetSearch()"
            class="btn srchbadge"
          >
            {{ searchTextTag }} <i class="fa fa-close"></i>
          </button>
          <button
            *ngIf="searchTextTag.length!=0 && searchTextTag.length &gt; 8"
            data-toggle="tooltip"
            data-placement="top"
            title="{{ searchTextTag }}"
            (click)="resetSearch()"
            class="btn srchbadge"
          >
            {{ searchTextTag | slice : 0 : 8 }}... <i class="fa fa-close"></i>
          </button>
        </div>
        <hr class="mt-2 mb-0" />
        <!--<div class="row">-->
        <!--<div class="col ">-->
        <!--<div class="float-left"> <span class="align-middle catgeryNo">{{totalcategory}} &nbsp;Categories</span></div>-->
        <!--<div class="btn-group float-right">-->
        <!--<button type="button" class="btn btn-link dropdown-toggle catgeryNo" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">-->
        <!--Sort by:-->
        <!--</button>-->
        <!--<div class="dropdown-menu dropdown-menu-right">-->
        <!--<button class="dropdown-item" type="button">Action</button>-->
        <!--<button class="dropdown-item" type="button">Another action</button>-->
        <!--<button class="dropdown-item" type="button">Something else here</button>-->
        <!--</div>-->
        <!--</div>-->
        <!--</div>-->
        <!--</div>-->

        <div *ngIf="qbCategoryList.length != 0" class=" ">
          <div class="row">
            <div
              class="col-lg-4 mtop10 pr-0"
              *ngFor="let list of qbCategoryList"
            >
              <a
                class="button"
                style="cursor: pointer"
                (click)="redirectToQuestionList(list)"
                [ngClass]="[
                  'br-5',
                  list.categoryVisibility == 'public' ? 'bg-green br-green' : 'none',
                  list.assessmentType == 'Technical' ? 'technical-card-background': list.assessmentType == 'Aptitude' ? 'aptitude-card-background': ''
                ]"
                ><strong class="title eclipse">
                  {{ list.parentCategoryName }}
                  <!-- <i *ngIf="list.categoryVisibility=='private'" class="fa fa-lock arch" ></i>
                  <i *ngIf="list.categoryVisibility=='public'" class="fa fa-unlock arch" ></i> -->
                </strong>
                <span class="details">
                  <strong>{{ list.subCategoryCount }}&nbsp;</strong>Subcategory(s)
                </span>
                <span class="details">
                  <span class="vLeft"></span>
                  <strong>{{ list.categoryQuestionCount }}&nbsp;</strong>Question(s)
                </span>
              </a>
            </div>
          </div>
        </div>

        <div class="row mtop30 mbot30">
          <div class="col text-center">
            <button
              [hidden]="!isLoadmore"
              type="button"
              class="btn btn-more"
              (click)="loadmoreCategory($event)"
            >
              Load more
            </button>
          </div>
        </div>
        <div *ngIf="noDataFoundFlag" class="text-center mt-5">
          <img class=" " src="/assets/img/Create-Questions-2.png" alt="" />
          <p class="mt-3 mb-3 RR-16">No Matching Category Found</p>
        </div>
      </div>
    </div>
  </div>
</div>
<!--end for show QB Dashoboard-->

<div
  class="container-fluid create-block pt-4 mt-1"
  id="newcategory"
  [hidden]="openCategoryDiv"
>
  <!-- start for create new category-->
  <div class="row mt-0">
    <div class="col form-group">
      <h5>Create Category</h5>
    </div>
  </div>
  <form [formGroup]="parentCategoryForm">
    <div class="row mt-0">
      <div class="col">
        <label>For Assessment Type<span style="color: red">*</span> </label>
        <br />
        <div class="form-check form-check-inline">
          <input
            type="radio"
            id="categoryAptitude"
            class="form-check-input"
            name="categoryType"
            value="Aptitude"
          />
          <label class="form-check-label" for="categoryAptitude"
            >Aptitude</label
          >
        </div>
        <div class="form-check form-check-inline">
          <input
            type="radio"
            id="categoryTechnical"
            class="form-check-input"
            name="categoryType"
            value="Technical"
            checked
          />
          <label class="form-check-label" for="categoryTechnical"
            >Technical</label
          >
        </div>
      </div>
    </div>
    <div class="row mt-2">
      <div class="col">
        <label for="addcategory"
          >Category Name<span style="color: red">*</span>
        </label>
        <input
          type="text"
          id="addcategory"
          name="addcategory"
          formControlName="categoryName"
          class="form-control"
        />
        <span
          *ngIf="parentCategoryForm.controls['categoryName'].touched && parentCategoryForm.controls['categoryName'].invalid"
          class="text text-danger"
          ><small>Please Provide Valid Category Name  </small></span>
        <!-- <small *ngIf="parentName.errors.pattern" class="text-danger">Please enter valid Category name</small> -->
      </div>
    </div>
    <div class="row mt-2">
      <div class="col">
        <label for="name">Skills<span style="color: red">*</span></label>
        <div class="dropselect">
          <angular2-multiselect
            [data]="dropdownList"
            id="name"
            [ngModel]="selectedItems"
            [settings]="dropdownSettings"
            (onSelect)="onItemSelect($event)"
            (onDeSelect)="OnItemDeSelect($event)"
            (onSelectAll)="onSelectAll($event)"
            (onDeSelectAll)="onDeSelectAll($event)"
            formControlName="skillsField"
          >
          </angular2-multiselect>
        </div>
        <br />
      </div>
    </div>
    <div *ngIf="errorMessage" style="text-align: center">
      <small style="color: red">{{ errorMessage }}</small>
    </div>
    <div class="row">
      <div class="col text-center mtop30">
        <button
          type="button"
          [disabled]="parentCategoryForm.invalid || selectedTags.length == 0"
          class="btn btn-primary"
          aria-haspopup="true"
          aria-expanded="false"
          (click)="addnewCategory(parentCategoryForm.value)"
        >
          Save
        </button>
        <button
          type="button"
          aria-haspopup="true"
          class="btn btn-cancel"
          aria-expanded="false"
          (click)="landingDashBorad('cancele')"
        >
          Cancel
        </button>
      </div>
    </div>
  </form>
</div>
<!-- end for create new category-->

<div
  *ngIf="!noDataFoundFlag && qbCategoryList.length == 0"
  class="container-fluid main-div text-center"
  [hidden]="noCategoryDiv"
>
  <!-- start for main entry Landing Page-->
  <img src="./assets/img/qa-empty.png" id="" alt="" class="mt-5" />
  <h3 class="text-center mt-5">Not created any category?</h3>
  <p class="text-center">Get started!</p>
  <button
    type="button"
    class="btn btn-outline"
    aria-haspopup="true"
    aria-expanded="false"
    (click)="openNewCatDiv()"
  >
    Create Category
  </button>
</div>
<!-- end for main entry Landing Page-->

<div
  class="container-fluid create-block pt-4 mt-1"
  id="subcategory"
  [hidden]="openSubcategoryDiv"
>
  <!-- start for create sub category-->
  <form [formGroup]="subCategoryForm">
    <div class="row mt-0">
      <div class="col form-group">
        <h5>Create Sub Category</h5>
      </div>
    </div>
    <div class="row mt-0">
      <div class="col">
        <label for="addcategory"
          >For Assessment Type<span style="color: red">*</span>
        </label>
        <br />
        <div class="form-check form-check-inline">
          <input
            type="radio"
            id="subCatAptitude"
            class="form-check-input"
            name="assesstype"
            value="Aptitude"
            (change)="listOfCategoryName()"
          />
          <label class="form-check-label" for="subCatAptitude">Aptitude</label>
        </div>
        <div class="form-check form-check-inline">
          <input
            type="radio"
            id="subCatTechnical"
            class="form-check-input"
            name="assesstype"
            value="Technical"
            (change)="listOfCategoryName()"
            checked
          />
          <label class="form-check-label" for="subCatTechnical"
            >Technical</label
          >
        </div>
      </div>
    </div>
    <div class="row mt-2">
      <div class="col form-group">
        <label for="subCat">Category<span style="color: red">*</span></label>
        <select
          class="form-control"
          id="subCat"
          name="subCat"
          formControlName="categoryName"
          (change)="parentCatId($event.target.value)"
        >
          <option value="" selected >Select Category</option>
          <option
            *ngFor="let cat of qbCategoryList1"
            [value]="cat.parentCategoryId"
          >
            {{ cat.parentCategoryName }}
          </option>
        </select>
      </div>
      <div class="col form-group">
        <label for="subCatName"
          >Subcategory Name<span style="color: red">*</span>
        </label>
        <input
          class="form-control"
          type="text"
          id="subCatName"
          name="subCatName"
          formControlName="subCatName"
        />
        <!--<span *ngIf="subCatListName.touched && subCatListName.invalid" class="text text-danger">Exceeds character limit(100).</span>-->
        <span
          *ngIf="subCatListName.touched && subCatListName.invalid"
          class="text text-danger"
          ><small>Please Enter Valid Subcategory Name </small></span
        >
      </div>
    </div>
    <div class="row">
      <div class="col">
        <label for="name1">Skills</label>
        <div class="dropselect">
          <angular2-multiselect
            [data]="dropdownList"
            id="name1"
            [(ngModel)]="selectedItems"
            [settings]="dropdownSettings"
            (onSelect)="onItemSelect($event)"
            (onDeSelect)="OnItemDeSelect($event)"
            (onSelectAll)="onSelectAll($event)"
            (onDeSelectAll)="onDeSelectAll($event)"
            formControlName="tagsField"
          >
          </angular2-multiselect>
        </div>
      </div>
    </div>
    <br />
    <div *ngIf="errorMessage">
      <small style="color: red; margin-left: 120px">{{ errorMessage }}</small>
    </div>
    <div class="row">
      <div class="col text-center mtop30">
        <button
          type="button"
          [disabled]="subCategoryForm.invalid"
          class="btn btn-primary"
          aria-haspopup="true"
          aria-expanded="false"
          (click)="addSubCategory(subCategoryForm.value)"
        >
          Save
        </button>

        <button
          type="button"
          aria-haspopup="true"
          class="btn btn-cancel"
          aria-expanded="false"
          (click)="landingDashBorad('cancele')"
        >
          Cancel
        </button>
      </div>
    </div>
  </form>
</div>

<app-archive-cat-modal></app-archive-cat-modal>
