import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { ObserverService } from '../observer.service';

@Component({
  selector: 'app-live-batches',
  templateUrl: './live-batches.component.html',
  styleUrls: ['./live-batches.component.css']
})
export class LiveBatchesComponent implements OnInit {
  userRole: any;
  userDetails: any;
  liveBatches = [];
  accessToken = '';
  companyId = '';
  observerErrMessage = '';

  constructor(public router: Router, public observerService: ObserverService, public toastrService: ToastrService) { }

  ngOnInit() {
    this.accessToken = localStorage.getItem('accessToken');
    this.companyId = localStorage.getItem('companyId');
    this.userDetails = JSON.parse(localStorage.getItem('userInfo'));
    this.userRole = this.userDetails.Companies[0].RoleId;
    this.reloadLiveBatchList();
  }

  reloadLiveBatchList() {
    this.liveBatches = [];
    this.observerService.fetchListOfLiveBatches(this.companyId, this.accessToken)
      .subscribe(
        response => {
          this.liveBatches = response.liveList;
          this.observerErrMessage = '';
        },
        error => {
          const errorMsg = error.error;
          this.observerErrMessage = errorMsg.message;
        });
  }

}
