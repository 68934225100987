<app-user-header (assessmentPage)="RidirectionMessage();" (profileInfo)="dashboardRefresh()"></app-user-header>

<div *ngIf="isLoading==true" class="buttonload">
  <i class="fa fa-refresh fa-spin"></i> Loading Assessment...
</div>

<!--Breadcrumb Section-->
<nav class="container-fluid pt-3 pb-3 bd-navbar sub-header">
  <div class="row">
    <div class="col">
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb" style="cursor: pointer">
          <li class="breadcrumb-item active" aria-current="page" (click)="assessmentDashboard();">
            <a>Assessments</a>
          </li>
        </ol>
      </nav>
    </div>

    <div class="col-2">
      <div class="btn-group float-right">
        <button *ngIf="userRole == 'admin'" type="button" class="btn btn-primary " aria-haspopup="true"
          (click)="createNewAssessment();">
          Create Assessment
        </button>

      </div>
    </div>
  </div>
</nav>
<!--Breadcrumb Ends-->

<!--start of assessment dashbord landing page-->
<div *ngIf="showAssessments" class="container-fluid main-div">
  <div class="row mtop30 " id="animated">
    <div class="col-3" *ngFor="let list of assementDashboardShow; let i =index;"
      (click)="getassessmentList(list.assessmentType,list.assessments, true);">
      <a class="button" style="width:281.5px;height: 98px;">
        
        <img *ngIf="i == 0" alt="Responsive image" class="img-fluid assessment-img" src="/assets/img/aptitude-test.png">
        <img *ngIf="i == 1" alt="Responsive image" class="img-fluid assessment-img"
          src="/assets/img/technical-assessment.png">
        <img *ngIf="i == 2" alt="Responsive image" class="img-fluid assessment-img " 
          src="/assets/img/psychometric-assessment.png">
        <img *ngIf="i == 3" alt="Responsive image" class="img-fluid assessment-img"
          src="/assets/img/online-compiler.png">
        <img *ngIf="i == 4" alt="Responsive image" class="img-fluid assessment-img" style="width:58px;height:78px"
          src="/assets\img\rolebased_assessment.png" >
        <img *ngIf="i == 6" alt="Responsive image" class="img-fluid assessment-img" style="width:58px;height:78px"
          src="/assets\img\skillbased_assessment.png">
        
        <img *ngIf="i !== 0 && i !== 1 && i !== 2 && i !== 3 && i!== 4 && i!== 6 " alt="Responsive image" class="img-fluid assessment-img" src="/assets/img/technical-assessment.png">
        
        <strong class="title text-truncate pl-0"> {{list.assessmentType}} </strong>
        <p class="RR-14 grey"> {{list.assessments}} Assessment(s) </p>
        
      </a>
      
    </div>
  </div>
</div>
<!--end of assessment dashbord landing page-->



<!--no assessments found start-->
<div *ngIf="assessmentDashMessage" class="container-fluid row">
  <div class="col text-center">
    <div class="m-top">
      <img class=" mx-auto d-block" src="/assets/img/Create-Assessment-blank.png" alt="">
      <h5 class="mt-5">Not created any Assessment?</h5>
      <p *ngIf="userRole == 'admin'">Creating an Assessment is simpler now!</p>

      <button *ngIf="userRole == 'admin'" type="button" class="btn btn-primary " (click)="createNewAssessment();">
        Create New
      </button>
    </div>
  </div>
</div>
<!--no assessment found ends-->

<!----Access Denied Popup Modal-->
<div class="modal fade" id="AccessDenied" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
  aria-hidden="true" data-backdrop="static" data-keyboard="false">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Alert!</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body text-center">
        You have limited access to in-built question bank and premium features. Write to <b>support@calibrat.com'</b>.
      </div>
    </div>
  </div>
</div>
<!-- Access Denied Modal complete-->

<!--------------------Quick Assessment creation Template --->


<div class="modal fade" id="quickAssessmentModal" bsModal tabindex="-1" role="dialog" #quickAssessmentModal="bs-modal"
  [config]="{ backdrop: 'static' }">
  <div class="modal-dialog modal-md" role="document">
    <form [formGroup]="assessmentCreationFlow">
      <div class="modal-content">
        <div class="modal-header draggable-handle">
          <h5 class="modal-title">Create Assessment </h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close"
            (click)="quickAssessmentModal.hide();resetForm()">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body ">

          <p class="text2 text-center">Select whether you would like to create assessment using quick template or create
            manually</p>
          <div class="row">
            <div class="col">
              <ul class="radio-container ml-4 mt-3">
                <li>
                  <input id="radio1" class="styled-radio" name="selectedAssessmentCreationFlow"
                    formControlName="selectedAssessmentCreationFlow"
                    (input)="assessmentCreationType($event.target.value)" type="radio" value="quickTemplate" />
                  <label for="radio1" class="styled-radio-label text-center">
                    <p><img src="../../assets/img/create-questions.png" height="60" alt=""></p>Use Assessment Template
                  </label>
                </li>

                <li>
                  <input id="radio2" class="styled-radio" name="selectedAssessmentCreationFlow"
                    formControlName="selectedAssessmentCreationFlow" type="radio"
                    (input)="assessmentCreationType($event.target.value)" value="manual" />
                  <label for="radio2" class="styled-radio-label text-center">
                    <p><img src="../../assets/img/upload-questions.png" height="60" alt=""></p>
                    Create Your Own
                  </label>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="modal-footer  d-block mx-auto mbot30">
          <button type="button" [disabled]="selectedAssessmentCreationFlow==''" class="btn btn-primary"
            data-dismiss="modal" (click)="openAssessmentTypeModal()">Next</button>
          <button type="button" class="btn btn-cancel" data-dismiss="modal"
            (click)="quickAssessmentModal.hide();resetForm()">Cancel</button>
        </div>

      </div>
    </form>
  </div>
</div>

<!--------------------Quick Assessment creation Template ENDs --->


<!-------------Quick Assessment creation Template AssessmentType Modal----------->
<div class="modal fade" id="quickAssessmentTypeModal" bsModal tabindex="-1" role="dialog"
  #quickAssessmentTypeModal="bs-modal" [config]="{ backdrop: 'static' }">
  <div class="modal-dialog modal-md" role="document">
    <form [formGroup]="quickAssessmentType">
      <div class="modal-content">
        <div class="modal-header draggable-handle">
          <h5 class="modal-title">Create Assessment </h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close"
            (click)="quickAssessmentTypeModal.hide();resetForm()">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body ">

          <p class="text2 text-center">Select Assessment Type</p>
          <div class="row">
            <div class="col">
              <ul class="radio-container ml-4 mt-3">
                <li>
                  <input id="radio3" class="styled-radio" name="selectedquickAssessmentType"
                    formControlName="selectedquickAssessmentType" (input)="selectedAssessmentType($event.target.value)"
                    type="radio" value="Aptitude" />
                  <label for="radio3" class="styled-radio-label text-center">
                    <p><img src="/assets/img/aptitude-test.png" height="60" alt=""></p>Aptitude
                  </label>
                </li>

                <li>
                  <input id="radio4" class="styled-radio" name="selectedquickAssessmentType"
                    formControlName="selectedquickAssessmentType" type="radio"
                    (input)="selectedAssessmentType($event.target.value)" value="Technical" />
                  <label for="radio4" class="styled-radio-label text-center">
                    <p><img src="/assets/img/technical-assessment.png" height="60" alt=""></p>
                    Technical
                  </label>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="modal-footer  d-block mx-auto mbot30">
          <button type="button" [disabled]="selectedTemplate==''" class="btn btn-primary" data-dismiss="modal"
            (click)="showTemplateList()">Next</button>
          <button type="button" class="btn btn-cancel" data-dismiss="modal"
            (click)="quickAssessmentTypeModal.hide();resetForm()">Cancel</button>
        </div>

      </div>
    </form>
  </div>
</div>

<!-------------Quick Assessment creation Template AssessmentType Modal Ends----------->

