<section class="bg-white">
  <app-header-static></app-header-static>
</section>
<div class="container">
  <div class="container pt-5 pb-2 ">
    <div class="row ">
      <div class="col">
        <p class="GB-40 text-center">Privacy Policy</p>
      </div>
    </div>
    <div class="row ">
      <div class="col-md-12 pb-2 pl-0">
        <p class="RR-13 font-weight-bold f-size-14">Last modified : February 5, 2020</p>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-md-12 pb-5">
      <p class="RR-13">Your privacy is important to us. This privacy statement explains the personal data collected by TalentBridge, how TalentBridge processes the personal data and the purpose of processing the personal data collected.</p>
      <p class="RR-13">TalentBridge offers two products</p>
      <ol type="a" class="RR-13">
        <li>ODIGO – Enterprise Messaging and Tracking Platform</li>
        <li>CALIBRAT – Online Assessment Platform</li>
      </ol>
      <p class="RR-13">References to TalentBridge products in this statement include TalentBridge and TalentBridge’s product websites, applications and related services including support.</p>
      <p class="RR-16 font-weight-bold text-gray">Personal Data TalentBridge Collects</p>
      <p class="RR-13">TalentBridge collects data from the user, through the interactions with the user and through the user’s usage of products. User provide some of this data directly and some of this data will be collected by the user’s usage of the product. The data collected depends on the context of the user’s interaction with TalentBridge and the choices made, including the configurations preferred in the products and features that the user use.</p>
      <p class="RR-13">TalentBridge takes the user consent while collecting the personal information. When TalentBridge asks the user to provide personal data, the user have a choice decline. TalentBridge’s products require some personal data to provide the user with a service(s). If the user choose not to provide data, the user cannot use that product or feature. Likewise, where TalentBridge needs to collect personal data by law or to enter into or carry out a contract with the user, and the user do not provide the data, TalentBridge will not be able to enter into the contract; or if this relates to an existing product the user is using, TalentBridge may have to terminate the service(s) or the product usage or the feature(s) usage. TalentBridge will notify the user about the termination.</p>
      <p class="RR-13">Where providing the data is optional, and the user choose not to share personal data, features like personalisation that use such data will not work for the user.</p>
      <p class="RR-13">The personal information TalentBridge collects may include but not limited to the User’s</p>
         <ol class="RR-13">
           <li>Name, Mobile Number, Email Address</li>
           <li>Live images and Geo location</li>
           <li>Audio and Video recording</li>
           <li>IP Address and Device details</li>
           <li>Any personal or sensitive data that the user may provide in the course of using the product/service(s).</li>
         </ol>
      <p class="RR-13">The personal data collected by TalentBridge varies from the product used and the features used by your User’s Enterprise Account.</p>
      <p class="RR-16 font-weight-bold text-gray">Purpose Of Processing Personal Data</p>
      <p class="RR-13">TalentBridge collects the personal data through ODIGO and CALIBRAT from the users of the enterprise account.</p>
      <p class="RR-13">TalentBridge process the user’s personal data on the grounds of contractual arrangement between TalentBridge and the enterprise using the Product/Service(s).</p>
      <p class="RR-13">Through TalentBridge’s products, the Enterprise collects the personal data from its users. The usage of collected personal data includes, but not limited to</p>
      <ol class="RR-13">
        <li>Create a consolidated report on the activity of the users</li>
        <li>Create a consolidated report on the evaluation of assessment of the users</li>
        <li>Capture live face image to avoid impersonation</li>
        <li>Capture live location to track the field operations</li>
      </ol>
      <p class="RR-13">TalentBridge uses the personal information to communicate to the user about the product enhancements.</p>
      <p class="RR-13">Any personal information or sensitive information that the user may provide during the course of use of the product, will be processed on contractual requirement.</p>
      <p class="RR-13">The collected personal information will be used for the mentioned purpose. At any point of time, the users personal information is shared with any third party, the consent will be obtained from the user.</p>
      <p class="RR-16 font-weight-bold text-gray">Data Retention Period</p>
      <p class="RR-13">TalentBridge will retain the user’s data in accordance with the Enterprise Account’s instructions, including any applicable terms in the agreement between TalentBridge and The Enterprise Account. The deletion of user data may result in deletion and/or de-identification of certain associated information. TalentBridge may retain other information pertaining to the user as long as necessary for the purpose of processing. This may include retaining the user’s other information after deactivation of user account for the period of time required by TalentBridge to pursue legitimate business interests, conduct audits, comply with legal obligations, resolve disputes and enforce the agreement between TalentBridge and the Enterprise. For this reason, any request from User for deletion processed by the enterprise account result in Pseudonymization/anonymization of User identity till the retention of Enterprise Account’s information.</p>
      <p class="RR-16 font-weight-bold text-gray">Data Storage</p>
      <p class="RR-13">TalentBridge’s product uses the following third party service(s) to host the user data or provide other infrastructure that helps with the delivery of associated features.</p>
      <ol class="RR-13">
        <li>Amazon Web Services, Singapore - Cloud Service Provider</li>
        <li>Google Inc, United States - Cloud Service Provider</li>
        <li>Twilio, United States - SMS Carrier</li>
      </ol>
      <p class="RR-16 font-weight-bold text-gray">User Data Protection Rights</p>
      <p class="RR-13">The users mapped with the Enterprise Account can exercise their right by writing to Enterprise Account Administrator. Direct users and Enterprise Account Administrator can exercise their rights by writing to <span>   <a href="mailto:privacy@talentbridge.co.in" style="display:inline "> privacy@talentbridge.co.in</a></span></p>
      <p class="RR-13">Please provide the following details for user identification:</p>
      <p class="RR-13">Name <br/> Mobile Number <br/>Product using/used <br/> Email address <br/> Enterprise Account Name</p>
      <p class="RR-13">Any issue or grievance with respect to privacy raised by the user will be addressed within 30 days from the date of receipt of the request. Any queries or concerns about this privacy policy, the users can contact us at</p>
      <p class="RR-13">
        TalentBridge Technologies Pvt. Ltd. <br/>
        91 Springboard Business Hub Private Limited, <br/>
        5th Floor, 175 & 176, Bannerghatta Main Rd, Dollars Colony,<br/>
        Bengaluru, Karnataka 560076, India. <br/>PH: 080 42086194 <br/>Email: <span> <a href="mailto:privacy@talentbridge.co.in">privacy@talentbridge.co.in</a> </span></p>
      <p class="RR-13">This privacy policy is effective from February 5, 2020.</p>
    </div>
  </div>
</div>
<app-footer-static></app-footer-static>
