<app-user-header (assessmentPage)="RidirectionMessage();" (profileInfo)="dashboardRefresh()"></app-user-header>

<div *ngIf="isLoading==true" class="buttonload">
  <i class="fa fa-refresh fa-spin"></i>  Loading Assessment...
</div>

<nav *ngIf="!createAssessment" class="container-fluid pt-3 pb-3 bd-navbar sub-header">
  <div class="row">
    <div class="col">
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb" style="cursor: pointer">
          <!--<li class="breadcrumb-item" routerLink="/dashboard"><a >Home</a></li>-->
          <li class="breadcrumb-item active" aria-current="page" (click)="assessmentDashboard();">
            <a>Assessments</a>
          </li>
          <li *ngIf="assessmentList1 || assessmentDescriptionDash" class="breadcrumb-item inactive" aria-current="page">
            <a> {{assessmentTypeForLoadMore}} </a>
          </li>
        </ol>
      </nav>
    </div>

    <div class="col-2">
      <div class="btn-group float-right">
        <button *ngIf="multipleDelete && !assessmentDescriptionDash" class="btn btn-link"
                (click)="deleteMultipleAssessment()">
          <span class="align-middle"><img class=" " src="/assets/img/Delete.png" alt=""> Delete</span>
        </button>
       
        <button *ngIf="userRole == 'admin' && assessmentTypeForLoadMore=='Aptitude' || assessmentTypeForLoadMore=='Technical' " type="button" class="btn btn-primary " aria-haspopup="true" (click)="createNewAssessment();">
          Create Assessment
        </button>
        <button *ngIf="userRole == 'admin' &&  assessmentTypeForLoadMore=='Role Based' || assessmentTypeForLoadMore==='POSH' || assessmentTypeForLoadMore==='Skill Based' " type="button" class="btn btn-primary " aria-haspopup="true" (click)="createNewRoleBasedAssessment();">
          Create Assessment
        </button>
        <button *ngIf="userRole == 'admin' && assessmentTypeForLoadMore=='Psychometer' ||assessmentTypeForLoadMore=='Online Compiler' " type="button" class="btn btn-primary " aria-haspopup="true" (click)="createNewAssessmentManually();">
          Create Assessment
        </button>
      </div>
    </div>
  </div>
</nav>


<!--No assessments  start-->
<!--<div *ngIf="assessmentDashMessage" class="container-fluid row">-->
<!--<div class="col text-center">-->
<!--<div class="m-top">-->
<!--<img class=" mx-auto d-block" src="/assets/img/Create-Assessment-blank.png" alt="">-->
<!--<h5 class="mt-5">Not created any Assessment?</h5>-->
<!--<p>Creating an Assessment is simpler now!</p>-->

<!--<button type="button" class="btn btn-primary " (click)="createNewAssessment();">-->
<!--Create New-->
<!--</button>-->
<!--</div>-->
<!--</div>-->
<!--</div>-->
<!--No assessments ends-->


<!--start showing the assessment creation and its Details-->

<!-- start asseesment list page2-->
<div *ngIf="assessmentList1" >
  <div class="row no-gutters">
    <div class="col-3 col-sm">
      <div class="panel-left container-fluid bg1 pb-0">
        <div style="position: fixed; top: auto; left:12px;" class="pr-5">

          <h6 class="ml-2"><b>Search</b></h6>
          <div class="caldiv clearfix ">
            <input
              class="form-control "
              type="text"
              placeholder="Assessment Name"
              [(ngModel)]="searchStr"
              (keyup.enter)="search()"

            />
          </div>
          <div class="caldiv clearfix ml-0" style="display: flex;">
            <button
              type="button"
              class="btn btn-outline ml-0 btn-more side-panel-btn"
              (click)="search()"
              [disabled]="searchStr.length < 3"
              aria-expanded="false"
            >
              Search
            </button>

          </div>
        </div>
      </div>
    </div>

    <div class="col-9">

      <div class="panel-right container-fluid">
        <div class="row">
          <p *ngIf="assessmentNumber > 0 " class="RR-14 ml-3">{{assessmentTypeForLoadMore}} - {{assessmentNumber}} / {{totalCount}}
            Assessment(s)</p>
          <!-- <span *ngIf="searchTextTag.length!=0" class=" RR-14 btn-primary srchbadge">
                {{searchTextTag}} <button class=" btn fa fa-close srchbadgebtn  pl-0" (click)="resetSearch()">
              </button>
          </span> -->
          <button *ngIf="searchTextTag.length!=0 && searchTextTag.length < 9" (click)="resetSearch()" class="btn  srchbadge "> {{searchTextTag}} <i class="fa fa-close"></i> </button>
          <button *ngIf="searchTextTag.length!=0 && searchTextTag.length > 8" data-toggle="tooltip" data-placement="top" title="{{searchTextTag}}" (click)="resetSearch()" class="btn srchbadge"> {{searchTextTag|slice:0:8}}... <i class="fa fa-close"></i> </button>


        </div>
        <hr *ngIf="noDataFoundFlag"  class="mt-2 mb-0"/>
        <div  *ngIf="!noDataFoundFlag" class="row" id="customers">
          <div class="col">
            <div class="card ">
              <div class="card-body ">
                <div class="row GR-14">
                  <div class="col-1 "></div>
                  <div class="col-3">Assessment Name</div>
                  <div class="col">Section</div>
                  <div class="col">Questions</div>
                  <div class="col">Duration</div>
                  <div class="col" [hidden]="hidePassing">Passing(%)</div>
                  <div class="col">Modified On</div>
                  <div class="col-1"></div>
                </div>
              </div>
            </div>

            <!-- <div *ngIf="noDataFoundFlag" class="text-danger errorField text-center mt-3">
              <p class="mb-0">No Data Found</p>
            </div> -->
            <div class="card card__two mtop15 border-0" *ngFor="let list of getAssementList; let i=index;">
              <div class="card-body card-bg">
                <div class="row">
                  <div class="col-1 text-center">
                    <label *ngIf="userRole == 'admin'"  class="mb-0"><input id="assessment-{{list._id}}" value="{{list._id}}" (change)="selectedDelete($event);" type="checkbox"
                                                                            class="mr-3"></label>
                  </div>
                  <div class="col-3 text-truncate qHeading dect" (click)="showAssessmentDetails(list);">
                    {{list.assessmentName}}
                    <!-- <i class="fa fa-send ml-2" aria-hidden="true" *ngIf="list.quickAssessmentTemplateId != ''"></i> -->
                  </div>
                  <div class="col text-truncate qRest ">{{list.sections.length}}</div>
                  <div class="col text-truncate qRest ">{{list.questionCount}}</div>
                  <div class="col text-truncate qRest ">{{list.duration}}</div>
                  <div class="col text-truncate qRest " [hidden]="hidePassing" > {{list.cutOff}}</div>
                  <div class="col text-truncate qRest ">{{list.modifiedOn | date: 'dd/MM/yyyy' }}</div>

                  <div class="col-1">
                    <button *ngIf="userRole == 'admin'"  type="button" class="btn float-right btnMore" role="button" id="dropdownMenuLink"
                            [style.visibility]="!multipleDelete && !assessmentDescriptionDash ? 'visible' : 'hidden'"    data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                      <i class="material-icons">more_vert</i>
                    </button>
                    <div *ngIf="userRole == 'admin'" class="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuLink"
                         x-placement="bottom-end"
                         style="position: absolute; transform: translate3d(-155px, 25px, 0px); top: 0; left: 0; will-change: transform;">
                      <a class="dropdown-item" *ngIf="list.quickAssessmentTemplateId == ''" [ngClass]="{'disabled': masterQBAccess == false && editFlag == false}"  (click)="editAssessment(list._id);">Edit</a>
                      <a class="dropdown-item" *ngIf="list.quickAssessmentTemplateId != ''"  (click)="editAssessment(list._id);">Edit</a>

                      <a class="dropdown-item" (click)="deleteAssessment(list._id,false);">Archive</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row my-5">
          <div class="col text-center">
            <button type="button" [hidden]="!isLoadmore" class="btn btn-more  mtop15 "
                    (click)="loadMoreAssessmentList($event);">
              Load more
            </button>
          </div>
        </div>
        <div *ngIf="noDataFoundFlag" class="text-center">
          <img class=" " src="/assets/img/Create-Assessment.png"  alt="">
          <p class="mt-3 mb-3 RR-16">No Matching Assessment Found</p>
        </div>


      </div>


    </div>
  </div>
</div>


<!-- end asseesment list page2-->


<div class="modal fade" id="deleteMultiple" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
     aria-hidden="true" data-backdrop="static" data-keyboard="false">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Alert!</h5>
        <button type="button" (click)="multiDeleteCancel();" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body text-center">
        Are you sure you want to delete the assessment(s)?
      </div>
      <div class="modal-footer d-block mx-auto">
        <button type="button" class="btn btn-primary" data-dismiss="modal" (click)="deleteMultipleData();">Yes</button>
        <button type="button" class="btn btn-cancel" data-dismiss="modal" (click)="multiDeleteCancel();">No</button>
      </div>
    </div>
  </div>
</div>

<div class="modal fade" id="deleteSingleAssessmentData" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
     aria-hidden="true" data-backdrop="static" data-keyboard="false">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Alert!</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body text-center">
        Are you sure you want to archive this assessment?
      </div>
      <div class="modal-footer d-block mx-auto">
        <button type="button" class="btn btn-primary" data-dismiss="modal" (click)="deleteAssessmentDATA(true);">Yes
        </button>
        <button type="button" class="btn btn-cancel" data-dismiss="modal">No</button>
      </div>
    </div>
  </div>
</div>

<div class="modal fade" id="deleteAssessmentData" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
     aria-hidden="true" data-backdrop="static" data-keyboard="false">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Alert!</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body text-center">
        Are you sure you want to archive this assessment?
      </div>
      <div class="modal-footer d-block mx-auto">
        <button type="button" class="btn btn-primary" data-dismiss="modal" (click)="deleteAssessmentDATA(false);">Yes
        </button>
        <button type="button" class="btn btn-cancel" data-dismiss="modal">No</button>
      </div>
    </div>
  </div>
</div>

<!----Access Denied Popup Modal-->
<div class="modal fade" id="AccessDenied" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
     aria-hidden="true" data-backdrop="static" data-keyboard="false">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Alert!</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body text-center">
        You have limited access to in-built question bank and premium features. Write to <b>support@calibrat.com'</b>.
      </div>
    </div>
  </div>
</div>
<!-- Access Denied Modal complete-->


<!--------------------Quick Assessment creation Template --->


<div
  class="modal fade"
  id="quickAssessmentModal"
  bsModal
  tabindex="-1"
  role="dialog"
  #quickAssessmentModal="bs-modal"
  [config]="{ backdrop: 'static' }"
>
  <div class="modal-dialog modal-md" role="document">
    <form [formGroup]="assessmentCreationFlow" >
    <div class="modal-content">
      <div class="modal-header draggable-handle">
        <h5 class="modal-title">Create Assessment </h5>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
          (click)="quickAssessmentModal.hide();resetForm()"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <p class="text2 text-center">Select whether you would like to create assessment using quick template or create manually</p>
        <div class="row">
          <div class="col">
            <ul class="box-styled-radio-container ml-4 mt-3">
              <li>
                <input id="radio1" class="box-styled-radio" name="selectedAssessmentCreationFlow" formControlName="selectedAssessmentCreationFlow" formGroupName="selectedAssessmentCreationFlow" (input)="assessmentCreationType($event.target.value)" type="radio" value="quickTemplate" />
                <label for="radio1" class="box-styled-radio-label text-center">
                  <p><img src="../../assets/img/create-questions.png"  height="60" alt=""></p>Use Assessment Template</label>
              </li>

              <li>
                <input id="radio2" class="box-styled-radio" name="selectedAssessmentCreationFlow" formControlName="selectedAssessmentCreationFlow" formGroupName="selectedAssessmentCreationFlow" type="radio" (input)="assessmentCreationType($event.target.value)" value="manual" />
                <label for="radio2" class="box-styled-radio-label text-center">
                  <p><img src="../../assets/img/upload-questions.png"  height="60" alt=""></p>
                  Create Your Own</label>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="modal-footer  d-block mx-auto mbot30">
        <button type="button" [disabled]="selectedAssessmentCreationFlow==''" class="btn btn-primary" data-dismiss="modal" (click)="routeToRespectiveUrl()">Next</button>
        <button type="button" class="btn btn-cancel" data-dismiss="modal" (click)="quickAssessmentModal.hide();resetForm()">Cancel</button>
      </div>

    </div>
    </form>
  </div>
</div>

<!--------------------Quick Assessment creation Template ENDs --->
