<section class="bg-white">
  <app-header-static></app-header-static>
</section>
<section class="bg-white c-pattern mobile_block">
  <div class="container pt-5 pb-3">
    <div class="row">
      <div class="col">
        <h1 class="GB-40 text-center">Skill Based Assessment</h1>
      </div>
    </div>
  </div>

  <div class="container-fluid">
    <div class="container pb-5 pt-5">
      <div class="row">
        <div class="row justify-content-between">
          <div class="col-lg-3 data-filter-container">
            <div class="card">
              <div class="card-body text-left">
                <section id="assessmentType">
                  <label class="RR-16 font-weight-bold text-gray">Assessment Type</label>
                  <select
                    class="form-control selectFontSize selectWidth"
                    name="assessmentType"
                    (change)="setAssessmentType()"
                    [(ngModel)]="forAssessmentType"
                  >
                    <option value="" selected disabled>Assessment Type</option>
                    <option value="Aptitude">Aptitude</option>
                    <option value="Technical">Technical</option>
                  </select>
                </section>

                <section id="functionalField">
                  <label class="RR-16 font-weight-bold text-gray">Function</label>
                  <select
                    class="form-control selectFontSize selectWidth mb-2"
                    name="assessmentType"
                    (change)="setFunctionLevelOne()"
                    [(ngModel)]="functionLevel1"
                  >
                    <option value="" selected disabled>Level 1</option>
                    <option
                      *ngFor="let functionObj of quickAssessmentFunctionLevel"
                    >
                      {{ functionObj.name }}
                    </option>
                  </select>
                  <select
                    *ngIf="functionLevel2Flag"
                    class="form-control selectFontSize selectWidth mb-2"
                    name="assessmentType"
                    (change)="setFunctionLevelTwo()"
                    [(ngModel)]="functionLevel2"
                  >
                    <option value="" selected disabled>Level 2</option>
                    <option
                      *ngFor="let function2 of quickAssessmentFunctionLevel2"
                    >
                      {{ function2.name }}
                    </option>
                  </select>
                  <select
                    *ngIf="functionLevel3Flag"
                    class="form-control selectFontSize selectWidth mb-2"
                    name="assessmentType"
                    (change)="setFunctionLevelThree()"
                    [(ngModel)]="functionLevel3"
                  >
                    <option value="" selected disabled>Level 3</option>
                    <option
                      *ngFor="let function3 of quickAssessmentFunctionLevel3"
                    >
                      {{ function3.name }}
                    </option>
                  </select>
                </section>

                <section id="industry">
                  <label class="RR-16 font-weight-bold text-gray">Industry</label>
                  <select
                    class="form-control selectFontSize selectWidth"
                    name="assessmentType"
                    (change)="setIndustry()"
                    [(ngModel)]="selectedIndustry"
                  >
                    <option value="" selected disabled>Select Industry</option>
                    <option *ngFor="let industry of industryList">
                      {{ industry.name }}
                    </option>
                  </select>
                </section>

                <section id="jobRole">
                  <label class="RR-16 font-weight-bold text-gray">Job Role</label>
                  <select
                    class="form-control selectFontSize selectWidth"
                    name="assessmentType"
                    (change)="setJobRole()"
                    [(ngModel)]="selectedJobRole"
                  >
                    <option value="" selected disabled>Select Job Role</option>
                    <option *ngFor="let job of receivedjobRoleList">
                      {{ job.name }}
                    </option>
                  </select>
                </section>

                <section id="accessibility">
                  <label class="RR-16 font-weight-bold text-gray">Accessibility</label>
                  <select
                    class="form-control selectFontSize selectWidth"
                    name="assessmentType"
                    (change)="setAccessibility()"
                    [(ngModel)]="templateAccessibility"
                  >
                    <option value="" selected disabled>Accessibility</option>
                    <option value="open">Open</option>
                    <option value="restricted">Restricted</option>
                  </select>
                </section>
              </div>
            </div>
          </div>
          <div class="col-12 col-md-9 col-lg-9">
            <div class="row">
              <div class="col-12">
                <a class="filterTag" *ngFor="let tag of searchTaglist">
                  {{ tag }} <i class="fa fa-close cursor-pointer" (click)="removeFilter(tag)"> </i>
                </a>
                <a *ngIf="searchTaglist.length > 1" class="filterTag">
                  Clear All <i class="fa fa-close cursor-pointer" (click)="clearFilter()"></i>
                </a>
              </div>
              <!-- <div class="col-4 input-group pl-0 pr-0">
                <input
                  class="input-group-text form-control"
                  type="text"
                  placeholder=" Search "
                  [(ngModel)]="searchText1"
                  (keyup.enter)="searchTemplate()"
                />
                <div class="input-group-append">
                <span
                  class="input-group-text"
                  [ngClass]="[searchText1.length < 3 ?'disabled2':'btnclr']"
                  id="search-btn"
                  (click)="searchTemplate()"
                ><i class="fa fa-search"></i
                ></span>
                </div>
              </div> -->
            </div>

            <div class="row mtop30" id="animated">
              <div class="col-12 col-md-8 no-temp-error" *ngIf="noTemplateFoundErr">
                <p class="mt-5">
                  <img src="assets/img/hourglass.png" alt="Coming soon" class="mr-4 rotate">
                  {{ noTemplateFoundErr }}
                </p>
              </div>
              <div class="col-lg-4 col-12 col-md-4" *ngFor="let list of skillBasedTemplateList; let i = index">
                <a class="button visible-right-border">
                  <p class="pr-2 pl-2">
                    <strong class="title text-truncate pl-2 text-truncate pt-3">
                      {{ list.templateTitle }}
                    </strong>
                  </p>
                  <p class="RR-12 mb-1 mt-2 pr-2 pl-2 meta-info-color">
                    <i class="fa fa-hashtag pl-2"></i> {{ list.metaInfo.difficultyLevel}}
                    <i class="fa fa-clock-o pl-2"></i> {{ list.duration | titlecase }}
                    <i class="fa fa-question-circle pl-2"></i> {{ list.questionCount }} Question(s)<br />
                  </p>
                  <p class="RR-12 pr-2 pl-2 meta-info-color">
                    <i style="text-transform: uppercase" class="fa fa-suitcase pl-2 "></i><span class="text-truncate ml-1">{{ showTruncatedIndustryTitle(list.metaInfo.industry.title)}}</span>
                    <img src="assets/images/Experience.svg" class="pl-2" height="13" alt="Experience" /> {{ list.metaInfo.experienceLevel.min }} to {{list.metaInfo.experienceLevel.max }} Year(s)
                  </p>
                  <div class="pr-2 pl-2">
                    <button type="button" class="btn btn-primary my-2 ml-2 btn-responsive" (click)="getTemplateDetails(list)">View Details</button>
                  </div>
                </a>
              </div>
              <div *ngIf="isMoreTemplateFlag" class="row" style="margin: auto;">
                <div class="col text-center">
                  <button type="button"  class="btn btn-more mtop30 "
                          (click)="loadMore()">
                    Load more
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<app-footer-static></app-footer-static>
