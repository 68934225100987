<app-user-header></app-user-header>

<nav class="container-fluid pt-3 pb-3 bd-navbar sub-header">
  <div class="row">
    <div class="col">
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb" style="cursor: pointer">
          <i class="material-icons green align-middle" routerLink="/observer">keyboard_arrow_left</i>
          <li class="breadcrumb-item active" aria-current="page" routerLink="/observer"><a>Observer</a></li>
          <li class="breadcrumb-item text-truncate"><a>{{assessment_name}}</a></li>
        </ol>
      </nav>
    </div>
  </div>
</nav>

<div class="container-fluid"><!--start for showing candidate live list-->
  <div class="row">
    <div class="col-3 pl-0">
      <div class="panel-left container-fluid bg1">
      </div>
    </div>
    <div class="col-9" style="margin-top: 160px;">
      <div class="row">
        <div class="col-6">
          <h5>{{assessment_name}}</h5>
          <label>Candidate Live: <span class="text-success"> {{live_batch_candidates.length}} </span> </label>
        </div>
        <div class="col-6">
          <!--<p class="text-info text-right pt-3" (click)="completedCandList();" style="cursor: pointer;"> View completed list</p>-->
        </div>
      </div>
      <div class="row mt-3" style="padding-right: 15px;" *ngIf="live_batch_candidates.length == 0">
        <div class="col-12 mt-12 info_container bg1">
          No live candidate is available at this moment for this batch
        </div>
      </div>
      <div class="row mt-3" *ngIf="live_batch_candidates.length > 0">
        <div class="col-3 mt-3" *ngFor="let candidate of live_batch_candidates; let i = index">
          <div class="text-center border candidate_box" style="width: 180px;height: 270px;" (click)="observerCandidateDetail(candidate);">
            <img class="mt-3" style="height:150px;width:150px;" src={{candidate.snapshot.fileName}}>
            <p class="mt-1 mb-1 GR-14">{{candidate.name}}</p>
            <span *ngIf="candidate.suspiciousCount != 0 " class="badge badge-danger" style="border-radius: 0px;">
            <i class="material-icons align-middle" style="font-size: 12px;">warning</i> {{candidate.suspiciousCount}} Counts</span>

            <span *ngIf="candidate.faceViolationCount != 0 " class="badge badge-danger" style="border-radius: 0px;">
              <i class="material-icons  align-middle" style="font-size: 12px;">photo</i> {{candidate.faceViolationCount}} Counts
            </span>
            <p class="text-center GR-12 mt-3">{{candidate.testStartTime | date:'d MMM y'}}, {{candidate.testStartTime | date:'shortTime'}}</p>

          </div>
        </div>
      </div>
    </div>
  </div>
</div>


<div class="modal fade" id="showProctorData" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" data-backdrop="static" data-keyboard="false">
  <div class="modal-dialog modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Candidate Snapshot</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="proctorSnapshot();">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body mt-0">
        <div class="row">
          <div class="col-6">
            <p class="">Name: {{selCandidateName}}</p>
            <p class="">Mobile No: {{selCandidatePhoneNumber}}</p>
          </div>
          <div class="col-6 text-right">
            <div *ngIf="assessment_type == 'Aptitude'">
              <span><img  alt="Responsive image" src="/assets/img/aptitude-test.png" style="height:20px;" ></span>
              <span>{{assessment_name}}</span>
            </div>
            <span *ngIf="assessment_type == 'Technical'"><img  alt="Responsive image"  src="/assets/img/technical-assessment.png" style="height:20px;" >
                <p>{{assessment_name}}</p>
            </span>
          </div>
        </div>
        <hr class="mt-0">

        <!-- main slider carousel -->
        <div class="row">
          <div class="col-lg-12 offset-lg-0" id="slider">
            <!-- main slider carousel items -->

            <div class="row">
              <div *ngIf="candidateBaseImg != ''" class="col">

                <div id="carouselExampleControls" class="carousel slide" data-ride="carousel" data-interval="2000">

                  <div class="carousel-inner">

                    <div class="active item carousel-item" attr.data-slide-number="0">
                      <div style="height: 250px; background-size: contain;">
                        <img src="{{candidateBaseImg}}" class="col img-fluid">
                      </div>
                    </div>
                    <div class="item carousel-item" *ngFor="let candidateImgObj of candidateImages; let i=index;" attr.data-slide-number="{{i+1}}">
                      <div *ngIf ="candidateImgObj.suspiciousCount">
                        <div style="height: 250px; background-size: contain;">
                          <img src="{{candidateImgObj.fileName}}" class="col img-fluid">
                        </div>
                        <div class="suspicion_reason_cnt">{{candidateImgObj.reason}}</div>
                      </div>
                      <div *ngIf ="!candidateImgObj.suspiciousCount">
                        <div style="height: 250px; background-size: contain;">
                          <img src="{{candidateImgObj.fileName}}" class="col img-fluid">
                        </div>
                      </div>
                    </div>
                  </div>
                  <a class="carousel-control-prev"  href="#carouselExampleControls" role="button" data-slide="prev">
                    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                    <span class="sr-only">Previous</span>
                  </a>
                  <a class="carousel-control-next"  href="#carouselExampleControls" role="button" data-slide="next" >
                    <span class="carousel-control-next-icon" aria-hidden="true"></span>
                    <span class="sr-only">Next</span>
                  </a>
                </div>
              </div>

              <div class="col">
                  <div  id="mapss" style="width:100%;height:150px"></div>
                <span class="badge badge-light mb-2 mt-3" style="color:#808080" >
                        <i class="material-icons mr-1 align-middle" style="font-size: 12px">warning</i> Violation  <span style="color:#000">{{candidateSuspiciousCount}}</span> </span>
                <br><span  class="badge badge-light mb-2" style="color:#808080" >
                      <i class="material-icons mr-1 align-middle" style="font-size: 12px">image</i>Image Violation  <span style="color:#000">{{candidateFaceViolationCount}}</span></span>
                <br><span  class="badge badge-light mb-2" style="color:#808080" >
                      <i class="material-icons mr-1 align-middle" style="font-size: 12px">schedule</i>Time Spent  <span style="color:#000">{{candidateTimeSpentForAssessment}}</span> </span>

              </div>

              <!-- main slider carousel nav controls -->


              <div *ngIf="candidateBaseImg != '' && candidateImages.length > 0" class="carousel-inner ml-3 thumnail">
                <div  class="indicators list-inline mt-3" *ngFor="let image of candidateImages; let j = index;">

                  <div *ngIf="j == 0 " class=" active list-inline-item" style="position: fixed;z-index:33">
                    <a class=" selected"  attr.data-slide-to="{{j}}" data-target="#carouselExampleControls">
                      <img src="{{candidateBaseImg}}" class="" height="100px" width="100px">
                      <img src="assets/img/attendee_snapshot.png" class="snapshot-badge" style="top: 0;">
                    </a>
                  </div>
                  <div *ngIf="j == 0 " class=" list-inline-item diff" >
                    <a class="selected"  attr.data-slide-to="{{j+1}}" data-target="#carouselExampleControls" >
                      <div *ngIf ="image.suspiciousCount">
                        <img src="{{image.fileName}}" class="" height="100px" width="100px">
                        <span aria-hidden="true"  style="color:red;position:absolute;top:15px;z-index:30;margin-left: -1.5rem;"><i class="material-icons">flag</i></span>
                      </div>
                      <div *ngIf ="!image.suspiciousCount">
                        <img src="{{image.fileName}}" class="" height="100px" width="100px">
                      </div>
                    </a>
                  </div>
                  <div *ngIf="j > 0 " class="list-inline-item ml-1 diff" >
                    <a class="selected"  attr.data-slide-to="{{j+1}}" data-target="#carouselExampleControls" >
                      <div *ngIf ="image.suspiciousCount">
                        <img src="{{image.fileName}}" class="" height="100px" width="100px">
                        <span aria-hidden="true"  style="color:red;position:absolute;top:15px;z-index:30;margin-left: -1.5rem;"><i class="material-icons">flag</i></span>
                      </div>
                      <div *ngIf ="!image.suspiciousCount">
                        <img src="{{image.fileName}}" class="" height="100px" width="100px">
                      </div>
                    </a>
                  </div>
                </div>
              </div>

              <div *ngIf="candidateBaseImg != '' && candidateImages.length == 0" class="carousel-inner ml-3 thumnail">
                <div class=" active list-inline-item mt-3">
                  <a class=" selected"  attr.data-slide-to="0" data-target="#carouselExampleControls">
                    <img src="{{candidateBaseImg}}" class="" height="100px" width="100px">
                  </a>
                </div>
              </div>

            </div>
            <!-- main slider carousel -->
            <!-- notify changes -->
          </div>
        </div>
        <hr>
        <div class="row mt-3">
          <div class="col">
            <label >Notify User</label>
            <select (change)="updateNotificationDetails($event)" class="custom-select" id="ddNotificationType">
              <option value="">Select Reason</option>
              <option *ngFor="let List of notificationData" [value]="List.notificationTitle">{{List.notificationTitle}}</option>
              <option value="Other">Other</option>
            </select>
            <div style="margin-top: 15px !important;">
              <textarea rows="3" colums="5" class="form-control" [hidden]="hideNotifyMsgBox" [(ngModel)]="customNotificationMsg" (keyup)="updateTextAreaValue($event)"></textarea>
            </div>
          </div>
          <div class="col"></div>
        </div>
        <small style="color: red;margin-left: 15px">{{errorMessage}}</small>
        <div class="text-left mt-3">
          <button *ngIf="assessment_type != 'Online Compiler'" type="button" class="btn btn-primary" (click)="notifyCandidate('terminate')" >Terminate User</button>
          <button type="button" *ngIf="notifyValue!='Other'" [disabled]="notifyValue==''|| notifyValue==undefined" class="btn btn-primary" (click)="notifyCandidate('notify')" >Notify User</button>
          <button type="button" *ngIf="notifyValue == 'Other'" [disabled]="textAreaValue==''" class="btn btn-primary" (click)="notifyCandidate('notify')" >Notify User</button>
        </div>
      </div>
    </div>
  </div>
</div>
<img class="p-by" src="assets/img/p-by.png" alt="">
