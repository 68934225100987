import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AssessmentService } from '../../assessments-module/assessment.service';

@Component({
  selector: 'app-blog-title',
  templateUrl: './blog-list.component.html',
  styleUrls: ['./blog-list.component.css'],
  providers: [ AssessmentService ]
})
export class BlogListComponent implements OnInit {

  companyId: any;
  accessToken: any;
  blogListResponse: any ;
  blogCategory: any;
  primaryImage: any;
  author: any;
  createdOn: any;
  title: any;
  subjectBody: any;
  subTitle: any;
  blogSlug: any;
  apiCalled: boolean = false;

  constructor(
    private router: Router,
    private  assessmentService: AssessmentService,
  ) {
    this.accessToken = localStorage.getItem('accessToken');
    this.companyId = localStorage.getItem('companyId');
  }

  ngOnInit() {
    this.blogListFunc();
  }


  blogListFunc() {
    const payload = {};
    this.assessmentService.blogListApi(payload)
      .subscribe(
        (response:any) => {
          this.apiCalled = true;
          this.blogListResponse = response.message;
          if(this.blogListResponse != 'No blog found.'){
            this.primaryImage = this.blogListResponse[0].primaryImage;
            this.blogCategory = this.blogListResponse[0].category[0];
            this.author = this.blogListResponse[0].author;
            this.createdOn = this.blogListResponse[0].createdOn;
            this.title = this.blogListResponse[0].title;
            this.subTitle = this.blogListResponse[0].subTitle;
            this.subjectBody = this.blogListResponse[0].subjectBody;
            this.blogSlug = this.blogListResponse[0].blogSlug;
            this.blogListResponse.shift();
          }
        }
      );
  }

  goToDetails(slug){
    this.router.navigate(['blog/' + slug]);
  }

}


