<section class="bg-white">
  <app-header-static></app-header-static>
</section>
<section class="bg-white c-pattern mobile_block">
  <div class="container pt-5 pb-3 ">
    <div class="row ">
      <div class="col">
        <h1 class="GB-40 text-center">Security Features</h1>
      </div>
    </div>
  </div>

  <div class="container-fluid bg-3">
    <div class="container mt-5">
      <div class="row ">
        <div class="col-md-8">
          <img src="assets/img/security-01.png"  class="img-fluid" alt="">
        </div>
        <div class="col-md-4">
          <h2 class="GB-26 mt-5 heading1">Remote Proctoring Console</h2>
          <p class="RR-13">Online proctoring enables the assessment administrator to proctor the candidates remotely through a monitoring console. Build to protect the Assessment integrity, in case of any violation the assessment administrator can notify/warn the candidates or terminate the assessment session for the particular candidate.</p>
        </div>

      </div>
    </div>
  </div>

  <div class="container-fluid bg-gray">
    <div class="container pb-5">
      <div class="row ">
        <div class="col-md-4 col-sm-12">
          <img src="assets/img/track_browser_movement.png"  height="" alt="" class="mt-5 mb-3">
          <p class="RR-16 font-weight-bold text-gray">Track Browser Movement</p>
          <p class="RR-13">Platform keep the track of browser movements and warns the candidate from navigating away from the assessment window. This restricts the candidate from using the device's inbuilt tools and software.</p>
        </div>
        <div class="col-md-4 col-sm-12">
          <img src="assets/img/ip_blocking.png"  height="" alt="" class="mt-5 mb-3">
          <p class="RR-16 font-weight-bold text-gray">IP Blocking</p>
          <p class="RR-13">IP address blocking prevents unsolicited or unwarranted candidates from accessing published assessment.</p>
        </div>
       <!-- <div class="col">
          <img src="assets/img/Pay-per use.png"  height="" alt="" class="mt-5 mb-3">
          <p class="RR-16 font-weight-bold text-gray">Robust Concurrency</p>
          <p class="RR-13">Access all the features but only pay for what you actually use. You can pay to match your varying assessment needs. Cost effective approach for assessment requirements.</p>
        </div>-->
      </div>
    </div>
  </div>


  <div class="container mt-5 mb-5">
    <div class="row ">
      <div class="col-md-8">
        <img src="assets/img/feature-02.png"  class="img-fluid" alt="">
      </div>
      <div class="col-md-4">

        <img src="assets/img/aadhaar_authentication.png"  height="" alt="" class="mt-5 mb-3">
        <p class="RR-16 font-weight-bold text-gray">Aadhaar Based Authentication</p>
        <p class="RR-13">Validate candidate's Identity with the Aadhaar details (for Indian Users).</p>
        <img src="assets/img/SSL_Enabled.png"  height="" alt="" class="mt-5 mb-3">
        <p class="RR-16 font-weight-bold text-gray">SSL Enabled</p>
        <p class="RR-13">Standard security technology for establishing an encrypted link between a web server and a browser. SSL link ensures 100% data integrity between the web server and browser.</p>

      </div>

    </div>
  </div>



  <div class="container mt-5 pb-5">
    <div class="row ">
      <div class="col-md-8 d-sm-block d-md-none">
        <img src="assets/img/Robust-Concurrency-feature.png"  class="img-fluid" alt="">
      </div>

      <div class="col-md-4">

        <img src="assets/img/robust_concurrency.png"  height="" alt="" class="mt-5 mb-3">
        <p class="RR-16 font-weight-bold text-gray">Robust Concurrency</p>
        <p class="RR-13">The platform is built with robust concurrency measures to support a significant number of users taking the online assessment at a time besides eliminating any latency or delay in rendering the online assessments.</p>
        <!--<img src="assets/img/Pay-per use.png"  height="" alt="" class="mt-5 mb-3">
        <p class="RR-16 font-weight-bold text-gray">SSL Enabled</p>
        <p class="RR-13">Access all the features but only pay for what you actually use. You can pay to match your varying assessment needs. Cost effective approach for assessment requirements.</p>-->

      </div>
      <div class="col-md-8 d-none d-sm-block">
        <img src="assets/img/Robust-Concurrency-feature.png"  class="img-fluid" alt="">
      </div>
    </div>
  </div>

  <div  class="container mt_5">
    <div class="text-center section-heading GB-40 mb-5">Compliance Certifications And Attestations</div>
    <div class="text-center certifications pt-2">
        <img style="height: 85px;" src="assets/images/iso-compliance.png" class="iso-icon mb-1">
        <h5 class="mb-4">ISO/IEC 27001</h5>
        <p class="RR-16">Information Security Management System (ISMS)</p>
        <a href="https://cdn.calibrat.com/static/TalentBridge_Technologies_Private_Limited_IS716907.pdf" target="_blank" class="download-link">Download Certificate</a>
      </div>
  </div>

</section>
<app-footer-static></app-footer-static>
