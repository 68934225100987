<div class="container-fluid"> <!-- start for main container-fluid-->
<br/>
  <div class="row"> <!-- code for button group-->
    <button class="btn btn-primary ml-3" (click)="checkParameter('folder')"> <span><i class="fa fa-folder" aria-hidden="true"></i>
</span> Create Folder</button>
    <button class="btn btn-primary" (click)="checkParameter('upload')"> <span><i class="fa fa-upload" aria-hidden="true"></i></span>
      Upload</button>
    <button class="btn btn-primary"> <span><i class="fa fa-download" aria-hidden="true"></i></span>
      Download</button>
    <div class="dropdown">
      <button class="btn btn-primary dropdown-toggle" type="button" data-toggle="dropdown">Actions
        <span class="caret"></span></button>
      <ul class="dropdown-menu">
        <li><a href="#">Delete</a></li>
        <li><a href="#">Rename</a></li>
        <li><a href="#">Undo Delete</a></li>
        <li><a href="#">Copy</a></li>
        <li><a href="#">Cut</a></li>
        <li><a href="#">Paste</a></li>
      </ul>
    </div>
  </div> <!-- end of code for button group-->
  <br/>

  <div class="row" id="customers"> <!-- code for tabular structure of file details-->
    <div class="col">
      <div class="card ">
        <div class="card-body ">
          <div class="row GR-14">
            <div class="col-1"></div>
            <div class="col-4">Name</div>
            <div class="col">Last Modified</div>
            <div class="col">Size</div>
            <div class="col">Storage Class</div>
            <!--<div class="col">Created On</div>-->
            <div class="col-1"></div>
          </div>
        </div>
      </div>
      <div class="card card__two mtop15 border-0">
        <div class="card-body card-bg" >
          <div class="row" >
            <div class="col-1 text-center">
              <div class="form-check">
                <label class="mb-0"><input id="checkBox" type="checkbox"><span class="label-text"> <i class="fa fa-folder ml-3" aria-hidden="true"></i> </span></label>
              </div>
            </div>
            <div class="col-4 text-truncate qHeading" style="cursor: pointer" >Java</div>
            <div class="col text-truncate qRest"> 18th March 2020</div>
            <div class="col text-truncate qRest">20 mb</div>
            <div class="col qRest"> Aws</div>
            <!--<div class="col text-truncate qRest">{{list.createdOn | date:'dd,  MMM y'}}</div>-->
            <div class="col-1">
              <button type="button" class="btn float-right btnMore" role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><i class="material-icons">more_vert</i></button>
              <div class="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuLink" x-placement="bottom-end" style="position: absolute; transform: translate3d(-155px, 25px, 0px); top: 0px; left: 0px; will-change: transform;">
                <a class="dropdown-item">Edit</a>
                <a class="dropdown-item">Delete</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div> <!-- end of code for tabular structure of file details-->

</div> <!-- end for main container-fluid-->



<!-- code for file upload modal -->
<div class="modal fade" bsModal tabindex="-1" role="dialog" #uploadModal="bs-modal" [config]="{backdrop: 'static'}"><!-- delete modal for single Question delete-->
  <div class="modal-dialog modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header draggable-handle">
        <h5 class="modal-title" *ngIf="!showUpload">Create New Folder</h5>
        <h5 class="modal-title text-center"  *ngIf="showUpload">Upload Files</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close"(click)="uploadModal.hide()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <!--<angular-file-uploader *ngIf="showUpload"-->
        <!--[config]="afuConfig" (click)="dummy($event)" (change)="valueChanged($event)">-->
        <!--</angular-file-uploader>-->

 <div *ngFor="let file of selectedFiles" class="file-list">
   <p *ngIf="file?.fileName.length>0">Selected File: {{file?.fileName}}</p>
<mat-progress-bar style="margin-top: 30px" mode="determinate" ></mat-progress-bar>
   <button type="button" class="btn btn-link">
     <span class="align-middle"><img class=" " src="/assets/img/Delete.png" alt=""> Delete</span>
   </button>
 </div>
<div id="drop_zone" (drop)="dropHandler($event)" (dragover)="dragOverHandler($event)" >
  <p class="text-center"> Drag and drop your files here</p>
</div> <br/>

        <button class="btn btn-primary" (click)="uploadFiless()"> <span><i class="fa fa-upload" aria-hidden="true"></i></span>
          Upload</button>

        <span *ngIf="!showUpload"><i class="fa fa-folder" aria-hidden="true"></i></span> &nbsp; <input type="text" *ngIf="!showUpload" placeholder="New Folder">

      </div>
      <div class="modal-footer mx-auto d-block">
        <button type="button" class="btn btn-cancel" data-dismiss="modal" (click)="uploadModal.hide()">Cancel</button>
      </div>
    </div>
  </div>
</div> <!-- end of code for file upload modal -->







