import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs/Observable';
import { Injectable } from '@angular/core';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import 'rxjs/add/observable/throw';

@Injectable()
export class AuthGuardService implements CanActivate {
  accessToken = '';
  companyId = '';
  constructor(private router: Router) { }
  canActivate (route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    this.accessToken = localStorage.getItem('accessToken');
    this.companyId = localStorage.getItem('companyId');
    if (this.accessToken === '' || this.accessToken === undefined || this.accessToken === null ||
      this.companyId === '' || this.companyId === undefined || this.companyId === null) {
      this.router.navigate(['/']);
      return false;
    } else {
      return true;
    }
  }
}

@Injectable()
export class NonLoggedInState implements CanActivate {
  accessToken = '';
  companyId = '';
  constructor(private router: Router) { }
  canActivate (route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    this.accessToken = localStorage.getItem('accessToken');
    this.companyId = localStorage.getItem('companyId');
    if (this.accessToken === '' || this.accessToken === undefined || this.accessToken === null ||
      this.companyId === '' || this.companyId === undefined || this.companyId === null) {
      return true;
    } else {
      this.router.navigate(['/dashboard']);
      return false;
    }
  }
}



