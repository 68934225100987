<div class="container-fluid free-bg bg-white mt-5">
  <div class="container mt_5">
    <div class="row pt-5">
      <div class="col-md-5 col-sm-12 mobile_block">
        <p class="text-black GR-26" >Start Your Free Trial Now.</p>
        <p><a routerLink="/register" class="btn btn-primary my-2 ">Start Free Trial</a></p>
      </div>
      <div class="col-md-7 col-sm-12">
        <img src="assets/img/Devices-free.png"  class="img-fluid" alt="Calibrat available across all devices">
      </div>
    </div>
  </div>
</div>
<footer class="text-muted footer-bg">
  <div class="container">
    <div class="row">
      <div class="col-md-3 col-sm-12 mt-3 mb-3 mobile_block">
        <a class="navbar-brand" routerLink=""><img src="assets/img/logo-web.png"  height="36" alt="calibrat Logo - Assessment Simplied"></a>
      </div>
      <div class="col-md-4 col-sm-12 mt-3 mb-3">
        <h6 class="text-black" >Information</h6>
        <nav class="nav flex-column">
          <div class="row">
          <div class="col-md-6">
          <a class="nav-link pl-0" routerLink="" routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}">Home</a>
          <a class="nav-link pl-0" (click)="scrollToUses('Fresher-Lateral-Screening');" routerLink="/home/Fresher-Lateral-Screening" routerLinkActive="active">Product</a>
          <a class="nav-link pl-0" routerLink="/features" routerLinkActive="active">Features</a>
          <a class="nav-link pl-0"  routerLink="/terms-of-use" routerLinkActive="active">Terms of Use</a>
          <a class="nav-link pl-0" routerLink="/privacy-policy" routerLinkActive="active">Privacy Policy</a>

        </div>
        <div class="col-md-5">
          <a class="nav-link pl-0" routerLink="/blog" routerLinkActive="active">Blog</a>
          <a class="nav-link pl-0" routerLink="/media" routerLinkActive="active">Media</a>
          <a class="nav-link pl-0" routerLink="/security" routerLinkActive="active">Security</a>
        </div>
      </div>
        </nav>
      </div>
      <div class="col-md-3 col-sm-12 mt-3 mb-3">
        <h6 class="text-black" >Contact</h6>
        <div class="footer-contact-info">
          <p><span><img src="assets/img/Email.svg"  class="pr-3" alt=""></span>info@calibrat.com</p>
          <p><span><img src="assets/img/phone.svg"  class="pr-3" alt=""></span>+91 8042086194</p>
          <p>
            <span class="float-left"><img src="assets/img/Location.svg"  class="pr-3" alt=""></span>
            <span style="display:  block;padding-left:  30px;">TalentBridge Technologies Pvt. Ltd, 91 Springboard Business Hub Private Limited, 5th Floor, 175 & 176, Bannerghatta Main Rd, Dollars Colony, Bengaluru, Karnataka 560076, India.</span>
          </p>
        </div>
      </div>
      <div class="col-md-2 col-sm-12 mt-3 mb-3">
        <h6 class="text-black" >Follow</h6>
        <!--<i class="fa fa-instagram fa-2x" aria-hidden="true"></i> &nbsp;-->
        <i class="fa fa-facebook-square fa-2x redirect"  (click)="redirectTo('facebook')" aria-hidden="true"></i> &nbsp;
        <i class="fa fa-linkedin-square fa-2x redirect"  (click)="redirectTo('linkedin')" aria-hidden="true"></i>&nbsp;
        <i class="fa fa-youtube-square fa-2x redirect"  (click)="redirectTo('youtube')" aria-hidden="true"></i> &nbsp;

        <i class="fa fa-twitter-square fa-2x redirect"  (click)="redirectTo('twitter')" aria-hidden="true"></i> <br/> <br/>
        <p>Download Now</p>
        <a  href="https://apps.apple.com/us/app/calibrat/id1474952424" target="_blank"> <img src="assets/images/appstore.webp"  alt="" style="height: 40px; margin-right: 10px;"> </a>
        <a  href="https://play.google.com/store/apps/details?id=com.talentbridge.calibrat" target="_blank"> <img src="assets/images/playstore.webp"  alt="" style="height: 40px"> </a>

      </div>
    </div>
    <hr>
    <p class="text-center mb-0 pb-3 footer-copyright">Copyright © 2021 TalentBridge Technologies Pvt. Ltd.</p>
  </div>


  <div *ngIf="!cookiesAccepted" style="background-color: white;" class="cookie-consent-banner">
    <div class="cookie-consent-banner__inner">

      <div class="cookie-consent-banner__header">We value your privacy</div>
      <div class="cookie-consent-banner__description ">We use cookies to offer you a great user experience. This site uses cookies to personalise content, to provide social media features and to analyse traffic. By continuing to use this website you consent to use our cookies.</div>


      <div _ngcontent-dsm-c40 class="text-center float-right mt-xl-3 mt-lg-3 mt-md-3">
        <button _ngcontent-dsm-c40 type="button" (click)="acceptCookies()" class="btn btn-primary ml-0">Accept</button>
      </div>
    </div>
  </div>


</footer>
<!-- Global site tag (gtag.js) - Google Analytics -->

<script async src="https://www.googletagmanager.com/gtag/js?id=UA-122486298-1"></script>

<script>

    window.dataLayer = window.dataLayer || [];

    function gtag(){dataLayer.push(arguments);}

    gtag('js', new Date());



    gtag('config', 'UA-122486298-1');

</script>
