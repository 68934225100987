<body class="bg1" >
<div class="container-center">
  <div class="box-1">
    <div class="container">

      <div class="logo2">
        <img src="../../assets/img/logo2.png" alt="logo"  style="cursor: pointer">
      </div>

      <!--carousel -->
      <div id="carouselExampleIndicators " class="carousel slide carouselWrap" data-ride="carousel">
        <ol class="carousel-indicators ">
          <li data-target="#carouselExampleIndicators" data-slide-to="0" class="active"></li>
          <li data-target="#carouselExampleIndicators" data-slide-to="1"></li>
          <li data-target="#carouselExampleIndicators" data-slide-to="2"></li>
        </ol>
        <div class="carousel-inner carousel-height">
          <div class="carousel-item active">
            <h5>Simple to Use</h5>
          </div>
          <div class="carousel-item">
            <h5>Pay Per Use</h5>
          </div>
          <div class="carousel-item">
            <h5>Reduce Effort and Cost</h5>
          </div>
        </div>

      </div>
      <!-- carousel end-->
    </div>
  </div>

  <div *ngIf="generatePassAndOTP" class="box-2">

    <div *ngIf="verifyingRequest" class="container">
      <form class="form-padding" name="abc">
        <div [hidden]="OTPGenerated != 'false'">
          <div class="row">
            <div class="col">
              <h5 for="exampleInputEmail1">Mobile Number</h5>
              <p>Your email has been verified. Enter the OTP
                to complete the verification process.</p>
              <div class="input-group" id="test">
                <div class="form-group mtop15 full-width-container" >
                  <label>Mobile Number<span style="color: red">*</span></label>
                  <!--<input type="text" class="verifyMobile" OnlyNumber="true" id="phone" style="cursor:not-allowed;"aria-describedby="inputGroup-sizing-sm" (keypress)="clearServerErr();" required value="{{mobile}}" [disabled]=true name='phone'>-->
                  <international-phone-number2 [allowDropdown]="false"
                                               (focus)="clearServerErr()" aria-disabled="true"
                                               (keypress)="clearServerErr()"
                                               id="phone"
                                               name="phone"
                                               [defaultCountry]="'in'"
                                               [ngModel]="phoneNumber"
                                               #myInput
                                               [maxlength]="20"
                                               [required]="true"></international-phone-number2>
                </div>
              </div>
              <div class="form-group mtop15" *ngIf="hideOtpScreen">
                <label>Enter the OTP sent to your registered mobile number</label>
                <div class="input-group mb-1" id="otpvalidate">
                  <input type="text" required OnlyNumber="true" maxlength="1" class="form-control verifyGrid" aria-label="Text input with button" autocomplete="off" name="digit1" id="digit1" #digit1="ngModel" [(ngModel)]="otpDigit1" (keyup)="changeFocus('digit2', $event)" (keypress)="keyprees($event)" autofocus >

                  <input type="text" required OnlyNumber="true" maxlength="1" class="form-control verifyGrid" aria-label="Text input with button" autocomplete="off"name="digit2" id="digit2" #digit2="ngModel" [(ngModel)]="otpDigit2" (keyup)="changeFocus('digit3',$event)" (keypress)="keyprees($event)">

                  <input type="text" required OnlyNumber="true" maxlength="1" class="form-control verifyGrid" aria-label="Text input with button" autocomplete="off" name="digit3" id="digit3" #digit3="ngModel" [(ngModel)]="otpDigit3" (keyup)="changeFocus('digit4',$event)" (keypress)="keyprees($event)">

                  <input type="text" required OnlyNumber="true" maxlength="1" class="form-control verifyGrid" aria-label="Text input with button" autocomplete="off" name="digit4" id="digit4" #digit4="ngModel" [(ngModel)]="otpDigit4" (keyup)="changeFocus('digit5',$event)" (keypress)="keyprees($event)">

                  <input type="text" required OnlyNumber="true" maxlength="1" class="form-control verifyGrid" aria-label="Text input with button" autocomplete="off" name="digit5" id="digit5" #digit5="ngModel" [(ngModel)]="otpDigit5" (keyup)="changeFocus('digit6',$event)" (keypress)="keyprees($event)">

                  <input type="text" required OnlyNumber="true" maxlength="1" class="form-control verifyGrid" aria-label="Text input with button" autocomplete="off" name="digit6" id="digit6" #digit6="ngModel" [(ngModel)]="otpDigit6" (keyup)="changeFocus('digit6',$event)" (keypress)="keyprees($event)">


                </div>

                <small *ngIf="validateotpERR != '' " class="text-danger" > {{validateotpERR}} </small>
                <!--<small id="emaiHelp" class="form-text text-muted float-right"> Didn’t receive OTP? <a  class="text-info" (click)="resendOTP();" style="cursor: pointer">Resend OTP</a></small>-->
                <div class="col" *ngIf="hideTimer">
                  <div class="clock float-right text-center">
                    <h4  class="green">0{{minute}}:<span *ngIf="secondsvalue">0</span>{{seconds}}</h4>
                  </div>
                </div>
              </div>


            </div>
          </div>

          <div class="text-danger" >
            <small> {{otpError}} </small>
          </div>

          <div id="message" name="message" style="display: none;">
            <small style="color: green">{{message}}</small>
          </div>

          <div class="row marginTopmore">
            <div class="col">
              <button *ngIf="!showResend" (click)="validateOTP();clearServerErr();"  id="submit " class="btn btn-login mt-3" type="submit" value="Login">Verify</button>
              <button *ngIf="showResend" (click)="resendOTP();clearServerErr();"  id="submit1 " class="btn btn-login mt-3" type="submit" value="Login">Resend OTP</button>
            </div>
          </div>
        </div>

        <div *ngIf="passwodSet">
          <form #feed="ngForm">

            <div class="row">

              <div class="col">

                <h5>Set Password</h5>
                <div class="input-group mtop30 no-padding-left">
                  <input appPassword  (blur)="matchPasswordFormat($event)" (keyup)="passwordMatch();" type="password" autocomplete="false" class="verifyMobile password-height rbw-0 form-control" id="passwordd" #passwordd="ngModel" [(ngModel)]="password" name="password"
                         pattern="(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,20}$"
                         popover="{{ pwdFormat }}" [popoverCloseOnClickOutside]="true" popoverPlacement="right"
                         required placeholder="Enter Password" reverse="false">

                </div>
                <div class="mb-3">
                  <small *ngIf="invalidPassword" class="text-danger" >
                    {{passwordValidation}}
                    <i class="material-icons password-info-icon" popover="{{ pwdFormat }}" [popoverOnHover]="true" [popoverCloseOnMouseOutside]="true" popoverPlacement="right">info</i>
                  </small>
                </div>

                <div class="form-group">
                  <input style="width: 350px; height: 38px;" (keyup)="confirmMatch();" type="password" class="verifyMobile form-control" id="cPassword" #cPassword="ngModel"
                         [(ngModel)]="confirmPassword" name="confirmPassword" pattern="(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,20}$"
                         required placeholder="Confirm Password" validateEqual="password" reverse="false">
                  <small class="text-danger" >{{matchPassword}}</small>
                </div>
                <!--<small id="emailHelp" class="form-text text-muted float-right"><a  class="text-info">Forgot Password?</a></small>-->


                <div class="form-group">
                  <small style="font-size: 13px;">Do you want to set your password later?
                    You can set your password from your profile. </small>
                </div>

                <div class="row pt-3">
                  <button [disabled]="passwordd.invalid || feed.invalid||cPassword.invalid || invalidPassword" class="btn btn-primary" id="submitPassword" (click)="submitPassword();">Continue</button>
                  <button class="btn btn-cancel" (click)="skipCreatePassword();">Cancel</button>

                </div>

              </div>
            </div>
          </form>
        </div>
      </form>
    </div>

    <div *ngIf="!verifyingRequest" class="alert alert-danger alert-dismissible fade show">
      <i class="fa fa-exclamation-triangle" aria-hidden="true" style=" font-size: 45px;"></i>
      <span style="margin-left: 20px;">{{otpError}}</span>
    </div>

  </div>
  <div *ngIf="successMSG" class="box-2">
    <div class="container">
      <form class="form-padding text-center">
        <h3 class="mtop15">Account Successfully Created</h3>
        <img class="  mtop50" src="../../assets/img/account-created.png" alt="">
        <p class="mtop50"></p>

        <div>
          <small id="emailHelp" class="form-text text-muted">You can Login with OTP or <a  class="text-info" (click)="setPasswordPage();" style="cursor: pointer">Set Password</a></small>
        </div>

        <button (click)="continueDashboard();" type="submit" id="validatedPassword" class="btn btn-login mtop30"> Continue </button>
      </form>
    </div>
  </div>
</div>
</body>



