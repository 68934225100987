import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { SuperadminQbService } from '../superadmin-qb.service';

@Component({
  selector: 'app-archive-cat-modal',
  templateUrl: './archive-cat-modal.component.html',
  styleUrls: ['./archive-cat-modal.component.css']
})
export class ArchiveCatModalComponent implements OnInit {

  accessToken:any;
  companyId:any;
  categoryId:any;
  status:any;
  pageType:any;
  archiveStatus:string;
  archiveType:string;
  flag:string='';
  constructor(
    private SuperadminQbServiceObj: SuperadminQbService,
    private toastrService: ToastrService,
    private router:Router
  ) { }

  ngOnInit(): void {
   
    this.accessToken = localStorage.getItem("accessToken");
    this.companyId = localStorage.getItem("companyId");
  }

 async archiveunarchive(){
    
    this.flag=localStorage.getItem('categoryFlag');
    this.categoryId=localStorage.getItem('archiveId');
    this.status=localStorage.getItem('archiveStatus');
    this.archiveType=localStorage.getItem('archiveType');
    
    const  payload={
      "categoryId":this.categoryId,
      "status":this.status,
     }
     
    await this.SuperadminQbServiceObj.archiveUnarchive(payload,this.companyId,this.accessToken).toPromise()
     .then((response:any)=>{
       var state;
       if(this.status=='inactive'){
        state='archived';
       }
       else{
         state='unarchived';
       }
       this.toastrService.success(
        this.archiveType+" "+ state+"  successfully",
        "Success"
      );
       
     
     
     if(this.archiveType=='category'){
       
       localStorage.setItem('redirectValue','QbDashboard');
       localStorage.removeItem('NoQuestionDiv')
      
       if(this.flag=='true'){
        this.router.navigate(['/super_admin/questionbank']);
       }
       else{
         window.location.reload();
       }
      
        
     }
     else{
      window.location.reload();
     }
     localStorage.removeItem('archiveId');
     localStorage.removeItem('archiveType');
     localStorage.removeItem('archiveStatus');

     
     },
     (error:any)=>{
       console.log(error);
       localStorage.removeItem('archiveId');
       localStorage.removeItem('archiveType');
       localStorage.removeItem('archiveStatus');
     })

  }

  showModal(status,Id?:any,type?:any){
    localStorage.setItem('archiveType',type); // what has to be archived.. category,  subcategory etc
    localStorage.setItem('archiveId',Id); // id of a element that has to be archived or unarchived
    localStorage.setItem('archiveStatus',status); //archive or unarchive
    
    if(status=='inactive'){
      ($("#archiveModal") as any).modal("show");
    }
    else{
      ($("#unarchiveModal") as any).modal("show");
    }

    // this.categoryId=localStorage.getItem('archiveId');
    // this.status=localStorage.getItem('archiveStatus');
    // this.archiveType=localStorage.getItem('archiveType');
    
   
  }


}
