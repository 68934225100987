import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../shared/shared.module';
import { LiveBatchesComponent } from './live-batches/live-batches.component';
import { BatchCandidatesComponent } from './live-batches/batch-candidates/batch-candidates.component';
import { ObserverRoutingModule } from './observer-routing.module';
import {ObserverService} from './observer.service';
import { NotificationComponent } from './notification/notification.component';


@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    ObserverRoutingModule
  ],
  declarations: [
    LiveBatchesComponent,
    BatchCandidatesComponent,
    NotificationComponent,
  ],
  providers: [ObserverService]
})
export class ObserverModule { }
