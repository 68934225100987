import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ObserverService } from '../../observer.service';
declare var myExtObject: any;
declare let $: any;
declare var google: any;


@Component({
  selector: 'app-batch-candidates',
  templateUrl: './batch-candidates.component.html',
  styleUrls: ['./batch-candidates.component.css']
})

export class BatchCandidatesComponent implements OnInit, OnDestroy {
  accessToken = '';
  companyId = '';
  batchID = '';
  assessmentId = '';
  live_batch_candidates = [];
  candImages=[];
  assessment_name = '';
  assessment_type = '';
  fetchLiveBatchCandidateList: any;
  selCandidate: any;
  selCandidateName = '';
  selCandidatePhoneNumber = '';
  candidateBaseImg: string;
  candidateImages = [];
  candidateSuspiciousCount = '';
  candidateFaceViolationCount = '';
  candidateTimeSpentForAssessment = '';
  candidateLat;
  candidateLong;
  hideNotifyMsgBox = true;
  customNotificationMsg = '';
  notifyValue: string;
  errorMessage: string;
  dropdownValue: string;
  textAreaValue: string;
  notificationData = [];
  notificationdSelected = '';
  zoom = 0;
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private observerService: ObserverService,
    public toastrService: ToastrService
  ) { }

  ngOnInit() {
    this.batchID = this.route.snapshot.params['batchId'];
    this.assessmentId = this.route.snapshot.params['assessmentId'];
    this.live_batch_candidates = [];
    this.assessment_name = '';
    this.assessment_type = '';
    if (this.batchID === null || this.assessmentId == null) {
      this.router.navigate(['observer']);
    }
    this.accessToken = localStorage.getItem('accessToken');
    this.companyId = localStorage.getItem('companyId');
    this.observerService.liveBatchCandidates(this.companyId, this.accessToken, {'batchId': this.batchID, 'assessmentId': this.assessmentId})
      .subscribe(
        response => {
          this.live_batch_candidates = response.live_candidate_list;
          this.assessment_name = response.assessment_name;
          this.assessment_type = response.assessment_type;
        },
        error => {

        });
    this.refreshLiveBatchCandidateList();
    this.notificationMessage();
  }
  observerCandidateDetail(candidateInfo) {
    this.candidateImages=[];
    this.selCandidate = candidateInfo;
    this.selCandidateName = candidateInfo.name;
    this.selCandidatePhoneNumber = candidateInfo.mobile;
    this.candidateSuspiciousCount = candidateInfo.suspiciousCount;
    this.candidateFaceViolationCount = candidateInfo.faceViolationCount;
    this.candidateTimeSpentForAssessment = candidateInfo.timeSpentForAssessment;
    if (candidateInfo.trackGeo.startLocation.latitude === 'null' && candidateInfo.trackGeo.startLocation.longitude === 'null' ) {
      this.candidateLat = candidateInfo.trackGeo.endLocation.latitude;
      this.candidateLong = candidateInfo.trackGeo.endLocation.longitude;
    } else {
      this.candidateLat = candidateInfo.trackGeo.startLocation.latitude;
      this.candidateLong = candidateInfo.trackGeo.startLocation.longitude;
    }
    const lat = parseFloat(this.candidateLat);
    const lng = parseFloat(this.candidateLong);
    myExtObject.initMap(lat, lng);
    const payload = {
      'linkId': this.batchID,
      'sessionId': candidateInfo.sessionId,
      'assessmentId': candidateInfo.assessmentId,
      'skip': 0,
      'wantAllImages': true
    };
    this.observerService.observerCandidateDetail(this.companyId, this.accessToken, payload)
      .subscribe(
        response => {
          this.candidateBaseImg = response.CandidateListOfImages[0].fileName;
          this.candImages =  Object.assign([],response.CandidateListOfImages);
          // this.candidateImages.push(response.CandidateListOfImages[0]);
          for(var i=1;this,i<this.candImages.length;i++) {
            this.candidateImages.push(this.candImages[i]);
          }
          $('#showProctorData').modal('show');
          // this.ObserverCandDtails.splice(0, 1);
        },
        error => {
          // this.observerErrMessage = error.error.message;
        });
  }

  proctorSnapshot() {
    if (!$('#carouselExampleControls').carousel(0)) {
      $('#carouselExampleControls').carousel(0);
      $('#carouselExampleControls').carousel('prev');
    }
    this.errorMessage = '';
    this.customNotificationMsg = '';
    this.notifyValue = '';
    this.hideNotifyMsgBox = true;
    $('#ddNotificationType').val('');
  }

  refreshLiveBatchCandidateList() {
    this.fetchLiveBatchCandidateList = setInterval(() => {
      this.observerService.liveBatchCandidates(this.companyId, this.accessToken,
        {'batchId': this.batchID, 'assessmentId': this.assessmentId})
        .subscribe(
          response => {
            this.live_batch_candidates = response.live_candidate_list;
          },
          error => {

          });
    }, 10000);
  }

  updateNotificationDetails(data) {
    this.notifyValue = data;
    this.notificationdSelected = '';
    this.customNotificationMsg = '';
    this.dropdownValue = '';
    this.textAreaValue = '';
    this.notificationdSelected = data.target.value;
    if (data.target.value === '') {
      this.notifyValue = '';
    }
    if (data.target.value === 'Other') {
      this.notifyValue = data.target.value;
      this.hideNotifyMsgBox = false;
      this.dropdownValue = data.target.value;
    } else {
      this.hideNotifyMsgBox = true;
      this.dropdownValue = data.target.value;
    }
  }

  notifyCandidate(data) {
    let notifyReason;
    let notificationType;
    if (this.notificationdSelected !== 'Other') {
      for ( let i = 0; i < this.notificationData.length; i++) {
        if (this.notificationdSelected == this.notificationData[i].notificationTitle ) {
          notifyReason = this.notificationData[i].notificationMessage;
          notificationType = this.notificationData[i].notificationType;
        }
      }
    }
    const payload = {
      'linkId': this.batchID,
      'sessionId': this.selCandidate.sessionId,
      'mobile': this.selCandidate.mobile,
      'assessmentId': this.assessmentId
    };
    let toast_message = '';
    if (data === 'terminate') {
      payload ['notifyReason'] = '' ;
      payload ['terminateStatus'] = 'Terminated';
      payload ['isNotify'] = 'false';
      payload ['notificationType'] = '';
      toast_message = 'User session terminated successfully.';
    } else if (data === 'notify') {
      payload ['notifyReason'] = (this.notificationdSelected == 'Other') ? this.customNotificationMsg  : notifyReason ;
      payload ['notificationType'] = (this.notificationdSelected == 'Other') ? 'Other'  : notificationType ;
      payload ['terminateStatus'] = '';
      payload ['isNotify'] = 'true';
      toast_message = 'Notification sent successfully.';
    }
    this.observerService.terminateCandidate(this.companyId, this.accessToken, payload).subscribe(
      response => {
        this.errorMessage = '';
        this.toastrService.success(toast_message, '', {
          timeOut: 5000,
        });
      },
      error => {
        this.errorMessage = error.error.message;
        // this.observerErrMessage = error.error.message;
      });
  }
  notificationMessage() {
    this.notificationData = [];
    this.observerService.listNotification(this.companyId, this.accessToken)
      .subscribe(
        response => {
          this.notificationData = response.notifications;
        },
        error => {
          const errorMsg = error.error;
          this.errorMessage = errorMsg.message;
        });
  }

  updateTextAreaValue(event) {
    this.textAreaValue = event.target.value;
  }

  ngOnDestroy() {
    if (this.fetchLiveBatchCandidateList) {
      clearInterval(this.fetchLiveBatchCandidateList);
    }
  }
}
