import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import {DashBoardService} from './dashboard.service';
import { ToastrService } from 'ngx-toastr';


@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css'],
  providers: [DashBoardService]
})

export class DashboardComponent implements OnInit {
  userRole: any;
  userDetails: any;
  userSource = '';
  companyId = '';
  accessToken = '';
  errorMessage = '';
  constructor(private router: Router , private dashboardService: DashBoardService , private toastrService: ToastrService) {
    const message1 = localStorage.getItem('questionredirect');
    if (message1 === 'refreshQuestionbank') {
      this.router.navigate(['question']);
      localStorage.removeItem('questionredirect');
      return;
    }
    this.userSource = localStorage.getItem('userSource') === null || undefined ? '' : localStorage.getItem('userSource');
  }

  ngOnInit() {
    this.accessToken = localStorage.getItem('accessToken');
    this.companyId = localStorage.getItem('companyId');
    this.userDetails = JSON.parse(localStorage.getItem('userInfo'));
    this.userRole = this.userDetails.Companies[0].RoleId;
    localStorage.removeItem('Active-Batch-Tab');
  }


  checkRecords() {
    this.dashboardService.getRecords( this.accessToken, this.companyId).subscribe(
      (response: any) => {
        if (response !== undefined || response !== null) {
          window.open(response.redirectUrl, '_blank').focus();
        }
      },
      error => {
        this.toastrService.error(error.error.message, 'Error');
      });
  }

  redirect(page_route) {
    this.router.navigate([page_route]);
  }
}
