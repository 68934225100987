import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BlogDetailsComponent } from './blog-details/blog-details.component';
import { BlogListComponent } from './blog-list/blog-list.component';
import { RouterModule, Routes } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';
import { SharedModule } from '../shared/shared.module';
import { TruncateBlogContentPipe } from './truncate-blog-content.pipe';
import { CreateBlogComponent } from './create-blog/create-blog.component';
import { BlogListAdminComponent } from './blog-list-admin/blog-list-admin.component';
import { AngularEditorModule } from '@kolkov/angular-editor';

const routes: Routes = [
  { path: '', component: BlogListComponent },
  { path: 'create', component: CreateBlogComponent},
  { path: 'admin', component: BlogListAdminComponent},
  { path: ':id', component: BlogDetailsComponent},
];

@NgModule({
  declarations: [
    BlogDetailsComponent,
    BlogListComponent,
    TruncateBlogContentPipe,
    CreateBlogComponent,
    BlogListAdminComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    RouterModule.forChild(routes),
    HttpClientModule,
    AngularEditorModule
  ],

})
export class BlogModule { }
