import { Component, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { AssessmentService } from "../assessment.service";

declare let $: any;

@Component({
  selector: "app-assessment-type",
  templateUrl: "./assessment-type.component.html",
  styleUrls: ["./assessment-type.component.css"],
  providers: [AssessmentService],
})
export class AssessmentTypeComponent implements OnInit {
  userRole: any;
  userDetails: any;
  assementDashboardShow: any = [];
  companyId: any;
  accessToken: any;
  assessmentType: any;
  assessmentCount: any;
  isLoading: boolean = false;
  assessmentDashMessage: boolean = false;
  showAssessments: boolean = true;
  MastertQBAcess: boolean = false;
  assessmentCreationFlow: FormGroup;
  quickAssessmentType:FormGroup;
  selectedAssessmentCreationFlow: String='';
  selectedTemplate:string='';
  
  
  constructor(
        
        private router: Router,
        private assessmentService: AssessmentService,
  ) {
    this.accessToken = localStorage.getItem("accessToken");
    this.companyId = localStorage.getItem("companyId");
    this.assessmentDashboard();
    this.assessmentCreationFlow = new FormGroup({
      selectedAssessmentCreationFlow: new FormControl("", [Validators.required]),
    });
    this.quickAssessmentType = new FormGroup({
      selectedquickAssessmentType: new FormControl("", [Validators.required]),
    });
  }

   ngOnInit() {
    // localStorage.removeItem('_assessmentType');
    localStorage.removeItem("redirectValue");
    this.userDetails = JSON.parse(localStorage.getItem("userInfo"));
    this.userRole = this.userDetails.Companies[0].RoleId;
    var qbAccess = localStorage.getItem("masterQBAccess");
    this.MastertQBAcess = qbAccess == "allowed";

    this.assessmentCreationFlow = new FormGroup({
      selectedAssessmentCreationFlow: new FormControl("", [Validators.required]),
    });
    this.quickAssessmentType = new FormGroup({
      selectedquickAssessmentType: new FormControl("", [Validators.required]),
    });
    
  }

  assessmentDashboard() {
    localStorage.removeItem("_assessmentType");
    this.showAssessments = true;
    this.assessmentDashMessage = false;
    this.assessmentService
      .assessmentDashboard(this.companyId, this.accessToken)
      .subscribe({
        error: (error) => {},
        next: (response) => {
          this.assementDashboardShow = response;
        },
      });
  }

  RidirectionMessage() {
    localStorage.setItem("assessmentPage", "HelloAssessment");
    this.router.navigate(["jobs"]);
  }

  dashboardRefresh() {
    localStorage.setItem("profileInfo", "show profileInfo");
    this.router.navigate(["dashboard"]);
  }

  getassessmentList(type: any, count: any, isTrue: boolean) {
    localStorage.setItem("_assessmentType", type);
    if (count > 0) {
      this.router.navigate(["assessment/list/" + type.toLowerCase()]);
    } else {
      this.assessmentDashMessage = true;
      this.showAssessments = false;
    }
  }

  createNewAssessment() {

    const assessmentType=localStorage.getItem("_assessmentType");
    if(assessmentType){
      if(assessmentType == "Psychometer" ||   assessmentType == "Online Compiler"){
        this.validateAndNavigateToCreateManualAssessmentScreen();
      }else if (assessmentType == "Role Based") {
        this.router.navigate(["/skill-based-assessment"])   
      }else if (assessmentType == "Skill Based") {
        this.router.navigate(["/skill-based-assessment"])  
      }else if (assessmentType == "POSH") {
        this.router.navigate(["/skill-based-assessment"])   
      }else{
        ($("#quickAssessmentModal") as any).modal("show");
      }
    
    } else{
      ($("#quickAssessmentModal") as any).modal("show");
    }
  }

  assessmentCreationType(type: string) {
    this.selectedAssessmentCreationFlow = type;
  }

  openAssessmentTypeModal() {
    if (this.selectedAssessmentCreationFlow === "quickTemplate") {
      ($("#quickAssessmentModal") as any).modal("hide");
      ($("#quickAssessmentTypeModal") as any).modal("show");
    } else {
      ($("#quickAssessmentModal") as any).modal("hide");
      this.validateAndNavigateToCreateManualAssessmentScreen();
    }
  }

  validateAndNavigateToCreateManualAssessmentScreen() {
    const assessmentType = localStorage.getItem("_assessmentType");
    if (
      assessmentType == "Aptitude" ||
      assessmentType == "Psychometer" ||
      assessmentType == "Online Compiler"
    ) {
      if (!this.MastertQBAcess) {
        $("#AccessDenied").modal("show");
      } else {
        this.router.navigate(["assessment/create"]);
      }
    } else {
      if (assessmentType == "Technical") {
        this.router.navigate(["assessment/create"]);
      } else {
        this.router.navigate(["assessment/create"]);
      }
    }
  }

  selectedAssessmentType(value:any){
    this.selectedTemplate=value;
  }

  showTemplateList(){
    ($("#quickAssesmentTypeModal") as any).modal("hide");
    const url='/skill-based-assessment';
    this.router.navigate([url], {
      queryParams: {
        forassessmenttype:this.selectedTemplate,
      },
    });

  }

  resetForm(){
    this.quickAssessmentType.reset();
    this.assessmentCreationFlow.reset();
    this.selectedTemplate="";
    this.selectedAssessmentCreationFlow="";
  }
  

}
