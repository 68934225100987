import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router} from '@angular/router';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { FormGroup, FormControl } from '@angular/forms';
import { Location } from '@angular/common';
import { ToastrService } from 'ngx-toastr';
import { AssessmentService } from '../../assessments-module/assessment.service';
import { UUID } from 'angular2-uuid';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-create-blog',
  templateUrl: './create-blog.component.html',
  styleUrls: ['./create-blog.component.css'],
  providers: [ AssessmentService ]
})

export class CreateBlogComponent implements OnInit {
  blogForm: FormGroup;
  errorMsgUnApproved: string;
  userInfo: any;
  companyId:any;
  pageTitle = 'Create Blog';
  blogId: string;
  isEditBlog = false;
  fileError = '';
  fileUploadError = '';
  primaryImage: string;
  secondaryImage: string;
  blogDetails = [];
  blogErrorMessage = '';
  isImage = true;
  file: File[];
  categories = [
    {id: 1, name: 'Enterprise Messaging'},
    {id: 2, name: 'Attendance Management'},
    {id: 3, name: 'Lead Management'},
    {id: 4, name: 'Field Force Tracking'},
    {id: 5, name: 'Data Collection'},
    {id: 6, name: 'Video Sales Experience'},
    {id: 7, name: 'Collaboration Tools And Distributed Remote Teams'}
  ];
  subjectBody = '';
  globalUrl: string = environment.serverUrl;
  config: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: 'auto',
    minHeight: '150px',
    maxHeight: 'auto',
    width: 'auto',
    minWidth: '0',
    translate: 'yes',
    enableToolbar: true,
    showToolbar: true,
    placeholder: 'Enter text here...',
    defaultParagraphSeparator: '',
    defaultFontName: '',
    defaultFontSize: '',
    fonts: [
      {class: 'arial', name: 'Arial'},
      {class: 'times-new-roman', name: 'Times New Roman'},
      {class: 'calibri', name: 'Calibri'},
      {class: 'comic-sans-ms', name: 'Comic Sans MS'}
    ],
    customClasses: [
      {
        name: 'quote',
        class: 'quote',
      },
      {
        name: 'redText',
        class: 'redText'
      },
      {
        name: 'titleText',
        class: 'titleText',
        tag: 'h1',
      },
    ],
    uploadUrl: this.globalUrl + '/business/services/file/upload/image',
    uploadWithCredentials: false,
    sanitize: true,
    toolbarPosition: 'top',
    toolbarHiddenButtons: []
  };
  accessToken: string;
  isLoading: boolean;
  private base64textString = "";

  constructor(private router: Router,
              private assessmentService: AssessmentService,
              private route: ActivatedRoute,
              private location: Location,
              private toastr: ToastrService) {
  }


  ngOnInit() {
    this.companyId = localStorage.getItem('companyId');
    this.accessToken = localStorage.getItem('accessToken');
    this.blogForm = new FormGroup({
      title: new FormControl(''),
      subTitle: new FormControl(''),
      blogSlug: new FormControl(''),
      category: new FormControl(''),
      subjectBody: new FormControl(''),
      author: new FormControl('')
    });
    this.getRouteParams();
  }

  getRouteParams() {
    this.route.queryParams.subscribe((Params) => {
      if (Params['param'] === undefined || Params['params'] === '') {
        this.location.replaceState('blog/create')
      } else {
        this.blogDetails = JSON.parse(Params['param']);
        this.blogId = this.blogDetails['_id'];
        this.location.replaceState('blog/update/' + this.blogId);
        this.pageTitle = 'Edit Blog';
        this.editBlogDetails();
      }
    });
  }

  // goToDashboard() {
  //   this.router.navigate(['blog/list']);
  // }

  fileChangeEventJpg(fileInput) {
    this.blogErrorMessage = '';
    this.fileUploadError = '';
    this.file = fileInput.target.files;
    this.isImage = false;
    let filenameArray = this.file[0].name.split('.');
    let fileType = filenameArray[filenameArray.length - 1];
    if (fileType == 'jpg' || fileType == 'png' || fileType == 'jpeg') {
      this.fileUploadError = '';
      this.base64textString = '';
      if (this.file[0].size > 2000000) {
        this.fileUploadError = 'File size exceeds 2MB.';
        return;
      }
      if (this.file && this.file[0]) {
        const reader = new FileReader();
        reader.onload = this._handleReaderLoaded.bind(this);
        reader.readAsBinaryString(this.file[0]);
      } else {
        this.fileUploadError = 'Please select a file first';
        return;
      }
    } else {
      this.fileUploadError = 'Only jpg, png or jpeg files are supported. Please select proper file.';
      return false;
    }
  }

  _handleReaderLoaded(readerEvt) {
    const binaryString = readerEvt.target.result;
    this.base64textString = btoa(binaryString);
    this.uploadNewBlogImage();
  }

  uploadNewBlogImage() {
    this.isLoading=true;
    const payload = {
      'baseString': this.base64textString,
      'fileId': UUID.UUID() + '.jpg'
    };
    this.assessmentService.uploadBlogImage(this.companyId, this.accessToken, payload).subscribe({
      next: (response) => {
        this.primaryImage = response.location;
      },
      error: (error) => {
        let errorMessage: string;
        if(typeof error == 'string') {
          error = JSON.parse(error);
          errorMessage = error.message;
        } else {
          errorMessage = error.error.message;
        }
        if (errorMessage === 'Session expired' ||
          errorMessage === 'Unauthorized request' ||
          errorMessage === 'Update privacy policy') {
          this.router.navigate(['error/' + error.error.message]);
        } else {
          this.toastr.error(errorMessage, "Error");
        }
      }
    })
  }

  createBlog() {
    const payload = {
      title: this.blogForm.value.title,
      subTitle: this.blogForm.value.subTitle,
      category: [this.blogForm.value.category],
      blogSlug : this.blogForm.value.blogSlug,
      subjectBody: this.blogForm.value.subjectBody,
      author: this.blogForm.value.author,
      primaryImage: this.primaryImage ? this.primaryImage : "",
      secondaryImage: this.secondaryImage ?this.secondaryImage : "",
    };
    this.assessmentService.createNewBlog(payload, this.companyId, this.accessToken).subscribe({
      next: (response) => {
        this.toastr.success(response.message);
        this.router.navigate(['/blog/admin']);
      },
      error: (error) => {
        this.errorMsgUnApproved = error.error.message;
        console.log(this.errorMsgUnApproved);
      }
    });
  }

  editBlogDetails() {
    this.isEditBlog = true;
    this.blogForm.controls['title'].setValue(this.blogDetails['title']);
    this.blogForm.controls['subTitle'].setValue(this.blogDetails['subTitle']);
    // Considering the 1st element in Category array only, since we are accepting this value
    // from a text field & the entire field value is considered as the Category Title
    this.blogForm.controls['category'].setValue(this.blogDetails['category'][0]);
    this.blogForm.controls['subjectBody'].setValue(this.blogDetails['subjectBody']);
    this.blogForm.controls['author'].setValue(this.blogDetails['author']);
    this.blogForm.controls['blogSlug'].setValue(this.blogDetails['blogSlug']);
    this.primaryImage = this.blogDetails['primaryImage'];
    this.secondaryImage = this.blogDetails['secondaryImage'];
  }

  updateBlog() {
    const payload = {
      _id: this.blogId,
      secondaryImage: this.secondaryImage,
      blogSlug: this.blogForm.value.blogSlug,
      title: this.blogForm.value.title,
      subTitle: this.blogForm.value.subTitle,
      category: [this.blogForm.value.category],
      subjectBody: this.blogForm.value.subjectBody,
      author: this.blogForm.value.author,
      primaryImage: this.primaryImage,
    };
    this.assessmentService.updateBlog(payload, this.companyId , this.accessToken).subscribe({
      next: (response) => {
        this.toastr.success(response.message);
        this.router.navigate(['/blog/admin']);
      },
      error: (error) => {
        this.errorMsgUnApproved = error.error.message;
        if (error.error.message === 'Session expired' ||
          error.error.message === 'Unauthorized request' ||
          error.error.message === 'Update privacy policy') {
          this.router.navigate(['error/' + error.error.message]);
        }
      }
    });
  }

  gotoBlogListScreen() {
    this.router.navigate(['/blog/admin']);
  }
}
