import { Component, OnInit } from "@angular/core";
import { CarouselComponent } from "../carousel/carousel.component";
@Component({
  selector: "app-unauthorised-error-handle",
  templateUrl: "./unauthorised-error-handle.component.html",
  styleUrls: ["./unauthorised-error-handle.component.css"],
})
export class UnauthorisedErrorHandleComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {
    
    localStorage.clear();
  
   
   
  }

 
}
