import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'truncateBlogContent'
})
export class TruncateBlogContentPipe implements PipeTransform {

  transform(blogBody: any, ...args: any[]): string {
    if (blogBody === undefined || blogBody == "") {
      return "..."
    }
    let textTransformed = false;
    let textTruncateCharLimit = 100;
    let showContinuedContentSymbol = true;
    if(args.length > 0) {
      textTruncateCharLimit = args[0];
    }
    if(args.length > 1) {
      showContinuedContentSymbol = args[1];
    }
    while (!textTransformed) {
      let tagStartIndex = blogBody.indexOf('<');
      if (tagStartIndex == -1) {
        textTransformed = true;
      } else {
        let tagEndIndex = blogBody.indexOf('>');
        let contentBeforeTag = "";
        if (tagStartIndex > 0) {
          contentBeforeTag = blogBody.substring(0, tagStartIndex)
        }
        if (contentBeforeTag.length >= textTruncateCharLimit) {
          textTransformed = true;
          if (showContinuedContentSymbol) {
            return contentBeforeTag.substr(0, textTruncateCharLimit) + "...";
          } else {
            return contentBeforeTag.substr(0, textTruncateCharLimit);
          }
        } else {
          blogBody = contentBeforeTag + blogBody.substring(tagEndIndex+1);
        }
      }
    }
    return blogBody;
  }

}
