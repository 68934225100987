import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {AssessmentService} from '../assessment.service';
import {isNull} from 'util';
import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderService } from 'ngx-ui-loader';


@Component({
  selector: 'app-list-assigned-batches',
  templateUrl: './list-assigned-batches.component.html',
  styleUrls: ['./list-assigned-batches.component.css'],
  providers: [ AssessmentService ]
})
export class ListAssignedBatchesComponent implements OnInit {
  batchListType: string = 'OPEN';
  assignedRole: string = '';
  showFetchMsg: boolean = false;
  assignedBatchList: any = [];
  accessToken: string = '';
  companyId: string = '';
  companyType: string = '';
  centerManagerInstruction:string="";
  selectedLinkId:any;
  selectedLinkLockStatus:any;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private assessmentService: AssessmentService,
    private toastrService: ToastrService,
    private ngxService:NgxUiLoaderService
  ) {
   
    
  }

 async ngOnInit() {
    
    this.companyId = localStorage.getItem('companyId');
    this.companyType = localStorage.getItem('companyType');
    await this.getRouteParams();
    if(isNull(localStorage.getItem('Active-Batch-Tab'))) {
      this.ListAssignedBatches('OPEN');
    } else {
      this.batchListType = localStorage.getItem('Active-Batch-Tab');
      this.ListAssignedBatches(this.batchListType);
    }
    localStorage.removeItem('Active-Batch-Tab');
  }

  getRouteParams() {
    this.route.params.subscribe(
      params => {
        if (params.role) {
          if(params.role=="centermanager"){
            this.assignedRole="centerManager"
          }else{
          this.assignedRole = params['role'];
          }
        }
      },
      error => console.log(error)
    );
  }

  showLiveBatches() {
    this.router.navigate(['/observer']);
  }

  async ListAssignedBatches(linkAvailabilityStatus) {
    this.ngxService.start();
    // 'linkAvailabilityStatus' possible values - OPEN | UPCOMING
    this.showFetchMsg = true;
    this.batchListType = linkAvailabilityStatus;
    this.assignedBatchList = [];
    this.accessToken = localStorage.getItem('accessToken')
    const payload = {"role": this.assignedRole, "linkAvailabilityStatus": linkAvailabilityStatus};
    await this.assessmentService.fetchAssignedBatches(payload, this.companyId, this.accessToken)
      .toPromise().then(
        response => {
          this.showFetchMsg = false;
          
          this.assignedBatchList = response.links;
          this.ngxService.stop();

          

        },
        error => {
          this.showFetchMsg = false;
          let errorMsg = error.error.message;
          console.log(errorMsg);
          this.ngxService.stop();

        });
  }

  showBatchCandidates(selectedLinkId: any,linkType:any) {
    
    let candidateListPage = '/assessment/batch-candidates/'+selectedLinkId;
    localStorage.setItem('Active-Batch-Tab', this.batchListType);
    localStorage.setItem('assessmentTypeFromDetails', linkType.assessments[0].assessmentType);

    this.router.navigate([candidateListPage])
  }


 async changeLockUnlock(){
    const  Payload={
      "linkId": this.selectedLinkId, 
      "requestLockStatus": this.selectedLinkLockStatus
    }
    
    const companyId=localStorage.getItem('companyId');
    var accessToken=localStorage.getItem('accessToken');
   await this.assessmentService.changeLockStatus(Payload,companyId,accessToken).toPromise()
   .then(
     (response:any)=>{
      ($("#centerMngrLockModal")as any).modal("hide");
      
    this.toastrService.success(response.message, '', {
      timeOut : 5000,
    })
    this.ListAssignedBatches(this.batchListType);
  },
    (error:any)=>{
      
      this.toastrService.error(error.error.message);
    })
   

  }

  showInstructions(centerManagerInstruction:string){
    
    this.centerManagerInstruction=centerManagerInstruction;
    ($("#centerMngrInstructionModal")as any).modal("show");
  }

  openLockUnlockModal(linkId,lockStatus){

  ($("#centerMngrLockModal")as any).modal("show");
// this.changeLockUnlock(linkId,lockStatus)
this.selectedLinkId=linkId;
this.selectedLinkLockStatus=lockStatus;

  }
}
