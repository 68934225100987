import { Component, OnInit, ViewChild } from '@angular/core';
import {AssessmentService} from '../assessment.service';
import {ActivatedRoute, Router} from '@angular/router';
import {DomSanitizer} from '@angular/platform-browser';
import {ToastrService} from 'ngx-toastr';
declare let $: any;

@Component({
  selector: 'app-list-assigned-batch-candidates',
  templateUrl: './list-assigned-batch-candidates.component.html',
  styleUrls: ['./list-assigned-batch-candidates.component.css'],
  providers: [ AssessmentService ]
})
export class ListAssignedBatchCandidatesComponent implements OnInit {

  accessToken: string = '';
  companyId: string = '';
  assessmentDetails: any;
  markBtnLabel: any = 'Evaluate';
  statusLabel: any = '';
  linkId: string = '';
  linkType: string = '';
  linkName: string = '';
  linkDetails: any;
  isLinkOpenForEvaluation: boolean = false;
  batchCandidatePageNo: number = 0;
  batchCandidates: any = [];
  hasMoreCandidate: boolean = false;
  remarkDetails: any = [];
  commentedBy: any = '';
  reviewDetails:any = [];
  formattedAns: any = [];
  descriptiveMarks: any = [];
  emptyArray: any = [];
  isEnabled: boolean = false;
  showBatchCandidateList: boolean = true;
  showCandidateAssessmentDetails: boolean = false;
  isFetchingCandidateList: boolean = true;
  evaluateBtnLabel: string = '';
  maxLength: number = 4;
  selectedCandidate: any;
  reviewComment: string = '';
  showCommentSecion: boolean = false;
  candidateAssessmentResult: any;
  showDescriptiveIcon: any;
  startTimeMap: any = '';
  endTimeMap: any = '';
  startLocation: string;
  endLocation: string;
  trackGeoLocation = {
    'startLocation': {
      'latitude': '',
      'location': '',
      'longitude': '',
      'reverseGeo': ''
    },
    'endLocation': {
      'latitude': '',
      'location': '',
      'longitude': '',
      'reverseGeo': ''
    }
  };

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private assessmentService: AssessmentService,
    private sanitizer: DomSanitizer,
    private toastrService: ToastrService,
  ) {
    this.accessToken = localStorage.getItem('accessToken');
    this.companyId = localStorage.getItem('companyId');
    this.getRouteParams();
  }

  ngOnInit() {
    // this.fetchLinkDetails();
    //  this.fetchBatchCandidates(true);
  }

  getRouteParams() {
    this.route.params.subscribe(
      params => {
        if (params.linkId) {
          this.linkId = params['linkId'];
          this.assessmentService.setLinkId(this.linkId);
        }
      },
      error => console.log(error)
    );
  }

  async fetchBatchCandidates(fetchFirstSet: boolean) {
    const payload = { 'linkId': this.linkId };
    if(fetchFirstSet) {
      this.batchCandidates = [];
      this.showBatchCandidateList = true;
      this.showCandidateAssessmentDetails = false;
      this.isFetchingCandidateList = true;
      this.batchCandidatePageNo = 0;
    } else {
      this.isFetchingCandidateList = true;
      this.batchCandidatePageNo++;
    }
    await this.assessmentService.candidateReport(payload, this.batchCandidatePageNo, this.companyId, this.accessToken)
      .toPromise().then(
        response => {
          this.isFetchingCandidateList = false;
          let fetchedCandidates = response.results;

          this.assessmentService.setBatchData(fetchedCandidates);
          if(!(fetchedCandidates == null)) {
            this.batchCandidates = this.batchCandidates.concat(fetchedCandidates);
          }
          this.hasMoreCandidate = response.isMoreCandidates;
        },
        error => {
          this.isFetchingCandidateList = false;
          let errorMsg = error.error.message;
        });
  }

  fetchLinkDetails() {
    const payload = { 'linkId' : this.linkId };
    this.assessmentService.candidatesLinkIDDetails(payload, this.companyId, this.accessToken)
      .subscribe(
        response => {
          this.linkDetails = response.message;
          this.linkType = this.linkDetails.linkType;
          this.linkName = this.linkDetails.linkName;
          this.isLinkOpenForEvaluation = response.isLinkOpenForEvaluation;
          this.assessmentDetails = this.linkDetails.assessments[0];
        },
        error => {
          let errorMsg = error.error.message;
        });
  }

  initiateReviewProcess(candidateObj) {
    this.selectedCandidate = candidateObj;
    console.log(this.selectedCandidate);
    this.getReviewDetails();
  }

  checkForLabel(mobile: any) {
    this.batchCandidates.forEach((val: { mobile: any; candidateReviewAssessmentDetails: any[]; }) => {
      if (mobile === val.mobile && val.candidateReviewAssessmentDetails != null ) {
        val.candidateReviewAssessmentDetails.forEach((review) => {
          if (this.reviewDetails.length === this.emptyArray.length) {
            this.markBtnLabel = 'Mark As Evaluated';
            this.statusLabel = '';
            this.showCommentSecion = false;
            if (review.isReviewAnswer === 'false') {
              this.isEnabled = true;
            } else {
              this.isEnabled = false;
            }
          } else if ( review.isReviewAnswer === 'true' ) {
            this.markBtnLabel = 'Evaluate';
            this.statusLabel =  '';
            this.showCommentSecion = false;
            this.isEnabled = false;
            this.checkForEmpty();
          } else {
            this.markBtnLabel = 'Re-Evaluate';
            this.statusLabel = 'Re-Evaluate';
            this.showCommentSecion = true;
            this.isEnabled = true;
          }
        });
      }
    });
  }

  getReviewDetails() {
    if(!this.isLinkOpenForEvaluation) {
      return;
    }
    const payload = {
      'assessmentId' : this.linkDetails.assessments[0].assessmentId,
      'linkId'       : this.linkId,
      'mobile'       : this.selectedCandidate.mobile,
      'countryCode'  : this.selectedCandidate.countryCode,
    };
    this.remarkDetails = [];
    this.reviewDetails = [];
    this.reviewComment = '';
    this.assessmentService.getDescriptiveQuestions(payload, this.companyId, this.accessToken)
      .subscribe(
        response => {
          if (response.descriptiveQuestions !== null) {
            if (response.remarkedDetails && response.remarkedDetails.length > 0) {
              this.remarkDetails = response.remarkedDetails.reverse();
              this.commentedBy = response.remarkedBy;
              this.showCommentSecion=true;
            }
            this.reviewDetails = response.descriptiveQuestions;
            this.formatAnswer(this.reviewDetails);
            this.reviewDetails.forEach((val, j) => {
              if (val.answer === '') {
                this.descriptiveMarks[j] = {'questionId': val.questionId, 'marks': val.adminScore};
                this.emptyArray.push(val);
              } else {
                this.descriptiveMarks[j] = {'questionId': val.questionId, 'marks': val.adminScore};
                if (val.adminScore >= 0) {
                  this.descriptiveMarks[j] = {'questionId': val.questionId, 'marks': val.adminScore};
                }
                if (val.adminScore === '') {
                  this.isEnabled = true;
                }
              }
            });

            this.setEvaluateButtonLabel();
            this.checkForLabel(this.selectedCandidate.mobile)
            $('#reviewModal').modal('show');
            // this.isReviewLoading = true;
          } else {
            $('#reviewModal').modal('hide');
          }
          $('#reviewModal').modal('show');
        },
        error => {
          // this.errorMsg = error.error.message;
          $('#reviewModal').modal('hide');
        });
  }

  formatAnswer(data) {
    this.formattedAns = [];
    if (data !== undefined) {
      data.forEach((str) => {
        if (str.answer !== null) {
          const formatStr = str.answer.split('\n').join('<br>').split(' ').join('&nbsp;');
          this.formattedAns.push(formatStr);
        }
      });
    }
  }

  reviewCancel() {
    $('#reviewModal').modal('hide');
    this.descriptiveMarks = [];
    this.reviewDetails = [];
  }

  setEvaluateButtonLabel() {
    this.evaluateBtnLabel = 'Evaluate';

  }


  marksScored(e, idx) {
    if (e.target.value !== '' && e.target.value !== ' ') {
      this.descriptiveMarks[idx]['marks'] = e.target.value;
    }
  }

  validateNumber(e, idx) {
    if ($.inArray(e.keyCode, [46, 8, 9, 27, 13, 110]) !== -1 ||
      (e.keyCode === 65 && e.ctrlKey === true) ||
      (e.keyCode >= 35 && e.keyCode <= 39)) {
      return;
    }
    // Ensure that it is a number and stop the keypress
    if ((e.shiftKey || (e.keyCode < 48 || e.keyCode > 57)) && (e.keyCode < 96 )
      || (e.keyCode !== 190 && e.keyCode > 105 ) || (e.keyCode > 105 && e.shiftKey)) {
      e.preventDefault();
    }
  }

  restrictMax(e, idx) {
    const num = e.target.value.split('.');
    if (num[0] === '') {
      this.maxLength = 3;
    } else if (num[0].length > 1) {
      this.maxLength = 5;
    } else  {
      this.maxLength = 4;
    }
    if (e.target.value === '') {
      this.isEnabled = true;
    } else {
      this.isEnabled = false;
      this.checkForEmpty();
    }
    if (e.target.value > Number(this.reviewDetails[idx].questionScore) ) {
      e.target.value = '';
      this.descriptiveMarks[idx]['marks'] = '';
      this.isEnabled = true;
      this.checkForEmpty();
    }
  }

  checkForEmpty() {
    this.descriptiveMarks.forEach((val, k) => {
      if (this.reviewDetails[k].answer !== '' && val.marks === '') {
        this.isEnabled = true;
      }
    });
  }

  checkForComment(val: any) {
    if (val.charAt(0) === ' ') {
      val = '';
    } else if (val === '') {
      this.isEnabled = true;
    } else {
      this.isEnabled = false;
    }
    this.checkForEmpty();
  }

  publishResult() {
    /*this.isReviewLoading = false;
    if (this.isReviewLoading ) {
      return;
    }*/
    const payload = {
      'linkId': this.linkId,
      'mobile': this.selectedCandidate.mobile,
      'countryCode': this.selectedCandidate.countryCode,
      'assessmentId': this.linkDetails.assessments[0].assessmentId,
      'adminScore': this.descriptiveMarks,
      'isMarkAsEvaluated': true,
      'status': this.statusLabel,
      'remark': this.reviewComment
    };
    this.assessmentService.saveDescriptiveScore(payload, this.companyId, this.accessToken)
      .subscribe(
        response => {
          $('#reviewModal').modal('hide');
          this.toastrService.success(response.Response);

          this.reviewComment = '';
        },
        error => {
          // this.errorMsg = error.error.message;
          $('#reviewModal').modal('hide');
          this.reviewComment = '';
        });
  }

  fetchCandidateDetails(candidateInfo: any) {
    this.selectedCandidate = candidateInfo;
    console.log(this.selectedCandidate);
    const payload = {
      'linkId': this.linkId,
      'mobile': this.selectedCandidate.mobile,
      'countryCode': this.selectedCandidate.countryCode,
    };
    this.showDescriptiveIcon = this.selectedCandidate.candidateReviewAssessmentDetails[0];
    this.assessmentService.cadidatesReports(payload, this.companyId, this.accessToken)
      .subscribe(
        response => {
          this.candidateAssessmentResult = response.results[0];
          this.showBatchCandidateList = false;
          this.showCandidateAssessmentDetails = true;
        },
        error => {
          // this.errorMsg = error.error.message;
          // this.CandidateReportDetails = false;
        });
  }

  showBatchCandidateListView() {
    this.showBatchCandidateList = true;
    this.showCandidateAssessmentDetails = false;
  }


}
