
<section class="bg-white">
  <app-user-header></app-user-header>
</section>

<nav class="container-fluid pt-3 pb-3 bd-navbar sub-header">
  <div class="row">
    <div class="col">
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb" style="cursor: pointer">
          <li class="breadcrumb-item active" aria-current="page">
            <a>Blog List</a>
          </li>
        </ol>
      </nav>
    </div>

    <div class="col-2">
      <div class="btn-group float-right">
        <button type="button" class="btn btn-primary" aria-haspopup="true" (click)="goToCreateBlog();">
          Add Blog
        </button>
      </div>
    </div>
  </div>
</nav>

<div class="container-fluid main-div">
  <div class="row" id="customers">
    <div class="col">
      <div class="card">
        <div class="card-body">
          <div class="row GR-14">
            <div class="col-7">Blog Name</div>
            <div class="col-2">Author</div>
            <div class="col-1">Status</div>
            <div class="col-2" >Action</div>
          </div>
        </div>
      </div>
      <div class="card card__two mtop15 border-0" *ngFor="let selBlog of blogListResponse ;let i=index;">
        <div class="card-body card-bg">
          <div class="row">
            <div class="col-7 text-truncate qHeading cursor-pointer" (click)="gotToBlogAdminDetails(selBlog);">
              {{selBlog.title}}
              <p class="RR-12 grey mt-1">{{selBlog.modifiedOn | date: 'dd/MMMM/yyyy' }}</p>
            </div>
            <div class="col-2 text-truncate qRest">{{selBlog.author}}</div>
            <div class="col-1 text-truncate qRest">{{selBlog.status | titlecase }}</div>
            <div class="col-2 qRest">
              <button type="button" class="btn btn-sm btn-outline-info" (click)="editBlog(selBlog._id);">
                <i class="fa fa-pencil-square-o"></i>
              </button>
              <button *ngIf="selBlog.status == 'PENDING' || selBlog.status == 'UNPUBLISHED' " type="button" class="btn btn-sm btn-outline-info" (click)="publishBlog(selBlog._id);">
                <i class="fa fa-upload"></i>
              </button>
              <button type="button" class="btn btn-sm btn-outline-info" (click)="archiveBlog(selBlog._id);">
                <i class="fa fa-trash"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
