<section class="bg-white">
  <app-header-static></app-header-static>
</section>

<div  class="blog-page">
  <div class="container">
    <!-- blog-section2  -->
    <div *ngIf="blogListResponse != 'No blog found.' && apiCalled" class="blog-section2 pt-xl-4 pt-lg-4 pt-md-4 pt-sm-3 pt-1">
      <div class="row align-items-center">
        <div class="col-xl-8 col-lg-8 col-md-8 col-sm-12 col-12 blog-banner" (click)="goToDetails(blogSlug)" style="cursor: pointer;">
          <img src="{{primaryImage}}" alt="">
        </div>
        <div class="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12 blog-brief">
          <div class="d-flex align-items-center justify-content-between">
            <div class="blog-subject">{{blogCategory}}</div>
          </div>
          <h5>{{title}}</h5>
          <p class="mb-0 blog-text" [innerHTML]="subjectBody | truncateBlogContent: [160]"></p>
          <p class="read-article text-left cursor-pointer" (click)="goToDetails(blogSlug)">Read Full Article</p>
          <div class="d-flex align-items-center justify-content-between">
            <div class="from-whoom">{{author}}</div>
            <div class="date-year">{{createdOn | date: 'dd MMMM yyyy' }}</div>
          </div>
        </div>
      </div>
    </div>
    <!-- blog-section2 end -->
    <div *ngIf="blogListResponse == 'No blog found.'">  <h6 class="GR-26" style=" color: red;text-align: center;">No Blog Found!!</h6></div>
    <!-- blog and resources section -->
    <div *ngIf="blogListResponse != 'No blog found.'" class="blog-list">
      <div class="container">
        <div  class="row">
          <div *ngFor="let list of blogListResponse; let i =index;"
               class="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12 d-flex bloglist-brief mt-4" data-aos="fade-up" data-aos-duration="800"
               data-aos-once="true">
            <div (click)="goToDetails(list.blogSlug)" class="card flex-fill">
              <img src="{{list.primaryImage}}" class="blog-img" alt="">
              <div class="card-body p-0 d-flex flex-column">
                <div class="d-flex align-items-center justify-content-between mt-12">
                  <div class="blog-subject">{{list.category[0]}}</div>
                </div>
                <h3>{{list.title}}</h3>
                <p (click)="goToDetails(list.blogSlug)" class="short-text" [innerHTML]="list.subjectBody | truncateBlogContent"></p>
                <div class="d-flex align-items-center cursor-pointer mt-auto justify-content-between">
                  <p class="bloglist-date mb-0">{{list.createdOn | date: 'dd MMM yyyy' }}</p>
                  <img src="assets/images/blog-arrow.svg" class="blog-arrow" alt="">
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- blog and resources section end-->



  </div>
</div>

<app-footer-static ></app-footer-static>
