<section class="bg-white">
    <app-header-static></app-header-static>
  </section>
    <!-- banner section -->
    <!-- <div class="position-relative">
     
      <div class="solution-banner media-page w-100">
        <div class="container">
          <div class="col-lg-12">
            <div class="row align-items-center justify-content-center">
              <div class="col-xl-5 col-lg-5 col-md-6 col-sm-12  col-12 p-xl-0 text-xl-left text-lg-left text-md-left text-sm-center text-center media-caption">
                <div>Media</div>
                <p>Read about the innovations done by CALIBRAT published on the different blogs and websites</p>
              </div>
              <div class="col-xl-7 col-lg-7 col-md-6 col-sm-12 col-12 text-center media-banner">
                <img src="assets/images/frame.svg">
              </div>
            </div>
          </div>
        </div>
      </div>
    </div> -->
    <!-- banner end -->

    <!-- media section section-->
    <div class="media-section">
      <div class="container">
       
         <div class="media-title text-left" data-aos="fade-down" data-aos-duration="800" data-aos-once="true">2020 Media Releases</div>
        <div class="row">
         
          <div class="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-12 d-flex" data-aos="fade-up" data-aos-duration="800" data-aos-once="true">
            <a class="card flex-fill" target="_blank" href="https://industry.siliconindia.com/ranking/10-most-promising-online-assessment-solution-providers-2020-rid-1060.html">
              <div class="card-body d-flex flex-column p-0">
                <p class="mb-0 text-left"> August, 2020</p>
                <div class="details">10 most promising Online assessment solution providers – 2020</div>
                <div class="text-center mt-auto"><img src="assets/images/silicon_india.jpeg"></div>
              </div>
            </a> 
          </div>
          <div class="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-12 d-flex" data-aos="fade-up" data-aos-duration="800" data-aos-once="true">
            <a class="card flex-fill" target="_blank" href="https://industry.siliconindia.com/vendor/talentbridge-offering-stupendous-assessment-solutions-product-calibrat-cid-13639.html">
              <div class="card-body d-flex flex-column p-0">
                <p class="mb-0 text-left">August, 2020</p>
                <div class="details">TalentBridge: Offering Stupendous Assessment Solutions Product  </div>
                <div class="text-center mt-auto"><img src="assets/images/silicon_india.jpeg"></div>
              </div>
            </a>
          </div>

          <div class="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-12 d-flex" data-aos="fade-up" data-aos-duration="800" data-aos-once="true">
            <a class="card flex-fill" target="_blank" href="https://theenterpriseworld.com/talentbridge-technologies-calibrat-assessment-simplified">
              <div class="card-body d-flex flex-column p-0">
                <p class="mb-0 text-left">27 May, 2020</p>
                <div class="details">The 10 Most Reliable HR Solution Providers In 2020</div>
                <div class="text-center mt-auto"><img  src="assets/images/the-enterprise-world.png"></div>
              </div>
            </a>
          </div>
          
          <div class="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-12 d-flex" data-aos="fade-up" data-aos-duration="800" data-aos-once="true">
            <a class="card flex-fill" target="_blank" href="https://issuu.com/theenterpriseworld/docs/5_20recommended_20hr_20tech_20solution_20providers/18">
              <div class="card-body d-flex flex-column p-0">
                <p class="mb-0 text-left">August, 2020</p>
                <div class="details">The Best Of 5 Recommended HR Tech Solution Providers For 2020 </div>
                <div class="text-center mt-auto"><img src="assets/images/the-enterprise-world.png"></div>
              </div>
            </a>
          </div>
        
          
        </div>





        <div class="media-title text-left" data-aos="fade-down" data-aos-duration="800" data-aos-once="true">2019 Media Releases</div>
        <div class="row">
          <div class="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-12 d-flex" data-aos="fade-up" data-aos-duration="800" data-aos-once="true">
            <a class="card flex-fill" target="_blank" href="https://hr-technology.cioreviewindia.com/vendors/2019/1">
              <div class="card-body d-flex flex-column p-0">
                <p class="mb-0 text-left">2019</p>
                <div class="details">20 Most Promising HR Tech Startups – 2019</div>
                <div class="text-center mt-auto"><img src="assets/images/CIOReviewIndia-logo1.png"></div>
              </div>
            </a>
          </div>
          <div class="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-12 d-flex" data-aos="fade-up" data-aos-duration="800" data-aos-once="true">
            <a class="card flex-fill" target="_blank" href="https://www.cioreviewindia.com/magazines/hr-tech-special-august-2019/#page=34">
              <div class="card-body d-flex flex-column p-0">
                <p class="mb-0 text-left">August, 2019</p>
                <div class="details">TalentBridge Technologies: Enhancing the Enterprise Decision Making with Innovative Assessment Platform</div>
                <div class="text-center mt-auto"><img src="assets/images/CIOReviewIndia-logo1.png"></div>
              </div>
            </a>
          </div>
</div>
          <div class="media-title text-left" data-aos="fade-down" data-aos-duration="800" data-aos-once="true">2018 Media Releases</div>
          <div class="row">
          <div class="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-12 d-flex" data-aos="fade-up" data-aos-duration="800" data-aos-once="true">
            <a class="card flex-fill" target="_blank" href="https://recruitment.cioreviewindia.com/vendor/2018/talentbridge_technologies">
              <div class="card-body d-flex flex-column p-0">
                <p class="mb-0 text-left">2018</p>
                <div class="details">20 Most Promising Recruitment Technology Solution Providers - 2018</div>
                <div class="text-center mt-auto"><img src="assets/images/CIOReviewIndia-logo1.png"></div>
              </div>
            </a>
          </div>
         
        </div>
     
        </div>

       
      </div>
    

    <!-- media section section end-->
    <app-footer-static ></app-footer-static>