import { Component, OnInit, OnDestroy, ViewChild, ElementRef, AfterViewChecked } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { LoginService } from './login.service';
import { VerifyService } from '../verify-comp/verify-comp.service';
import { CommonService } from '../common.service';
import * as CryptoJS from 'crypto-js';
import '../externalJs/external.js';
declare let myExtObject: any;
declare let dummyObj: any;
declare let $: any;


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
  providers: [LoginService, VerifyService, CommonService],
})

export class LoginComponent implements OnInit, AfterViewChecked, OnDestroy {
  counter: number;
  companyList: any = [];
  unRegisteredUserFlag: boolean = false;
  passwordErrFlag: boolean = false;
  showCompany: boolean;
  OneCompany: boolean = false;
  disableMobileorEmail: boolean = false;
  errorMsg: string;
  mobile: string;
  showotpsent: boolean;
  countryCode: string;
  OTPGenerated: boolean;
  message: string;
  hour: number;
  minute: number;
  seconds: number;
  timerVar: any;
  showTimer: boolean;
  otpDigit1: string;
  otpDigit2: string;
  otpDigit3: string;
  otpDigit4: string;
  otpDigit5: string;
  otpDigit6: string;
  OTPerror: string;
  Form2: boolean = false;
  users: string;
  phoneNumber = '';
  loginButton: Boolean = true;
  CompanyName: string = null;
  companyId :string=null;
  createPassword: Boolean = false;
  password: string;
  validatePassword: Boolean = false;
  validPassword: string = '';
  passworderror: string;
  disableLogin: Boolean = false;
  disableCallBack: Boolean = false;
  secondsvalue: boolean;
  loginMethod = 'mobile';
  showEmailOrMobile = 'email';
  showResend: Boolean = false;
  showEditOption = '';
  regex = '/^[0-9]+$/';
  loginERR: any;
  email = '';
  otp: any;
  errmsgMobile = '';
  phoneNumberInput: any;
  constructor(public router: Router, private loginService: LoginService, private commonService: CommonService,
    private verifyService: VerifyService,
    private route: ActivatedRoute) {
    this.loginERR = 'please enter valid mobile number';
  }

  ngOnInit() {
    document.body.classList.add('registration_login_bg_image');
    this.showotpsent = true;
    this.route.queryParams.subscribe((params: Params) => {
      this.companyId = params['param'];
    });
  }

  ngAfterViewChecked(): void {
    const mobileNumberField = document.getElementById('primaryPhoneInput');
    if (mobileNumberField !== null && mobileNumberField !== undefined && !mobileNumberField.classList.contains('form-control')) {
      mobileNumberField.classList.add('form-control');
    }
  }

  clearServerErr(event) {
    this.OTPerror = '';
    this.errmsgMobile = '';
    this.loginERR = '';
    this.passworderror = '';
    if (event.target.value.trim() === '' && event.target.value.length !== 0) {
      this.disableLogin = true;
    } else {
      this.disableLogin = false;
    }
  }
  showLoginOption(event) {

    this.mobile = '';
    this.email = '';
    this.errmsgMobile = '';
    this.phoneNumber = '';
    if (event === 'email') {
      this.showEmailOrMobile = 'mobile';
      this.loginMethod = 'email';
    } else {
      this.showEmailOrMobile = 'email';
      this.loginMethod = 'mobile';
    }
  }
  keyprees(event) {
    return (event.charCode === 8 || event.charCode === 0) ? null : event.charCode >= 48 && event.charCode <= 57;
  }
  changeFocus(id: string, event) {
    if (event.keyCode === 8 || event.keyCode === 8 || event.keyCode === 32) {
      return;
    }
    if (event.keyCode === 46) {
      event.target.value = '';
      return;
    }
    myExtObject.changefocus(id);
    this.OTPerror = '';
  }

  checkCountryCode(event) {
    this.phoneNumberInput = event.valueAccessor;
    this.countryCode = '+' + this.phoneNumberInput.dialCode;
    this.mobile = this.phoneNumberInput.phoneNumberOnly;

  }

  changePhoneNumber() {
    this.companyId=null;
    this.CompanyName = null;
    this.Form2 = false;
    this.OTPGenerated = false;
    this.validatePassword = false;
    this.loginButton = true;
    this.showResend = false;
    this.clearOTP();
    this.validPassword = '';
    this.passworderror = '';
    this.OTPerror = '';
    this.errmsgMobile = '';
    this.disableCallBack = true;
    if (this.showEditOption === 'mobile') {
      this.phoneNumberInput.phoneNumberInput.nativeElement.disabled = false;
      this.phoneNumberInput.allowDropdown = true;
      this.showEditOption = '';
      this.showEmailOrMobile = 'email';
    } else if (this.showEditOption === 'email') {
      this.showEditOption = '';
      this.showEmailOrMobile = 'mobile';
    }
  }

  clearOTP() {
    this.otpDigit1 = '';
    this.otpDigit2 = '';
    this.otpDigit3 = '';
    this.otpDigit4 = '';
    this.otpDigit5 = '';
    this.otpDigit6 = '';
  }

  getOtpOrPassword() {

    const payLoad = {
      'email': this.email,
      'countryCode': this.countryCode,
      'mobileNo': this.mobile,
      'verifyBy': this.loginMethod
    };
    this.companyList.forEach((element: any) => {
      if (element._id == this.companyId) {

        this.CompanyName = element.companyName;
      }

    });
    this.loginService.getOtpOrPassword(payLoad, this.companyId)
      .subscribe(
        response => {


          let mobileNumber = '';
          if (this.loginMethod === 'mobile') {
            this.phoneNumberInput.phoneNumberInput.nativeElement.disabled = true;
            this.phoneNumberInput.allowDropdown = false;
            this.showEditOption = 'mobile';
            mobileNumber = this.mobile.substring(6, 10);
          } else if (this.loginMethod === 'email') {
            this.showEditOption = 'email';
          }
          this.showEmailOrMobile = '';
          if (response.message === 'OTP') {
            this.gerateOtp(this.companyId);
            this.showTimer = true;
            this.secondsvalue = false;
            this.clearOTP();
            this.disableCallBack = false;
            this.timer(2);
            this.OTPGenerated = true;
            this.OneCompany = true;
            this.showCompany = false;
            this.validatePassword = false;
            this.loginButton = false;
          } else if (response.message === 'PASSWORD') {

            this.OTPGenerated = false;
            this.validatePassword = true;
            // this.phoneNumber = false;
            this.loginButton = false;
          }
          this.createPassword = false;
          this.message = 'We have sent an OTP to your registered mobile number XXXXX' + mobileNumber + '. Please enter the OTP.';
        },
        error => {

          this.errorMsg = error.error.message;
        });
  }

  timer(duration) {
    duration = Number(duration);
    const durationpercentage = duration * 10 / 100;
    const hours = Math.floor(duration / 60);
    let minutes = duration % 60;
    this.hour = hours;
    if (duration % 60 === 0) {
      minutes = 60;
      this.hour = hours - 1;
    }
    this.minute = minutes - 1;
    this.seconds = 59;
    this.timerVar = setInterval(() => {
      if (this.disableCallBack === true) {
        clearInterval(this.timerVar);
      } else {
        this.seconds = this.seconds - 1;
        if (this.seconds < 0) {
          if (this.minute > 0) {
            this.minute = this.minute - 1;
            this.seconds = 59;
          }
          if (this.seconds === 0 && this.minute === 0) {
            this.showTimer = false;
            this.OTPerror = '';
            this.errmsgMobile = '';
            this.showResend = true;
            this.disableCallBack = true;
            this.seconds = 0;
            this.minute = 0;
            this.hour = 0;
          }
        }
        if (this.minute === 0) {
          if (this.hour > 0) {
            this.hour = this.hour - 1;
            this.minute = 60;
          }
          if (this.seconds === 0 && this.minute === 0) {
            this.showTimer = false;
            this.OTPerror = '';
            this.errmsgMobile = '';
            this.showResend = true;
            this.disableCallBack = true;
            this.seconds = 0;
            this.minute = 0;
            this.hour = 0;
          }
        }
        (this.seconds < 10) ? this.secondsvalue = true : this.secondsvalue = false;
      }
    }, 1000);
  }

  loginByOtp() {
    this.showotpsent = true;
    this.OTPerror = '';
    this.errmsgMobile = '';
    const otpDigits = this.otpDigit1 + this.otpDigit2 + this.otpDigit3 + this.otpDigit4 + this.otpDigit5 + this.otpDigit6;
    const otp = Number(otpDigits);
    const payLaod = {
      'verifyBy': this.loginMethod,
      'mobileNo': this.mobile,
      'countryCode': this.countryCode,
      'email': this.email,
      'otp': otp
    };
    this.loginService.loginByOtp(payLaod, this.companyId).subscribe((responseData: any) => {

      if (responseData.redirectUrl === '') {

        this.companyId = responseData.companyId;
        localStorage.setItem('corp_Sec_Count', JSON.stringify(responseData));
        localStorage.setItem('accessToken', responseData.accessToken);
        localStorage.setItem('userSource', responseData.source === undefined ? '' : responseData.source);
        const accessToken = localStorage.getItem('accessToken');
        this.loginService.getUserInfo(this.companyId, accessToken).subscribe(
          response => {

            const responseString = JSON.stringify(response);
            localStorage.setItem('userInfo', JSON.stringify(response.userDetails));
            localStorage.setItem('userRole', response.userDetails.Companies[0].RoleId);
            this.commonService.storeAPIKeyWebhookDetails(response);
            localStorage.setItem('companyURL', response.companyUrl);
            localStorage.setItem('companyType', responseData.companyType);
            localStorage.setItem('biReportURL', response.biReportUrl);
            localStorage.setItem('isPremiumAcc',response.userDetails.Companies[0].IsPremiumAccount);
            localStorage.setItem('masterQBAccess',response.userDetails.Companies[0].MasterQuestionAccess);
            localStorage.removeItem('checkProfile');

            if (responseData.companyType === 'ssc') {

              this.router.navigate(['company']);
            } else if (responseData.companyType === 'corporate') {
              localStorage.setItem('checkProfile', 'dashboard');
              localStorage.setItem('check', 'true');
              if (response.userDetails.Companies[0].RoleId === 'super_admin') {
                this.router.navigate(['super_admin']);
              }
              else {
                this.router.navigate(['dashboard']);
              }
            } else if (responseData.companyType === 'partner') {

              this.router.navigate(['partner']);
            } else {

              localStorage.setItem('sectorCount', responseData.sectorCount);
              this.router.navigate(['dashboard']);
            }
          },
          error => {
          });
      } else {
        window.location = responseData.redirectUrl;
      }
    },
      error => {

        if (error.error.message.includes('temporarily blocked')) {
          this.clearOTP();
          this.unRegisteredUserFlag = true;
          this.Form2 = false;
          this.errmsgMobile = error.error.message;
          this.OTPGenerated = false;
          this.loginButton = true;
          this.disableCallBack = true;
          if (this.loginMethod === 'mobile') {
            this.phoneNumberInput.phoneNumberInput.nativeElement.disabled = false;
            this.phoneNumberInput.allowDropdown = true;
            this.showEditOption = '';
            this.showEmailOrMobile = 'email';
          } else {
            this.showEmailOrMobile = 'mobile';
            this.showEditOption = '';
          }
        } else {

          this.OTPerror = error.error.message;
        }
      });
  }

  loginByPassword() {

    const password = this.SubmitsEncry(this.validPassword);
    this.validPassword = password.toString();
    this.errmsgMobile = '';
    this.passworderror = '';
    const payLaod = {
      'verifyBy': this.loginMethod,
      'countryCode': this.countryCode,
      'mobileNo': this.mobile,
      'email': this.email,
      'password': JSON.stringify(this.validPassword)
    };

    this.loginService.loginByPassword(payLaod, this.companyId).subscribe((responseData: any) => {

      if (responseData.redirectUrl === '') {
        this.companyId = responseData.companyId;
        localStorage.setItem('accessToken', responseData.accessToken);
        localStorage.setItem('userSource', responseData.source === undefined ? '' : responseData.source);
        localStorage.setItem('corp_Sec_Count', JSON.stringify(responseData));
        const accessToken = localStorage.getItem('accessToken');
        this.loginService.getUserInfo(this.companyId, accessToken).subscribe(
          response => {

            localStorage.setItem('userInfo', JSON.stringify(response.userDetails));
            localStorage.setItem('companyType', responseData.companyType);
            localStorage.setItem('companyURL', response.companyUrl);
            localStorage.setItem('userRole', response.userDetails.Companies[0].RoleId);
            localStorage.setItem('biReportURL', response.biReportUrl);
            localStorage.setItem('isPremiumAcc',response.userDetails.Companies[0].IsPremiumAccount);
            localStorage.setItem('masterQBAccess',response.userDetails.Companies[0].MasterQuestionAccess);
            this.commonService.storeAPIKeyWebhookDetails(response);
            localStorage.removeItem('checkProfile');

            if (responseData.companyType === 'ssc') {
              localStorage.setItem('checkProfile', 'dashboard');
              localStorage.setItem('check', 'true');
              this.router.navigate(['dashboard']);
            } else if (responseData.companyType === 'corporate') {
               localStorage.setItem('checkProfile', 'dashboard');
              localStorage.setItem('check', 'true');
              if (response.userDetails.Companies[0].RoleId === 'super_admin') {
                this.router.navigate(['super_admin']);
              }
              else {
                this.router.navigate(['dashboard']);
              }
            } else {

              localStorage.setItem('checkProfile', 'dashboard');
              localStorage.setItem('check', 'true');
              localStorage.setItem('sectorCount', responseData.sectorCount);
              this.router.navigate(['dashboard']);
            }

          },
          error => {

            console.log(error);
            console.log(error.error.message);
          });
      } else {
        window.location = responseData.redirectUrl;
      }

    },
      error => {

        console.log("blocked Error Occured");

        this.passwordErrFlag = true;
        this.passworderror = error.error;
        if (error.error.message.includes('temporarily blocked')) {

          this.unRegisteredUserFlag = true;
          this.Form2 = false;
          this.errmsgMobile = error.error.message;
          this.OTPGenerated = false;
          this.validatePassword = false;
          this.loginButton = true;

        }
      });
    this.validPassword = '';
  }

  onSubmit(feed) {

  }

  resendOTP() {
    this.showotpsent = false;
    this.errmsgMobile = '';
    this.clearOTP();
    this.showResend = false;
    this.secondsvalue = false;
    const payLaod = {
      'mobileNo': this.mobile,
      'countryCode': this.countryCode,
      'verifyBy': this.loginMethod,
      'email': this.email,

    };
    this.verifyService.resendOTP(payLaod, this.companyId).subscribe(
      response => {
        this.disableCallBack = false;
        this.timer(2);
        this.showTimer = true;
        this.message = response.response;
        $('#message').show().delay(2000).fadeOut();
      },
      error => {
        this.errmsgMobile = error.error.message;
        this.OTPGenerated = false;
        this.mobile = '';
        this.loginButton = true;
      });
  }

  gerateOtp(companyId) {
    this.showCompany = false;
    const payLaod = {
      'email': this.email,
      'mobileNo': this.mobile,
      'countryCode': this.countryCode,
      'verifyBy': this.loginMethod
    };
    this.verifyService.generateOTP(payLaod, companyId).subscribe(
      response => {
        this.message = response.response;
      },
      error => {
        this.errmsgMobile = error.error.message;
        this.OTPGenerated = false;
        this.mobile = '';
      });
  }
  getCompanyList() {

    this.errmsgMobile = '';
    this.disableMobileorEmail = true;
    const payload = {
      'email': this.email,
      'verifyBy': this.loginMethod,
      'countryCode': this.countryCode + '',
      'mobile': this.mobile
    };

    this.loginService.getCompanyList(payload)
      .subscribe(
        response => {
          this.Form2 = true;
          this.unRegisteredUserFlag = false;

          // const company_id = response[0]._id;

          this.companyList = response;
          // this.companyId = company_id;
          // localStorage.setItem('companyId', company_id);

          if (this.companyList.length > 1) {

            this.showCompany = true;
            this.OneCompany = true;
            // this.OTPGenerated = true;
            // this.validatePassword = false;
            // this.phoneNumber = false;
            this.loginButton = false;
          } else {

            // this.OTPGenerated = false;
            this.showCompany = false;
            this.OneCompany = true;
            // this.validatePassword = false;
             this.getOtpOrPassword();
            const company_id = response[0]._id;
             this.companyId = company_id;
          localStorage.setItem('companyId', company_id);
          this.CompanyName=response[0].companyName;

          }
        },
        error => {

          this.Form2 = false;
          this.unRegisteredUserFlag = true;
          this.errmsgMobile = error.error.message;
        });
  }

  getCountryCode() {
    setTimeout(function () { myExtObject.loadFlag(''); });
  }

  selectedCompanyName(companyId) {
    this.companyId = companyId;
    localStorage.setItem('companyId', this.companyId);
  }

  SubmitsEncry(password): string {
    const key = CryptoJS.enc.Utf8.parse('8080808080808080');
    const iv = CryptoJS.enc.Utf8.parse('8080808080808080');
    const encryptedpassword = CryptoJS.AES.encrypt(CryptoJS.enc.Utf8.parse(password), key,
      {
        keySize: 128 / 8,
        iv: iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7
      });
    return encryptedpassword;
  }
  switchOtp() {
    this.validatePassword = false;
    this.showResend = false;
    this.OTPGenerated = true;
    this.gerateOtp(this.companyId);
    this.secondsvalue = false;
    this.showTimer = true;
    this.disableCallBack = false;
    this.timer(2);
    this.OneCompany = true;
    this.showCompany = false;
  }

  changeCompany() {
    this.CompanyName=null;
    this.OneCompany = false;
    this.showCompany = true;
    this.OTPGenerated = false;
    this.validatePassword = false;
    // this.CompanyName=null;
    this.otpDigit1='';
    this.otpDigit2='';
    this.otpDigit3='';
    this.otpDigit4='';
    this.otpDigit5='';
    this.otpDigit6='';
   this.companyId=null;
   this.validPassword="";



  }
  ngOnDestroy() {
    document.body.classList.remove('registration_login_bg_image');
  }
}
