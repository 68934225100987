<app-user-header></app-user-header>

<nav class="container-fluid pt-3 pb-3 bd-navbar sub-header">
  <div class="row">
    <div class="col">
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb" style="cursor: pointer">
          <i class="material-icons green align-middle" routerLink="/observer" style="cursor: pointer" >keyboard_arrow_left</i>

          <li class="breadcrumb-item active" aria-current="page"><a>Observer Notification</a></li>
        </ol>
      </nav>
    </div>
    <div class="col">
      <div class="btn-group float-right ">
        <button type="button" class="btn btn-link" *ngIf="showMDelete" (click)="deleteNotification.show()">
          <span class="align-middle"><img class=" " src="/assets/img/Delete.png" alt=""> Delete</span>
        </button>
        <button type="button" class="btn btn-primary" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" (click)="createNotification.show();checkForEdit('create','list')">
          Create New
        </button>
      </div>
    </div>
  </div>
</nav>



<div class="main-div-assessment container-fluid bg-white pt-1 pb-5 ">

    <!--<div class="row mt-3">-->
    <!--<div class="col ">-->
    <!--<form action="" class="Search">-->
    <!--<input class="Search-box" type="search" id="Search-box" autocomplete="off" placeholder="Search">-->
    <!--<label class="Search-label" for="Search-box"><i class="material-icons">search</i></label>-->
    <!--</form>-->
    <!--</div>-->
    <!--</div>-->
    <div class="row mt-5">
      <div class="col">
        {{notificationData.length}} Notification
      </div>
    </div>
    <div class="row mt-3">
      <div class="col">
        <div class="card ">
          <div class="card-body ">
            <div class="row GR-14">
              <div class="col-1"></div>
              <div class="col-4">Title</div>
              <div class="col -3">Message</div>
              <div class="col -3">Notification</div>
              <div class="col-1"></div>
            </div>
          </div>
        </div>
        <ng-container>
          <div  class="card card__two mtop15 border-0" *ngFor="let list of notificationData; let i=index;">
            <div class="card-body card-bg">
              <div class="row">
                <!--<div class="col-1 text-center">-->
                <!--<div class="form-check">-->
                <!--<label class="mb-0"></label>-->
                <!--</div>-->
                <!--</div>-->
                <div class="col-1 text-center">
                  <div class="form-check">
                    <label class="mb-0"><input value="{{list._id}}" *ngIf="list.companyId !==''" id="checkBox" type="checkbox" (click)="multipleDelete($event)"><span class="label-text"></span></label>
                  </div>
                </div>
                <div class="col-4 text-truncate qHeading dect">
                  {{list.notificationTitle}}
                </div>
                <div class="col-3 text-truncate qRest">{{list.notificationMessage}}</div>
                <div class="col-3 text-truncate qRest"><span>{{list.notificationType}}</span></div>
                <div class="col-1" >
                  <button  type="button" class="btn float-right btnMore" role="button" id="dropdownMenuLink"
                           data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    <i class="material-icons">more_vert</i>
                  </button>
                  <div class="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuLink"
                       x-placement="bottom-end"
                       style="position: absolute; transform: translate3d(-155px, 25px, 0px); top: 0px; left: 0px; will-change: transform;">
                    <a class="dropdown-item" *ngIf="list.companyId !==''" (click)="createNotification.show();checkForEdit('edit',list)">Edit</a>
                    <a class="dropdown-item" (click)="viewNotification.show();checkForEdit('view',list)">View</a>
                    <a class="dropdown-item" *ngIf="list.companyId !==''" (click)="deleteNotification.show();getNotificationID(list)">Delete</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
  <!--<div class="row mtop30">-->
    <!--<div class="col text-center">-->
      <!--<button type="button"  class="btn btn-more mtop30 ">-->
        <!--Load more-->
      <!--</button>-->

    <!--</div>-->
  <!--</div>-->
</div>

<div class="modal fade" bsModal tabindex="-1" role="dialog" #createNotification="bs-modal" [config]="{backdrop: 'static'}">
  <div class="modal-dialog modal-dialog-centered-md ">
    <!-- Modal content-->
    <form [formGroup]="formData" (ngSubmit)="submitForm()">
      <div class="modal-content" style="">
        <div class="modal-header">
          <h5 class="modal-title" *ngIf="!editMode">Add Notification</h5>
          <h5 class="modal-title" *ngIf="editMode">Edit Notification</h5>
          <button type="button" *ngIf="!editMode" class="close" data-dismiss="modal" aria-label="Close" (click)="createNotification.hide();clearFormData();">
            <span aria-hidden="true">&times;</span>
          </button>
          <button type="button" class="close" *ngIf="editMode" data-dismiss="modal" aria-label="Close" (click)="createNotification.hide()">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body ">
          <label for="title1"><b class="boldSize">Title</b></label>
          <input type="text" id="title1" class="form-control" name="title1" formControlName="title1" (focus)="clearServerError()" [ngModel]="editedObj.notificationTitle">
          <div class="text text-danger" *ngIf="titles.touched && titles.invalid" >
            <!--<small *ngIf="lastName.errors.pattern">Enter last name.</small>-->
            <!--<small *ngIf="lastName.errors.maxlength">Maximum 101 characters are allowed.</small>-->
          </div>
          <br/>
          <label for="message1"><b class="boldSize">Message</b></label>
          <textarea rows="6" cols="120" class="form-control" id="message1" name="message1" (focus)="clearServerError()" formControlName="message1" [ngModel]="editedObj.notificationMessage"></textarea>
          <div class="text text-danger" *ngIf="messages.touched && messages.invalid" >
          <!--<small *ngIf="lastName.errors.pattern">Enter last name.</small>-->
          <!--<small *ngIf="lastName.errors.maxlength">Maximum 101 characters are allowed.</small>-->
        </div>
          <br/>
          <label for="notification1"><b class="boldSize">Notification Type</b></label>
          <select  class="custom-select" id="notification1" name="notification1" (focus)="clearServerError()" formControlName="notification1" [ngModel]="editedObj.notificationType">
            <option value="">Select Reason</option>
            <option value="Screen Violation">Screen Violation</option>
            <option value="Facial Violation">Facial Violation</option>
            <option value="Other">Other</option>
          </select>
          <div class="text text-danger" *ngIf="notifications.touched && notifications.invalid" >
            <!--<small *ngIf="lastName.errors.pattern">Enter last name.</small>-->
            <!--<small *ngIf="lastName.errors.maxlength">Maximum 101 characters are allowed.</small>-->
          </div>
        </div>
        <div class="text-danger text-center">
          <small>{{errorMessage}}</small>
        </div>
        <div class="modal-footer d-block mx-auto mbot30">
          <button type="button" *ngIf="!editMode" class="btn btn-primary" [disabled]="formData.invalid" data-dismiss="modal" (click)="addNotification(formData.value)">Submit</button>
          <button type="button" *ngIf="editMode" class="btn btn-primary" [disabled]="formData.invalid" data-dismiss="modal" (click)="editNotification(formData.value)">Submit</button>
          <button type="button" *ngIf="!editMode" class="btn btn-cancel" data-dismiss="modal" (click)="createNotification.hide();clearFormData();">Cancel</button>
          <button type="button" *ngIf="editMode" class="btn btn-cancel" data-dismiss="modal" (click)="createNotification.hide()">Cancel</button>
        </div>
      </div>
    </form>
  </div>
</div><!-- end for modal-->

<div class="modal fade" bsModal tabindex="-1" role="dialog" #deleteNotification="bs-modal" [config]="{backdrop: 'static'}">
  <div class="modal-dialog modal-dialog-centered-md ">
    <!-- Modal content-->
    <form>
      <div class="modal-content" style="">
        <div class="modal-header">
          <h5 class="modal-title">Alert</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="deleteNotification.hide()">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body ">
         <p class="text-center">Are you sure want to delete notification (s)?</p>
        </div>
        <div class="modal-footer d-block mx-auto mbot30">
          <button type="button" *ngIf="showMDelete" (click)="deleteMultipledata()" class="btn btn-primary"  data-dismiss="modal">Yes</button>
          <button type="button" *ngIf="!showMDelete" (click)="deleteNotificationData()" class="btn btn-primary"  data-dismiss="modal">Yes</button>
          <button type="button" class="btn btn-cancel" data-dismiss="modal" (click)="deleteNotification.hide();clearFormData();">No</button>
        </div>
      </div>
    </form>
  </div>
</div><!-- end for modal-->


<div class="modal fade" bsModal tabindex="-1" role="dialog" #viewNotification="bs-modal" [config]="{backdrop: 'static'}">
  <div class="modal-dialog modal-dialog-centered-md ">
    <!-- Modal content-->
    <form [formGroup]="formData" (ngSubmit)="submitForm()">
      <div class="modal-content" style="">
        <div class="modal-header">
          <h5 class="modal-title">Notification Details</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="viewNotification.hide();">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body ">
          <label for="title"><b class="boldSize">Title</b></label>
          <p>{{editedObj.notificationTitle}}</p>
          <label for="message"><b class="boldSize">Message</b></label>
          <p>{{editedObj.notificationMessage}}</p>
          <label for="notificationTypes"><b class="boldSize">Notification Type</b></label>
          <p>{{editedObj.notificationType}}</p>
        </div>
      </div>
    </form>
  </div>
</div><!-- end for modal-->

