import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { SharedserviceService } from '../../sharedservice.service';
import { Meta, Title } from "@angular/platform-browser";


@Component({
  selector: 'app-skill-based-template-detail-public-view',
  templateUrl: './skill-based-template-detail-public-view.component.html',
  styleUrls: ['./skill-based-template-detail-public-view.component.css']
})
export class SkillBasedTemplateDetailPublicViewComponent implements OnInit {
  urlSlug:string="";
  templateDetails:any;
  userDetails:any;
  companyName: string = "";
  contactPerson: string = "";
  contactEmail: string = "";
  contactMobNo: number;
  phoneNumberInput: any;
  countryCode: string;
  mobile: string;
  contactForm: FormGroup;
  selectedUsesTab = 0;
  serverErrMessage: string;
  requestedtemplate:any;
  requestingTemplateName: string;
  query:string;

  constructor(private route:ActivatedRoute, private SharedserviceObj:SharedserviceService,
              private toastrService: ToastrService,
              private router:Router,
              private titleService: Title,
              private metaService: Meta,
  ) {
    this.contactForm = new FormGroup({
      companyName: new FormControl("", Validators.required),
      contactPerson: new FormControl("", Validators.required),
      contactEmail: new FormControl("", Validators.required),
      contactMobNo: new FormControl("", Validators.required),
      query: new FormControl(""),
    });
  }

  ngOnInit(): void {
    this.userDetails = JSON.parse(localStorage.getItem("userInfo"));
    this.contactForm = new FormGroup({
      companyName: new FormControl("", Validators.required),
      contactPerson: new FormControl("", Validators.required),
      contactEmail: new FormControl("", [Validators.required,Validators.pattern('^(([^<>()\\[\\]\\\\.,;:\\s@\"]+(\\.[^<>()\\[\\]\\\\.,;:\\s@\"]+)*)|(\".+\"))@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\])|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,}))$')]),
      contactMobNo: new FormControl("",Validators.required),
      query: new FormControl(""),
    });
    this.getRouteParams();
  }

  getRouteParams(){
    this.route.params.subscribe((params:any)=>{
      if(params.urlSlug)
      // this.metaService.addTags([
      //   { name: "keywords", content: "Keyword1, Keyword2" },
      //   { name: "description", content: "Your SEO desc" },
      // ]);
      
        this.urlSlug=params.urlSlug;
      this.getTemplateDetail();
    })
  }

  getTemplateDetail(){
    const payLoad={ 
      "templateUrl":this.urlSlug 
  
  } 
  this.SharedserviceObj.getPublicTemplateDetails(payLoad).toPromise()
  .then((response:any)=>{
    
    this.templateDetails=response.message;
    this.titleService.setTitle(this.templateDetails.templateTitle);
  })


  }


  loginToUse(){
      this.router.navigate(["/login"]);
  }

  ngAfterViewChecked(): void {
    const mobileNumberField = document.getElementById("primaryPhoneInput");
    if (
      mobileNumberField !== null &&
      mobileNumberField !== undefined &&
      !mobileNumberField.classList.contains("form-control")
    ) {
      mobileNumberField.classList.add("form-control");
    }
  }

  get contactNumber() {return this.contactForm.get("contactMobNo"); }
  get email() { return this.contactForm.get('contactEmail'); }
  get companyname() { return this.contactForm.get('companyName'); }
  get contactperson() { return this.contactForm.get('contactPerson'); }

  clearForm(){
    this.contactForm.reset();
  }


  keypress(event) {
    return event.charCode === 8 || event.charCode === 0
      ? null
      : event.charCode >= 48 && event.charCode <= 57;
  }

  clearServerErr() {
    this.serverErrMessage = "";
  }


  contactToUse(temp:any) {

    this.requestedtemplate=temp;
    this.requestingTemplateName=temp.templateTitle;
    if(this.userDetails){
      ($("#contactToUseModal2") as any).modal("show");

    }
    else{
      ($("#contactToUseModal") as any).modal("show");
    }
  }

  checkCountryCode(event) {
    this.phoneNumberInput = event.valueAccessor;
    this.countryCode = "+" + this.phoneNumberInput.dialCode;
    this.mobile = this.phoneNumberInput.phoneNumberOnly;
  }

  submitData() {

    const Payload={
      "templateId":this.requestedtemplate._id,
      "contactPersonName":this.contactForm.controls.contactPerson.value,
      "email":this.contactForm.controls.contactEmail.value,
      "additionQuery":this.contactForm.controls.query.value,
      "mobileNo":this.contactForm.controls.contactMobNo.value.slice(3),
      "mobileCountryCode":"+91",
      "companyName":this.contactForm.controls.companyName.value

    }
    this.SharedserviceObj.contactToUse(Payload).toPromise()
    .then((response:any)=>{
      this.toastrService.success(response.response);
      this.contactForm.reset();

    },(error:any)=>{
      
      this.toastrService.error(error.error.message, "Error", {
        timeOut: 5000,
      });
      this.contactForm.reset();
    })
  }

  submitData2(){
    const Payload={
      "templateId":this.requestedtemplate._id,
      "contactPersonName":this.userDetails.FirstName.concat(this.userDetails.LastName),
      "email":this.userDetails.Companies[0].EmailId,
      "additionQuery":this.query,
      "mobileNo":this.userDetails.MobileNo,
      "mobileCountryCode":"+91",
      "companyName":this.userDetails.Companies[0].CompanyName

    }
    this.SharedserviceObj.contactToUse(Payload).toPromise()
    .then((response:any)=>{
      this.toastrService.success(response.response);
      this.contactForm.reset();

    },(error:any)=>{
      
      this.toastrService.error(error.error.message, "Error", {
        timeOut: 5000,
      });
      this.contactForm.reset();
    })

  }

  detailPage(tempid){
    this.router.navigate(["/assessment/create-from-template/"+tempid]);

  }

  ngOnDestroy(){
    
    this.titleService.setTitle("CALIBRAT");
  }

}
