<section class="bg-white">
  <app-header-static></app-header-static>
</section>

<div class="blog-breadcrumb mt-3">
  <div class="d-flex top-bloggap align-items-center justify-content-start">
    <a>
      <img src="assets/img/tick-logo.png" class="blogo" alt="">
    </a>
    <img src="assets/images/blog-arrow.svg" class="barrow" alt="">
    <a>
      <p class="mb-0">Resources</p>
    </a>
    <img src="assets/images/blog-arrow.svg" class="barrow" alt="">
    <a routerLink="/blog"><p class="mb-0">Blog</p></a>
  </div>
</div>
<div class="blog-details">
  <div *ngIf="blogDetailsResponseError != ''" class="container text-center text-danger GB-40 mt-30">
    {{blogDetailsResponseError}}
  </div>
  <div *ngFor="let blogInfo of blogDetailsResponse; let i =index;" class="container">
    <div class="blog-subject">{{blogInfo.category[0]}}</div>
    <h5 class="text-left">{{blogInfo.title}}</h5>
    <p class="short-line text-left mb-0">{{blogInfo.subTitle}}</p>
    <div class="row text-left align-items-start">
      <span class="blogdetails-dash"></span>
    </div>
    <div class="d-flex align-items-center justify-content-between ">
      <div class="d-flex align-items-center justify-content-start">
        <p class="by mb-0">{{blogInfo.author}}</p>
        <div class="vertical-dash mb-0"></div>
        <p class="date-year mb-0">{{blogInfo.createdOn | date: 'dd MMMM yyyy'}}</p>
      </div>
      <!-- <div class="d-flex align-items-center justify-content-end social-icons">
        <a><img src="assets/images/blog-twitter.svg" class="blog-twitter cursor-pointer"></a>
        <a><img src="assets/images/blog-fb.svg" class="blog-fb cursor-pointer"></a>
        <a><img src="assets/images/blog-linkedin.svg" class="blog-linkedin cursor-pointer"></a>
      </div> -->
    </div>
    <div class="text-center">
      <img src="{{blogInfo.primaryImage}}" class="blog-bannerimage w-100 h-100" alt="">
    </div>
    <div class="fontclass" [innerHTML]="blogInfo.subjectBody"></div>
  </div>
</div>
<app-footer-static ></app-footer-static>
