<section class="bg-home">
  <app-header-static (productsMenuSelectionEvent)="onProductSelection($event)"></app-header-static>

<!--<div class="bg-home2 ">-->
  <!--<img src="assets/img/tick-bg.png"  height="" alt="" class="img-fluid">-->
  <div class="container spacing3">
    <div class="pt-3 px-3 pt-md-5 px-md-5 text-center overflow-hidden">
      <div class="my-0 p-0">
        <h1 class="GR-30">Assessment Simplified</h1>
        <p class="lead">For reliable and accurate measure of capabilities</p>
        <p>
          <a routerLink="/register" class="btn btn-primary my-2">Start Free Trial</a>
          <a href="" (click)="scheduleDemo()"
             class="btn btn-primary my-2">Schedule Demo
          </a>

        </p>
      </div>
      <div class="mx-auto d-none d-sm-block">
        <img src="assets/img/devices.png"  height="" alt="Calibrat - Online Assessment available on Desktop and Mobile" class="img-fluid">
      </div>
    </div>
  </div>
<!--</div>-->

<!--<div class="bg-home3 "><img src="assets/img/home-bg2.png"  height="" alt="" class="img-fluid"></div>-->


  <div class="container spacing1 mt-5">
    <div class="row">
      <!--<div class=" col-md-7 col-sm-12"></div>-->
      <div class="spacing2">
        <div class=" pl-3 pr-3 pb-5 pt-3 bg-white block_1" >
          <h2 class="GR-26 pl-3">Why Calibrat?</h2>
          <img src="assets/img/Ease-and Convenience.png"  height="" alt="Calibrat is Easy to use tool" class="mt-5 mb-3 pl-3">
          <h3 class="RR-16 font-weight-bold text-gray pl-3">Simple to Use</h3>
          <p class="RR-13 pl-3">Assessing a large number of candidates is cumbersome & painstaking task. This platform organises and streamlines the manual processes in simple steps to conduct assessments online with easy-to-follow administration, scoring, and interpretation.</p>
          <img src="assets/img/Pay-per use.png"  height="" alt="" class="mt-5 mb-3 pl-3">
          <h3 class="RR-16 font-weight-bold text-gray pl-3">Pay Per Use</h3>
          <p class="RR-13 pl-3">You can pay to match your assessment needs. Cost-effective approach for assessment requirements with access to all the platform features.</p>
          <img src="assets/img/reduce-cost-and effort.png"  height="" alt="Calibrat is cost an effort effective for assessment" class="mt-5 mb-3 pl-3">
          <h3 class="RR-16 font-weight-bold text-gray pl-3">Reduce Effort and Cost</h3>
          <p class="RR-13 pl-3">Eliminate the logistical costs of paper-based assessments. Auto-evaluation or platform aided evaluation reduces evaluation efforts thereby reducing costs incurred in traditional paper-based methods.</p>
          <img src="assets/img/Company_Branding.png"  height="" alt="Calibrat us reliable and accurately measure capabilitie" class="mt-5 mb-3 pl-3">
          <h3 class="RR-16 font-weight-bold text-gray pl-3">Reliable And Accurate Measure of Capabilities</h3>
          <p class="RR-13 pl-3">Individual judgment about candidates tend to be subjective and prone to error. Standardised assessments can help to reduce such subjective judgements and help in making the accurate and effective decision about candidates.</p>
        </div>
      </div>
    </div>
  </div>
</section>

<div class="container-fluid bg-gray">
  <div class="container pb-5 pt-5">


    <h2 class="GR-26 mx-auto" style="width:160px;" id="usesLabel">PRODUCT</h2>
    <select [(ngModel)]="selectedUsesTab" (change)="selectTab($event.target.value)" class="form-control d-block d-sm-none">
      <option value="0">Fresher/Lateral Screening</option>
      <option value="1">Campus Screening</option>
      <option value="2">Employee Assessments</option>
      <option value="3">Entrance Examinations</option>
      <option value="4">Skill India</option>
    </select>
    <tabset [justified]="true" type="pills" #usesTabs>
      <tab heading="Fresher/Lateral Screening" customClass="usesTab" (select)="setSelectedUsesTab(0)">
        <div class="row justify-content-between">
          <div class="col-md-6 col-sm-12">
            <p class="RR-16 font-weight-bold text-gray">Fresher/Lateral Screening</p>
            <p class="RR-13">Validating the skills or capabilities claimed by candidates (fresher as well as lateral) in resume/CV is always an extremely resource and time consuming task for the talent management team of an organisation.</p>
            <p class="RR-13">CALIBRAT offers an effective screening on technical/functional skills to eliminate the unsuitable candidates for the role to reduce the rejections or increase the selections by line of business or functional team.</p>
            <p class="RR-13">CALIBRAT is completely secure to conduct proctored assessments with few clicks.</p>

          </div>

          <div class="col-md-5 col-sm-12">
            <div class="row text-center RR-14 text-gray2">
              <div class="col col-mob">

                <div class="step-1">
                  <img src="assets/img/tab1_1.png"  height="40" alt="" class="mob-h1">
                  <p class="mt-2">Create and Configure Assessments</p>
                </div>
              </div>

              <div class="col col-mob">
                <div class="step-2">
                  <img src="assets/img/tab1_2.png"  height="40" alt="" class="mob-h1">
                  <p class="mt-2">Share Access Code with Candidates</p>
                </div>
              </div>

              <div class="w-100 mt-3 mb-3"></div>
              <div class="col col-mob">
                <div class="step-3">
                  <img src="assets/img/tab1_3.png"  height="40" alt="" class="mob-h1">
                 <p class="mt-2"> Get Assessment Scores/Reports</p>
                </div>
              </div>
              <div class="col col-mob">
                <div class="step-4">
                  <img src="assets/img/tab1_4.png"  height="40" alt="" class="mob-h1">
                  <p class="mt-2">Select Suitable <br>Candidates</p>
                </div>
              </div>
            </div>
          </div>

        </div>
      </tab>
      <tab heading="Campus Screening" customClass="usesTab" (select)="setSelectedUsesTab(1)">
        <div class="row justify-content-between">
          <div class="col-md-6 col-sm-12">
            <p class="RR-16 font-weight-bold text-gray">Campus Screening</p>
            <p class="RR-13">The traditional paper and pencil mode of conducting campus screening is the thing of past.</p>
            <p class="RR-13">This mode of screening not only involve resources and time in distributing the question paper/answer sheets, collecting and evaluating the answer sheets, and correctly recording the evaluation scores but also had limited scope to check the violations as well manual errors in evaluation.</p>
            <p class="RR-13">CALIBRAT offers a simple and secure way to conduct campus assessments in just a few steps.</p>
          </div>
          <div class="col-md-5 col-sm-12">
            <div class="row text-center RR-14  text-gray2">
              <div class="col">
                <div class="step-1">
                  <img src="assets/img/tab1_1.png"  height="40" alt="" class="mob-h1">
                  <P class="mt-2">Create and Configure Assessments</P>
                </div>
              </div>

              <div class="col ">
                <div class="step-2">
                  <img src="assets/img/tab1_2.png"  height="40" alt="" class="mob-h1">
                  <p class="mt-2">Share Access Code with Candidates</p></div>
              </div>

              <div class="w-100 mt-3 mb-3"></div>
              <div class="col ">
                <div class="step-3">
                  <img src="assets/img/tab1_3.png"  height="40" alt="" class="mob-h1">
                  <p class="mt-2">Get Assessment Scores/Reports</p></div>
              </div>
              <div class="col ">
                <div class="step-4">
                  <img src="assets/img/tab1_4.png"  height="40" alt="" class="mob-h1">
                  <p class="mt-2">Select Suitable <br>Candidates</p></div>
              </div>
            </div>
          </div>
        </div>
      </tab>
      <tab heading="Employee Assessments" customClass="usesTab" (select)="setSelectedUsesTab(2)">
        <div class="row justify-content-between">
          <div class="col-md-6 col-sm-12">
            <p class="RR-16 font-weight-bold text-gray">Employee Assessment</p>
            <p class="RR-13">With rapid evolution and growth of industries, 21st century workforce needs to be relevant with latest skills and knowledge.</p>
            <p class="RR-13">The retention, managing attrition and engaging a demanding & versatile workforce remains a key challenge for Talent management team. Hence Learning and Development team of an organisation is constantly involved in training need identification, training delivery and impact measurement of training delivered.</p>
            <p class="RR-13">CALIBRAT (integrated with content publishing) offers an amazing interface to conduct online training and evaluation with ease and effectiveness.</p>
          </div>
          <div class="col-md-5 col-sm-12">
            <div class="row text-center RR-14  text-gray2">
              <div class="col">
                <div class="step-1">
                  <img src="assets/img/tab1_1.png"  height="40" alt="" class="mob-h1">
                  <p class="mt-2">Create and Publish <br>your own Course Content.</p></div>
              </div>

              <div class="col ">
                <div class="step-2">
                  <img src="assets/img/tab1_2.png"  height="40" alt="" class="mob-h1">
                  <p class="mt-2">Share the Course Access Code</p></div>
              </div>

              <div class="w-100 mt-3 mb-3"></div>
              <div class="col ">
                <div class="step-3">
                  <img src="assets/img/tab1_3.png"  height="40" alt="" class="mob-h1">
                  <p class="mt-2">Online Evaluation with Integrated Assessments</p></div>
              </div>
              <div class="col ">
                <div class="step-4">
                  <img src="assets/img/tab1_4.png"  height="40" alt="" class="mob-h1">
                  <p class="mt-2">Monitor Performance and Progress</p></div>
              </div>
            </div>
          </div>
        </div>
      </tab>
      <tab heading="Entrance Examinations" customClass="usesTab" (select)="setSelectedUsesTab(3)">
        <div class="row justify-content-between">
          <div class="col-md-6 col-sm-12">
            <p class="RR-16 font-weight-bold text-gray">Entrance Examination</p>
            <p class="RR-13">Entrance examinations are most commonly used by Educational Institutions for admissions as well as by Professional Bodies for professional selections.</p>
            <p class="RR-13">The traditional paper and pencil mode of conducting entrance examinations is resource consuming and highly error or abuse prone due to manual intervention.</p>
            <p class="RR-13">CALIBRAT offers secure way to conduct entrance examinations either at the examination venue or remotely.</p>
          </div>
          <div class="col-md-5 col-sm-12">
            <div class="row text-center RR-14  text-gray2">
              <div class="col">
                <div class="step-1">
                  <img src="assets/img/tab1_1.png"  height="40" alt="" class="mob-h1">
                  <p class="mt-2">Create and Configure <br>Assessment</p></div>
              </div>

              <div class="col ">
                <div class="step-2">
                  <img src="assets/img/tab1_2.png"  height="40" alt="" class="mob-h1">
                  <p class="mt-2">Share Access Code with Applicants</p></div>
              </div>

              <div class="w-100 mt-3 mb-3"></div>
              <div class="col ">
                <div class="step-3">
                  <img src="assets/img/tab1_3.png"  height="40" alt="" class="mob-h1">
                  <p class="mt-2">Get Assessment Scores/Reports</p></div>
              </div>
              <div class="col ">
                <div class="step-4">
                  <img src="assets/img/tab1_4.png"  height="40" alt="" class="mob-h1">
                  <p class="mt-2">Select Suitable Applicants</p></div>
              </div>
            </div>
          </div>
        </div>
      </tab>
      <tab heading="Skill India" customClass="usesTab" (select)="setSelectedUsesTab(4)">
        <div class="row justify-content-between">
          <div class="col-md-6 col-sm-12">
            <p class="RR-16 font-weight-bold text-gray">Skill India</p>
            <p class="RR-13">Skill India Campaign launched by the Government of India aims to train and assess individuals in India across different industry sectors.</p>
            <p class="RR-13">Skill India assessments are designed to certify individuals who have demonstrated necessary knowledge and skills adequate to perform specific roles competently. Sector skill assessments are developed to ascertain that individuals can demonstrate a minimum level of knowledge and/or skill for proficient performance in an occupation or professional role at a specified level offered by various sector skills.</p>
            <p class="RR-13">The assessment guidelines and frameworks developed by the NSDC include several security measures to authenticate the identity of the candidate and to evaluate the training provided.</p>
            <p class="RR-13">CALIBRAT offer a range of security features in line with the online/offline assessment guidelines.</p>
          </div>
          <div class="col-md-5 col-sm-12">
            <div class="row text-center RR-14  text-gray2">
              <div class="col">
                <div class="step-1">
                  <img src="assets/img/Assign_batch_to_assessor.png"  height="40" alt="Calibrat feature assigning batch to assessor" class="mob-h1">
                  <p class="mt-2">Create and Configure Job Roles</p></div>
              </div>

              <div class="col ">
                <div class="step-2">
                  <img src="assets/img/Create_and_configure_job_roles.png"  height="40" alt="" class="mob-h1">
                  <p class="mt-2">Assign Batch to Assessor</p></div>
              </div>

              <div class="w-100 mt-3 mb-3"></div>
              <div class="col ">
                <div class="step-3">
                  <img src="assets/img/Get_NOS_Wise_scores.png"  height="40" alt="Calibrate assesement with NOS wise scores " class="mob-h1">
                  <p class="mt-2">Get NOS Wise Scores</p></div>
              </div>
              <div class="col ">
                <div class="step-4">
                  <img src="assets/img/Upload_to_SDMS.png"  height="40" alt="Calibrat supports Results to be uploadedin SDMS" class="mob-h1">
                  <p class="mt-2">Upload Reports in SDMS</p></div>
              </div>
            </div>
          </div>
        </div>
      </tab>
    </tabset>
  </div>
</div>

<div class="container-fluid">
  <div class="container pb-5 pt-5">

    <h2 class="GR-26 mx-auto" style="width:266px;">PLATFORM USERS</h2> <br/>
    <div class="col-md-12">
      <ng-image-slider [images]="imageObject" [imagePopup]="false" [infinite]="true" [imageSize]="imageStyling" [autoSlide]="true" disabled="true" #nav></ng-image-slider>
    </div>
  </div>
</div>

<div class="container-fluid bg-gray">
  <div class="container pb-5 pt-5">
    <img src="assets/img/dotted2.png"  class="dotted2" alt="">

    <h2 class="GR-26 mx-auto" style="width:268px;">TESTIMONIALS</h2>
      <div class="col-md-12 bg-white1">
        <img src="assets/img/thumb.png" class="thumbs"  alt="">
        <div class="row">
          <div class="col-md-4 testi-Padding" style="padding: 40px">
            <div class='container2'>
              <div>
                <img src="assets/img/krish1.jpeg" class="testimonial_logo"  alt="">
              </div>
              <div class="NS-Names">
                <p class="New-Style">Dr Krish Ranganath</p>
                <div class="designation">CTO</div>
                <div class="designation">Medallion Communications  Limited, Nigeria</div>
              </div>
            </div>
            <br/> <br/>
            <p class="RR-13 justify-content-between">As part of skill enhancement and internal assessment for  our internal and external team, we tried many solutions, but eventually when we adopted Calibrat, we found that it is an amazing platform and value for money. Now we are in the process of using the same platform for assessments in the educational sector as a carrier mapping tool. The application is so flexible that it can be used across multiple industries with minimum customization, and the result of the assessments gives a real time perspective of the person taking the assessment and the technology used behind the tool is the best, because without that the results may not be so accurate </p>
          </div>
          <div class="col-md-8">
            <div  class="row firstRow">
              <p class="RR-13 justify-content-between" >“I just wanted to share a quick note and let you know that you guys do a really good job @calibrat Assessment. I’m glad we decided to work with you on integrating our Edustack Project. It’s really great how easy your solution helped our schools and students to scale their assessment and testing. calibrat has been a wonderful assessment solution for us and we have integrated it with all our campus solutions so far”</p>
              <div class='container2'>
                <div>
                  <img src="assets/img/cpt1.jpg" class="testimonial_logo"  alt="">
                </div>
                <div class="NS-Names">
                  <p class="New-Style">UGI AUGUSTINE UGI</p>
                  <div class="designation">Chief Executive Officer</div>
                  <div class="designation">Nugi Technologies Nig Limited, Nigeria</div>
                </div>
              </div>
            </div>
            <div  class="row secondRow">
              <p id="RR-20">When it comes to customised assessment platform then CALIBRAT has attained a credible position. Teammates across levels along with their leadership are more than eager to put themselves in our shoes and work around our requirements and extending us the comfort and confidence of an in-house team. Extremely delighted to be associated personally and professionally.</p>
              <div class='container2'>
                <div>
                  <img src="assets/img/sanjay.jpg" class="testimonial_logo"  alt="">
                </div>
                <div class="NS-Names">
                  <p class="New-Style1">Sanjay Kapoor</p>
                  <div class="designation1">Director & Co-Founder</div>
                  <div class="designation1">IRIS Corporate Solutions Private Limited, India</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    <img src="assets/img/dotted1.png" class="dotted1" alt="">
  </div>
</div>

<div class="container-fluid">
  <div class="container pt-5">
    <h2 id="GR-001">AWARDS AND RECOGNITIONS</h2> <br/>
  </div>
</div>

<div class="container-fluid ">
  <div class="container pb-5">
    <div class="row">
      <div class="col-md-12">
        <ng-image-slider [images]="awardObject"  [infinite]="true" [autoSlide]="true" [imageSize]="awardStyling" [imagePopup]="false" #nav1></ng-image-slider>
      </div>
    </div>
  </div>
</div>




<!--<div class="modal fade" bsModal tabindex="-1" role="dialog" #liveDemos="bs-modal" [config]="{backdrop: 'static'}">-->
  <!--<div class="modal-dialog modal-dialog-centered-md ">-->
    <!--&lt;!&ndash; Modal content&ndash;&gt;-->
    <!--<form>-->
      <!--<div class="modal-content" style="">-->
        <!--<div class="modal-header">-->
          <!--<h5 class="modal-title">Notification Details</h5>-->
          <!--<button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="liveDemos.hide();">-->
            <!--<span aria-hidden="true">&times;</span>-->
          <!--</button>-->
        <!--</div>-->
        <!--<div class="modal-body ">-->
          <!--<label for="title">Title</label>-->
          <!--<p>{{editedObj.notificationTitle}}</p>-->
          <!--<label for="message">Message</label>-->
          <!--<p>{{editedObj.notificationMessage}}</p>-->
          <!--<label for="notificationTypes">Notification Type</label>-->
          <!--<p>{{editedObj.notificationType}}</p>-->
        <!--</div>-->
      <!--</div>-->
    <!--</form>-->
  <!--</div>-->
<!--</div>&lt;!&ndash; end for modal&ndash;&gt;-->





<app-footer-static (footerMenuSelectionEvent)="onProductSelection($event)"></app-footer-static>
