import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-header-static',
  templateUrl: './header-static.component.html',
  styleUrls: ['./header-static.component.css']
})

export class HeaderStaticComponent implements OnInit {
  accessToken = '';
  loggedInUser: Boolean = false;
  userRole:string;
  @Output() productsMenuSelectionEvent = new EventEmitter<string>();
  constructor(private router: Router) { }

  ngOnInit() {
    this.accessToken = localStorage.getItem('accessToken');
    if (this.accessToken === '' || this.accessToken === undefined || this.accessToken === null) {
      this.loggedInUser = false;
    } else {
      this.loggedInUser = true;
      
      this.userRole=localStorage.getItem('userRole');
      // this.userRole=JSON.parse(this.userRole);
    }
  }

  scrollToUses(usesTabReference) {
    if (this.router.url.indexOf('/home/') === -1) {
      this.router.navigate(['/home', usesTabReference]);
    } else {
      this.productsMenuSelectionEvent.emit(usesTabReference);
    }
  }
}
