<div role="dialog" >
  <div  class="modal-content">
    <div class="modal-header">
      <h5 class="modal-title" id="exampleModalLabel">Alert!</h5>
    </div>
    <div class="modal-body text-center">
      Internet Connection lost.Check your internet connection and try again.
    </div>
    <div class="modal-footer d-block mx-auto">
      <button type="button" class="btn btn-primary" (click)="closeModal()" data-dismiss="modal">OK</button>
    </div>
  </div>
</div>

