import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';
import { throwError} from 'rxjs';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import 'rxjs/add/observable/throw';
import {environment} from '../../environments/environment';


@Injectable()
export class UserHeaderService {

  private headers = new HttpHeaders({
    'Content-Type': 'application/json',
  });
  private url = '' ;
  private localhost = 'localhost';
  globalUrl = environment.serverUrl;

  constructor(private http: HttpClient) { }

  createJD(payLoad: any, companyId: string, accessToken: any): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'bearer ' + accessToken
    });
    this.url = this.globalUrl + '/business/services/user/jobdescription/create?companyId=' + companyId;
    return this.http.post(this.url, payLoad, {headers: headers}).pipe(
      map( response => response),
      catchError(this._errorHandler)
    );
  }

  changeSector(companyId: string, accessToken: any): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'bearer ' + accessToken
    });
    this.url = this.globalUrl + '/business/services/user/fetch/sectors?companyId=' + companyId;
    return this.http.post(this.url, {}, {headers: headers}).pipe(
      map( response => response),
      catchError(this._errorHandler)
    );
  }

  switchSector(payload, companyId: string, accessToken: any): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'bearer ' + accessToken
    });
    this.url = this.globalUrl + '/business/services/user/switchsector?companyId=' + companyId;
    return this.http.post(this.url, payload, {headers: headers}).pipe(
      map( response => response),
      catchError(this._errorHandler)
    );
  }

  getUserInfo(companyId: string, accessToken: any): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'bearer ' + accessToken
    });
    this.url = this.globalUrl + '/business/services/user/info?companyId=' + companyId;
    return this.http.post(this.url,  {}, {headers: headers}).pipe(
      map( response => response),
      catchError(this._errorHandler)
    );
  }

  getdirectInfo(): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    this.url = 'https://l2y23bkd57.execute-api.ap-southeast-1.amazonaws.com/development/linkVerification';
    return this.http.post(this.url, {}, {headers: headers}).pipe(
      map( response => response),
      catchError(this._errorHandler)
    );
  }


  logOutUser(): Observable<any> {
    const accessToken = localStorage.getItem('accessToken');
    const companyId = localStorage.getItem('companyId');
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'bearer ' + accessToken
    });
    this.url = this.globalUrl + '/business/services/user/logout?companyId=' + companyId;
    return this.http.post(this.url,  {}, {headers: headers}).pipe(
      map( response => response),
      catchError(this._errorHandler)
    );
  }
  _errorHandler(error: HttpErrorResponse) {
    console.error(error);
    return throwError(error || 'Server Error');

  }
}

