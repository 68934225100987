<app-user-header></app-user-header>
<div class="container-fluid GR-16 message-strip" *ngIf="userSource !== ''">
  <div class="row">
    <div class="col text-center">
      Want to access your old records? <button class="btn btn-outline" (click)="checkRecords()">Click Here</button>
    </div>
  </div>
</div>
<div class="container-fluid mb-3" style="margin-top: 150px;">
  <div class="row mb-3">
    <div class="col text-center">
      <p class="GR-26">Welcome! <span style="font-family: Roboto-Regular"> {{ userDetails.FirstName + " " + userDetails.LastName }}</span></p>
      <p class="GR-16">Simplify and optimise your assessment.</p>
    </div>
  </div>

  <div class="row">
    <div class="col" style="margin-left: 40px;">
      <div class="d-flex justify-content-center" > <!-- width changed from 800px to current one -->
        <div class="db-block" *ngIf="userRole == 'admin'">
          <img class=" " src="/assets/img/Create-Assessment.png" height="83" alt="">
          <p class="mt-3 mb-3 RR-16">Use inbuilt question bank or upload questions</p>
          <button class="btn btn-outline" type="button" (click)="redirect('assessment')">Create Assessment</button>
        </div>

        <!--<div class="db-block">
          <img class=" " src="/assets/img/Create-Jobs.png" alt="">
          <p class="mt-3 mb-3 RR-16">Create job description for screening</p>
          <button class="btn btn-outline" type="button" (click)="redirect('jobs')">Create Job Description</button>
        </div>-->

        <div class="db-block" *ngIf="userRole == 'admin'">
          <img class=" " src="/assets/img/Create-Questions-2.png" alt="">
          <p class="mt-3 mb-3 RR-16">Add, organise and manage the question bank</p>
          <button class="btn btn-outline" type="button"(click)="redirect('question')">Add Question</button>
        </div>

        <div class="db-block"  *ngIf="userRole == 'admin'">
          <img class=" mb-3" src="/assets/img/Observer.png" alt="">
          <p disabled="true" class="mt-3 mb-3 RR-16">Monitor the candidates remotely </p>
          <button  class="btn btn-outline" type="button" (click)="redirect('observer')">Proctor</button>
        </div>

        <div class="centerProctor"  *ngIf="userRole == 'evaluator'">
          <img class=" mb-3" src="/assets/img/Observer.png" alt="">
          <p disabled="true" class="mt-3 mb-3 RR-16">Monitor the candidates remotely </p>
          <button  class="btn btn-outline" type="button" (click)="redirect('observer')">Proctor</button>
        </div>

        <div class="db-block" *ngIf="userRole == 'external'">
          <img class=" " src="/assets/img/Create-Assessment.png" height="83" alt="">
          <p class="mt-3 mb-3 RR-16">Evaluate candidates of assigned batches</p>
          <button class="btn btn-outline" type="button" (click)="redirect('assessment/assigned-batches/evaluate')">Evaluate</button>
        </div>
        <div class="db-block"  *ngIf="userRole == 'external'">
          <img class=" mb-3" src="/assets/img/Observer.png" alt="">
          <p disabled="true" class="mt-3 mb-3 RR-16">Monitor the candidates remotely</p>
          <button  class="btn btn-outline" type="button" (click)="redirect('assessment/assigned-batches/proctor')">Proctor</button>
        </div>
        <!-- New block  added for Center Manager -->
        <div class="db-block"  *ngIf="userRole == 'external'">
          <img class=" mb-3" src="/assets/img/centerMngr.png" alt="" height="75px">
          <p disabled="true" class="mt-3 mb-3 RR-16">Manage assigned batches - Lock/Unlock</p>
          <button  class="btn btn-outline" type="button" (click)="redirect('assessment/assigned-batches/centermanager')">Manage</button>
        </div>
      </div>
    </div>
  </div>
</div>


