import {Component, OnInit, AfterViewChecked, ViewChild} from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { VerifyService } from './verify-comp.service';
import { CommonService } from '../common.service';
import * as $ from 'jquery';
import * as CryptoJS from 'crypto-js';
import '../externalJs/external.js';
declare let myExtObject: any;
import {PhoneNumberComponent} from 'ngx-international-phone-number2';


@Component({
  selector: 'app-verify-comp',
  templateUrl: './verify-comp.component.html',
  styleUrls: ['./verify-comp.component.css'],
  providers: [VerifyService, CommonService]
})

export class VerifyCompComponent implements OnInit, AfterViewChecked  {
  errorMsg: string;
  mobile: string;
  countryCode: string;
  OTPGenerated = 'true';
  message: string;
  otpDigit1;
  otpDigit2;
  otpDigit3;
  otpDigit4;
  otpDigit5;
  otpDigit6;
  verifyingRequest = true;
  otpError = '';
  users: string;
  password = '';
  confirmPassword: string;
  matchPassword = '';
  requestToken: any;
  requestType: any;
  mobileNo: string;
  companyId: string;
  accessToken: string;
  companyType: string;
  sectorCount: any;
  generatePassAndOTP: Boolean = true;
  disableCallBack: Boolean = false;
  hour: number;
  minute: number;
  seconds: number;
  timerVar: any;
  hideTimer: boolean;
  showResend: boolean;
  secondsvalue: boolean;
  hideOtpScreen: Boolean = false;
  passwodSet: Boolean = false;
  successMSG: boolean;
  verify_email: boolean;
  validateotpERR = '';
  pwdFormat = 'Password must contain minimum 8 and maximum 15 characters, including at least one uppercase alphabet, one lowercase alphabet, one numeric digit and one special character. Password must not contain spaces';
  passwordValidation: any;
  invalidPassword: boolean;
  phoneNumber: string;
  @ViewChild('feed') feed: any;
  @ViewChild('myInput') phoneNumberInput: PhoneNumberComponent;


  constructor(public router: Router, private verifyService: VerifyService, private commonService: CommonService, private route: ActivatedRoute) {
    // setTimeout(function () { myExtObject.loadFlag(''); });

  }

  ngOnInit() {
    this.companyId = localStorage.getItem('companyId');
    this.route.queryParams.subscribe((params: Params) => {
      this.requestToken = params['token'];
      this.requestType = params['type'] === undefined? '': params['type'];
      this.otpError = '';
      if (this.requestToken !== undefined && this.requestToken !== '') {
        this.OTPGenerated = 'false';
        this.hideOtpScreen = true;
        this.hideTimer = true;
        const payload = { 'token': this.requestToken, 'type': this.requestType };
        this.verifyService.emailVerification(payload)
          .subscribe(
            response => {
              this.timer(2);
              this.countryCode = response.countryCode;
              this.verify_email = response.emailVerify;
              myExtObject.getCountryCode();
              if (this.verify_email === true) {
                this.generatePassAndOTP = false;
                this.router.navigate(['login']);
              }
              this.mobile = response.mobileNo;
              this.phoneNumber = response.countryCode + ' ' + response.mobileNo;
              this.companyId = response.companyId;
              const mobileNumber = this.mobile.substring(6, 10);
              this.message = 'We have sent an OTP to your registered mobile number XXXXX' + mobileNumber + '. Please enter the OTP.';
            },
            error => {
              if (error.error.message.includes('temporarily blocked')) {
                this.otpError = error.error.message;
                this.mobile = '';
                this.hideOtpScreen = false;
                this.OTPGenerated = 'false';
                this.showResend = false;
                this.verifyingRequest = false;
              } else {
                this.verifyingRequest = false;
                this.otpError = error.error.message;
                this.showResend = false;
              }
        });

      } else {
        this.router.navigate(['invalidLink']);
      }
    });
  }

  ngAfterViewChecked(): void {
    const mobileNumberField = document.getElementById('primaryPhoneInput');
    if (mobileNumberField !== null && mobileNumberField !== undefined && !mobileNumberField.classList.contains('form-control')) {
      mobileNumberField.classList.add('form-control');
      mobileNumberField.setAttribute('disabled', 'true');
    }
  }

  clearServerErr() {
    this.phoneNumberInput.allowDropdown = false;
    this.phoneNumberInput.phoneNumberInput.nativeElement.disabled = true;
    this.errorMsg = '';
  }
  keyprees(event) {
    return (event.charCode === 8 || event.charCode === 0) ? null : event.charCode >= 48 && event.charCode <= 57;
  }
  changeFocus(id: string, event) {
    if (event.keyCode === 8 || event.keyCode === 8 || event.keyCode === 32 ) {
      return;
    }
    if (event.keyCode === 46) {
      event.target.value = '';
      return;
    }
    myExtObject.changefocus(id);
    this.otpError = '';
  }

  validateOTP() {
    this.otpError = '';
    const otpDigits = this.otpDigit1 + this.otpDigit2 + this.otpDigit3 + this.otpDigit4 + this.otpDigit5 + this.otpDigit6;
    const otp = Number(otpDigits);

    if (this.requestType == 'invite') {
      const payload = {
        'mobileNo': this.mobile,
        'countryCode': this.countryCode,
        'otp': otp
      };
      this.verifyService.validateInvitationOTP(payload, this.companyId)
        .subscribe(
          response => {
            this.companyId = response.companyId;
            this.accessToken = response.accessToken;
            this.companyType = response.companyType;
            this.sectorCount = response.sectorCount;
            localStorage.setItem('accessToken', response.accessToken);
            localStorage.setItem('companyId', response.companyId);
            localStorage.setItem('corp_Sec_Count', JSON.stringify(response));
            localStorage.setItem('userSource', response.source === undefined ? '' : response.source);

            this.verifyService.getUserInfo(this.companyId, this.accessToken).subscribe(
              res => {
                localStorage.setItem('companyURL', res.companyUrl);
                localStorage.setItem('userInfo', JSON.stringify(res.userDetails));
                localStorage.setItem('userRole', res.userDetails.Companies[0].RoleId);
                this.commonService.storeAPIKeyWebhookDetails(res);
              },
              error => {
                console.log(error);
              });
            this.OTPGenerated = '';
            this.passwodSet = true;
            this.generatePassAndOTP = false;
            this.successMSG = true;
          },
          error => {
            if (error.error.message.includes('temporarily blocked')) {
              this.otpError = error.error.message;
              this.mobile = '';
              this.hideOtpScreen = false;
              this.OTPGenerated = 'false';
              this.showResend = false;
              this.verifyingRequest = false;
            } else {
              this.otpError = error.error.message;
              this.showResend = false;
            }
          });
    } else {
      const payload = {
        'mobileNo': this.mobile,
        'countryCode': this.countryCode,
        'otp': otp,
        'emailVerify': true
      };
      this.verifyService.validateOTP(payload, this.companyId)
        .subscribe(
          response => {
            this.companyId = response.companyId;
            this.accessToken = response.accessToken;
            this.companyType = response.companyType;
            this.sectorCount = response.sectorCount;
            localStorage.setItem('accessToken', response.accessToken);
            localStorage.setItem('companyId', response.companyId);
            localStorage.setItem('corp_Sec_Count', JSON.stringify(response));
            localStorage.setItem('userSource', response.source === undefined ? '' : response.source);

            this.verifyService.getUserInfo(this.companyId, this.accessToken).subscribe(
              res => {
                localStorage.setItem('companyURL', res.companyUrl);
                localStorage.setItem('userInfo', JSON.stringify(res.userDetails));
                localStorage.setItem('userRole', res.userDetails.Companies[0].RoleId);
                this.commonService.storeAPIKeyWebhookDetails(res);
              },
              error => {
                console.log(error);
              });
            this.OTPGenerated = '';
            this.passwodSet = true;
            this.generatePassAndOTP = false;
            this.successMSG = true;
          },
          error => {
            if (error.error.message.includes('temporarily blocked')) {
              this.otpError = error.error.message;
              this.mobile = '';
              this.hideOtpScreen = false;
              this.OTPGenerated = 'false';
              this.showResend = false;
              this.verifyingRequest = false;
            } else {
              this.otpError = error.error.message;
              this.showResend = false;
            }
          });
    }
  }

  submitPassword() {
    if (this.confirmPassword !== this.password) {
      this.matchPassword = 'Password mismatch';
      return false;
    }
    const password = this.SubmitsEncry(this.confirmPassword);
    this.confirmPassword = password.toString();
    const payLaod = {
      'countryCode': this.countryCode,
      'mobileNo': this.mobile,
      'password': JSON.stringify(this.confirmPassword),
      'loginTypeOtp': false,
      'oldPassword': ''
    };
    const accessToken = localStorage.getItem('accessToken');
    this.verifyService.generatePassword(payLaod, this.companyId, accessToken)
      .subscribe(
        () => {
          localStorage.setItem('companyType', this.companyType);
          this.successMSG = false;
          this.generatePassAndOTP = false;
          this.OTPGenerated = '';
          const userInfo = JSON.parse(localStorage.getItem('userInfo'));
          userInfo.IsPassword = true;
          localStorage.setItem('userInfo', JSON.stringify(userInfo));
          localStorage.setItem('userRole', userInfo.Companies[0].RoleId);
          this.continueDashboard();
        },
        error => {
          this.otpError = error.error.message;
    });

  }

  skipCreatePassword() {
    this.generatePassAndOTP = false;
    this.OTPGenerated = 'null';
    this.successMSG = true;
  }

  timer(duration) {
    duration = Number(duration);
    const hours = Math.floor( duration / 60);
    let minutes = duration % 60;
    this.hour = hours;
    if (duration % 60 === 0) {
      minutes = 60;
      this.hour = hours - 1;
    }
    this.minute = minutes - 1;
    this.seconds = 59;
    this.timerVar = setInterval(() => {
      if (this.disableCallBack === true) {
        clearInterval(this.timerVar);
      } else {
        this.seconds =  this.seconds - 1;
        if (this.seconds < 0) {
          if (this.minute > 0) {
            this.minute =  this.minute - 1;
            this.seconds = 59;
          }

          if (this.seconds === 0 && this.minute === 0) {
            this.otpError = '';
            this.hideTimer = false;
            this.showResend = true;
            this.disableCallBack = true;
            this.seconds = 0;
            this.minute = 0;
            this.hour = 0;
          }
        }
        if ( this.minute === 0) {
          if (this.hour > 0) {
            this.hour =  this.hour - 1;
            this.minute = 60;
          }
          if (this.seconds === 0 && this.minute === 0) {
            this.hideTimer = false;
            this.otpError = '';
            this.showResend = true;
            this.disableCallBack = true;
            this.seconds = 0;
            this.minute = 0;
            this.hour = 0;
          }
        }
        (this.seconds < 10) ? this.secondsvalue = true  : this.secondsvalue = false;
      }
    }, 1000);
  }

  resendOTP() {
    this.otpDigit1 = '';
    this.otpDigit2 = '';
    this.otpDigit3 = '';
    this.otpDigit4 = '';
    this.otpDigit5 = '';
    this.otpDigit6 = '';
    this.disableCallBack = false;
    this.timer(2);
    this.showResend = false;
    this.secondsvalue = false;
    this.hideTimer = true;
    this.disableCallBack = false;
    const payLaod = {
      'mobileNo': this.mobile,
      'countryCode': this.countryCode
    };
    this.validateotpERR = '';
    this.verifyService.resendOTP(payLaod, this.companyId)
      .subscribe(
        response => {
          this.message = response.response;
          $('#message').show().delay(2000).fadeOut();
        },
        error => {
          this.otpError = error.error.message;
          this.hideOtpScreen = false;
          this.mobile = '';
          this.showResend = false;
    });
  }

  SubmitsEncry(password): string {
    const key = CryptoJS.enc.Utf8.parse('8080808080808080');
    const iv = CryptoJS.enc.Utf8.parse('8080808080808080');
    const encryptedpassword = CryptoJS.AES.encrypt(CryptoJS.enc.Utf8.parse(password), key,
      { keySize: 128 / 8,
        iv: iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7
      });
    return encryptedpassword;
  }

  submitQuestion() {}

  continueDashboard() {
    if (this.companyType === 'ssc') {
      localStorage.setItem('checkProfile', 'dashboard');
      localStorage.setItem('check', 'true');
      this.router.navigate(['dashboard']);
    } else if (this.companyType === 'corporate') {
      localStorage.setItem('checkProfile', 'dashboard');
      localStorage.setItem('check', 'true');
      this.router.navigate(['dashboard']);
    } else {
      localStorage.setItem('checkProfile', 'dashboard');
      localStorage.setItem('check', 'true');
      localStorage.setItem('sectorCount', this.sectorCount);
      this.router.navigate(['dashboard']);
    }
  }

  setPasswordPage() {
    this.generatePassAndOTP = true;
    this.OTPGenerated = 'null';
    this.successMSG = false;
    this.confirmPassword = '';
    this.password = '';
  }

  passwordMatch() {
    this.matchPassword = '';
    for (let i = 0; i < this.password.length; i++) {
      if (this.password.charAt(i) === ' ') {
        break;
      } else {
        this.invalidPassword = false;
      }
    }
    if (this.invalidPassword === false) {
      this.passwordValidation = '';
    }
  }

  matchPasswordFormat(e) {
    if (this.feed.controls.password !== undefined && e.target.value !== '' && !this.feed.controls.password.valid ) {
      this.invalidPassword = true;
      this.passwordValidation = 'Invalid Password';
    } else {
      this.invalidPassword = false;
      this.passwordValidation = '';
    }
  }

  confirmMatch() {
    if (this.matchPassword !== '') {
      if (this.confirmPassword === this.password) {
        this.matchPassword = '';
        return ;
      }
    }
  }

}
