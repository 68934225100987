<section class="bg-white">
  <app-header-static></app-header-static>
</section>
<section class="bg-white c-pattern mobile_block">
  <div class="container pt-5 pb-3 ">
    <div class="row ">
      <div class="col">
        <p class="GB-40 text-center">General Features</p>
      </div>
    </div>
  </div>

  <div class="container-fluid bg-3">
    <div class="container mt_5">
      <div class="row ">
        <div class="col-md-4">
          <h2 class="GB-26 mt_5 mb-3">Assessment Analytics</h2>
          <p class="RR-13">Assessment Analytics help in identifying the patterns and interpreting the assessment performance effectively for better decision making.</p>
        </div>
        <div class="col-md-8">
          <img src="assets/img/feature-01.png"  class="img-fluid" alt="">
        </div>
      </div>
    </div>
  </div>

  <div class="container-fluid bg-gray ">
    <div class="container pb-5 pt-3">
      <div class="row ">
        <div class="col-md-4 col-sm-12">
          <img src="assets/img/Question_bank_analytics.png"  height="" alt="" class="mt-5 mb-3">
          <p class="RR-16 font-weight-bold text-gray">Question Bank Analysis</p>
          <p class="RR-13">Question Bank Analytics help to identify the usage pattern of the questions. Such analytics assist in identifying the fitment of the question to the particular set of candidates.</p>
        </div>
        <div class="col-md-4 col-sm-12">
          <img src="assets/img/Powerful_Reporting.png"  height="" alt="" class="mt-5 mb-3">
          <p class="RR-16 font-weight-bold text-gray">Powerful Reporting</p>
          <p class="RR-13">The flexible and customised reports help in quick decision making and select the candidates within the desirable score zone in a quick view.</p>
        </div>
        <div class="col-md-4 col-sm-12">
          <img src="assets/img/Geo_Tagging.png"  height="50" alt="" class="mt-5 mb-3">
          <p class="RR-16 font-weight-bold text-gray">Geo Tagging & Time Stamp</p>
          <p class="RR-13">Track candidate's geo-location and record time logs for the assessment taken by the candidate.</p>
        </div>
      </div>
    </div>
  </div>


  <div class="container mt-5 mb-5">
    <div class="row ">
      <div class="col-md-8 d-sm-block d-md-none">
        <img src="assets/img/feature-02.png"  class="img-fluid" alt="">
      </div>
      <div class="col-md-4 ">
        <img src="assets/img/Secure_API_Support.png"  height="" alt="" class="mt-5 mb-3">
        <p class="RR-16 font-weight-bold text-gray">Secure API</p>
        <p class="RR-13">Secure API support allows the user to integrate CALIBRAT with any system. This adds value to the existing workflow without disrupting the routine</p>
        <img src="assets/img/Private_Test_Link.png"  height="" alt="" class="mt-5 mb-3">
        <p class="RR-16 font-weight-bold text-gray">Private Assessment Link</p>
        <p class="RR-13">Use private link for assessment by invitation only. Assessment administrator can share the online assessment link with candidates wherein the candidate need not register or login.</p>
      </div>
      <div class="col-md-8 d-none d-sm-block">
        <img src="assets/img/feature-02.png"  class="img-fluid" alt="">
      </div>
    </div>
  </div>



  <div class="container mt-5 pb-5">
    <div class="row ">
      <div class="col-md-8">
        <img src="assets/img/feature-03_02.png"  class="img-fluid" alt="">
      </div>
      <div class="col-md-4">

        <img src="assets/img/All_device_Compatible.png"  height="" alt="" class="mt-5 mb-3">
        <p class="RR-16 font-weight-bold text-gray">All Device Compatible</p>
        <p class="RR-13">Online Assessment platform is designed to run on different types of devices, from phones to tablets and laptops.</p>
        <img src="assets/img/Multilingual_Assessment.png"  height="" alt="" class="mt-5 mb-3">
        <p class="RR-16 font-weight-bold text-gray">Multilingual Assessments & All Format Support</p>
        <p class="RR-13">Create and conduct multi-format and multilingual assessment. </p>

      </div>
    </div>
  </div>

  <div class="container mt-5 mb-5">
    <div class="row ">
      <div class="col-md-8 d-sm-block d-md-none">
        <img src="assets/img/feature-04.png"  class="img-fluid" alt="">
      </div>
      <div class="col-md-4">
        <img src="assets/img/Support_Centre.png"  height="" alt="" class="mt-5 mb-3">
        <p class="RR-16 font-weight-bold text-gray">Support Center</p>
        <p class="RR-13">Support team ready to guide the user on every step.</p>
        <img src="assets/img/Company_Branding.png"  height="" alt="Calibrat us reliable and accurately measure capabilitie" class="mt-5 mb-3">
        <p class="RR-16 font-weight-bold text-gray">Company Branding</p>
        <p class="RR-13">Enhance organisation profile with visibility of brand identity to candidates while taking the assessment.</p>
      </div>
      <div class="col-md-8 d-none d-sm-block">
        <img src="assets/img/feature-04.png"  class="img-fluid" alt="">
      </div>
    </div>
  </div>



  <div class="container mt-5 pb-5">
    <div class="row ">
      <div class="col-md-8">
        <img src="assets/img/feature-offline.png"  class="img-fluid" alt="">
      </div>
      <div class="col-md-4">

        <img src="assets/img/Online_Offline_Assessment.png"  height="" alt="" class="mt-5 mb-3">
        <p class="RR-16 font-weight-bold text-gray">Offline Mode Assessment</p>
        <p class="RR-13">Offline Mode Assessment to conduct assessments with internet infrastructure deficiency.</p>
        <!-- <img src="assets/img/Pay-per use.png"  height="" alt="" class="mt-5 mb-3">
         <p class="RR-16 font-weight-bold text-gray">Secure API Support</p>
         <p class="RR-13">Access all the features but only pay for what you actually use. You can pay to match your varying assessment needs. Cost effective approach for assessment requirements.</p>-->

      </div>
    </div>
  </div>

  <div class="container pt-5 pb-3 ">
    <div class="row ">
      <div class="col">
        <h2 class="GB-30 mx-auto heading1" >Additional Features</h2>
      </div>
    </div>
    <div class="row mt-5 mb-5 ">
      <div class="col text-center d-none d-sm-block">
        <img src="assets/img/additional-features.png"  class="img-fluid" alt="">
      </div>
      <div class="col text-left RR-14 d-sm-block d-md-none">

        <ul>
          <li>All Questions Mandatory</li>
          <li>Negative Marking</li>
          <li>Mark for Review</li>
          <li>Sequencing of Questions</li>
          <li>Move forward Only</li>
          <li>Randomisation of questions and options</li>
          <li>Reset Test</li>
          <li>Resume Test</li>
          <li>Copy/Paste Restricted</li>
          <li>Easy to Manage Question Bank</li>
          <li>One Time Test Code</li>
          <li>Random Candidate Photo Capture</li>
          <li>Audio & Video Recording</li>
          <li>Hint Based Partial Marking</li>
        </ul>
      </div>
    </div>
  </div>
</section>
<app-footer-static></app-footer-static>
