import { Component, EventEmitter, OnInit, Output } from "@angular/core";
import { UserHeaderService } from "./header.service";
import { Router } from "@angular/router";
import { NoInternetComponent } from "../modals/no-internet/no-internet.component";
import { BsModalService } from "ngx-bootstrap/modal";
import { ToastrService } from "ngx-toastr";
import { SuperAdminService } from "../super-admin/super-admin-dashboard/super-admin.service";
import { NgxUiLoaderService } from "ngx-ui-loader";
import { ListAssignedBatchesComponent } from "../assessments-module/list-assigned-batches/list-assigned-batches.component";
import { SuperadminCreateCatSubcatComponent } from "../superadmin-qb-module/superadmin-create-cat-subcat/superadmin-create-cat-subcat.component";

declare let myExtObject: any;
@Component({
  selector: "app-user-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.css"],
  providers: [UserHeaderService],
})
export class HeaderComponent implements OnInit {
  userRole: any;
  userInfo: any = localStorage.getItem("userDetails");
  sectorName: string;
  companyId: string;
  listSector = [];
  data: boolean;
  selectedSectorId = "";
  oldSectorId: string;
  assessment: boolean;
  companyName = "";
  userDetail;
  companyURL: string;
  accessToken = "";
  companyLogo;
  corp_Sec_Count: any;
  companyList: any = [];
  skipVal: number = 0;
  hasMore: boolean = false;
  showError: boolean = false;
  errorMsg: string;
  interval: any;
  time: number = 60;
  totalLiveCandidateCount = 0;
  biReportFlag: boolean = false;
  biReportVal:string="";
  @Output() showManualQuestion: EventEmitter<string> =
    new EventEmitter<string>();
  @Output() assessmentPage: EventEmitter<string> = new EventEmitter<string>();
  @Output() jobPage: EventEmitter<string> = new EventEmitter<string>();
  @Output() dashBoard: EventEmitter<string> = new EventEmitter<string>();
  @Output() profileInfo: EventEmitter<string> = new EventEmitter<string>();
  public onlineOffline: boolean = navigator.onLine;
  constructor(
    public router: Router,
    public userheaderService: UserHeaderService,
    public modalService: BsModalService,
    private toastrService: ToastrService,
    private SuperAdminServiceObj: SuperAdminService,
    private ngxService: NgxUiLoaderService,
    private SuperadminCreateCatSubcatComponentObj: SuperadminCreateCatSubcatComponent,
    private listAssignedBatch: ListAssignedBatchesComponent
  ) { }
  ngOnInit() {
    this.accessToken = localStorage.getItem("accessToken");
    this.userDetail = JSON.parse(localStorage.getItem("userInfo"));
    this.userRole = this.userDetail.Companies[0].RoleId;
    this.corp_Sec_Count = JSON.parse(localStorage.getItem("corp_Sec_Count"));
    this.biReportVal = localStorage.getItem('biReportURL');
    if (this.biReportVal == "") {
      this.biReportFlag = false;
    } else {
      this.biReportFlag = true;
    }

    if (localStorage.getItem("userInfo") !== null) {
      this.companyName = this.userDetail.Companies[0].CompanyName;
      this.companyLogo = this.userDetail.Companies[0].CompanyLogo;
    }
    this.companyURL = localStorage.getItem("companyURL");
    if (!this.onlineOffline) {
      const modalRef = this.modalService.show(NoInternetComponent);
    } else {
      const modalRef = this.modalService.hide(1);
    }
    const userRole = localStorage.getItem("userRole");

    if (userRole == "super_admin") {
      this.getLiveCandidateCount();
      this.startTimer();
    }
  }
  stopPro(event) {
    event.stopPropagation();
    return false;
  }
  listofSector() {
    const accesssToken = localStorage.getItem("accessToken");
    const userDetail = localStorage.getItem("userInfo");
    const userInfoJson = JSON.parse(userDetail);
    this.companyId = userInfoJson.userDetails.Companies[0].CompanyId;
    this.userheaderService.changeSector(this.companyId, accesssToken).subscribe(
      (response) => {
        this.listSector = response[0].associatedSectors;
      },
      (error) => {
        console.log(error.error.message);
      }
    );
  }
  selectedSector(selectedSector) {
    this.selectedSectorId = selectedSector;
  }
  questionPage() {
    this.ngxService.start();
    this.showManualQuestion.emit("");
    localStorage.removeItem("fromInviteComp");

  }
  ChangeSector() {
    const accesssToken = localStorage.getItem("accessToken");
    const userDetail = localStorage.getItem("userInfo");
    const userInfoJson = JSON.parse(userDetail);
    this.companyId = userInfoJson.userDetails.Companies[0].CompanyId;
    this.oldSectorId = userInfoJson.userDetails.Sectors[0].SectorId;
    const payLoad = {
      oldSectorId: this.oldSectorId,
      newSectorId: this.selectedSectorId,
    };
    this.userheaderService
      .switchSector(payLoad, this.companyId, accesssToken)
      .subscribe(
        (response) => {
          this.getUserInfo(response.accessToken);
        },
        (error) => {
          console.log(error.error.message);
        }
      );
  }
  getUserInfo(accessToken) {
    this.userheaderService.getUserInfo(this.companyId, accessToken).subscribe(
      (response) => {
        const responseString = JSON.stringify(response);
        localStorage.setItem("userInfo", responseString);
        localStorage.setItem("accessToken", accessToken);
        localStorage.setItem(
          "userRole",
          response.userDetails.Companies[0].RoleId
        );
        const userInfoJson = JSON.parse(responseString);
        this.sectorName = userInfoJson.userDetails.Sectors[0].SectorName;
      },
      (error) => {
        console.log(error.error.message);
      }
    );
  }
  addSector() {
    this.data = true;
  }
  logOutUser() {
    this.userheaderService.logOutUser().subscribe(
      (response) => {
        this.toastrService.success(response.message, "Success");
      },
      (error) => {
        console.log(error.error.message);
      }
    );
    this.router.navigate([""]);
    localStorage.clear();
    this.ngOnDestroy();
  }
  assesmentPage() {
    this.assessmentPage.emit("");
    localStorage.removeItem("link");
    localStorage.removeItem("jobId");
    localStorage.removeItem("editJobAsses");
    localStorage.removeItem("assessmentID");
    localStorage.removeItem("fromInviteComp");
    localStorage.removeItem("_assessmentType");
  }
  jobsAction() {
    this.jobPage.emit("");
    localStorage.removeItem("editAssess");
    localStorage.removeItem("jobId");
    localStorage.removeItem("fromInviteComp");
  }
  dashboard() {
    this.dashBoard.emit("");
    localStorage.removeItem("fromInviteComp");
  }
  showCompanyInfo() {
    this.profileInfo.emit("");
  }
  changeCompanyName() {
    localStorage.setItem("checkProfile", "profile");
    localStorage.setItem("check", "false");
    this.userDetail = JSON.parse(localStorage.getItem("userInfo"));
    this.companyName = this.userDetail.Companies[0].CompanyName;
    this.companyLogo = this.userDetail.Companies[0].CompanyLogo;
    this.router.navigate(["dashboard"]);
  }
  copyLink() {
    const copyText = document.getElementById("linkGen") as HTMLInputElement;
    copyText.select();
    document.execCommand("copy");
  }
  async getLiveCandidateCount() {
    this.companyList = [];
    // this.time=60;
    this.accessToken = localStorage.getItem("accessToken");
    this.companyId = localStorage.getItem("companyId");
    await this.SuperAdminServiceObj.getLiveCandidateCount(
      this.accessToken,
      this.companyId,
      this.skipVal
    )
      .toPromise()
      .then(
        (response: any) => {
          var response = response;
          this.companyList = response.message;
          this.totalLiveCandidateCount = response.totalLiveCount;
          if (response.hasMore == true) {
            this.hasMore = true;
            this.skipVal++;
          } else {
            this.hasMore = false;
          }
        },
        (error) => {
          this.errorMsg = error.error.message;
          this.showError = true;
          this.totalLiveCandidateCount = 0;
        }
      );
  }
  startTimer() {
    this.interval = setInterval(() => {
      this.getLiveCandidateCount();
    }, 60000);
  }

  fetchData() {
    this.listAssignedBatch.ngOnInit();
  }

  superadmindashboard() {
    this.router.navigate(['/super_admin']);
  }

  superAdminQB() {
    localStorage.setItem('localstorageValue', 'QbDashboard');
    localStorage.setItem('redirectValue', 'QbDashboard');
    localStorage.removeItem('NoQuestionDiv');
    this.SuperadminCreateCatSubcatComponentObj.ngOnInit();
  }

  biReport() {
    this.router.navigate(['/bi-report']);
  }

  ngOnDestroy() {
    clearInterval(this.interval);
  }
}
