import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import 'rxjs/add/observable/throw';
import {environment} from '../../environments/environment';

@Injectable()
export class RegisterService {
  private headers = new HttpHeaders({
    'Content-Type': 'application/json',
  });
  private url = '' ;
  private localhost = 'localhost';
  globalUrl = environment.serverUrl;

  constructor(private http: HttpClient) { }

  registerCompany(payLoad: any): Observable<any> {
    this.url = this.globalUrl + '/business/services/company/register';
    return this.http.post(this.url, payLoad, {headers: this.headers}).pipe(
      map( response => response),
      catchError(this._errorHandler)
    );
  }

  resentActivationLink(payLoad): Observable<any> {
    this.url = this.globalUrl + '/business/services/company/resendemail';
    return this.http.post(this.url, payLoad, {headers: this.headers}).pipe(
      map( response => response),
      catchError(this._errorHandler)
    );
  }

  _errorHandler(error: HttpErrorResponse) {
    console.error(error);
    return throwError(error || 'Server Error');
  }
}
