import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { AssessmentService } from "../assessment.service";
import { ToastrService } from "ngx-toastr";
import { Location } from "@angular/common";
import { NgxUiLoaderService } from "ngx-ui-loader";
import { FormControl, FormGroup, Validators } from "@angular/forms";
declare let $: any;

@Component({
  selector: "app-list-assessment",
  templateUrl: "./list-assessment.component.html",
  styleUrls: ["./list-assessment.component.css"],
  providers: [AssessmentService],
})
export class ListAssessmentComponent implements OnInit {
  userRole: any;
  userDetails: any;
  showAssessemt: Boolean = true;
  privateLinkCreationStep1: Boolean = false;
  privateLinkCreationStep2: Boolean = false;
  publicLinkCreation: Boolean = false;
  showPreviewAssessment: Boolean = false;
  createAssessment: Boolean = false;
  step1: Boolean = false;
  step2: Boolean = false;
  step3: Boolean = false;
  assessmentList1: Boolean = false;
  assessmentDescriptionDash: Boolean = false;
  getAssementList: any = [];
  skip: any = 0;
  multipleDelete: Boolean = false;
  companyId: any;
  accessToken: any;
  companyType: any;
  proctor: any;
  assessmentTypeForLoadMore: any;
  isLoading: Boolean;
  publicerrorMsg: any;
  assessmentNumber: any;
  getAssementListStore: any = [];
  isRequestInProcess: Boolean;
  isLoadmore: Boolean;
  assessmentDashMessage: Boolean = false;
  deleteData: any = [];
  assessmentIdDelete: any;
  errorMsg: any;
  assessmentType: any;
  assessmentCount = 0;
  isTrue: Boolean;
  hidePassing: Boolean;
  onlinecompiler: boolean = false;
  masterQBAccess: boolean = false;
  editFlag: boolean = true;
  searchText: string = "";
  totalCount: string = "";
  noDataFoundFlag: boolean = false;
  searchTextTag: string = "";
  searchStr: string = "";
  assessmentCreationFlow: FormGroup;
  selectedAssessmentCreationFlow: string = "";

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private assessmentservice: AssessmentService,
    private toastrService: ToastrService,
    private _location: Location,
    private ngxService: NgxUiLoaderService
  ) {
    this.ngxService.start();
    this.accessToken = localStorage.getItem("accessToken");
    this.companyId = localStorage.getItem("companyId");
    this.companyType = localStorage.getItem("companyType");
    this.proctor = localStorage.getItem("proctor");
    this.assessmentCreationFlow = new FormGroup({
      selectedAssessmentCreationFlow: new FormControl("", [
        Validators.required,
      ]),
    });

    if (this.proctor === "proctor") {
      this.observerLanding();
      this.assessmentTypeForLoadMore = localStorage.getItem("assessmentType");
      localStorage.removeItem("proctor");
      localStorage.removeItem("assessmentType");
      this.isLoading = true;
    }
    this.getRouteParams();

    var assessmentType = localStorage.getItem("_assessmentType");
    if (assessmentType == "Aptitude" || assessmentType == "Psychometer") {
      this.editFlag = false;
    } else {
      this.editFlag = true;
    }
  }

  ngOnInit() {
    this.userDetails = JSON.parse(localStorage.getItem("userInfo"));
    this.userRole = this.userDetails.Companies[0].RoleId;

    var qbAccess = localStorage.getItem("masterQBAccess");
    if (qbAccess == "allowed") {
      this.masterQBAccess = true;
    } else {
      this.masterQBAccess = false;
    }

    this.assessmentCreationFlow = new FormGroup({
      selectedAssessmentCreationFlow: new FormControl("", [
        Validators.required,
      ]),
    });
  }

  getRouteParams() {
    this.route.params.subscribe(
      (params) => {
        if (params["type"]) {
          this.assessmentType =
            params["type"].charAt(0).toUpperCase() + params["type"].slice(1);

          this.assessmentCount = params["count"];
          if (this.assessmentType == "Online compiler") {
            this.assessmentType = "Online Compiler";
          }
          if (this.assessmentType === "Psychometer") {
            this.hidePassing = true;
          }
          if (this.assessmentType === "Role based") {
            this.assessmentType = "Role Based";
          }
          if(this.assessmentType === "Posh"){
            this.assessmentType = "POSH"
          }
          if(this.assessmentType === "Skill based"){
            this.assessmentType = "Skill Based"
          }

          if (this.assessmentType === "Online Compiler") {
            this.onlinecompiler = true;
            localStorage.setItem("onlineAssessmentType", this.assessmentType);
          }

          this.isTrue = params["isTrue"];
          this.getAssessmentList(
            this.assessmentType,
            this.assessmentCount,
            this.isTrue
          );
        }
      },
      (error) => console.log(error)
    );
  }

  RidirectionMessage() {
    localStorage.setItem("assessmentPage", "HelloAssessment");
    this.router.navigate(["jobs"]);
  }

  dashboardRefresh() {
    localStorage.setItem("profileInfo", "show profileInfo");
    this.router.navigate(["dashboard"]);
  }

  assessmentCreationType(type: string) {
    this.selectedAssessmentCreationFlow = type;
  }
  observerLanding() {
    const assessmentID = localStorage.getItem("assessmentId");
    const payload = {
      assessmentId: assessmentID,
    };
    this.assessmentservice
      .editAssessment(payload, this.companyId, this.accessToken)
      .subscribe(
        (response) => {
          this.showAssessmentDetails(response.message[0]);
        },
        (error) => {
          this.publicerrorMsg = error.error.message;
        }
      );
  }

  assessmentDashboard() {
    localStorage.removeItem("_assessmentType");
    this.router.navigate(["assessment/list"]);
  }

  getAssessmentList(assessmentType, assessmentNum, check) {
    this.assessmentTypeForLoadMore = assessmentType;
    this.assessmentNumber = assessmentNum;
    this.getAssementListStore = [];
    if (check === true) {
      this.skip = 0;
      this.getAssementList = [];
    }

    const payload = {
      assType: this.onlinecompiler ? "Online Compiler" : assessmentType,
      searchText: this.searchText,
    };

    const skip = "" + this.skip;
    if (this.isRequestInProcess) {
      return;
    }
    this.isRequestInProcess = true;

    this.assessmentservice
      .getassessmentList(payload, skip, this.companyId, this.accessToken)
      .subscribe(
        (response) => {
          this.ngxService.stop();
          this.isRequestInProcess = false;
          this.assessmentType = "";
          this.getAssementListStore = response.message;
          this.isLoadmore = response.isMoreCategory;
          this.totalCount = response.totalCount;
          this.getAssementList = this.getAssementList.concat(
            this.getAssementListStore
          );
          this.assessmentNumber = this.getAssementList.length; // Set displayed assessment count
          this.assessmentDescriptionDash = false;
          this.showAssessemt = false;
          this.assessmentList1 = true;
          this.assessmentDashMessage = false;
        },
        (error) => {
          this.ngxService.stop();

          this.isRequestInProcess = false;

          this.assessmentDashMessage = true;
          // this.assessmentList1 = false;
          this.showAssessemt = false;
          this.assessmentType = assessmentType;
          //if no data ound on search operation

          if (
            error.error["message"] === "Assessment not found" &&
            this.searchText != ""
          ) {
            this.noDataFoundFlag = true;
            this.assessmentNumber = 0;
            this.isLoadmore = false;
            // this.totalCount=''
            return;
          } else if (error.error["message"] === "Assessment not found") {
            //no assessment is created yet
            this.router.navigate(["assessment/list"]);
          }
        }
      );
  }

  loadMoreAssessmentList(event?: any) {
    if (event.pointerType != "mouse") {
      return;
    }
    this.skip++;
    this.getAssementListStore = [];
    this.getAssessmentList(
      this.assessmentTypeForLoadMore,
      this.assessmentNumber,
      false
    );
  }
  createNewAssessment() {
    ($("#quickAssessmentModal") as any).modal("show");
  }

  createNewRoleBasedAssessment(){
    this.router.navigate(["/skill-based-assessment"])
  }

  createNewAssessmentManually() {
    this.route.params.subscribe((params) => {
      if (params["type"]) {
        if (params.type == "aptitude" || params.type == "psychometer") {
          if (!this.masterQBAccess) {
            $("#AccessDenied").modal("show");
          } else {
            localStorage.setItem("_assessmentType", params.type);
            this.router.navigate(["assessment/create"]);
          }
        } else {
          localStorage.setItem("_assessmentType", params.type);
          this.router.navigate(["assessment/create"]);
        }
      } else {
        localStorage.removeItem("_assessmentType");
        this.router.navigate(["assessment/create"]);
      }
    });
  }

  editAssessment(id) {
    
    let obj=this.getAssementList.find(obj => obj._id === id);
    if(obj.quickAssessmentTemplateId){
      this.router.navigate(["assessment/update-quick-assessment/"+obj._id]);
    }
    else{
      this.router.navigate(["assessment/update/" + id]);
    }
  }

  selectedDelete(event) {
    if (event.target.checked) {
      if (this.deleteData.indexOf(event.target.value) < 0) {
        this.deleteData.push(event.target.value);
      }
    } else {
      if (this.deleteData.indexOf(event.target.value) >= 0) {
        const index = this.deleteData.indexOf(event.target.value);
        this.deleteData.splice(index, 1);
      }
    }
    this.multipleDelete = this.deleteData.length !== 0;
  }

  deleteAssessment(assessmentId, check) {
    if (check === true) {
      $("#deleteSingleAssessmentData").modal("show");
    } else {
      $("#deleteAssessmentData").modal("show");
    }
    this.assessmentIdDelete = assessmentId;
  }

  deleteMultipleAssessment() {
    $("#deleteMultiple").modal("show");
  }

  multiDeleteCancel() {
    // Uncheck the selected assessments
    for (let i = 0; i < this.deleteData.length; i++) {
      const selChBox = document.getElementById(
        "assessment-" + this.deleteData[i]
      );
      if (selChBox != null) {
        (<HTMLInputElement>selChBox).checked = false;
      }
    }
    this.deleteData = [];
    this.multipleDelete = false;
  }

  deleteAssessmentDATA(check) {
    const payload = {
      arrayData: [this.assessmentIdDelete],
    };
    for (let i = 0; i < this.getAssementList.length; i++) {
      if (this.getAssementList[i]._id === this.assessmentIdDelete) {
        this.getAssementList.splice(i, 1);
      }
    }
    this.assessmentservice
      .assessmentDelete(payload, this.companyId, this.accessToken)
      .subscribe(
        () => {
          this.assessmentDescriptionDash = false;
          this.assessmentIdDelete = "";
          this.assessmentNumber -= 1;
          this.multipleDelete = false;
          this.getAssessmentList(
            this.assessmentTypeForLoadMore,
            this.assessmentNumber,
            true
          );

          if (check === true) {
            this.toastrService.success("Assessment deleted successfully", "", {
              timeOut: 5000,
            });
          } else {
            this.toastrService.success("Assessment archived successfully", "", {
              timeOut: 5000,
            });
          }
        },
        (error) => {
          this.errorMsg = error.error.message;
        }
      );
  }

  deleteMultipleData() {
    const payload = {
      arrayData: this.deleteData,
    };
    this.assessmentservice
      .assessmentDelete(payload, this.companyId, this.accessToken)
      .subscribe(
        () => {
          this.getAssementList = [];
          this.skip = 0;
          this.assessmentNumber -= this.deleteData.length;
          this.deleteData = [];
          this.multipleDelete = false;
          this.assessmentIdDelete = "";
          this.getAssessmentList(
            this.assessmentTypeForLoadMore,
            this.assessmentNumber,
            true
          );
          this.toastrService.success("Assessment(s) deleted successfully", "", {
            timeOut: 5000,
          });
        },
        (error) => {
          this.errorMsg = error.error.message;
        }
      );
  }

  showAssessmentDetails(data) {
    this.ngxService.start();
    localStorage.removeItem("fromInviteComp");
    this.router.navigate(["assessment/details/" + data._id]);
  }

  backClicked() {
    this._location.back();
  }

  search() {
    if (!this.searchStr.replace(/\s/g, "").length) {
      this.searchStr = "";
      return;
    }
    if (
      this.searchStr.charAt(0) == " " ||
      this.searchStr.charAt(this.searchStr.length - 1) == " "
    ) {
      this.searchStr = this.searchStr.trim();
    }
    if (this.searchStr.length < 3) {
      return;
    }
    // this.getAssementList=[];
    // if (this.searchText) {
    //   for (var i = 0; i < this.getAssessmentList.length; i++) {
    //     if (this.searchText == this.getAssessmentList[i].companyName) {

    //       this.searchResult.companyName.push(
    //       this.CandidateCountData[i].companyName);
    //       this.searchResult.assessmentCount.push(
    //       this.CandidateCountData[i].assessmentCount);
    //     }
    //   }
    // }
    this.ngxService.start();
    this.searchText = this.searchStr;
    this.noDataFoundFlag = false;
    this.searchTextTag = this.searchText;

    this.getAssessmentList(
      this.assessmentTypeForLoadMore,
      this.assessmentNumber,
      true
    );
    this.searchStr = "";
    this.multiDeleteCancel();
  }

  resetSearch() {
    this.ngxService.start();
    this.noDataFoundFlag = false;
    this.searchText = "";
    this.getAssessmentList(
      this.assessmentTypeForLoadMore,
      this.assessmentNumber,
      true
    );
    this.searchTextTag = "";
    this.multiDeleteCancel();
  }

  // inputCheck(){

  //   if(this.searchText=""){
  //     this.getAssessmentList(
  //       this.assessmentTypeForLoadMore,
  //       this.assessmentNumber,
  //       true
  //     );
  //   }
  // }

  resetForm() {
    
    this.assessmentCreationFlow.reset();
    this.selectedAssessmentCreationFlow="";
  }
  routeToRespectiveUrl() {
    var queryParamVal="";
    ($("#quickAssesmentTypeModal") as any).modal("hide");
    if (this.selectedAssessmentCreationFlow === "quickTemplate") {
      this.route.params.subscribe((params) => {
        if (params["type"]) {
          if (params.type == "aptitude") {
            queryParamVal="Aptitude";
          }
          if(params.type == "technical"){
            queryParamVal="Technical";
          }
        }
      });

      const url = "/skill-based-assessment";
      this.router.navigate([url], {
        queryParams: {
          forassessmenttype:queryParamVal ,
        },
      });
    }
    else{
      this.createNewAssessmentManually();
    }
  }

}
