import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from '../../environments/environment'

@Injectable({
  providedIn: 'root'
})
export class SuperadminQbService {
  private url = '';
  globalUrl = environment.serverUrl;
  private headers = new HttpHeaders({
    'Content-Type': 'application/json'
  });

  constructor(private http: HttpClient) { }

  getCategoryList(accessToken: any, payload: any, companyId: any,skip:number) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'bearer ' + accessToken,
    });

    this.url = this.globalUrl + '/business/services/company/category/list?companyId=' + companyId + '&skip=' + skip;;
    return this.http.post(this.url, payload, { headers: headers }).pipe(
      map(response => response),
      catchError(this._errorHandler)
    );

  }

  fetchSkillset(payload): Observable<any> {
    this.url = this.globalUrl + '/business/services/company/get/masterdata';
    return this.http.post(this.url, payload, {headers: this.headers}).pipe(
      map(response => response),
      catchError(this._errorHandler)
    

    );
  }

  getLevelOfAssess(payload, companyId, accessToken): Observable<any> {

    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      // 'Authorization':'bearer '+accessToken
    });
    this.url = this.globalUrl + '/business/services/company/get/masterdata';
    return this.http.post(this.url, payload, {headers: headers}).pipe(
      map( response => response),
      catchError(this._errorHandler)
    );

  }
  
  getSkillSetCategoryWise(accessToken, payload, companyId): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'bearer ' + accessToken
    });
    this.url = this.globalUrl + '/business/services/company/getskills/category?companyId=' + companyId;
    return this.http.post(this.url, payload, {headers: headers}).pipe(
      map(response => response),
      catchError(this._errorHandler)
    );

  }
  categoryList1(payLoad, companyId, accessToken): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'bearer ' + accessToken
    });
    
    this.url = this.globalUrl + '/business/services/company/category/list-all?companyId=' + companyId;
    return this.http.post(this.url, payLoad, {headers: headers}).pipe(
      map(response => response),
      catchError(this._errorHandler)
    );


  }

  subCatList(accessToken, companyId, payLoad): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'bearer ' + accessToken
    });
    this.url = this.globalUrl + '/business/services/company/subcategory/list?companyId=' + companyId;
    return this.http.post(this.url, payLoad, {headers: headers}).pipe(
      map(response => response),
      catchError(this._errorHandler)
    );
  }

  listOfQuesn(accessToken, companyId, payload, skip): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'bearer ' + accessToken
    });
    this.url = this.globalUrl + '/business/services/user/question/search?companyId=' + companyId + '&skip=' + skip;
    return this.http.post(this.url, payload, {headers: headers}).pipe(
      map(response => response),
      catchError(this._errorHandler)
    );
  }

  deleteSingleQuestion(payload, accessToken, companyId): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'bearer ' + accessToken
    });
    this.url = this.globalUrl + '/business/services/user/question/delete?companyId=' + companyId;
    return this.http.post(this.url, payload, {headers: headers}).pipe(
      map(response => response),
      catchError(this._errorHandler)
    );

  }

  addCategory(payLoad, companyId, accessToken){
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'bearer ' + accessToken
    });
    this.url = this.globalUrl + '/business/services/company/category/add?companyId=' + companyId;
    return this.http.post(this.url, payLoad, {headers: headers}).pipe(
      map(response => response),
      catchError(this._errorHandler)
    );

  }

  getSubcategoryList(payLoad, companyId, accessToken,skip){
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'bearer ' + accessToken
    });
    this.url = this.globalUrl + '/business/services/company/subcategory/list/stats?companyId=' + companyId +"&skip="+skip;
    return this.http.post(this.url, payLoad, {headers: headers}).pipe(
      map(response => response),
      catchError(this._errorHandler)
    );

  }

  getCategoryDetails(payLoad:any,companyId,accessToken){
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'bearer ' + accessToken
    });
    this.url = this.globalUrl + '/business/services/company/category/details?companyId=' + companyId 
    return this.http.post(this.url, payLoad, {headers: headers}).pipe(
      map(response => response),
      catchError(this._errorHandler)
    );

  }

 

  updateCategoryDetails(payLoad:any,companyId,accessToken){
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'bearer ' + accessToken
    });
    this.url = this.globalUrl + '/business/services/company/category/edit/v1?companyId='+ companyId ;
    return this.http.post(this.url, payLoad, {headers: headers}).pipe(
      map(response => response),
      catchError(this._errorHandler)
    );

  }

  updateSubcategoryDetails(payLoad:any,companyId,accessToken){
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'bearer ' + accessToken
    });
    this.url = this.globalUrl + '/business/services/company/subcategory/edit?companyId='+ companyId ;
    return this.http.post(this.url, payLoad, {headers: headers}).pipe(
      map(response => response),
      catchError(this._errorHandler)
    );

  }

  archiveUnarchive(payLoad:any,companyId,accessToken){
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'bearer ' + accessToken
    });
    this.url = this.globalUrl + '/business/services/company/category/change/status?companyId='+ companyId ;
    
    return this.http.post(this.url, payLoad, {headers: headers}).pipe(
      map(response => response),
      catchError(this._errorHandler)
    );
  }
  _errorHandler(error: HttpErrorResponse) {
    return throwError(error || 'Server Error');
  }
}
