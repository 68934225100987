<section class="bg-white">
  <app-header-static></app-header-static>
</section>
<section class="bg-white c-pattern mobile_block">
  <div class="container pt-5 pb-3 ">
    <div class="row ">
      <div class="col">
        <h1 class="GB-40 text-center">Assessment Types</h1>
      </div>
    </div>
  </div>

  <div class="container-fluid ">
    <div class="container pb-5 pt-5">



      <div class="container">
        <div class="row justify-content-between">
          <div class="col-md-3 col-sm-12">
            <select [(ngModel)]="selAssessmentType" (change)="setAssessmentTab($event.target.value)" class="form-control d-block d-sm-none mb-5">
              <option value="v-pills-technical">Technical Assessment</option>
              <option value="v-pills-aptitude">Aptitude Assessment</option>
              <option value="v-pills-psychometric">Psychometric Assessment</option>
              <option value="v-pills-messages">Written English Assessment</option>
              <option value="v-pills-voice">Voice Assessment</option>
              <option value="v-pills-home">Video Assessment</option>
              <option value="v-pills-profile">Profile Assessment</option>
              <option value="v-pills-online">Online Compiler</option>
            </select>
          </div>
        </div>
        <div class="row justify-content-between">
          <div class="col-md-3 col-sm-12 assessment-tab-container">
            <div class="nav flex-column nav-pills" id="v-pills-tab" role="tablist" aria-orientation="vertical">
              <a class="nav-link assessmentTab active" id="v-pills-technical-tab" data-toggle="pill" href="#v-pills-technical" (click)="setAssessmentType('v-pills-technical')" role="tab" aria-controls="v-pills-technical" aria-selected="false"><img src="assets/img/technical-assessment.png"  width="40" alt="" class="pl-2 pr-2">Technical Assessment</a>
              <a class="nav-link assessmentTab" id="v-pills-aptitude-tab" data-toggle="pill" href="#v-pills-aptitude" (click)="setAssessmentType('v-pills-aptitude')" role="tab" aria-controls="v-pills-aptitude" aria-selected="false"><img src="assets/img/aptitude-test.png"  width="40" alt="" class="pl-2 pr-2">Aptitude Assessment</a>
              <a class="nav-link assessmentTab" id="v-pills-psychometric-tab" data-toggle="pill" href="#v-pills-psychometric" (click)="setAssessmentType('v-pills-psychometric')" role="tab" aria-controls="v-pills-psychometric" aria-selected="false"><img src="assets/img/psychometric-assessment.png"  width="40" alt="" class="pl-2 pr-2">Psychometric Assessment</a>
              <a class="nav-link assessmentTab" id="v-pills-messages-tab" data-toggle="pill" href="#v-pills-messages" (click)="setAssessmentType('v-pills-messages')" role="tab" aria-controls="v-pills-messages" aria-selected="false"><img src="assets/img/writing-assessment.png"  width="40" alt="" class="pl-2 pr-2">Written English Assessment</a>
              <a class="nav-link assessmentTab" id="v-pills-voice-tab" data-toggle="pill" href="#v-pills-voice" (click)="setAssessmentType('v-pills-voice')" role="tab" aria-controls="v-pills-voice" aria-selected="false"><img src="assets/img/voice-assessment.png"  width="40" alt="" class="pl-2 pr-2">Voice Assessment</a>
              <a class="nav-link assessmentTab" id="v-pills-home-tab" data-toggle="pill" href="#v-pills-home" (click)="setAssessmentType('v-pills-home')" role="tab" aria-controls="v-pills-home" aria-selected="true"><img src="assets/img/video-assessment.png"  width="40" alt="" class="pl-2 pr-2">Video Assessment</a>
              <a class="nav-link assessmentTab" id="v-pills-profile-tab" data-toggle="pill" href="#v-pills-profile" (click)="setAssessmentType('v-pills-profile')" role="tab" aria-controls="v-pills-profile" aria-selected="false"><img src="assets/img/profile-assessment.png"  width="40" alt="" class="pl-2 pr-2">Profile Assessment</a>
              <a class="nav-link assessmentTab" id="v-pills-online-tab" data-toggle="pill" href="#v-pills-online" (click)="setAssessmentType('v-pills-online')" role="tab" aria-controls="v-pills-online" aria-selected="false"><img src="assets/img/online-compiler.png" height="25" alt="" class="pl-2 pr-2">Online Compiler</a>

            </div>
          </div>
          <div class="col-md-8 col-sm-12 c-pattern">
            <div class="tab-content" id="v-pills-tabContent">
              <div class="tab-pane fade" id="v-pills-home" role="tabpanel" aria-labelledby="v-pills-home-tab">
                <div class="row">
                  <div class="col">
                    <p class="RR-16 font-weight-bold text-gray">Video Assessment</p>
                    <p class="RR-13">The Video Assessment Tool helps in evaluating the candidates’ video responses to randomised, qualifying functional question(s). Video responses to the pre-determined functional questions evaluated by the Evaluator.</p>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-4 col-sm-12 text-center">
                    <div class="step-1">
                      <img src="assets/img/Situational-or-Technical-Questions.png"  height="60" alt="" class="mob-h1">
                      <p class="mt-5 RR-13 ">Situational/Functional/Technical Question(s)</p>
                    </div>
                  </div>
                  <div class="col-md-4 col-sm-12 text-center">
                    <div class="step-1">
                      <img src="assets/img/Candidate-Response-Recorded.png"  height="60" alt="" class="mob-h1">
                      <p class="mt-5 RR-13">Candidate Response Recorded</p>
                    </div>
                  </div>
                  <div class="col-md-4 col-sm-12 text-center">
                    <div class="step-1">
                      <img src="assets/img/Playback-to-Evaluate.png"  height="60" alt="" class="mob-h1">
                      <p class="mt-5 RR-13">Playback to Evaluate</p>
                    </div>
                  </div>
                </div>
              </div>

              <div class="tab-pane fade" id="v-pills-voice" role="tabpanel" aria-labelledby="v-pills-voice-tab">
                <div class="row">
                  <div class="col">
                    <p class="RR-16 font-weight-bold text-gray">Voice Assessment</p>
                    <p class="RR-13">Voice Assessment Tool helps in evaluating Language Speaking Skills. Get the candidates’ spontaneous audio responses to randomised, qualifying functional questions. Audio responses to pre-determined functional questions evaluated by the Evaluator.</p>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-4 col-sm-12 text-center">
                    <div class="step-1">
                      <img src="assets/img/Ask open ended Questions or to read the text.png"  height="60" alt="" class="mob-h1">
                      <p class="mt-5 RR-13 ">Ask open ended question or to read the text</p>
                    </div>
                  </div>
                  <div class="col-md-4 col-sm-12 text-center">
                    <div class="step-1">
                      <img src="assets/img/Candidate Response Recorded.png"  height="60" alt="" class="mob-h1">
                      <p class="mt-5 RR-13">Candidate Response Recorded</p>
                    </div>
                  </div>
                  <div class="col-md-4 col-sm-12 text-center">
                    <div class="step-1">
                      <img src="assets/img/Playback to Evaluate.png"  height="60" alt="" class="mob-h1">
                      <p class="mt-5 RR-13">Playback to Evaluate</p>
                    </div>
                  </div>
                </div>
              </div>

              <div class="tab-pane fade" id="v-pills-messages" role="tabpanel" aria-labelledby="v-pills-messages-tab">
                <div class="row">
                  <div class="col">
                    <p class="RR-16 font-weight-bold text-gray">Written English Assessment</p>
                    <p class="RR-13">Written English Analyser evaluates and quantifies the Written English skills of the candidates. Candidates’ written responses to random question(s) are analysed and the quality of Written English is quantified on 7 parameters.</p>
                  </div>
                </div>

                <div class="row">
                  <div class="col-md-4 col-sm-12 text-center">
                    <div class="step-1">
                      <img src="assets/img/Spelling Error.png"  height="60" alt="" class="mob-h1">
                      <p class="mt-5 RR-13 ">Spelling Errors</p>
                    </div>
                  </div>
                  <div class="col-md-4 col-sm-12 text-center">
                    <div class="step-1">
                      <img src="assets/img/Grammar Error.png"  height="60" alt="" class="mob-h1">
                      <p class="mt-5 RR-13">Grammer Errors</p>
                    </div>
                  </div>
                  <div class="col-md-4 col-sm-12 text-center">
                    <div class="step-1">
                      <img src="assets/img/Passive Voice Usage.png"  height="60" alt="" class="mob-h1">
                      <p class="mt-5 RR-13">Passive Voice Usage</p>
                    </div>
                  </div>
                </div>

                <div class="row mt-5">
                  <div class="col-md-4 col-sm-12 text-center">
                    <div class="step-1">
                      <img src="assets/img/Complex phrases_Construction.png"  height="60" alt="" class="mob-h1">
                      <p class="mt-5 RR-13 ">Complex Phrases Construction</p>
                    </div>
                  </div>
                  <div class="col-md-4 col-sm-12 text-center">
                    <div class="step-1">
                      <img src="assets/img/Usage of cliches.png"  height="60" alt="" class="mob-h1">
                      <p class="mt-5 RR-13">Usage of Cliches</p>
                    </div>
                  </div>
                  <div class="col-md-4 col-sm-12 text-center">
                    <div class="step-1">
                      <img src="assets/img/Sentence Variety.png"  height="60" alt="" class="mob-h1">
                      <p class="mt-5 RR-13">Sentence Variety</p>
                    </div>
                  </div>
                </div>

                <div class="row mt-5 justify-content-start">
                  <div class="col-md-4 col-sm-12 text-center">
                    <div class="step-1">
                      <img src="assets/img/Average Sentence Length.png"  height="60" alt="" class="mob-h1">
                      <p class="mt-5 RR-13">Average Sentence Length</p>
                    </div>
                  </div>
                  <div class="col-md-4 col-sm-12 text-center">
                    <div class="step-1">
                      <img src="assets/img/Word Count.png"  height="60" alt="" class="mob-h1">
                      <p class="mt-5 RR-13">Word Count</p>
                    </div>
                  </div>
                </div>

              </div>

              <div class="tab-pane fade" id="v-pills-aptitude" role="tabpanel" aria-labelledby="v-pills-aptitude-tab">
                <div class="row">
                  <div class="col">
                    <p class="RR-16 font-weight-bold text-gray">Aptitude Assessment</p>
                    <p class="RR-13">Assess candidates on Aptitude. Conduct Online aptitude tests with inbuilt question bank of 70 types of questions covering Quantitative, Verbal and Logical Reasoning on 3 levels of toughness or upload own question bank in a matter of few clicks or mix and match from both question banks to set up an assessment.</p>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-4 col-sm-12 text-center">
                    <div class="step-1">
                      <img src="assets/img/Logical Reasoning.png"  height="60" alt="" class="mob-h1">
                      <p class="mt-5 RR-13 ">Quantitative Skills</p>
                    </div>
                  </div>
                  <div class="col-md-4 col-sm-12 text-center">
                    <div class="step-1">
                      <img src="assets/img/Quantitative Skills.png"  height="60" alt="" class="mob-h1">
                      <p class="mt-5 RR-13">Logical Reasoning</p>
                    </div>
                  </div>
                  <div class="col-md-4 col-sm-12 text-center">
                    <div class="step-1">
                      <img src="assets/img/Verbal Reasoning.png"  height="60" alt="" class="mob-h1">
                      <p class="mt-5 RR-13">Verbal Reasoning</p>
                    </div>
                  </div>
                </div>
              </div>

              <div class="tab-pane fade show active" id="v-pills-technical" role="tabpanel" aria-labelledby="v-pills-technical-tab">
                <div class="row">
                  <div class="col">
                    <p class="RR-16 font-weight-bold text-gray">Technical Assessment</p>
                    <p class="RR-13">Assess candidate on Technical/Functional skills. Conduct online MCQ tests with inbuilt question bank or upload own question bank in a matter of few clicks or mix and match from both question banks.</p>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-4 col-sm-12 text-center">
                    <div class="step-1">
                      <img src="assets/img/Technical Skills.png"  height="60" alt="" class="mob-h1">
                      <p class="mt-5 RR-13 ">Technical Skills</p>
                    </div>
                  </div>
                  <div class="col-md-4 col-sm-12 text-center">
                    <div class="step-1">
                      <img src="assets/img/Functional Skills.png"  height="60" alt="" class="mob-h1">
                      <p class="mt-5 RR-13">Functional Skills</p>
                    </div>
                  </div>
                </div>
              </div>

              <div class="tab-pane fade" id="v-pills-psychometric" role="tabpanel" aria-labelledby="v-pills-psychometric-tab">
                <div class="row">
                  <div class="col">
                    <p class="RR-16 font-weight-bold text-gray">Psychometric  Assessment</p>
                    <p class="RR-13">Evaluate emotional quotient using psychometric test. Psychometer test is an online Personality Measure test. These tests are ‘standardised’, on corporate Readiness as a key evaluation objective. The test provides an objective way of assessing strengths and weaknesses with respect to a particular profile sought. It is a quick way to prima facie determine the suitability/fitment with respect to certain profiles.</p>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-4 col-sm-12 text-center">
                    <div class="step-1">
                      <img src="assets/img/Intrapersonal Quotient.png"  height="60" alt="" class="mob-h1">
                      <p class="mt-5 RR-13">Interapersonal Quotient</p>
                    </div>
                  </div>
                  <div class="col-md-4 col-sm-12 text-center">
                    <div class="step-1">
                      <img src="assets/img/Interpersonal Quotient.png"  height="60" alt="" class="mob-h1">
                      <p class="mt-5 RR-13">Interpersonal Quotient</p>
                    </div>
                  </div>
                  <div class="col-md-4 col-sm-12 text-center">
                    <div class="step-1">
                      <img src="assets/img/Rational Emotive Quotient.png"  height="60" alt="" class="mob-h1">
                      <p class="mt-5 RR-13 ">Rational Emotive Quotient</p>
                    </div>
                  </div>
                </div>

                <div class="row mt-5 justify-content-start">
                  <div class="col-md-4 col-sm-12 text-center">
                    <div class="step-1">
                      <img src="assets/img/General Well-Being Quotient.png"  height="60" alt="" class="mob-h1">
                      <p class="mt-5 RR-13">General Well-Being Quotient</p>
                    </div>
                  </div>
                </div>

              </div>

              <div class="tab-pane fade" id="v-pills-online" role="tabpanel" aria-labelledby="v-pills-online-tab">
                <div class="row">
                  <div class="col">
                    <p class="RR-16 font-weight-bold text-gray">Online Compiler</p>
                    <p class="RR-13">CALIBRAT's online compiler measure programming language acumen for real world coding. It measures programming language expertise and analyse the code quality. The online compiler offer wide variety of technologies and application frameworks.</p>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-4 col-sm-12 text-center">
                    <div class="step-1">
                      <img src="assets/img/Programming Question.png"  height="60" alt="" class="mob-h1">
                      <p class="mt-5 RR-13 ">Programming Question</p>
                    </div>
                  </div>
                  <div class="col-md-4 col-sm-12 text-center">
                    <div class="step-1">
                      <img src="assets/img/Candidate write a code.png"  height="60" alt="" class="mob-h1">
                      <p class="mt-5 RR-13">Candidate Write a code</p>
                    </div>
                  </div>
                  <div class="col-md-4 col-sm-12 text-center">
                    <div class="step-1">
                      <img src="assets/img/Evaluate Coding Errors.png"  height="60" alt="" class="mob-h1">
                      <p class="mt-5 RR-13">Evaluate coding error(s)</p>
                    </div>
                  </div>
                </div>
              </div>

              <div class="tab-pane fade" id="v-pills-profile" role="tabpanel" aria-labelledby="v-pills-profile-tab">
                <div class="row">
                  <div class="col">
                    <p class="RR-16 font-weight-bold text-gray">Profile Assessment</p>
                    <p class="RR-13">Automated Resume Analysis and Evaluation. Resume rating is quantitative estimation of the quality of the resume on 35 different parameters. Student's/candidate's build resume online and the tool generates resume score.</p>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-4 col-sm-12 text-center">
                    <div class="step-1">
                      <img src="assets/img/Education.png"  height="60" alt="" class="mob-h1">
                      <p class="mt-5 RR-13 ">Education</p>
                    </div>
                  </div>
                  <div class="col-md-4 col-sm-12 text-center">
                    <div class="step-1">
                      <img src="assets/img/Work Experience.png"  height="60" alt="" class="mob-h1">
                      <p class="mt-5 RR-13">Work Experience</p>
                    </div>
                  </div>
                  <div class="col-md-4 col-sm-12 text-center">
                    <div class="step-1">
                      <img src="assets/img/Skills.png"  height="60" alt="" class="mob-h1">
                      <p class="mt-5 RR-13">Skills</p>
                    </div>
                  </div>
                </div>
                <div class="row mt-5 justify-content-start">
                  <div class="col-md-4 col-sm-12 text-center">
                    <div class="step-1">
                      <img src="assets/img/Certifications.png"  height="60" alt="" class="mob-h1">
                      <p class="mt-5 RR-13">Certifications</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
</section>
<app-footer-static></app-footer-static>
