<div class="container-center">
  <div class="box-1">
    <div class="container">
      <div class="logo2" routerLink='/'>
        <img src="/assets/img/logo2.png" alt="logo"  style="cursor: pointer">
      </div>
      <!--carousel -->
      <div id="carouselExampleIndicators " class="carousel slide carouselWrap" data-ride="carousel">
        <ol class="carousel-indicators ">
          <li data-target="#carouselExampleIndicators" data-slide-to="0" class="active"></li>
          <li data-target="#carouselExampleIndicators" data-slide-to="1"></li>
          <li data-target="#carouselExampleIndicators" data-slide-to="2"></li>
        </ol>
        <div class="carousel-inner carousel-height">
          <div class="carousel-item active">
            <h5>Simple to Use</h5>
          </div>
          <div class="carousel-item">
            <h5>Pay Per Use</h5>
          </div>
          <div class="carousel-item">
            <h5>Reduce Effort and Cost</h5>
          </div>
        </div>

      </div>
      <!-- carousel end-->
    </div>
  </div>
  <div class="box-2">
    <div class="container"  >
      <div  class="parent">
        <i class="fa fa-refresh fa-spin fa-3x" style="color: #0ce2b8"></i> <span style="font-size: 40px;margin-left: 13px;color: #0ce2b8"> Processing </span>
      </div><br/>
      <div class="formDiv">

      </div>
    </div>


    <div  class="success-msg-container">
      <div class="alert alert-success fade show">
        <i class="fa fa-check-circle" aria-hidden="true" style=" font-size: 45px;"></i>
        <span style="margin-left: 20px;">Wait for a moment we are under construction !!!!</span>
      </div>
      <div class="mt-4 text-center">
        <button  class="btn btn-primary text-center" routerLink="/login">Happy Now</button>
      </div>
    </div>
  </div>
</div>

