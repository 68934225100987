<header>
  <nav class="navbar navbar-expand-lg navbar-light">
    <div class="container d-flex justify-content-between">
      <a class="navbar-brand" routerLink=""><img src="assets/img/logo-web.png"  height="36" alt="calibrat Logo - Assessment Simplied"></a>
      <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>

      <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <ul class="navbar-nav ml-auto">
          <li class="nav-item mr-3" routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}">
            <a class="nav-link" routerLink="" >Home <span class="sr-only">(current)</span></a>
          </li>
          <li class="nav-item dropdown mr-3" routerLinkActive="active">
            <a class="nav-link dropdown-toggle"  href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              Product
            </a>
            <div class="dropdown-menu" aria-labelledby="navbarDropdown">
              <a class="dropdown-item" (click)="scrollToUses('Fresher-Lateral-Screening');">Fresher/Lateral Screening</a>
              <a class="dropdown-item" (click)="scrollToUses('Campus-Screening');">Campus Screening</a>
              <!--<div class="dropdown-divider"></div>-->
              <a class="dropdown-item" (click)="scrollToUses('Employee-Assessment');">Employee Assessment</a>
              <a class="dropdown-item" (click)="scrollToUses('Entrance-Examination');">Entrance Examination</a>
              <a class="dropdown-item" (click)="scrollToUses('Skill-India');">Skill India</a>
            </div>
          </li>
          <li class="nav-item dropdown mr-3" routerLinkActive="active">
            <a class="nav-link dropdown-toggle"  href="#" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              Features
            </a>
            <div class="dropdown-menu" aria-labelledby="navbarDropdown">
              <a class="dropdown-item" routerLink="/features">General</a>
              <a class="dropdown-item" routerLink="/security">Security</a>
            </div>
          </li>

          <li class="nav-item mr-3" routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}">
            <a class="nav-link" routerLink="/assessment-types" >Assessment</a>
          </li>
          <li class="nav-item mr-3" routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}">
            <a class="nav-link" routerLink="/skill-based-assessment">Test Library</a>
          </li>

          <li class="nav-item mr-3" *ngIf="!loggedInUser" routerLinkActive="active">
            <a class="nav-link" routerLink="/login" >Login</a>
          </li>
          <li class="nav-item" *ngIf="!loggedInUser" routerLinkActive="active">
            <!--<a class="nav-link btn  " routerLink="/register">Signup</a>-->
            <button class="btn btn-primary ml-0" type="button" routerLink="/register">Sign up</button>
          </li>
          <li class="nav-item mr-3" *ngIf="loggedInUser" routerLinkActive="active">

            <button class="btn btn-primary ml-0" *ngIf="userRole=='super_admin'" type="button" routerLink="/super_admin">Dashboard</button>
            <button class="btn btn-primary ml-0" *ngIf="userRole!='super_admin'" type="button" routerLink="/dashboard">Dashboard</button>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</header>

