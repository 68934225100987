<div class="container-center">
  <div class="box-1">
   <app-carousel></app-carousel>
   
  </div>
  <div class="box-2">
    <div class="container">
      <form *ngIf="!Form2" class="form-padding" name="abc" (ngSubmit)="onSubmit(feed)" #feed="ngForm">
        <h5>Login</h5>
        <div class="form-group mtop30 mb-0 thumb50 full-width-container" oncontextmenu="false;">
          <international-phone-number2 *ngIf="loginMethod=='mobile'" [(ngModel)]="phoneNumber"
            (ngModelChange)="checkCountryCode(fieldReference)" placeholder="Enter Phone Number" [defaultCountry]="'in'"
            [required]="true" #fieldReference="ngModel" name="phone_number" [allowedCountries]="[]" class="cursor2">
          </international-phone-number2>
          <small class="form-text float-right green cursor" style="cursor: pointer;"
            *ngIf="loginMethod=='mobile' && showEditOption=='mobile'" href="/" (click)="changePhoneNumber();">Edit
            number?</small>
          <small *ngIf="showEmailOrMobile=='email'" class="form-text float-right green cursor" style="cursor: pointer"
            (click)="showLoginOption('email')">Use Email</small>
          <input *ngIf="loginMethod=='email'" [disabled]="OTPGenerated || validatePassword" required type="email"
            class="form-control height50" id="email" aria-describedby="emailHelp1"
            pattern="^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$" name="email" placeholder="Enter Email ID"
            [(ngModel)]="email" (focus)="clearServerErr($event)"><br />
          <small *ngIf="showEmailOrMobile=='mobile'" class="form-text float-right green cursor"
            style="cursor: pointer;margin-top: -15px" (click)="showLoginOption('mobile')">Use Mobile Number</small>
          <small class="form-text float-right green cursor" style="cursor: pointer;margin-top: -13px"
            *ngIf="loginMethod == 'email' && showEditOption=='email'" href="/" (click)="changePhoneNumber();">Edit
            Email?</small>
        </div>
        <br>
        <div>
          <small style="font-size:14px;" *ngIf="unRegisteredUserFlag" class="text-danger">{{errmsgMobile}}</small>
        </div>

        <div class="loginBtn" *ngIf="loginButton">
          <button (click)="getCompanyList();" [disabled]="feed.invalid " type="submit" id="submit"
            class="btn btn-login mtop15" value="Login">Next</button>
        </div>

        <p *ngIf="!OTPGenerated && !validatePassword " class="mtop30">Don’t have an account? <a routerLink="/register"
            class="text-info">Sign Up</a>
        </p>

      </form>

      <form *ngIf="Form2 && !unRegisteredUserFlag" class="form-padding" name="abc" (ngSubmit)="onSubmit(feed1)"
        #feed1="ngForm">
        <h5>Login</h5>
        <div class="form-group mtop30 mb-0 full-width-container" oncontextmenu="false;">
          <international-phone-number2 style="pointer-events:none ;" *ngIf="loginMethod=='mobile'"
            [(ngModel)]="phoneNumber" (ngModelChange)="checkCountryCode(fieldReference)"
            placeholder="Enter Phone Number" [defaultCountry]="'in'" [required]="true" #fieldReference="ngModel"
            name="phone_number" [allowedCountries]="[]">
          </international-phone-number2>
          <small class="form-text float-right green" style="cursor: pointer;"
            *ngIf="loginMethod=='mobile' || showEditOption=='mobile'" href="/"
            (click)="changePhoneNumber();showCompany=false;OneCompany=false">Edit
            number?</small>
          <!-- <small *ngIf="showEmailOrMobile=='email'" class="form-text float-right green" style="cursor: pointer"
            (click)="showLoginOption('email')">Use Email</small> -->
          <input *ngIf="loginMethod=='email'" disabled required type="text" class="form-control height50" id="email"
            aria-describedby="emailHelp1" pattern="^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$" name="email"
            placeholder="Enter Email ID" [(ngModel)]="email" (focus)="clearServerErr($event)"><br />
          <!-- <small *ngIf="showEmailOrMobile=='mobile'" class="form-text float-right green"
            style="cursor: pointer;margin-top: -10px" (click)="showLoginOption('mobile')">Use Mobile Number</small> -->
          <small class="form-text float-right green" style="cursor: pointer;margin-top: -13px"
            *ngIf="loginMethod == 'email' || showEditOption=='email'" href="/" (click)="changePhoneNumber();">Edit
            Email?</small>
        </div>

        <div *ngIf="showCompany && Form2">
          <div class="form-group mtop30 mb-0 full-width-container">
            <div class="form-group float-label-control ">
              <select name="companyList" (change)="selectedCompanyName($event.target.value);"
                placeholder="Select company sector" class="center-block form-control extra"
                [ngStyle]="{'pointer-events': OTPGenerated || validatePassword ? 'none' : 'all' }">
                <option *ngIf="CompanyName==null" selected value="" style="color: #2b669a">select company</option>
                <option *ngIf="CompanyName!=null" selected value="{{companyId}}">{{this.CompanyName}}</option>
                <option *ngFor="let company of companyList" value="{{company._id}}">{{company.companyName}}</option>
              </select>
              <br>
              <small *ngIf="OTPGenerated || validatePassword && companyList.length > 1"
                class="form-text float-right green" style="cursor: pointer;margin-top: -13px" href="/"
                (click)="changeCompany();">Change Company?</small>
               <div *ngIf="!OTPGenerated && !validatePassword">
              <button  [ngClass]="{'disabled':companyId== null}"  style="margin-top:20px;" class="btn btn-login mtop15"
                (click)="getOtpOrPassword();">Next</button>
              </div>
            </div>

          </div>
        </div>
        <div *ngIf="!showCompany && OneCompany && Form2">
          <div class="form-group mtop30 mb-0 full-width-container">
            <div class="form-group float-label-control ">
              <select [disabled]='true' name="companyList" class="center-block form-control extra"
                style="pointer-events:none ;">
                <option value="companyId">{{CompanyName}}</option>
              </select><br>
              <small *ngIf="companyList.length > 1" class="form-text float-right green"
                style="cursor: pointer;margin-top: -13px" href="/" (click)="changeCompany();">Change Company?</small>
              <!-- <button *ngIf="!OTPGenerated && !validatePassword"  style="margin-top:20px;" class="btn btn-login mtop15"
                (click)="getOtpOrPassword();">Next</button> -->

            </div>
          </div>
        </div>
        <div *ngIf="OTPGenerated" class="form-group mtop15">
          <!-- div start for mobile nr and otp field combination-->
          <label *ngIf="loginMethod == 'mobile'">Enter the OTP sent to your registered mobile number</label>
          <label *ngIf="loginMethod == 'email'">Enter the OTP sent to your registered Email ID</label>
          <div class="input-group mb-1" id="otpvalidate">

            <input type="text" required OnlyNumber="true" maxlength="1" class="form-control verifyGrid"
              aria-label="Text input with button" autocomplete="off" name="digit1" id="digit1" #digit1="ngModel"
              [(ngModel)]="otpDigit1" (keyup)="changeFocus('digit2', $event)" (keypress)="keyprees($event)" autofocus>

            <input type="text" required OnlyNumber="true" maxlength="1" class="form-control  verifyGrid"
              aria-label="Text input with button" autocomplete="off" name="digit2" id="digit2" #digit2="ngModel"
              [(ngModel)]="otpDigit2" (keyup)="changeFocus('digit3',$event)" (keypress)="keyprees($event)">

            <input type="text" required OnlyNumber="true" maxlength="1" class="form-control  verifyGrid"
              aria-label="Text input with button" autocomplete="off" name="digit3" id="digit3" #digit3="ngModel"
              [(ngModel)]="otpDigit3" (keyup)="changeFocus('digit4',$event)" (keypress)="keyprees($event)">

            <input type="text" required OnlyNumber="true" maxlength="1" class="form-control  verifyGrid"
              aria-label="Text input with button" autocomplete="off" name="digit4" id="digit4" #digit4="ngModel"
              [(ngModel)]="otpDigit4" (keyup)="changeFocus('digit5',$event)" (keypress)="keyprees($event)">

            <input type="text" required OnlyNumber="true" maxlength="1" class="form-control  verifyGrid"
              aria-label="Text input with button" autocomplete="off" name="digit5" id="digit5" #digit5="ngModel"
              [(ngModel)]="otpDigit5" (keyup)="changeFocus('digit6',$event)" (keypress)="keyprees($event)">

            <input type="text" required OnlyNumber="true" maxlength="1" class="form-control  verifyGrid"
              aria-label="Text input with button" autocomplete="off" name="digit6" id="digit6" #digit6="ngModel"
              [(ngModel)]="otpDigit6" (keyup)="changeFocus('digit6',$event)" (keypress)="keyprees($event)">

          </div>
          <small class="text-danger mt-0 mb-0"> {{OTPerror}} </small>
          <div class="col" *ngIf="showTimer">
            <div class="clock float-right text-center">
              <h4 class="green">0{{minute}}:<span *ngIf="secondsvalue">0</span>{{seconds}}</h4>
            </div>
          </div>

          <div id="message" name="message" style="display: none">
            <small style="color: green">{{message}}</small>
          </div>

          <button *ngIf="!showResend" [disabled]="!feed1.valid" type="submit" class="btn btn-login mtop30" id="submit "
            (click)="loginByOtp();clearServerErr($event);">Login</button>
          <button *ngIf="showResend" type="submit" class="btn btn-login mtop30" id="submit1 "
            (click)="resendOTP();clearServerErr($event);">Resend OTP</button>
        </div><!-- div End for mobile nr and otp field combination-->

        <div *ngIf="validatePassword" class="login-button">
          <div class="input-group login-input-group">
            <input appPassword type="password" class="form-control form-login rbw-0 height50" id="exampleInputPassword1"
              (input)="clearServerErr($event);" name="validPassword" #pass="ngModel" placeholder="Password"
              [(ngModel)]="validPassword" required>
          </div>
          <small class="form-text float-right green" style="cursor: pointer;font-size: 12px" *ngIf="validatePassword"
            (click)="switchOtp();">Use OTP</small>

          <div>
            <small class="text-danger" *ngIf="passwordErrFlag">{{passworderror.message}}</small>
          </div>

          <button type="submit" [disabled]="disableLogin||feed1.invalid" class="btn btn-login mtop30"
            id="validatedPassword" (click)="loginByPassword();clearServerErr($event);">Login</button>

        </div>
      </form>



    </div>
  </div>
</div>