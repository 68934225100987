import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';
import { throwError } from 'rxjs';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import 'rxjs/add/observable/throw';


@Injectable({
  providedIn: 'root'
})
export class SuperAdminService {
  private url = '';
  globalUrl = environment.serverUrl;

  constructor(private http: HttpClient) { }

  getAssessmentData(accessToken: any, payload: any, companyId: any) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'bearer ' + accessToken,
    });

    this.url = this.globalUrl + '/business/services/platform-stats/usage-stats?companyId=' + companyId;
    return this.http.post(this.url, payload, { headers: headers }).pipe(
      map(response => response),
      catchError(this._errorHandler)
    );

  }


  activateCompany(accessToken: any, payload: any, companyId: any){
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'bearer ' + accessToken,
    });

    this.url = this.globalUrl +'/business/services/company/reactivate-account?companyId=' + companyId;
    return this.http.post(this.url, payload, { headers: headers }).pipe(
      map(response => response),
      catchError(this._errorHandler)
    );

  }

  deActivateCompany(accessToken: any, payload: any, companyId: any){
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'bearer ' + accessToken,
    });

    this.url = this.globalUrl +'/business/services/company/deactivate-account?companyId=' + companyId;
    return this.http.post(this.url, payload, { headers: headers }).pipe(
      map(response => response),
      catchError(this._errorHandler)
    );
  
  }


  getCompanyList(accessToken: any, payLoad: any, companyId: any, skipVal: any) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'bearer ' + accessToken
    });

    this.url = this.globalUrl + '/business/services/company/list?companyId=' + companyId + '&skip=' + skipVal;
    return this.http.post(this.url, payLoad, { headers: headers }).pipe(
      map(response => response),
      catchError(this._errorHandler)
    );

  }
  getLiveCandidateCount(accessToken: any, companyId: any, skipVal: any) {
    const payload = {}
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'bearer ' + accessToken
    });

    this.url = this.globalUrl + '/business/services/platform-stats/live-candidate-count-grouped-by-company?companyId=' + companyId + '&skip=' + skipVal;
    return this.http.post(this.url, payload, { headers: headers }).pipe(
      map(response => response),
      catchError(this._errorHandler)
    );
  }


  getcompanyDetails(accessToken: any, companyId: any,payload:any) {
   
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'bearer ' + accessToken
    });

    this.url = this.globalUrl + '/business/services/company/info?companyId=' + companyId ;
    return this.http.post(this.url, payload, { headers: headers }).pipe(
      map(response => response),
      catchError(this._errorHandler)
    );
  }

  
  getcompanyStats(accessToken: any, companyId: any,payload:any) {
  
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'bearer ' + accessToken
    });

    this.url = this.globalUrl + '/business/services/company/stats?companyId='+companyId;
       return this.http.post(this.url, payload, { headers: headers }).pipe(
      map(response => response),
      catchError(this._errorHandler)
    );
  }


  submitMetaData(payload:any,accessToken:any,companyId:any){
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'bearer ' + accessToken
    });

    this.url = this.globalUrl + '/business/services/company/saml-assertion?companyId='+companyId;
       return this.http.post(this.url, payload, { headers: headers }).pipe(
      map(response => response),
      catchError(this._errorHandler)
    );
  }


  _errorHandler(error: HttpErrorResponse) {
    return throwError(error || 'Server Error');
  }
}
