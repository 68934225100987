import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { LiveBatchesComponent } from './live-batches/live-batches.component';
import { BatchCandidatesComponent } from './live-batches/batch-candidates/batch-candidates.component';
import {NotificationComponent} from './notification/notification.component';
import { AuthGuardService } from '../auth-guard.service';

const observerRoutes: Routes = [
  { path: 'observer',  canActivate: [AuthGuardService], component: LiveBatchesComponent},
  { path: 'observer/live-batch-candidates/:batchId/:assessmentId', canActivate: [AuthGuardService], component: BatchCandidatesComponent},
  { path: 'observer/notification', canActivate: [AuthGuardService], component: NotificationComponent}
   ];
@NgModule({
  imports: [
    RouterModule.forChild(observerRoutes)
  ],
  exports: [RouterModule]
})
export class ObserverRoutingModule {}
