<div class="container">
    <div class="logo2" routerLink='/'>
      <img src="/assets/img/logo2.png" alt="logo" style="cursor: pointer">
    </div>
    <!--carousel -->
    <div id="carouselExampleIndicators " class="carousel slide carouselWrap" data-ride="carousel">
      <ol class="carousel-indicators">
        <li data-target="#carouselExampleCaptions" data-slide-to="0" class="active"></li>
        <li data-target="#carouselExampleCaptions" data-slide-to="1"></li>
        <li data-target="#carouselExampleCaptions" data-slide-to="2"></li>
      </ol>
      <div class="carousel-inner carousel-height">
        <div class="carousel-item active">
          <h5>Simple to Use</h5>
        </div>
        <div class="carousel-item">
          <h5>Pay Per Use</h5>
        </div>
        <div class="carousel-item">
          <h5>Reduce Effort and Cost</h5>
        </div>
      </div>

    </div>
    <!-- carousel end-->
</div>
