import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})

export class AppComponent {

  constructor(private router: Router) {
    this.router.events.subscribe(() => {
      window.scroll(0, 0);
    });
  }

  onActivate(e, outlet) {
    outlet.scrollTop = 0;
  }
}




