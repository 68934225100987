import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CommonService {

  constructor() { }

  storeAPIKeyWebhookDetails(integrationKeyDetails: any) {
    localStorage.setItem('showIntegrationBlock', integrationKeyDetails.hasOwnProperty('platformIntegrationEnabled') ? integrationKeyDetails.platformIntegrationEnabled.toString() : 'false');
    localStorage.setItem('apiKey', integrationKeyDetails.hasOwnProperty('apiKey') ? integrationKeyDetails.apiKey : '');
    localStorage.setItem('apiSecretKey', integrationKeyDetails.hasOwnProperty('apiSecretKey') ? integrationKeyDetails.apiSecretKey : '');
    localStorage.setItem('activeKeySetCreatedOn', integrationKeyDetails.hasOwnProperty('activeKeySetCreatedOn') ? integrationKeyDetails.activeKeySetCreatedOn : '');
    localStorage.setItem('webhookRef', integrationKeyDetails.hasOwnProperty('webhook') ? integrationKeyDetails.webhook : '');
  }
}
