import { Component, OnInit , ViewChild } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import { ObserverService } from '../observer.service';
import {Router} from '@angular/router';
import {ToastrService} from 'ngx-toastr';



@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.css'],
  providers: [ObserverService]
})
export class NotificationComponent implements OnInit {
  @ViewChild('createNotification') createNotification: ModalDirective;
  @ViewChild('viewNotification') viewNotification: ModalDirective;
  @ViewChild('deleteNotification') deleteNotification: ModalDirective;
  formData: FormGroup;
  errorMessage = '';
  accessToken = '';
  companyId = '';
  editMode: Boolean;
  showMDelete: Boolean;
  notificationIds = '';
  editedObj = {
    _id: '',
    notificationTitle: '',
    notificationMessage: '',
    notificationType: '',
    companyId: ''
  };
  notificationData = [];
  notificationIdArray = [];
  constructor(public router: Router, public observerService: ObserverService, public toastrService: ToastrService) {
    this.formData = new FormGroup({
      title1: new FormControl('', [Validators.required, Validators.minLength(3), Validators.maxLength(50)]),
      message1: new FormControl('', [Validators.required, Validators.minLength(3), Validators.maxLength(200)]),
      notification1: new FormControl('', [Validators.required]),
    });
  }

  ngOnInit() {
    this.accessToken = localStorage.getItem('accessToken');
    this.companyId = localStorage.getItem('companyId');
    this.notificationMessage();
  }
  get titles() { return this.formData.get('title1'); }
  get messages() { return this.formData.get('message1'); }
  get notifications() { return this.formData.get('notification1'); }

  clearFormData() {
    this.formData.reset();
  }
  clearServerError() {
    this.errorMessage =''
  }
  submitForm() {
  }
   notificationMessage() {
    this.notificationData = []
     this.observerService.listNotification(this.companyId, this.accessToken)
       .subscribe(
         response => {
           this.notificationData = response.notifications;
         },
         error => {
           const errorMsg = error.error;
           this.errorMessage = errorMsg.message;
         });
   }

  addNotification(formValue) {
    const payload = {
      notificationTitle: formValue.title1 ,
      notificationMessage: formValue.message1,
      notificationType: formValue.notification1
    }
    this.observerService.addNotifications(this.companyId, this.accessToken , payload)
      .subscribe(
        response => {
          this.toastrService.success(response.message, 'Success');
          this.clearFormData();
          this.createNotification.hide();
          this.notificationMessage();
        },
        error => {
          const errorMsg = error.error;
          this.errorMessage = errorMsg.message;
        });
  }
  editNotification(form) {
       const payload = {
         _id: this.editedObj._id,
         notificationTitle: (form.title1 === '') ? this.editedObj.notificationTitle : form.title1 ,
         notificationMessage: (form.message1 === '') ? this.editedObj.notificationMessage : form.message1 ,
         notificationType: (form.notification1 === '') ? this.editedObj.notificationType : form.notification1 ,
       }
    this.observerService.editNotifications(this.companyId, this.accessToken , payload)
      .subscribe(
        response => {
          this.toastrService.success(response.message, 'Success');
          this.createNotification.hide();
          this.notificationMessage();
        },
        error => {
          const errorMsg = error.error;
          this.errorMessage = errorMsg.message;
        });
  }

  getNotificationID(list) {
    this.notificationIds = list._id;
    if (this.notificationIdArray.length === 0) {
      this.notificationIdArray.push(this.notificationIds);
    }
  }
  multipleDelete(event) {
    this.showMDelete = true;
    if (event.target.checked) {
      if (this.notificationIdArray.indexOf(event.target.value) < 0) {
        this.notificationIdArray.push(event.target.value);
      }
    } else {
      if (this.notificationIdArray.indexOf(event.target.value) >= 0) {
        const index = this.notificationIdArray.indexOf(event.target.value);
        this.notificationIdArray.splice(index, 1);
      }
    }
    if (this.notificationIdArray.length === 0) {
      this.showMDelete = false;
    }
  }
  deleteMultipledata() {
    this.deleteNotificationData();
    this.showMDelete = false;
  }
  deleteNotificationData() {
    const deletedPayload = {
      notificationId: this.notificationIdArray
    };
    this.observerService.deleteNotification(deletedPayload, this.accessToken, this.companyId).subscribe(
      response => {
        this.notificationIds = '';
        this.notificationIdArray = [];
        this.deleteNotification.hide();
        this.toastrService.success(response.message, 'Success');
        this.notificationMessage();
      },
      error => {
        this.deleteNotification.hide();
        this.notificationMessage();
      });
  }

  checkForEdit(value, list) {
    if (value === 'create') {
      this.editMode = false;
      this.clearFormData();
    }
    this.editedObj = list;
    if (value === 'edit') {
      this.editMode = true;
    } else {
      this.editMode = false;
    }
  }
}
