<app-user-header></app-user-header>

<nav class="container-fluid pt-3 pb-3 bd-navbar sub-header">
  <div class="row">
    <div class="col-10">
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
          <li class="breadcrumb-item">
            <a>Assigned Batch List - {{ assignedRole | titlecase }}</a>
          </li>
        </ol>
      </nav>
    </div>
    <div class="col-2">
      <div class="btn-group float-right" *ngIf="assignedRole == 'proctor'">
        <button
          type="button"
          class="btn btn-primary pull-right"
          (click)="showLiveBatches()"
        >
          LIVE
        </button>
      </div>
    </div>
  </div>
</nav>

<div class="container-fluid main-div-tab" style="padding-top: 9rem">
  <div class="row">
    <div class="col">
      <nav class="navbar navbar-expand-lg navbar-light navAdjust pl-0 pb-0">
        <div class="collapse navbar-collapse" id="navbarTogglerDemo02">
          <ul class="navbar-nav mr-auto ml-0 mt-2 mt-lg-0">
            <li class="nav-item mr-5" style="cursor: pointer">
              <a
                class="nav-link GR-14"
                id="open"
                [ngStyle]="
                  batchListType == 'OPEN'
                    ? { color: '#0ec2d0', 'border-bottom': '2px solid' }
                    : {}
                "
                (click)="ListAssignedBatches('OPEN')"
                >On-going</a
              >
            </li>
            <li class="nav-item mr-5" style="cursor: pointer">
              <a
                class="nav-link GR-14"
                id="upcoming"
                [ngStyle]="
                  batchListType == 'UPCOMING'
                    ? { color: '#0ec2d0', 'border-bottom': '2px solid' }
                    : {}
                "
                (click)="ListAssignedBatches('UPCOMING')"
                >Upcoming</a
              >
            </li>
            <li class="nav-item mr-5" style="cursor: pointer">
              <a
                class="nav-link GR-14"
                id="over"
                [ngStyle]="
                  batchListType == 'OVER'
                    ? { color: '#0ec2d0', 'border-bottom': '2px solid' }
                    : {}
                "
                (click)="ListAssignedBatches('OVER')"
                >Closed</a
              >
            </li>
          </ul>
        </div>
      </nav>
    </div>
  </div>
</div>

<div class="main-div-assessment container-fluid bg-white pt-1 pb-5">
  <div class="row mt-3" *ngIf="assignedBatchList.length > 0">
    <div class="col">
      <div class="card">
        <div class="card-body">
          <div class="row GR-14">
            <div class="col">Batch/Group</div>
            <div class="col">Invite Type</div>
            <div class="col">Status</div>
            <div class="col">Created On</div>
            <div class="col" *ngIf="batchListType == 'UPCOMING'">Starts On</div>
            <div
              class="col"
              *ngIf="batchListType == 'OPEN' || batchListType == 'OVER'"
            >
              Started On
            </div>
            <div class="col" *ngIf="batchListType == 'OVER'">Ended On</div>
            <div
              class="col"
              *ngIf="
                (batchListType == 'OPEN' || batchListType == 'OVER') &&
                assignedRole == 'evaluate'
              "
            >
              Action
            </div>
            <div
              class="col-3"
              *ngIf="
               assignedRole == 'centerManager'
              "
            >
              Action
            </div>
          </div>
        </div>
      </div>
      <div
        class="card card__two mtop15 border-0"
        *ngFor="let selLink of assignedBatchList; let i = index"
      >
        <div class="card-body card-bg">
          <div class="row">
            <div class="col text-truncate qHeading dect">
              {{ selLink.linkName }}
            </div>
            <div class="col text-truncate qRest">{{ selLink.linkType }}</div>
            <div
              *ngIf="selLink.status == 'active'"
              style="color: green"
              class="col text-truncate qRest"
            >
              <span class="badge badge-success">{{
                selLink.status | titlecase
              }}</span>
              <i
                *ngIf="
                  selLink.lockStatus == 'LOCKED' &&
                  assignedRole == 'centerManager'
                "
                class="fa fa-lock"
                aria-hidden="true"
                style="color: #17a2b8; padding-left: 15px"
              ></i>
              <i
                *ngIf="
                  selLink.lockStatus == 'UNLOCKED' &&
                  assignedRole == 'centerManager'
                "
                class="fa fa-unlock"
                aria-hidden="true"
                style="color: #17a2b8; padding-left: 15px"
              ></i>
            </div>
            <div
              *ngIf="selLink.status == 'inactive'"
              style="color: red"
              class="col text-truncate qRest"
            >
              <span class="badge badge-danger">{{
                selLink.status | titlecase
              }}</span
              ><i
                class="fa fa-exclamation-triangle"
                title="Test admin has mandated the upload of candidate's profile photo. To activate the test link, upload the profile photos for all the candidates."
                style="padding-left: 4px; cursor: pointer"
                *ngIf="selLink.reason != ''"
              ></i>
            </div>
            <div class="col qRest">
              {{ selLink.createdOn | date: "dd/MM/yyyy" }}
            </div>
            <div class="col qRest">
              {{
                selLink.configuration.uStartDate | date: "dd/MM/yyyy hh:mm a"
              }}
            </div>
            <div class="col qRest" *ngIf="batchListType == 'OVER'">
              {{ selLink.configuration.uEndDate | date: "dd/MM/yyyy hh:mm a" }}
            </div>
            <div
              class="col qRest"
              *ngIf="
                (batchListType == 'OPEN' || batchListType == 'OVER') &&
                assignedRole == 'evaluate'
              "
            >
              <button
                type="button"
                class="btn btn-outline ml-0"
                (click)="showBatchCandidates(selLink._id, selLink)"
              >
                Candidates
              </button>
            </div>
            <div
              class="col-3 qRest"
              *ngIf="
                assignedRole == 'centerManager'
              "
            >
              <button
                type="button"
                class="btn btn-primary"
                (click)="showInstructions(selLink.centerManagerInstruction)"
              >
                Instructions
              </button>
              <button
                *ngIf="selLink.lockStatus == 'UNLOCKED' && batchListType == 'OPEN'"
                type="button"
                class="btn btn-outline  mr-1"
                (click)="openLockUnlockModal(selLink._id, 'LOCKED')"
              >
                Lock
              </button>
              <button
                type="button"
                *ngIf="selLink.lockStatus == 'LOCKED' && batchListType == 'OPEN'"
                class="btn btn-outline  mr-1"
                (click)="openLockUnlockModal(selLink._id, 'UNLOCKED')"
              >
                Unlock
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="container-fluid text-center" *ngIf="showFetchMsg">
  <h3 class="text-center mt-5">Fetching Invitation....</h3>
</div>

<div
  class="container-fluid text-center"
  *ngIf="!showFetchMsg && assignedBatchList.length == 0"
>
  <!-- start for main entry Landing Page-->
  <img src="./assets/img/qa-empty.png" id="" alt="" class="mt-5" />
  <h3 class="text-center mt-5">No Records Found !!!</h3>
</div>

<div
  class="modal fade"
  id="centerMngrInstructionModal"
  tabindex="-1"
  role="dialog"
  aria-labelledby="assignedPersonalModalLabel"
  aria-hidden="true"
  data-backdrop="static"
  data-keyboard="false"
>
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header "style="background-color:  rgba(195, 243, 246, 0.74);" >
        <h5 class="modal-title" id="assignedPersonalModalLabel">
          Instruction
        </h5>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true" >&times;</span>
        </button>
      </div>
      <div class="modal-body text-center">
      <p *ngIf="centerManagerInstruction!=''" [innerHTML]="centerManagerInstruction"></p>
      <p *ngIf="centerManagerInstruction==''">No instruction provided</p>

    </div>
    </div>
  </div>
</div>

<div
  class="modal fade"
  id="centerMngrLockModal"
  tabindex="-1"
  role="dialog"
  aria-labelledby="assignedPersonalModalLabel"
  aria-hidden="true"
  data-backdrop="static"
  data-keyboard="false"
>
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header " style="background-color:  rgba(195, 243, 246, 0.74);" >
        <h5 class="modal-title" id="assignedPersonalModalLabel">
         Confirm
        </h5>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true" >&times;</span>
        </button>
      </div>
      <div class="modal-body text-center">
        <p *ngIf="selectedLinkLockStatus=='LOCKED'">You are about to lock this batch. Please note, once locked no candidate will be allowed to initiate an assessment session for this batch. Click  <b>'Proceed'</b> to continue.</p>
        <p *ngIf="selectedLinkLockStatus=='UNLOCKED'">You are about to unlock this batch. Please note, once unlocked candidates will be allowed to initiate an assessment session for this batch. Click  <b>'Proceed'</b>  to continue.</p> 
       </div>
       <div class="modal-footer">
        <button
        type="button"
        class="btn btn-outline  mr-1"
        data-dismiss="modal"
        style="margin-left: 200px;"
      >Cancel</button>
      <button
      type="button"
      class="btn btn-primary "
      (click)="changeLockUnlock()"
    >Proceed</button> 

    </div>
    </div>
  </div>
</div>
<!---->