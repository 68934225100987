<div class="modal fade" id="archiveModal" bsModal tabindex="-1" role="dialog" #archiveModal="bs-modal"
     [config]="{backdrop: 'static'}">
  <!-- archive modal -->
  <div class="modal-dialog modal-md" role="document">
    <div class="modal-content">
      <div class="modal-header draggable-handle">
        <h5 class="modal-title">Alert!</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="archiveModal.hide()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body text-center">
        Are you sure you want to archieve the category ?
      </div>
      
      <div class="modal-footer mx-auto d-block">
        <button type="button" class="btn btn-primary" data-dismiss="modal"
                (click)="archiveunarchive();archiveModal.hide()">Yes</button>
        <button type="button" class="btn btn-cancel" data-dismiss="modal" (click)="archiveModal.hide()">No</button>
      </div>
    </div>
  </div>
</div>
<!-- archive modal -->

<!--Unarchive Modal-->
<div class="modal fade" id="unarchiveModal" bsModal tabindex="-1" role="dialog" #unarchiveModal="bs-modal"
     [config]="{backdrop: 'static'}">
  <div class="modal-dialog modal-md" role="document">
    <div class="modal-content">
      <div class="modal-header draggable-handle">
        <h5 class="modal-title">Alert!</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="unarchiveModal.hide()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body text-center">
        Are you sure you want to unarchive the category ?
      </div>
      
      <div class="modal-footer mx-auto d-block">
        <button type="button" class="btn btn-primary" data-dismiss="modal"
                (click)="archiveunarchive();unarchiveModal.hide()">Yes</button>
        <button type="button" class="btn btn-cancel" data-dismiss="modal" (click)="unarchiveModal.hide()">No</button>
      </div>
    </div>
  </div>
</div>
<!-- Unarchive modal -->