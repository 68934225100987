<section class="bg-white">
  <app-header-static></app-header-static>
</section>
<section class="bg-white c-pattern mobile_block" *ngIf="templateDetails">
  <div class="container pt-5 pb-3">
    <div class="row " >
      <div class="col">
        <h1 class="GB-40 text-center">{{templateDetails.templateTitle}}</h1>
      </div>
    </div>
  </div>

  <div class="container-fluid"  >
    <div class="container">
      <div class="container mt-3">
        <div class="row d-flex justify-content-left">
          <div *ngIf="templateDetails.forAssessmentType=='Technical'" class="col text-right">
            <img src="assets/img/technical-assessment.png" class="basic-info-icon" alt="{{templateDetails.forAssessmentType}}">
            <span class="RR-16 font-weight-bold text-gray ml-2">{{templateDetails.forAssessmentType}} </span>
          </div>
          <div *ngIf="templateDetails.forAssessmentType=='Aptitude'" class="col text-right">
            <img src="assets/img/aptitude-test.png" class="basic-info-icon" alt="{{templateDetails.forAssessmentType}}">
            <span class="RR-16 font-weight-bold text-gray ml-2">{{templateDetails.forAssessmentType}} </span>
          </div>
          <div class="vertical-divider"></div>
          <div class="col text-left">
            <img src="assets/img/unlock.png" class="basic-info-icon" alt="{{templateDetails.templateAccessibility}}">
            <span class="RR-16 font-weight-bold text-gray ml-2 text-capitalize ">{{templateDetails.templateAccessibility}}</span>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="container-fluid">
    <div class="container pb-5 pt-5">
      <div class="container">
        <div class="row justify-content-between">
          <div class="col-md-12 col-sm-12 c-pattern">
            <div class="tab-content" id="v-pills-tabContent">
              <div class="tab-pane fade show active">
                <div class="row" *ngIf="templateDetails.description">
                  <div class="col">
                    <h2 class="GB-26">Description</h2>
                    <p class="RR-13" [innerHTML]="templateDetails.description">
                    </p>
                  </div>
                </div>
                <div class="row">
                  <!--<div class="col-md-4 col-sm-12 text-center">
                    <div class="step-1">
                      <img src="assets/img/Technical Skills.png"  height="60" alt="" class="mob-h1">
                      <p class="mt-5 RR-13 ">Technical Skills</p>
                    </div>
                  </div>
                  <div class="col-md-4 col-sm-12 text-center">
                    <div class="step-1">
                      <img src="assets/img/Functional Skills.png"  height="60" alt="" class="mob-h1">
                      <p class="mt-5 RR-13">Functional Skills</p>
                    </div>
                  </div>-->
                  <div class="col-md-12 col-sm-12 d-flex flex-wrap info-card-flex-container justify-content-center">
                    <div class="card info-card">
                      <div class="row no-gutters pr-4">
                        <div class="col-4 info-card-image-container">
                          <img src="assets/img/Intrapersonal Quotient.png" alt="Intrapersonal Quotient">
                        </div>
                        <div class="col-8">
                          <div style="display: flex;" class="pl-2 pr-4 mt-2">
                            <strong class="title text-nowrap">Experience Level</strong>
                          </div>
                          <div class="pl-2 pr-4 mt-2" style="display: flex;">
                            <p class="RR-14 grey text-nowrap">{{templateDetails.metaInfo.experienceLevel.min}} to {{templateDetails.metaInfo.experienceLevel.max}} Year(s)</p>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="card info-card">
                      <div class="row no-gutters pr-4">
                        <div class="col-4 info-card-image-container">
                          <img src="assets/img/Create-Questions-2.png" alt="Configured Question Count">
                        </div>
                        <div class="col-8">
                          <div style="display: flex;" class="pl-2 pr-4 mt-2">
                            <strong class="title text-nowrap">No. of Questions</strong>
                          </div>
                          <div class="pl-2 pr-4 mt-2" style="display: flex;">
                            <p class="RR-14 grey text-nowrap">{{templateDetails.questionCount}}</p>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="card info-card">
                      <div class="row no-gutters">
                        <div class="col-4 info-card-image-container pl-2">
                          <img src="assets/img/hourglass.png" alt="Assessment Duration">
                        </div>
                        <div class="col-8">
                          <div style="display: flex;" class="pl-2 pr-4 mt-2">
                            <strong class="title text-nowrap">Duration</strong>
                          </div>
                          <div class="pl-2 pr-4 mt-2" style="display: flex;">
                            <p class="RR-14 grey text-nowrap">{{templateDetails.duration | titlecase}}</p>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="card info-card">
                      <div class="row no-gutters pr-4">
                        <div class="col-4 info-card-image-container">
                          <img src="assets/img/Question_bank_analytics.png" alt="Assessment Difficulty Level">
                        </div>
                        <div class="col-8">
                          <div style="display: flex;" class="pl-2 pr-4 mt-2">
                            <strong class="title text-nowrap">Difficulty Level</strong>
                          </div>
                          <div class="pl-2 pr-4 mt-2" style="display: flex;">
                            <p class="RR-14 grey text-nowrap">{{templateDetails.metaInfo.difficultyLevel}}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="container-fluid">
    <div class="container">
      <div class="container mt-3">
        <div class="row d-flex justify-content-left">
          <div class="col text-center">
            <button *ngIf="userDetails && templateDetails.templateAccessibility=='open'" class="btn btn-primary ml-0" (click)="detailPage(templateDetails._id)">Use This</button>
            <button *ngIf="templateDetails.templateAccessibility=='restricted'" class="btn btn-primary ml-0" (click)="contactToUse(templateDetails)"> Contact To Use</button>
            <button *ngIf="!userDetails && templateDetails.templateAccessibility=='open'"  class="btn btn-primary ml-0" (click)="loginToUse()" >Login To Use</button>

          </div>
        </div>
      </div>
    </div>
  </div>
</section>


<!--------------------------Contact To use For Non-Logged In User -->


<div
  class="modal fade"
  id="contactToUseModal"
  bsModal
  tabindex="-1"
  role="dialog"
  #contactToUseModal="bs-modal"
  data-backdrop="static"

>
  <div class="modal-dialog modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header draggable-handle">
        <h5 class="modal-title">Contact To Use</h5>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
          (click)="contactToUseModal.hide();clearForm()"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body text-center">
          <label>Requesting Access for "{{requestingTemplateName}}"</label>
        <form [formGroup]="contactForm">
          <div>
            <div class="row row-width">
              <label class="mt-2">
                Company Name: <span style="color: red">*</span></label
              >
              <div class="col-3 ">
               <input   class="form-control selectFontSize industryWidth" formControlName="companyName"
               type="text" placeholder="Enter Company Name" />
              </div>


            </div>
            <div class="text text-danger " *ngIf="companyname.touched && companyname.invalid">
              <small *ngIf="companyname.errors.required">Company Name is required</small>
           </div>
            <div class="row row-width ">
              <label class="mt-2">
                Contact Person: <span style="color: red">*</span></label
              >
              <div class="col-3 ml-1">
               <input type="text"
               class="form-control selectFontSize industryWidth"
               placeholder="Enter Your Name"
               formControlName="contactPerson"
              />
              </div>
            </div>
            <div class="text text-danger" *ngIf="contactperson.touched && contactperson.invalid">
              <small *ngIf="contactperson.errors.required">Contact Person Name is required</small>
           </div>
            <div class="row row-width ">
              <label class="mt-2 mr-5">
                Email: <span style="color: red">*</span></label
              >
              <div class="col-3 email-class">
               <input   class="form-control selectFontSize industryWidth"
               type="email" placeholder="Enter Email"
               formControlName="contactEmail"
              />
              </div>


            </div>
            <div class="text text-danger" *ngIf="email.touched && email.invalid">
              <small *ngIf="email.errors.required">Enter valid email ID.</small>
              <small *ngIf="email.errors.pattern">Enter valid email ID.</small>
            </div>
            <div class="row row-width">
              <label class="mt-2 mr-3">
                Mobile No: <span style="color: red">*</span></label
              >
              <div class="form-group phn-class" >
                <international-phone-number2
                (focus)="clearServerErr()"
                (keypress)="clearServerErr(); keypress($event)"
                formControlName="contactMobNo"
                [(ngModel)]="contactMobNo"
                (change)="checkCountryCode($event)"
                placeholder="Enter Phone Number"
                #myInput
                [defaultCountry]="'in'"
                [required]="true"
                [allowedCountries]="[]"

              ></international-phone-number2>
              <div
                class="text text-danger"
                *ngIf="contactNumber.touched && contactNumber.invalid"
              >
                <small>Enter valid mobile number.</small>
              </div>

              </div>

            </div>

            <div class="row row-width">
              <label class="mt-2 mr-4">
                Query:</label
              >
              <div class="col-3 ml-5">
                <textarea   class="form-control selectFontSize industryWidth"
               formControlName="query"
                placeholder="Your Query"
                rows="4" cols="50" maxlength="200"></textarea>
               </div>
            </div>

            <div class="row mt-3">
              <div class="center">
                <button
                  type="button"
                  class="btn btn-primary"
                  data-dismiss="modal"
                  (click)="submitData();"
                  [disabled]="contactForm.invalid"
                >
                  Save
                </button>
                <button
                  type="button"
                  class="btn btn-cancel"
                  data-dismiss="modal"
                  (click)="contactToUseModal.hide();clearForm()"
                >
                  Cancel
                </button>
              </div>
            </div>


          </div>
        </form>
      </div>
    </div>
  </div>
</div>
<!--------------------------Contact To use For Logged In User with Pre Populated Data-->

<div
  class="modal fade"
  id="contactToUseModal2"
  bsModal
  tabindex="-1"
  role="dialog"
  #contactToUseModal2="bs-modal"
  data-backdrop="static"
  *ngIf="userDetails !== null"
>
  <div class="modal-dialog modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header draggable-handle">
        <h5 class="modal-title">Contact To Use</h5>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
          (click)="contactToUseModal2.hide();"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body text-center">
          <label>Requesting Access for "{{requestingTemplateName}}"</label>

          <div *ngIf="userDetails">
            <div class="row row-width">
              <label class="mt-2">
                Company Name: <span style="color: red">*</span></label
              >
              <div class="col-3 ">
              {{userDetails.Companies[0].CompanyName}}
              </div>


            </div>

            <div class="row row-width ">
              <label class="mt-2">
                Contact Person: <span style="color: red">*</span></label
              >
              <div class="col-3 ml-1">
              {{userDetails.FirstName}} {{userDetails.LastName}}
              </div>
            </div>

            <div class="row row-width ">
              <label class="mt-2 mr-5">
                Email: <span style="color: red">*</span></label
              >
              <div *ngIf="userDetails.Companies[0].EmailId" class="col-3   email-class">
               {{userDetails.Companies[0].EmailId}}
              </div>
              <div *ngIf="!userDetails.Companies[0].EmailId" class="col-3 email-class">
                -
               </div>


            </div>

            <div class="row row-width">
              <label class="mt-2 mr-4">
                Mobile No: <span style="color: red">*</span></label
              >
              <div  *ngIf="userDetails.MobileNo"  class="form-group ml-5 " >
                {{userDetails.MobileNo}}
              </div>
              <div  *ngIf="!userDetails.MobileNo"  class="form-group ml-5" >
               -
              </div>

            </div>

            <div class="row row-width">
              <label class="mt-2 mr-4">
                Query:</label
              >
              <div class="col-3 ml-5">
                <textarea   class="form-control selectFontSize industryWidth"
                  [(ngModel)]="query"
                placeholder="Your Query"
                rows="4" cols="50" maxlength="200"></textarea>
               </div>
            </div>

            <div class="row mt-3">
              <div class="center">
                <button
                  type="button"
                  class="btn btn-primary"
                  data-dismiss="modal"
                  (click)="submitData2();"

                >
                  Save
                </button>
                <button
                  type="button"
                  class="btn btn-cancel"
                  data-dismiss="modal"
                  (click)="contactToUseModal2.hide();"
                >
                  Cancel
                </button>
              </div>
            </div>


          </div>

      </div>
    </div>
  </div>
</div>
<app-footer-static></app-footer-static>
