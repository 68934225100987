import { Component, OnInit } from '@angular/core';
declare let $: any;

@Component({
  selector: 'app-assessment-page',
  templateUrl: './assessment-page.component.html',
  styleUrls: ['./assessment-page.component.css']
})
export class AssessmentPageComponent implements OnInit {
  selAssessmentType = 'v-pills-technical';
  cookiesAccepted: boolean=false;
  constructor() { }

  ngOnInit() {
    if( localStorage.getItem('cookieValue') == 'Accepted'){
      this.cookiesAccepted = true;
    }
   }

  setAssessmentType(assessmentType) {
    this.selAssessmentType = assessmentType;
  }

  setAssessmentTab(tabRef) {
    $('#v-pills-tab a[href="#' + tabRef + '"]').tab('show');
  }
}
