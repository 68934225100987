import { Component, OnInit } from '@angular/core';
import { BsModalService } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-no-internet',
  templateUrl: './no-internet.component.html',
  styleUrls: ['./no-internet.component.css']
})
export class NoInternetComponent implements OnInit {

  constructor( private modalService: BsModalService) { }

  ngOnInit() {}

  closeModal() {
    this.modalService.hide(1);
    document.body.className = document.body.className.replace('modal-open', '');
  }
}
