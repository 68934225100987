import { Component, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { UUID } from 'angular2-uuid';
import {MediaManagerService} from './media-manager.service';


@Component({
  selector: 'app-media-manager',
  templateUrl: './media-manager.component.html',
  styleUrls: ['./media-manager.component.css'],
  providers: [MediaManagerService]
})
export class MediaManagerComponent implements OnInit {
  @ViewChild('uploadModal') uploadModal: ModalDirective;
    showUpload = true;
    selectedFiles = [];
    accessToken = '';


//   token = 'b5ed3723-7b63-4ec4-95e2-2fc56016ec26';
//   afuConfig = {
//     multiple: true,
//     formatsAllowed: '.jpg,.png,.pdf,.docx,.txt,.gif,.jpeg',
//     maxSize: '1',
//     uploadAPI:  {
//       url: 'https://0i6g34u2ji.execute-api.ap-southeast-1.amazonaws.com/development/uploadcandidatereferencefile',
//       payload:'dfdgfg',
//       headers: {
//         'Content-Type' : 'application/json',
//         'Authorization' : 'Bearer ' + this.token
//       }
//     },
//     theme: 'dragNDrop',
//     hideProgressBar: false,
//     hideResetBtn: true,
//     hideSelectBtn: true,
//     replaceTexts: {
//       selectFileBtn: 'Select Files',
//       resetBtn: 'Reset',
//       uploadBtn: 'Upload',
//       dragNDropBox: 'Drag N Drop',
//       attachPinBtn: 'Attach Files...',
//       afterUploadMsg_success: 'Successfully Uploaded !',
//       afterUploadMsg_error: 'Upload Failed !'
//     }
// }

  constructor(private mediaManagerService: MediaManagerService) { }

  ngOnInit() {

  }

  dummy(event) {
   
  }

  valueChanged(event){

  }

  checkParameter(parameter) {
    if (parameter === 'folder') {
      this.showUpload = false;
    } else {
      this.showUpload = true;
    }
    this.uploadModal.show();
}
  uploadFiless() {
  }
  dropHandler(event) {
    event.preventDefault();
    if(event.dataTransfer.items) {
      for ( let i = 0 ; i < event.dataTransfer.items.length; i++) {
        if (event.dataTransfer.items[i].kind === 'file') {
          const file = event.dataTransfer.items[i].getAsFile();
          const obj = {
            fileName: file.name,
            selectedFile: file,
            fileId: UUID.UUID(),
            uploadCompleted: false
          }
          this.selectedFiles.push(obj);
        }
      }
      this.selectedFiles.forEach(file => this.getFileUploadStatus(file));
    } else {
      for (let i = 0; event.dataTransfer.files.length; i++) {
      }
    }

  }

  dragOverHandler(event) {
    event.preventDefault();
    event.stopPropagation();
  }

  getFileUploadStatus(file) {
    const payload = {
      'fileId': file.fileId,
      'fileName': file.fileName,
      'size': file.selectedFile.size.toString()
      // 'baseString': this.base64textString,
      // 'companyId': this.companyId,
      // 'sessionId': this.sessionId,
      // 'assessmentId': this.assessmentId,
      // 'linkId': this.linkId
    };
    this.mediaManagerService.uploadDocumentsService(this.accessToken, payload)
      .subscribe(
        response => {
        },
        error => {
          if (error.status === 412) {
          } else {
            // alert('Something went wrong');
          }
        });

  }

}
