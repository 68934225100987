import { Component, OnInit } from '@angular/core';
import { Router ,ActivatedRoute } from '@angular/router';
import { AssessmentService } from '../../assessments-module/assessment.service';

@Component({
  selector: 'app-blog-details',
  templateUrl: './blog-details.component.html',
  styleUrls: ['./blog-details.component.css'],
  providers: [ AssessmentService ]
})
export class BlogDetailsComponent implements OnInit {
  companyId: any;
  accessToken: any;
  blogDetailsResponse: any=[];
  slugValue: any;
  blogDetailsResponseError = ""

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private  assessmentService: AssessmentService,
  ) {
    this.accessToken = localStorage.getItem('accessToken');
    this.companyId = localStorage.getItem('companyId');
  }

  ngOnInit() {
    this.companyId = localStorage.getItem('companyId') === null? "": localStorage.getItem('companyId');
    this.accessToken = localStorage.getItem('accessToken') === null? "": localStorage.getItem('accessToken');
    this.getRouteParams();
  }

  getRouteParams() {
    this.route.params.subscribe(
      params => {
        if (params.id) {
          this.slugValue = params['id'];
          this.blogDetailsFunc();
        }
      },
      error => console.log(error)
    );
  }

  blogDetailsFunc() {
    let blogStatus = localStorage.getItem("_selectedBlogStatus") === null ? "" : localStorage.getItem("_selectedBlogStatus");
    if(blogStatus == "" || blogStatus == "PUBLISHED" || this.accessToken == "") {
      // User is not logged-in OR requested blog is in PUBLISHED state
      const payload = {
        'blogIdentifier' : this.slugValue,
      };
      this.assessmentService.blogDetailsApi(payload)
        .subscribe({
          error: (error) => {
            this.blogDetailsResponseError = error.error.message;
          },
          next: (response) => {
            this.blogDetailsResponseError = "";
            this.blogDetailsResponse = [response.message];
          }
        });
    } else {
      // SuperAdmin is looking for the preview of an unpublished blog
      let blogId = localStorage.getItem("_selectedBlogId") === null? "": localStorage.getItem("_selectedBlogId");
      if(blogId != "") {
        const payload = {
          'blogId' : blogId,
        };
        this.assessmentService.blogDetailsAdminApi(payload,this.companyId, this.accessToken)
          .subscribe(
            (response:any) => {
              this.blogDetailsResponseError = "";
              this.blogDetailsResponse = response.blogDetails;
            }
          );
      } else {
        this.blogDetailsResponseError = "Unable to fetch requested blog details.";
      }

    }
  }

}


