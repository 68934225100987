<div class="container-center">
  <div class="box-1">
   <app-carousel></app-carousel>
  </div>
  <div class="box-2">
    <div class="container"  >
      <div *ngIf="!hideLoading" class="parent">
        <i class="fa fa-refresh fa-spin fa-3x" style="color: #0ce2b8"></i> <span style="font-size: 40px;margin-left: 13px;color: #0ce2b8"> Processing </span>
      </div><br/>
      <div class="formDiv">
      <form #feed="ngForm" *ngIf="showFormField" style="width: 85%;">
          <div class="col-md-12">
            <h5 class="mtop40">Set Password</h5>
            <div class="input-group mtop30 no-padding-left">
              <input appPassword (blur)="matchPasswordFormat($event)" (keyup)="passwordMatch();" type="password" autocomplete="false"
                     class="password-field form-control rbw-0" id="passwordd" #passwordd="ngModel" [(ngModel)]="password" name="password"
                     pattern="(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,20}$"
                     popover="{{ pwdFormat }}" [popoverCloseOnClickOutside]="true" popoverPlacement="right"
                     required placeholder="New Password" reverse="false">
            </div>
            <div class="mb-3">
              <small *ngIf="invalidPassword" class="text-danger" >
                {{passwordValidation}}
                <i class="material-icons password-info-icon" popover="{{ pwdFormat }}" [popoverOnHover]="true" [popoverCloseOnMouseOutside]="true" popoverPlacement="right">info</i>
              </small>
            </div>

            <div class="form-group no-padding-left">
              <input type="password" (keyup)="confirmMatch();" autocomplete="false" pattern="(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,20}$"
                     class="password-field form-control" id="cPassword" #cPassword="ngModel"
                     [(ngModel)]="confirmPassword" name="confirmPassword"
                     required placeholder="Confirm New Password" validateEqual="password" reverse="false">
              <small class="text-danger" >{{matchPassword}}</small>
            </div>

            <div class="text-danger">
              <small>{{formErrorMsg}}</small>
            </div><br/>
            <div class="row text-center">
              <button [disabled]="passwordd.invalid || feed.invalid||cPassword.invalid || invalidPassword" class="btn btn-primary" id="submitPassword" (click)="submitPassword();">Continue</button>
            </div>
          </div>
      </form>
      </div>
    </div>

    <div *ngIf="errorMessage != ''" class="alert alert-danger alert-dismissible fade show">
      <i class="fa fa-exclamation-triangle" aria-hidden="true" style=" font-size: 45px;"></i>
      <span style="margin-left: 20px;">{{errorMessage}}</span>
    </div>

    <div *ngIf="successMessage != ''" class="success-msg-container">
      <div class="alert alert-success fade show">
        <i class="fa fa-check-circle" aria-hidden="true" style=" font-size: 45px;"></i>
        <span style="margin-left: 20px;">{{successMessage}}</span>
      </div>
      <div class="mt-4 text-center">
        <button *ngIf="showLoginButton" class="btn btn-primary text-center" routerLink="/login">Login Now</button>
      </div>
    </div>
  </div>
</div>

