import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import 'rxjs/add/observable/throw';
import { environment } from '../../environments/environment';

@Injectable()
export class VerifyService {
  private headers = new HttpHeaders({
    'Content-Type': 'application/json',
  });
  private url = '' ;
  private localhost = 'localhost';
  globalUrl = environment.serverUrl;

  constructor(private http: HttpClient) { }

  generateOTP(payLoad: any, companyId): Observable<any> {
    this.url = this.globalUrl + '/business/services/user/generate/otp?companyId=' + companyId;
    return this.http.post(this.url, payLoad, {headers: this.headers}).pipe(
      map( response => response),
      catchError(this._errorHandler)
    );
  }

  resendOTP(payLoad: any, companyId): Observable<any> {
    this.url = this.globalUrl + '/business/services/user/resend/otp?companyId=' + companyId;
    return this.http.post(this.url, payLoad, {headers: this.headers}).pipe(
      map( response => response),
      catchError(this._errorHandler)
    );
  }

  validateOTP(payLoad: any, companyId): Observable<any> {
    this.url = this.globalUrl + '/business/services/user/login/otp?companyId=' + companyId;
    return this.http.post(this.url, payLoad, {headers: this.headers}).pipe(
      map( response => response),
      catchError(this._errorHandler)
    );
  }

  validateInvitationOTP(payLoad: any, companyId): Observable<any> {
    this.url = this.globalUrl + '/business/services/user/verify-invitation-otp?companyId=' + companyId;
    return this.http.post(this.url, payLoad, {headers: this.headers}).pipe(
      map( response => response),
      catchError(this._errorHandler)
    );
  }

  generatePassword(payLoad: any, companyId, accessToken): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'bearer ' + accessToken
    });
    this.url = this.globalUrl + '/business/services/user/generate/password?companyId=' + companyId;
    return this.http.post(this.url, payLoad, {headers: headers}).pipe(
      map( response => response),
      catchError(this._errorHandler)
    );
  }

  emailVerification(payLoad: any): Observable<any> {
    this.url = this.globalUrl + '/business/services/user/email/verify';
    return this.http.post(this.url, payLoad, {headers: this.headers}).pipe(
      map( response => response),
      catchError(this._errorHandler)
    );
  }


  forGotPassword(accessToken, companyId) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'bearer ' + accessToken
    });
    this.url = this.globalUrl + '/business/services/user/profile/reset/password?companyId=' + companyId;
    return this.http.post(this.url,  {}, {headers: headers}).pipe(
      map( response => response),
      catchError(this._errorHandler)
    );
  }

  getUserInfo(companyId: string, accessToken: any): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'bearer ' + accessToken
    });
    this.url = this.globalUrl + '/business/services/user/info?companyId=' + companyId;
    return this.http.post(this.url,  {}, {headers: headers}).pipe(
      map( response => response),
      catchError(this._errorHandler)
    );
  }


  passWordLinkVerification(payload) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    this.url = this.globalUrl + '/business/services/user/profile/reset/password/link/verification';
    return this.http.post(this.url, payload,  {headers: headers}).pipe(
      map( response => response),
      catchError(this._errorHandler)
    );
  }

  changePassword(payload) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    this.url = this.globalUrl + '/business/services/user/profile/reset/password/setnew/password';
    return this.http.post(this.url, payload , {headers: headers}).pipe(
      map( response => response),
      catchError(this._errorHandler)
    );

  }

  _errorHandler(error: HttpErrorResponse) {
    console.error(error);
    return throwError(error || 'Server Error');

  }


  getIntegrationApikey(companyId: string, accessToken: any): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'bearer ' + accessToken
    });
    this.url = this.globalUrl + '/business/services/company/generate-keys?companyId=' + companyId;
    return this.http.post(this.url,  {}, {headers: headers}).pipe(
      map( response => response),
      catchError(this._errorHandler)
    );
  }

  getIntegrationWebHook(payLoad: any,companyId: string, accessToken: any): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'bearer ' + accessToken
    });
    this.url = this.globalUrl + '/business/services/company/set-webhook?companyId=' + companyId;
    return this.http.post(this.url, payLoad, {headers: headers}).pipe(
      map( response => response),
      catchError(this._errorHandler)
    );
  }

  GetIntegrationContactUs(companyId: string, accessToken: any): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'bearer ' + accessToken
    });
    this.url = this.globalUrl + '/business/services/company/request-integration?companyId=' + companyId;
    return this.http.post(this.url, {}, {headers: headers}).pipe(
      map( response => response),
      catchError(this._errorHandler)
    );
  }
}
