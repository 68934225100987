<section class="bg-white">
  <app-header-static></app-header-static>
</section>
<div class="container">
  <div class="container pt-5 pb-2 ">
    <div class="row ">
      <div class="col">
        <p class="GB-40 text-center">Terms of Use</p>
      </div>
    </div>
    <div class="row ">
      <div class="col-md-12 pb-2 pl-0">
        <p class="RR-13 font-weight-bold f-size-14">Last modified : February 5, 2020</p>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-md-12 pb-5">
      <p class="RR-16 font-weight-bold text-gray">Overview</p>
      <p class="RR-13">The following are the terms of an agreement between you and TalentBridge Technologies Pvt. Ltd. By accessing, or using the CALIBRAT website, platforms and mobile application managed by TalentBridge Technologies Pvt. Ltd., you acknowledge that you have read, understand, and agree to be bound by these terms and to comply with all applicable laws and regulations. If you do not agree to these terms, please do not use this website, platform and mobile application offered by TalentBridge Technologies Pvt. Ltd.</p>
      <p class="RR-13">TalentBridge Technologies Pvt. Ltd. may, without notice to you, at any time, revise these Terms of Use and any other information contained in this website. TalentBridge Technologies Pvt. Ltd. may also make improvements or changes in the products, services, or programs described in this site or any other website at any time without notice.</p>
      <p class="RR-13">This website contains general information about the company, TalentBridge Technologies Pvt. Ltd., for users (including but not limited to enterprise users, enterprise clients, potential enterprise clients). This website legally connected or launched by us under any other domain name of any other brand name is not intended to be a source of legal advice. Therefore, you should not rely on the information provided herein as legal advice for any purpose. This website may contain links to other sites. TalentBridge Technologies Pvt. Ltd. does not necessarily endorse, and is not responsible for, any third-party content that may be accessed through this website. Also, please be aware that we are not and cannot be responsible for the privacy practices of other websites. We encourage you to read the terms of use & privacy statements of all third-party websites that you visit.</p>
      <p class="RR-16 font-weight-bold text-gray">Registration</p>
      <p class="RR-13">As a registered user, you state that you have completed the registration and provided other relevant details as required by TalentBridge Technologies Pvt. Ltd. You represent that you, in your individual capacity and/or as an authorised representative of the entity registering as a user on the website, are competent to contract, are of sound mind and are not disqualified from entering into lawful contract.</p>
      <p class="RR-13">You also represent that you have provided to TalentBridge Technologies Pvt. Ltd. your information such as name, mobile number, email address etc. In case of any commercial transactions you might have to produce Tax registration number and other compliance related details applicable as per the laws of land.</p>
      <p class="RR-16 font-weight-bold text-gray">Password and Security</p>
      <p class="RR-13">As a registered user, you agree not to use any false, inaccurate, misleading information to create an account in CALIBRAT. If you opt for password way of signing in to the platform, you are entirely responsible for maintaining the confidentiality of your password and account. The password must be created as per the shared password guidelines. You are entirely responsible for any activity under that occur under your account. You agree to notify TalentBridge Technologies Pvt. Ltd. immediately in case of any unauthorised use of your account or any security breach. TalentBridge Technologies Pvt. Ltd. will not be responsible for any loss that may incur as a result of someone else using your password or account, with or without your knowledge.</p>
      <p class="RR-16 font-weight-bold text-gray">Proprietary Rights</p>
      <p class="RR-13">CALIBRAT website, platform and mobile application contains Content of TalentBridge Technologies Pvt. Ltd. This content is protected by copyright, trademark, patent, trade secret and other laws, and TalentBridge Technologies Pvt. Ltd. owns and retains all rights of the content. You may not copy, modify, translate, publish, broadcast, transmit, distribute, perform, display, or sell any content appearing on or through the TalentBridge Technologies Pvt. Ltd. Services.</p>
      <p class="RR-16 font-weight-bold text-gray">User Content</p>
      <p class="RR-13">The websites of TalentBridge Technologies Pvt. Ltd. may provide you with the ability to upload various types of content, profile pages, and other content and media for the purpose intended for interaction, or certain information and materials for use within (collectively, "User Content"). TalentBridge Technologies Pvt. Ltd. does not claim ownership of any User Content you may submit or make available for your purpose of use. User will be the sole and exclusive owner of any and all rights, title and interest in and to the User Content.</p>
      <p class="RR-13">TalentBridge Technologies Pvt. Ltd. shall not be liable for the contents and images shared, uploaded or displayed on the website by the user and all consequent liability will be borne by the user only.</p>
      <p class="RR-16 font-weight-bold text-gray">Content Posted</p>
      <p class="RR-13">TalentBridge Technologies Pvt. Ltd. may delete any communication or content that, in the sole judgment of TalentBridge Technologies Pvt. Ltd. ,if the content (a) violates this Agreement (b) may be offensive or illegal or (c) harms, threatens the safety of, or violates the rights of any person. TalentBridge Technologies Pvt. Ltd. assumes no responsibility for monitoring the TalentBridge Technologies Pvt. Ltd. products and services for inappropriate communication, content or conduct. If at any time TalentBridge Technologies Pvt. Ltd. chooses, in its sole discretion, to monitor the TalentBridge Technologies Pvt. Ltd. products and services, TalentBridge Technologies Pvt. Ltd. nonetheless assumes no responsibility for any communication or Content. TalentBridge Technologies Pvt. Ltd. has no obligation to modify or remove any inappropriate Content, and no responsibility for the conduct of any User submitting such Content.</p>
      <p class="RR-13">Private correspondence or Content is not necessarily reviewed by TalentBridge Technologies Pvt. Ltd. prior to posting and does not necessarily reflect the opinions or policies of TalentBridge Technologies Pvt. Ltd. TalentBridge Technologies Pvt. Ltd. makes no warranties, express or implied, as to the Content or to the accuracy and reliability of the Content or any material or information that you transmit to other Members.</p>
      <p class="RR-16 font-weight-bold text-gray">Content/Activity Prohibited</p>
      <p class="RR-13">The following is a partial list of the kind of Content that is illegal or prohibited to post on or through the TalentBridge Technologies Pvt. Ltd. Services. TalentBridge Technologies Pvt. Ltd. reserves the right to investigate and take appropriate legal action against anyone who, in TalentBridge Technologies Pvt. Ltd. ’s sole discretion, violates this provision, including without limitation, removing the offending communication from the TalentBridge Technologies Pvt. Ltd. Services and terminating the Membership of such violators. Prohibited Content includes, but is not limited to content that, in the sole discretion of TalentBridge Technologies Pvt. Ltd.</p>
      <ul class="RR-13">
        <li>is patently offensive and promotes racism, bigotry, hatred or physical harm of any kind against any group or individual;</li>
        <li>harasses or advocates harassment of another person;</li>
        <li>exploits people in a sexual or violent manner;</li>
        <li>contains nudity, violence, or offensive subject matter or contains a link to an adult website;</li>
        <li>promotes information that you know is false or misleading or promotes illegal activities or conduct that is abusive, threatening, obscene, defamatory or libellous;</li>
        <li>promotes an illegal or unauthorised copy of another person's copyrighted work, such as providing pirated computer programs or links to them, providing information to circumvent manufacture-installed copy-protect devices, or providing pirated music or links to pirated music files;</li>
        <li>involves the transmission of "junk mail," "chain letters," or unsolicited mass mailing, instant messaging, "spimming," or "spamming";</li>
        <li>contains restricted or password only access pages or hidden pages or images (those not linked to or from another accessible page);</li>
        <li>furthers or promotes any criminal activity or enterprise or provides instructional information about illegal activities including, but not limited to, making or buying illegal weapons, violating someone's privacy, or providing or creating computer viruses;</li>
        <li>solicits passwords or personal identifying information for commercial or unlawful purposes from other Users;</li>
        <li>involves commercial activities and/or sales without our prior written consent such as contests, sweepstakes, barter, advertising, or pyramid schemes;</li>
        <li>includes a photograph of another person that you have posted without that person's consent; or</li>
        <li>destructive criticism about other users or any service, or encouraging other users to such acts.</li>
        <li>selling or otherwise transferring your profile;</li>
        <li>displaying an advertisement on your profile, or accepting payment or anything of value from a third person in exchange for your performing any commercial activity on or through the TalentBridge Technologies Pvt. Ltd. Services on behalf of that person, such as placing commercial content on your profile, posting blogs or bulletins with a commercial purpose, or sending private messages with a commercial purpose;</li>
        <li>using the TalentBridge Technologies Pvt. Ltd. Services in a manner inconsistent with any and all applicable laws and regulations linking to this site.</li>
      </ul>
      <p class="RR-13">Although TalentBridge Technologies Pvt. Ltd. does not routinely screen or monitor content posted by users to the site, TalentBridge Technologies Pvt. Ltd. reserves the right to remove Prohibited Content of which it becomes aware, but is under no obligation to do so.</p>
      <p class="RR-16 font-weight-bold text-gray">Copyright Policy</p>
      <p class="RR-13">You may not post, modify, distribute, or reproduce in any way any copyrighted material, trademarks, or other proprietary information belonging to others without obtaining the prior written consent of the owner of such proprietary rights. It is the policy of TalentBridge Technologies Pvt. Ltd. to terminate privileges of any registered user who repeatedly infringes the copyright rights of others upon receipt of proper notification to TalentBridge Technologies Pvt. Ltd. by the copyright owner or the copyright owner's legal agent. Without limiting the foregoing, if you believe that your work has been copied and posted on the TalentBridge Technologies Pvt. Ltd. Services in a way that constitutes copyright infringement, please provide our Copyright Agent with the following information: (i) an electronic or physical signature of the person authorised to act on behalf of the owner of the copyright interest; (ii) a description of the copyrighted work that you claim has been infringed; (iii) a description of where the material that you claim is infringing is located on the TalentBridge Technologies Pvt. Ltd. Services; (iv) your address, telephone number, and e-mail address; (v) a written statement by you that you have, in good faith, a belief that the disputed use is not authorised by the copyright owner, its agent, or the law; (vi) a statement by you, made under penalty of perjury, that the above information in your notice is accurate and that you are the copyright owner or authorised to act on the copyright owner's behalf.</p>
      <p class="RR-16 font-weight-bold text-gray">© 2020 TalentBridge Technologies Pvt. Ltd.</p>
      <p class="RR-13">All rights reserved. The reproduction or retransmission of the contents of this Web site is prohibited without the prior written consent of TalentBridge Technologies Pvt. Ltd.</p>
      <p class="RR-16 font-weight-bold text-gray">General</p>
      <p class="RR-13">This website contains proprietary notices and copyright information, the terms of which must be observed and followed. This site and any or all content in this site cannot be copied, reproduced, republished, uploaded, posted, transmitted, distributed, or used for the creation of derivative works without TalentBridge Technologies Pvt. Ltd.'s prior written consent. Notwithstanding the foregoing, any software and other materials that are made available for downloading, access or other use from this site with their own license terms, conditions, and notices will be governed by such terms, conditions and notices. Your failure to comply with the terms, conditions and notices on this site will result in automatic termination of any rights granted to you, without prior notice, and you must immediately destroy all copies of downloaded materials in your possession or control. Except for the limited permission in the preceding paragraph, TalentBridge Technologies Pvt. Ltd., does not grant you any express or implied rights or licenses under any patents, trademarks, copyrights, or other proprietary or intellectual property rights. You may not mirror any of the content from any of its sites on another website or in any other media.</p>
      <p class="RR-16 font-weight-bold text-gray">Certain Disclaimers</p>
      <p class="RR-13">Information on this website is not promised or guaranteed to be correct, current, or complete, and this site may contain technical inaccuracies or typographical errors. TalentBridge Technologies Pvt. Ltd. assumes no responsibility (and expressly disclaims responsibility) for updating this site to keep information current or to ensure the accuracy or completeness of any posted information. Accordingly, you should confirm the accuracy and completeness of all posted information before making any decision related to any services, products or other matters described in this site. TalentBridge Technologies Pvt. Ltd. provides no assurances that any reported problems related to contract will be resolved by TalentBridge Technologies Pvt. Ltd., even if TalentBridge Technologies Pvt. Ltd., elects to provide information with the goal of addressing a problem.</p>
      <p class="RR-16 font-weight-bold text-gray">Confidential Information</p>
      <p class="RR-13">TalentBridge Technologies Pvt. Ltd., does not want to receive confidential or proprietary information from you through any of its websites. Please note that any information or material sent to TalentBridge Technologies Pvt. Ltd., will be deemed NOT to be confidential.</p>
      <p class="RR-16 font-weight-bold text-gray">Business Relationships</p>
      <p class="RR-13">Any or all the websites of TalentBridge Technologies Pvt. Ltd. may provide links or references to other web sites and resources. TalentBridge Technologies Pvt. Ltd. makes no representations, warranties or other commitments whatsoever about any other websites or third-party resources. A link to other website does not mean that TalentBridge Technologies Pvt. Ltd. endorses the content or use of such website or its owner. In addition, TalentBridge Technologies Pvt. Ltd. is not a party to or responsible for any transactions you may enter into with third parties, even if you learn of such parties (or use a link to such parties) from a TalentBridge Technologies Pvt. Ltd.’s sites. Accordingly, you acknowledge and agree that TalentBridge Technologies Pvt. Ltd. is not responsible for the availability of such external sites or resources, and is not responsible or liable for any content, services, products, or other materials on or available from those sites or resources. When you access other website, even one that may contain reference to the TalentBridge Technologies Pvt. Ltd., please understand that it is independent from TalentBridge Technologies Pvt. Ltd., and that TalentBridge Technologies Pvt. Ltd. does not control the content on that website. It is up to you to take precautions to protect yourself from viruses, worms, Trojan horses and other potentially destructive programs, and to protect your information as you deem appropriate.</p>
      <p class="RR-16 font-weight-bold text-gray">Translations</p>
      <p class="RR-13">Certain information including but not limited to texts, audio, video, images or any other format in these websites may be made available in languages other than English. Text may be translated solely by computer software with no human intervention or review. These translations are provided as a convenience to you, and TalentBridge Technologies Pvt. Ltd., makes no representations or commitments regarding the accuracy or completeness of the translation, whether or not computer-generated or performed by a person.</p>
      <p class="RR-16 font-weight-bold text-gray">DISCLAIMER OF WARRANTY</p>
      <p class="RR-13">USE OF ANY OR ALL THE SITES OF TALENTBRIDGE TECHNOLOGIES PVT. LTD. IS AT YOUR SOLE RISK. ALL MATERIALS, INFORMATION, PRODUCTS, SOFTWARE, PROGRAMS AND SERVICES ARE PROVIDED "AS IS," WITH NO WARRANTIES OR GUARANTEES WHATSOEVER. TALENTBRIDGE TECHNOLOGIES PVT. LTD. EXPRESSLY DISCLAIMS TO THE FULLEST EXTENT PERMITTED BY LAW, ALL EXPRESS, IMPLIED, STATUTORY AND OTHER WARRANTIES, GUARANTEES, OR REPRESENTATIONS, INCLUDING, WITHOUT LIMITATION, THE WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, AND NON-INFRINGEMENT OF PROPRIETARY AND INTELLECTUAL PROPERTY RIGHTS. WITHOUT LIMITATION, TALENTBRIDGE TECHNOLOGIES PVT. LTD. MAKES NO WARRANTY OR GUARANTEES THAT ANY OR ALL OF ITS WEBSITES WILL BE UNINTERRUPTED, TIMELY, SECURE OR ERROR-FREE. YOU UNDERSTAND AND AGREE THAT IF YOU DOWNLOAD OR OTHERWISE OBTAIN MATERIALS, INFORMATION, PRODUCTS, SOFTWARE, PROGRAMS, AND SERVICES, YOU DO SO AT YOUR OWN DISCRETION AND RISK AND THAT YOU WILL BE SOLELY RESPONSIBLE FOR ANY DAMAGES THAT MAY RESULT, INCLUDING LOSS OF DATA OR DAMAGE TO YOUR COMPUTER SYSTEM.</p>
      <p class="RR-16 font-weight-bold text-gray">LIMITATION OF LIABILITY</p>
      <p class="RR-13">IN NO EVENT WILL TALENTBRIDGE TECHNOLOGIES PVT. LTD. BE LIABLE TO ANY PARTY FOR ANY DIRECT, INDIRECT, INCIDENTAL, SPECIAL, EXEMPLARY OR CONSEQUENTIAL DAMAGES OF ANY TYPE WHATSOEVER RELATED TO OR ARISING FROM ANY OR ALL OF ITS WEBSITES OR ANY USE OF THE SITES, OR OF ANY SITE OR RESOURCE LINKED TO, REFERENCED OR ACCESSED THROUGH ITS WEBSITES, OR FOR THE USE OR DOWNLOADING OF, OR ACCESS TO, ANY MATERIALS, INFORMATION, PRODUCTS, AND SERVICES, INCLUDING, WITHOUT LIMITATION, ANY LOST PROFITS, BUSINESS INTERRUPTION, LOST SAVINGS, LOSS OF PROGRAMS OR OTHER DATA, EVEN IF ANY WEBSITE IS EXPRESSLY ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. THIS EXCLUSION AND WAIVER OF LIABILITY APPLIES TO ALL CAUSES OF ACTION, WHETHER BASED ON CONTRACT, WARRANTY, TORT OR ANY OTHER LEGAL THEORIES. ADDITIONAL OR DIFFERENT TERMS, CONDITIONS AND NOTICES MAY APPLY TO SPECIFIC MATERIALS, INFORMATION, PRODUCTS, SOFTWARE AND SERVICES OFFERED THROUGH ALL ITS WEBSITES.</p>
    </div>
  </div>
</div>
<app-footer-static></app-footer-static>
