<div class="container-center">
  <div class="box-1">
    <app-carousel></app-carousel>
  </div>

  <div class="box-2">
    <div class="container">
      <div class="Content-center">
     <h5>Session Expired Please Login Again</h5>
     <button  class="btn btn-login mtop30" routerLink="/login"
     >Login</button>
    </div>
    </div>
  </div>
</div>
