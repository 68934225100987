<app-user-header></app-user-header>
<nav class="container-fluid pt-3 pb-3 bd-navbar sub-header">
  <div class="row">
    <div class="col">
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb" style="cursor: pointer">
          <li class="breadcrumb-item active" aria-current="page" routerLink="/assessment/assigned-batches/evaluate"><a>Assigned Batches</a>
          </li>
          <li class="breadcrumb-item inactive" aria-current="page">
            <a>Candidates</a>
          </li>
        </ol>
      </nav>
    </div>
  </div>
</nav>
<br><br><br><br><br><br>
<app-list-of-candidates></app-list-of-candidates>
<!--End of candidate location modal-->
