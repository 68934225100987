import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { RegisterCompComponent, RegistrationSuccessComponent } from './register-comp/register-comp.component';
import { LoginComponent } from './login/login.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { VerifyCompComponent } from './verify-comp/verify-comp.component';
import { JobsComponent} from './jobs/jobs.component';
import { PartnerComponent} from './partner/partner.component';
import { HomeComponent} from './home/home.component';
import { TermsOfUseComponent} from './terms-of-use/termsofuse.component';
import { SecurityPageComponent } from './security-page/security-page.component';
import { FeaturesPageComponent } from './features-page/features-page.component';
import { AssessmentPageComponent } from './assessment-page/assessment-page.component';
import { ProfileComponent } from './profile/profile.component';
import { EditProfileComponent } from './profile/edit-profile/edit-profile.component';
import {AuthGuardService, NonLoggedInState} from './auth-guard.service';
import {PrivacyPolicyComponent} from './privacy-policy/privacy-policy.component';
import {MediaManagerComponent} from './media-manager/media-manager.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import {LogOutComponent} from './log-out/log-out.component';
import { MediaComponent } from './media/media.component';
import { AssessmentsModule } from './assessments-module/assessments.module';
import { UnauthorisedErrorHandleComponent } from './unauthorised-error-handle/unauthorised-error-handle.component';
import { SkillBasedTemplateComponent } from './skill-based-template/skill-based-template.component';
import { SkillBasedTemplateDetailPublicViewComponent } from './skill-based-template/skill-based-template-detail-public-view/skill-based-template-detail-public-view.component';
import { BiReportComponent } from './bi-report/bi-report.component';
const routes: Routes = [
  { path: '',  component: HomeComponent },
  { path: 'home',  component: HomeComponent },
  { path: 'home/:uses', component: HomeComponent },
  { path: 'logout', component: LogOutComponent },
  { path: 'success', canActivate: [NonLoggedInState], component: RegistrationSuccessComponent },
  { path: 'register', canActivate: [NonLoggedInState], component: RegisterCompComponent },
  { path: 'login', canActivate: [NonLoggedInState], component: LoginComponent},
  { path: 'media-manager', component: MediaManagerComponent},
  { path: 'reset-password', component: ForgotPasswordComponent},
  { path: 'verify', component: VerifyCompComponent },
  { path: 'dashboard', canActivate: [AuthGuardService], component: DashboardComponent },
  { path: 'profile', canActivate: [AuthGuardService], component: ProfileComponent },
  { path: 'profile/edit', canActivate: [AuthGuardService], component: EditProfileComponent },
  { path: 'jobs', canActivate: [AuthGuardService],  component: JobsComponent },
  { path: 'assessment', canActivate: [AuthGuardService],
    loadChildren: () => {return AssessmentsModule} },
  { path: 'question', canActivate: [AuthGuardService],
    loadChildren: () => import('./qb-module/qb-module.module').then(m => m.QbModuleModule)},
  { path: 'manage-clients', canActivate: [AuthGuardService],
    loadChildren: () => import('./nsdc-module/nsdc-module.module').then(m => m.NsdcModuleModule)},
  { path: 'manage-users', canActivate: [AuthGuardService],
    loadChildren: () => import('./user-management-module/user-management.module').then(m => m.UserManagementModule)},
  { path: 'manage-invite', canActivate: [AuthGuardService],
    loadChildren: () => import('./invite-management/invite-management.module').then(m => m.InviteManagementModule)},
  { path: 'terms-of-use', component: TermsOfUseComponent},
  { path: 'super_admin', canActivate: [AuthGuardService],
    loadChildren: () => import('./super-admin/superadmin.module').then(m => m.SuperadminModule)},
  { path: 'quick-assessment-templating', canActivate: [AuthGuardService],
    loadChildren: () => import('./super-admin-quick-assessment/super-admin-quick-assessment.module').then(m => m.SuperAdminQuickAssessmentModule)},
  { path: 'privacy-policy', component: PrivacyPolicyComponent},
  { path: 'bi-report', component: BiReportComponent},

  { path: 'session-expired',component:UnauthorisedErrorHandleComponent},
  { path: 'security', component: SecurityPageComponent},
  { path: 'features', component: FeaturesPageComponent},
  { path: 'assessment-types', component: AssessmentPageComponent},
  { path: 'skill-based-assessment',component:SkillBasedTemplateComponent},
  { path: 'skill-based-assessment/:type/:urlSlug',component:SkillBasedTemplateComponent},
  { path: 'skill-based-assessment-template-detail/:urlSlug',component:SkillBasedTemplateDetailPublicViewComponent},
  { path: 'blog',    loadChildren: () => import('./blog/blog.module').then(itt => itt.BlogModule)},
  { path: 'media', component: MediaComponent},
  { path: '**',  component: PartnerComponent},

];

@NgModule({
  imports: [ RouterModule.forRoot(routes) ],
  exports: [ RouterModule ]
})

export class AppRoutingModule {}
