<app-user-header></app-user-header>
<br />
<div class="pt-5 pr-1 pl-1 pb-5">
<iframe
  title="BI_REPORT"
  width="100%"
  height="1000"
  [src]="BIReportUrl"
  frameborder="0"
  allowFullScreen="true"
></iframe>
</div>
