import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'app-footer-static',
  templateUrl: './footer-static.component.html',
  styleUrls: ['./footer-static.component.css']
})

export class FooterStaticComponent implements OnInit {
  @Output() footerMenuSelectionEvent = new EventEmitter<string>();
  cookiesAccepted: boolean = false;
  cookieValue: any;
  constructor(private router: Router , private cookieService: CookieService) { }

  ngOnInit() {
    if(this.cookieService.get('Test')== 'Accepted' || localStorage.getItem('cookieValue') == 'Accepted'){
      this.cookiesAccepted = true;
    }
  }

  scrollToUses(usesTabReference) {
    if (this.router.url.indexOf('/home/') === -1) {
      this.router.navigate(['/home', usesTabReference]);
    } else {
      this.footerMenuSelectionEvent.emit(usesTabReference);
    }
  }
redirectTo(event) {
    if (event === 'facebook') {
      window.open('https://www.facebook.com/CalibratOnline');
    } else if (event === 'linkedin') {
      window.open('https://www.linkedin.com/showcase/Calibrat');
    } else if (event === 'youtube') {
      window.open('https://www.youtube.com/channel/UCbvB3wLdHDMf34yI5YGhuVQ');
    } else if (event === 'twitter') {
      window.open('https://twitter.com/CALIBRAT11');
    }
}

acceptCookies(){
  this.cookiesAccepted = true;
  this.cookieService.set( 'Test', 'Accepted' );
  this.cookieValue = this.cookieService.get('Test');
  localStorage.setItem('cookieValue', this.cookieValue);
}


}
