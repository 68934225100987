import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { TabsetComponent } from 'ngx-bootstrap/tabs';
import { ActivatedRoute } from '@angular/router';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { CookieService } from 'ngx-cookie-service';
declare var Calendly: any;



@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})

export class HomeComponent implements OnInit {

  @ViewChild('usesTabs') usesTabs: TabsetComponent;
  @ViewChild('usesLabel') usesLabel: ElementRef;
  @ViewChild('liveDemos') liveDemos: ModalDirective;

  selectedUsesTab = 0;
  imageObject: Array<object> = [{
    thumbImage: 'assets/img/dynpro.png',
    alt: 'Dynpro using calibrat'
  }, {
    thumbImage: 'assets/img/csg.jpg', // Support base64 image
    alt: 'Csg using calibrat'
  }, {
    thumbImage: 'assets/img/Eurofins.jpg', // Support base64 image
    alt: 'Eurofins using calibrat'
  }, {
    thumbImage: 'assets/img/Hssc.jpg', // Support base64 image
    alt: 'HSSC using calibrat'
  }, {
    thumbImage: 'assets/img/Iris-Corp.jpg', // Support base64 image
    alt: 'Iris-Corp using calibrat'
  }, {
    thumbImage: 'assets/img/Magna.jpg', // Support base64 image
    alt: 'Magma using calibrat'
  }, {
    thumbImage: 'assets/img/Mphasis.jpg', // Support base64 image
    alt: 'Mphasis using calibrat'
  }, {
    thumbImage: 'assets/img/Rasci.png', // Support base64 image
    alt: 'Rasci using calibrat'
  }, {
    thumbImage: 'assets/img/Tssc.png', // Support base64 image
    alt: 'Tssc using calibrat'
  }, {
    thumbImage: 'assets/img/cyclepure.png', // Support base64 image
    alt: 'Cyclepure using calibrat'
  }, {
    thumbImage: 'assets/img/unisys.png', // Support base64 image
    alt: 'unisys using calibrat'
  }, {
    thumbImage: 'assets/img/oberoi.png', // Support base64 image
    alt: 'Oberoi using calibrat'
  }, {
    thumbImage: 'assets/img/aima.png', // Support base64 image
    alt: 'Aima using calibrat'
  }, {
    thumbImage: 'assets/img/Ajooni-Skills-India-Pvt-Ltd.jpg', // Support base64 image
    alt: 'Ajooni Skills India using calibrat'
  }, {
    thumbImage: 'assets/img/Avanti-Bai-Lodhi-Society.jpg', // Support base64 image
    alt: 'Avanti Bai Lodhi Society using calibrat'
  }, {
    thumbImage: 'assets/img/CEE-Vision-Technologies-Pvt-Ltd.jpg', // Support base64 image
    alt: 'CEE Vision Technologies using calibrat'
  }, {
    thumbImage: 'assets/img/Courses-Intelligence-Pvt-Ltd.png', // Support base64 image
    alt: 'Courses Intelligence Private Limited using calibrat'
  }, {
    thumbImage: 'assets/img/Fineline-Skills-Pvt-Ltd.png', // Support base64 image
    alt: 'Fineline Skills Pvt Ltd using calibrat'
  }, {
    thumbImage: 'assets/img/Palmary-Project-N-Services-Pvt-Ltd.jpg', // Support base64 image
    alt: 'Palmary Project & Services Pvt  Ltd using calibrat'
  }, {
    thumbImage: 'assets/img/Peepal-Technology-and-Management-Consulting-Pvt-Ltd.jpg', // Support base64 image
    alt: 'Peepal Technology and Management Consulting Pvt Ltd using calibrat'
  }, {
    thumbImage: 'assets/img/Pure-Wow-Media-Center-Pvt-Ltd.png', // Support base64 image
    alt: 'Pure Wow Media Center Pvt Ltd using calibrat'
  }, {
    thumbImage: 'assets/img/Sanskriti-Skills-Pvt-Ltd.jpg', // Support base64 image
    alt: 'Sanskriti Skills Pvt Ltd using calibrat'
  }, {
    thumbImage: 'assets/img/Six-Dee-Telecom-Solutions-Pvt-Ltd.png', // Support base64 image
    alt: 'Six Dee Telecom Solutions Pvt Ltd using calibrat'
  }, {
    thumbImage: 'assets/img/Skillsonics-India-Pvt-Ltd.jpg', // Support base64 image
    alt: 'Skillsonics India Pvt Ltd using calibrat'
  }, {
    thumbImage: 'assets/img/SP-Institute-of-Workforce-Development-Pvt-Ltd.jpg', // Support base64 image
    alt: 'SP Institute of Workforce Development Pvt Ltd using calibrat'
  }, {
    thumbImage: 'assets/img/Trident-Jaipur.jpg', // Support base64 image
    alt: 'Trident, Jaipur using calibrat'
  }

  ];
  imageStyling = {
    height: '10%',
    space: 10,
  };

  awardObject: Array<object> = [{
    thumbImage: 'assets/img/downloads.png',
  }, {
    thumbImage: 'assets/img/awd-hot.png', // Support base64 image
  } , {
    thumbImage: 'assets/img/cio-review.png', // Support base64 image
    alt: 'CIO review for Calibrat'
  } , {
    thumbImage: 'assets/img/silicon.png', // Support base64 image
    alt: 'Calibrat product mention in Silicon Review'
  }
  ];
  awardStyling = {
    // width: '40%',
    // height: '30%',
    space: 100,
  };
  cookiesAccepted: boolean = false;


  // testimonialObj: Array <object> = [{
  //   thumbImage: 'assets/img/Hsscs.jpg', // Support base64 image
  //   alt: 'alt of image',
  //   title: 'title of image'
  //    // video:'https://www.youtube.com/watch?v=SVjkxdjivts&list=RDSVjkxdjivts&start_radio=1'
  // }]


  constructor(private route: ActivatedRoute , private cookieService: CookieService) {}

  ngOnInit() {
    if( localStorage.getItem('cookieValue') == 'Accepted'){
      this.cookiesAccepted = true;
    }
    const usesRef = this.route.snapshot.params['uses'];
    setTimeout(() => {
      this.setFocusToUsesTab(usesRef);
    }, 300);
  }

  setSelectedUsesTab(selectedUsesTabIndex) {
    this.selectedUsesTab = selectedUsesTabIndex;
  }

  selectTab (tab_id: number) {
    this.usesTabs.tabs[tab_id].active = true;
  }

  onProductSelection($tabRef) {
    this.setFocusToUsesTab($tabRef);
  }
  setFocusToUsesTab(setFocusToTab) {
    if (setFocusToTab === 'Fresher-Lateral-Screening') {
      this.selectedUsesTab = 0;
      this.usesTabs.tabs[0].active = true;
    } else if (setFocusToTab === 'Campus-Screening') {
      this.selectedUsesTab = 1;
      this.usesTabs.tabs[1].active = true;
    } else if (setFocusToTab === 'Employee-Assessment') {
      this.selectedUsesTab = 2;
      this.usesTabs.tabs[2].active = true;
    } else if (setFocusToTab === 'Entrance-Examination') {
      this.selectedUsesTab = 3;
      this.usesTabs.tabs[3].active = true;
    } else if (setFocusToTab === 'Skill-India') {
      this.selectedUsesTab = 4;
      this.usesTabs.tabs[4].active = true;
    } else {
      setFocusToTab = '';
    }
    if (setFocusToTab !== '') {
      const yPosition = document.getElementById('usesLabel').offsetTop;
      window.scrollTo(0, yPosition);
    }
  }
  scheduleDemo() {
    Calendly.initPopupWidget({url: 'https://calendly.com/calibratassessment/30min?primary_color=00b3d4%27'});
    return false;
  }

}
