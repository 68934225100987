import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import 'rxjs/add/observable/throw';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class MediaManagerService {
  private headers = new HttpHeaders({
    'Content-Type': 'application/json'
  });
  private url = '' ;
  private localhost  = 'localhost';

  constructor(private http: HttpClient) { }

  uploadDocumentsService(accessToken, payload: any): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + accessToken
    });
    this.url = 'https://0i6g34u2ji.execute-api.ap-southeast-1.amazonaws.com/development/uploadcandidatereferencefile';
    return this.http.post(this.url, payload, {headers: headers}).pipe(
      map( response => response),
      catchError(this._errorHandler)
    );
  }

  _errorHandler(error: HttpErrorResponse) {
    console.error(error);
    return throwError(error || 'Server Error');
  }
}
