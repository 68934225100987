import { Component, OnInit, ViewChild } from "@angular/core";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { Router } from "@angular/router";
import { NgxUiLoaderService } from "ngx-ui-loader";
import { ToastrService } from "ngx-toastr";
import { SuperadminQbService } from "../superadmin-qb.service";
import { ArchiveCatModalComponent } from "../archive-cat-modal/archive-cat-modal.component";

@Component({
  selector: "app-superadmin-create-cat-subcat",
  templateUrl: "./superadmin-create-cat-subcat.component.html",
  styleUrls: ["./superadmin-create-cat-subcat.component.css"],
})
export class SuperadminCreateCatSubcatComponent implements OnInit {
  dropDownMenu: boolean = false;
  gobackIcon: boolean = true;
  searchText1: string = "";
  searchText: string = "";
  CategoryCount: number = 0;
  totalCategoryCount: number = 0;
  searchTextTag: string = "";
  qbCategoryList: any = [];
  isLoadmore: boolean = false;
  noDataFoundFlag: boolean = false;
  includeArchieved: boolean = false;
  includeSubCategories: boolean = false;
  searchFlag: boolean = false;
  skip: number = 0;
  openCategoryDiv: boolean = true;
  parentCategoryForm: FormGroup;
  selectedTags = [];
  errorMessage: string = "";
  dropdownList = [];
  selectedItems = [];
  dropdownSettings = {};
  QBDashboard: boolean = false;
  noQuestionDiv: boolean = true;
  openSubcategoryDiv: boolean = true;
  noCategoryDiv: boolean = true;
  parentCategoryname = "";
  subCategoryForm: FormGroup;
  parentId: string;
  qbCategoryList1 = [];
  accessToken: any;
  companyId: any;
  categoryID: any;
  loadMoreArray: any = [];
  assessmentType: string;
  createFlag:boolean=false;
  constructor(
    private router: Router,
    private SuperadminQbServiceObj: SuperadminQbService,
    private toastrService: ToastrService,
    private fb: FormBuilder,
    private ngxService: NgxUiLoaderService,
    private ArchiveModalComponentObj:ArchiveCatModalComponent
  ) {
    this.accessToken = localStorage.getItem("accessToken");
    this.companyId = localStorage.getItem("companyId");
    this.parentCategoryForm = new FormGroup({
      categoryName: new FormControl("", [
        Validators.required,
        Validators.pattern("^[ A-Za-z0-9_@.+-]*$"),
        Validators.minLength(1),
        Validators.maxLength(101),
      ]),
      skillsField: new FormControl("", [Validators.required]),
    });
    this.subCategoryForm = new FormGroup({
      categoryName: new FormControl("", [Validators.required]),
      subCatName: new FormControl("", [
        Validators.required,
        Validators.pattern("^[ A-Za-z0-9_@.+-]*$"),
        Validators.minLength(1),
        Validators.maxLength(101),
      ]),
      tagsField: new FormControl(""),
    });
  }

  async ngOnInit() {

    this.skip=0;
    this.qbCategoryList=[];
    const activeScreenRef = localStorage.getItem('localstorageValue');
    const NoQuestionDiv = localStorage.getItem('NoQuestionDiv');
    if (activeScreenRef === 'categoryCreation') {
      this.QBDashboard = true;
      this.noCategoryDiv = true;
      this.noQuestionDiv = true;
      this.openSubcategoryDiv = true;
      this.openCategoryDiv = false;
      this.gobackIcon = false;
      this.dropDownMenu = true;
    } else if (activeScreenRef === 'subCategoryCreation') {
      this.QBDashboard = true;
      this.noCategoryDiv = true;
      this.noQuestionDiv = true;
      this.openSubcategoryDiv = false;
      this.openCategoryDiv = true;
      this.gobackIcon = false;
      this.dropDownMenu = true;
      await this.listOfCategoryName();
    }else if(NoQuestionDiv==='openNoQuestionDiv'){
      this.QBDashboard = true;
      this.noCategoryDiv = true;
      this.noQuestionDiv = false;
      this.openSubcategoryDiv = true;
      this.openCategoryDiv = true;
      this.gobackIcon = false;
      this.dropDownMenu = true;
    }
    else {

      this.QBDashboard = false;
      this.noCategoryDiv = true;
      this.noQuestionDiv = true;
      this.openSubcategoryDiv = true;
      this.openCategoryDiv = true;
      this.gobackIcon = false;
      this.dropDownMenu = true;
      await this.listOfCategory();
    }
    this.dropdownSettings = {
      singleSelection: false,
      text: "Select Skills",
      selectAllText: "Select All",
      unSelectAllText: "UnSelect All",
      enableSearchFilter: true,
      classes: " myclass custom-class ",
      maxHeight: 100,
      autoPosition: false,
    };
    this.parentCategoryForm = new FormGroup({
      categoryName: new FormControl("", [
        Validators.required,
        Validators.pattern("^[ A-Za-z0-9_@.+-]*$"),
        Validators.minLength(1),
        Validators.maxLength(101),
      ]),
      skillsField: new FormControl("", [Validators.required]),
    });
    this.subCategoryForm = new FormGroup({
      categoryName: new FormControl("", [Validators.required]),
      subCatName: new FormControl("", [
        Validators.required,
        Validators.pattern("^[ A-Za-z0-9_@.+-]*$"),
        Validators.minLength(1),
        Validators.maxLength(101),
      ]),
      tagsField: new FormControl(""),
    });
    await this.getSkillSet();

  }

  get parentName() {
    return this.parentCategoryForm.get("categoryName");
  }

  get subCatListName() {
    return this.subCategoryForm.get("subCatName");
  }

  localReference() {
    localStorage.removeItem("redirectValue1");
    const localReferenceValue = localStorage.getItem("redirectValue");
    if (
      localReferenceValue === "QuestionListing" ||
      localReferenceValue === "QuestionDetails"
    ) {
      this.router.navigate(["question/question-listing-detailing"]);
    } else {
      localStorage.setItem("redirectValue", "QbDashboard");
    }
    this.router.navigate(["question/modal"]);
    // localStorage.removeItem("NoQuestionDiv");
  }

  redirectToQuestionList(list) {
    this.parentCategoryname = list.parentCategoryName;
    this.categoryID=list.parentCategoryId;
    localStorage.setItem("categoryID", this.categoryID);
    localStorage.setItem('categoryName',  this.parentCategoryname );


    // if (list.categoryQuestionCount === 0) {

    //   this.QBDashboard = true;
    //   this.noCategoryDiv = true;
    //   this.openCategoryDiv = true;
    //   this.openSubcategoryDiv = true;
    //   this.noQuestionDiv = false;
    //   this.gobackIcon = false;
    //   localStorage.setItem("redirectValue", "QuestionListing");
    //   localStorage.setItem("NoQuestionDiv", "openNoQuestionDiv");
    // } else {
    localStorage.setItem("redirectValue", "QuestionListing");
    localStorage.setItem("localstorageValue","QuestionListing")
    localStorage.removeItem("questionID");
    localStorage.removeItem("redirectValue1");
    this.router.navigate(["super_admin/questionbank/questionlisting"]);
    // }
  }

  openSubCatDiv() {
    let radBtnDefault = (document.getElementById("subCatTechnical")as HTMLInputElement|null);
    radBtnDefault.checked = true;
    this.createFlag=true;
    this.openSubcategoryDiv = false;
    this.openCategoryDiv = true;
    this.QBDashboard = true;
    this.gobackIcon = false;
    this.subCategoryForm.reset();
    this.noQuestionDiv = true;

    const localValue = localStorage.getItem('redirectValue');

    if (localValue === 'QuestionListing' || localValue === 'QuestionDetails') {
    } else {

      localStorage.setItem('redirectValue', 'QbDashboard');
    }
    this.clearQbList();
    this.listOfCategoryName();
  }



  openNewCatDiv() {
    this.createFlag=true;
    this.openCategoryDiv = false;
    this.QBDashboard = true;
    this.openSubcategoryDiv = true;
    this.gobackIcon = false;
    this.parentCategoryForm.reset();
    this.noQuestionDiv = true;
    let radBtnDefault = (document.getElementById("categoryTechnical")as HTMLInputElement|null);
    radBtnDefault.checked = true;
    const localValue = localStorage.getItem('redirectValue');
    if (localValue === 'QuestionListing' || localValue === 'QuestionDetails') {

    } else {
      localStorage.setItem('redirectValue', 'QbDashboard');

    }
    this.clearQbList();
    this.getSkillSet();
  }

  landingDashBorad(data) {
    this.createFlag=false;
    this.qbCategoryList = [];
    this.selectedTags.length = 0;
    localStorage.removeItem('localstorageValue');
    const localValue = localStorage.getItem('redirectValue');
    const NoQuestionDiv = localStorage.getItem('NoQuestionDiv');
    if (data === 'goBack' && this.noQuestionDiv === false) {
      localStorage.removeItem('NoQuestionDiv');
      this.listOfCategory();
      this.clearFormData();
    }
    if(data==='cancele' && localValue=='subCategory'){
      localStorage.setItem('redirectValue','subCategory');

      this.router.navigate(['super_admin/questionbank/subcategory']);

    }
    if (NoQuestionDiv === 'openNoQuestionDiv') {
      this.ngxService.start();
      this.parentCategoryname = localStorage.getItem('categoryName');
      this.QBDashboard = true;
      this.noCategoryDiv = true;
      this.openSubcategoryDiv = true;
      this.openCategoryDiv = true;
      this.noQuestionDiv = false;
      this.gobackIcon = false;
      this.dropDownMenu = true;
    } else if (localValue === 'QuestionListing' || localValue === 'QuestionDetails') {
      localStorage.setItem('redirectValue','QuestionListing');
      this.router.navigate(['super_admin/questionbank/questionlisting']);
    } else {
      this.listOfCategory();
      this.clearFormData();
    }
    this.ngxService.stop();
  }
  clearFormData() {
    // this.subCategoryForm.reset();
    this.parentCategoryForm.reset();
  }

  searchQB() {
    if (this.searchText1.length < 1) {
      return;
    }
    if (!this.searchText1.replace(/\s/g, "").length) {
      this.searchText1 = "";
      return;
    }
    if (
      this.searchText1.charAt(0) == " " ||
      this.searchText1.charAt(this.searchText1.length - 1) == " "
    ) {
      this.searchText1 = this.searchText1.trim();
    }

    this.searchText = this.searchText1;
    if (!this.searchFlag && this.searchText != "") {
      this.skip = 0;
      this.searchFlag = true;
    }
    this.ngxService.start();
    this.noDataFoundFlag = false;
    this.qbCategoryList = [];
    this.listOfCategory();
    this.searchTextTag = this.searchText;
    this.searchText1 = "";
  }

  loadmoreCategory(event: any) {
    if (event.pointerType != "mouse") {
      return;
    }

    this.skip++;

    this.listOfCategory();
  }

  clearQbList() {
    this.qbCategoryList = [];
    this.loadMoreArray = [];

    this.errorMessage = null;
  }

  listOfCategory() {
    this.ngxService.start();
    const payload = {
      searchText: this.searchText,
      includeArchived: this.includeArchieved,
    };
    this.SuperadminQbServiceObj.getCategoryList(
      this.accessToken,
      payload,
      this.companyId,
      this.skip
    )
      .toPromise()
      .then(
        (response: any) => {
          this.loadMoreArray = response.listOfCategory;
          this.dropDownMenu = true;
          if (response.isMoreCategory === false) {
            this.skip = 0;
          }

          this.qbCategoryList = this.qbCategoryList.concat(this.loadMoreArray);

          this.isLoadmore = response.isMoreCategory;
          this.CategoryCount = this.qbCategoryList.length;
          this.totalCategoryCount = response.totalCategoryCount;

          this.defaultValueInitialisation();
          this.noCategoryDiv = true;
          this.ngxService.stop();
        },
        (error: any) => {
          const text = error.error.message;
          if (
            error.error["message"] === "No matching category found" &&
            this.searchText != ""
          ) {
            this.noDataFoundFlag = true;
            this.noCategoryDiv = false;
            this.CategoryCount = 0;
            this.isLoadmore = false;
            // this.totalCount=''
            this.ngxService.stop();
            return;
          } else if (
            error.error["message"] === "You have not created a category"
          ) {
            this.noCategoryDiv = false;
            this.CategoryCount = 0;
            this.isLoadmore = false;
            this.noDataFoundFlag = false;
            this.ngxService.stop();
          }


        }
      );


  }

  resetSearch() {
    // const input = document.getElementById('chkArchieved') as HTMLInputElement | null;
    // input.checked=false;
    this.ngxService.start();
    this.searchText = "";

    this.skip = 0;
    this.qbCategoryList = [];
    this.listOfCategory();
    this.noDataFoundFlag = false;
    this.searchTextTag = "";
    this.searchFlag = false;
  }

  onItemSelect(item: any) {
    this.selectedTags.push(item.itemName);
  }

  OnItemDeSelect(item: any) {
    let index;
    for (let i = 0; i < this.selectedTags.length; i++) {
      if (item.itemName === this.selectedTags[i]) {
        index = this.selectedTags.indexOf(item.itemName);
        this.selectedTags.splice(index, 1);
      }
    }
  }

  onSelectAll(items: any) {
    for (let i = 0; i < this.dropdownList.length; i++) {
      this.selectedTags.push(this.dropdownList[i].itemName);
    }
  }

  onDeSelectAll(items: any) {
    this.selectedTags = [];
  }

  addnewCategory(formdata) {
    var input = document.querySelector(
      'input[name="categoryType"]:checked'
    ) as HTMLInputElement | null;

    this.ngxService.start();
    const payload = {
      assessmentType: input.value,
      categoryName: formdata.categoryName,
      skills: this.selectedTags,
    };

    this.SuperadminQbServiceObj.addCategory(
      payload,
      this.companyId,
      this.accessToken
    ).subscribe(
      (response) => {
        this.listOfCategory();
        this.toastrService.success("Category created successfully", "Success");
        this.openCategoryDiv = true;
        this.QBDashboard = false;
        this.openSubcategoryDiv = true;
        this.createFlag=false;
        this.ngxService.stop();
      },
      (error) => {
        this.errorMessage = error.error.message;
        this.ngxService.stop();
        this.createFlag=false;
      }
    );
  }

  async getSkillSet() {
    this.ngxService.start();
    this.dropdownList = [];
    const payload = {
      type: "skills",
      assessmentType: "",
    };
    var skillset = [];
    await this.SuperadminQbServiceObj.fetchSkillset(payload)
      .toPromise()
      .then(
        (response) => {
          let obj: { id: number; itemName: any };
          for (let i = 0; i < response.length; i++) {
            obj = {
              id: i + 1,
              itemName: response[i].name,
            };

            skillset.push(obj);
          }
          this.ngxService.stop();
        },

        (error) => {
          console.log(error);
          this.ngxService.stop();
        }
      );
    this.dropdownList = skillset;
  }

  addSubCategory(formData) {
    let input = document.querySelector(
      'input[name="assesstype"]:checked'
    ) as HTMLInputElement | null;

    this.ngxService.start();
    const payload = {
      parentCategoryId: this.parentId,
      assessmentType: input.value,
      subCategory: [
        {
          categoryName: formData.subCatName,
          Skills: this.selectedTags,
        },
      ],
    };

    this.SuperadminQbServiceObj.addCategory(
      payload,
      this.companyId,
      this.accessToken
    ).subscribe(
      (response) => {
        this.listOfCategory();

        this.toastrService.success(
          "Subcategory created successfully",
          "Success"
        );
        localStorage.removeItem("localstorageValue");
        this.ngxService.stop();
        this.openCategoryDiv = true;
        this.QBDashboard = false;
        this.openSubcategoryDiv = true;
        this.createFlag=false;
      },
      (error) => {
        this.errorMessage = error.error.message;
        this.ngxService.stop();
        this.createFlag=false
      }
    );
  }

  parentCatId(parentCategoryId) {
    this.parentId = parentCategoryId;
    this.categoryWiseSkill();
  }

  async categoryWiseSkill() {
    this.dropdownList = [];
    this.selectedItems = [];
    let obj;
    var skillset = [];
    const payload = { categoryId: this.parentId };
    await this.SuperadminQbServiceObj.getSkillSetCategoryWise(
      this.accessToken,
      payload,
      this.companyId
    )
      .toPromise()
      .then(
        (response) => {
          for (let i = 0; i < response[0].skills.length; i++) {
            obj = {
              id: i,
              itemName: response[0].skills[i],
            };

            skillset.push(obj);
          }
          this.dropdownList = skillset;
        },
        (error) => {
          console.log(error);
        }
      );
  }



  listOfCategoryName() {
    var assessType;
    var node=(document.getElementsByName('assesstype'))as NodeListOf<HTMLInputElement>|null;
    if(node[0].checked==true) {
      assessType='Aptitude'
    } else if(node[1].checked==true) {
      assessType="Technical";
    }

    this.ngxService.start();
    const payload = {
      talentBridgeQuestions: false,
      assessmentType: assessType
    };

    this.SuperadminQbServiceObj.categoryList1(
      payload,
      this.companyId,
      this.accessToken
    ).subscribe(
      (response) => {
        this.qbCategoryList1 = response.listOfCategory;
        this.ngxService.stop();
      },
      (error) => {
        this.ngxService.stop();
      }
    );
  }

  defaultValueInitialisation() {
    this.QBDashboard = false;
    this.noCategoryDiv = true;
    this.openCategoryDiv = true;
    this.openSubcategoryDiv = true;
    this.noQuestionDiv = true;
    this.gobackIcon = true;
    this.parentId = "";
    this.errorMessage = null;
    this.subCategoryForm.reset();
    this.parentCategoryForm.reset();
  }

  redirectTosubcategory(){
    this.noQuestionDiv=true;

    localStorage.setItem('redirectValue','subCategory')
    this.router.navigate(['super_admin/questionbank/subcategory']);
  }

  openarchiveModal(status:any,Id?:any){

    if(Id===undefined){
      Id=localStorage.getItem('categoryID');
    }
    localStorage.setItem('categoryFlag','false');
    this.ArchiveModalComponentObj.showModal(status,Id,'category');


  }

  openEditCat(){

    localStorage.setItem('localstorageValue','editCategory');
    localStorage.setItem('redirectValue','QuestionListing')
    this.router.navigate(['/super_admin/questionbank/questionlisting']);

  }
}
