import { Component } from '@angular/core';
import { LoginService } from '../login/login.service';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-bi-report',
  templateUrl: './bi-report.component.html',
  styleUrls: ['./bi-report.component.css']
})
export class BiReportComponent {
  BIReportUrl:any;
  constructor(protected _sanitizer: DomSanitizer){

  }

  ngOnInit(){
    this.BIReportUrl = this._sanitizer.bypassSecurityTrustResourceUrl(localStorage.getItem('biReportURL'));
  }

}
