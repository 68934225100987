import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';
import { throwError} from 'rxjs';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import 'rxjs/add/observable/throw';
import { environment } from '../../environments/environment';

@Injectable()
export class DashBoardService {

  private url = '';
  private localhost = 'localhost';
  globalUrl = environment.serverUrl;

  constructor(private http: HttpClient ) { }

  uploadDocumentsService(companyId, accessToken, payload: any, file: FileList): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'bearer ' + accessToken
    });
    this.url = this.globalUrl + '/business/services/company/upload/companylogo?companyId=' + companyId;
    return this.http.post(this.url, payload, {headers: headers}).pipe(
      map( response => response),
      catchError(this._errorHandler)
    );
  }

  changeProfileDetails(payload, companyId, accessToken): Observable<any> {

    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'bearer ' + accessToken
    });

    this.url = this.globalUrl + '/business/services/company/resetcompanydetails?companyId=' + companyId;
    return this.http.post(this.url, payload, {headers: headers}).pipe(
      map( response => response),
      catchError(this._errorHandler)
    );

  }
 getRecords(accessToken , companyId ) {
   const headers = new HttpHeaders({
     'Content-Type': 'application/json',
     'Authorization': 'bearer ' + accessToken
   });

   this.url = this.globalUrl + '/business/services/user/nsdc/request-access?companyId=' + companyId;
   return this.http.post(this.url, {}, {headers: headers}).pipe(
     map( response => response),
     catchError(this._errorHandler)
   );
 }
  _errorHandler(error: HttpErrorResponse) {
    return throwError(error || 'Server Error');
  }
}
