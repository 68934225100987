import { Component, OnInit, ViewChild } from "@angular/core";
import {
  FormControl,
  FormGroup,
  Validators,
  Form,
  NgForm,
} from "@angular/forms";
import { AssessmentService } from "../assessment.service";
import {
  NgGrid,
  NgGridItem,
  NgGridConfig,
  NgGridItemConfig,
} from "angular2-grid";
import { Location } from "@angular/common";
import { ActivatedRoute, Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { ModalDirective } from "ngx-bootstrap/modal";
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { I } from "@angular/cdk/keycodes";
declare let myExtObject: any;
declare let $: any;
declare let CKEDITOR: any;

interface Box {
  id: string;
  data: Array<any>;
  sectionName: string;
  questionsToBeAnswered: number;
  totalNumberOfQuestions: number;
  scorePerQuestion: number;
  hasOptionalQuestions: boolean
}

export interface NgGridItemEvent {
  payload: any;
  col: number;
  row: number;
  sizex: number;
  sizey: number;
  width: number;
  height: number;
  left: number;
  top: number;
}

@Component({
  selector: "app-create-assessment",
  templateUrl: "./create-assessment.component.html",
  styleUrls: ["./create-assessment.component.css"],
  providers: [AssessmentService],
})
export class CreateAssessmentComponent implements OnInit {
  modalLink: FormGroup;
  byLink: Boolean = false;
  setWizard1: Boolean = true;
  setWizard2: Boolean = false;
  setWizard3: Boolean = false;
  setWizard4: Boolean = false;
  linkWizard: Boolean = false;
  linkPrivate: Boolean = false;
  linkPublic: Boolean = true;
  step1: Boolean = true;
  step2: Boolean = false;
  step3: Boolean = false;
  assessmentTypeList: any = [];
  durationList: any = [];
  companyId: any;
  accessToken: any;
  durations = "";
  fillInTheBlanksQuesn = '';
  totalDurationTest: any;
  totalDuration: any;
  checkDuration: any;
  publicTags = "";
  publicBlockIpAddress = "";
  publicStartDate = "";
  publicInviteName = "";
  publicStartTime = "";
  publicEndDate = "";
  publicEndTime = "";
  publicLinkcreateion = "";
  privateInviteName = "";
  privateBlockIpAddress = "";
  privateTags = "";
  privateStartDate = "";
  privateStartTime = "";
  privateEndDate = "";
  privateEndTime = "";
  privateAuthanticationOTP: Boolean = false;
  privateMessage = "";
  privateSubject = "";
  proctor = "";
  modalResetCandidate: FormGroup;
  inviteCheck: Boolean = false;
  customFieldErr: Boolean;
  maxQuestErr: Boolean;
  private boxes: Array<Box> = [];
  section = 1;
  checkForEdit: Boolean;
  psychometricEdit: Boolean;
  AssessmentName: any;
  AssessmentType: any;
  curNum: any;
  index: any;
  removeSecData: any = [];
  deleteGrid: Boolean = false;
  deleteCount: any = [];
  tempArr2: any = [];
  assessmentName: any;
  assesmentNameError = true;
  assesmentNameErr: any;
  totalNumQuestion: any;
  nodeValue: any = [];
  dupQstSetSelectionErr: any;
  editSectionData: any;
  data: any = [];
  editAssesmentById: any;
  assessmentType: any;
  percentageMarks = "";
  isValidPercentageMarks = false; // Used to enable or disable next button for 'Basic Info' tab
  showPassingScoreErr = false; // Used in template to show error message below 'Passing Score' field
  assessmentDescription: any;
  shuffleQuestions: Boolean = true;
  disMaxMarks: Boolean = true;
  enableAutoResume: Boolean = false;
  displayCatSubCat: Boolean = false;
  shuffleOptions: Boolean = true;
  attemptAll: any;
  moveForwardOnly: any;
  recordSessioninAudio: any;
  negativeMarking: Boolean = false;
  Penalty = "";
  psychometerAssessment = "";
  allowSuspiciosEnable: Boolean = false;
  allowsuspiciosCount = "";
  autoResumeAttemptCount = "";
  allowresetEnable: any;
  allowresetCount: any;
  mainHistoryEnable: any;
  mainHistoryCount: any;
  instructForTest: Boolean = true;
  instructForTestValue = "<p>Instruction not included</p>";
  afterTestMessage: Boolean = true;
  afterTestMessageValue = "<p>Thank you for taking assessment</p>";
  redirectToWeb: any;
  redirectToWebValue: any;
  subCatErr: Boolean;
  subCategoryList: any = [];
  subCatLen: any;
  privateInviteStatus: String = "Active";
  publicInviteStatus: String = "Active";
  index1: any;
  index2: any;
  index3: any;
  index4: any;
  errorMsg: any;
  name2: any;
  showPreviewAssessment: Boolean;
  publicLinkCreation: Boolean;
  privateLinkCreationStep1: Boolean;
  privateLinkCreationStep2: Boolean;
  listErrMessage: any;
  showAssessemt: any;
  assessmentDescriptionDash: Boolean;
  publicAuthanticationOTP: Boolean = false;
  candidateProctor: Boolean;
  step2Complete: Boolean = false;
  TrackLocationForLink: Boolean = false;
  IDProofForLink: Boolean = false;
  candidateAttendanceLink: Boolean = false;
  candidateSnapshotForLink: Boolean = false;
  candidatesDetail = {
    candidatesName: "",
    email: "",
    phoneNo: "",
    countryCode: "",
  };
  candidatesDetails = [this.candidatesDetail];
  subCatDetails = [{ categories: [] }];
  isRequestInProcess: Boolean = false;
  editCandidates: Boolean;
  skipforLinkList = 0;
  categoryListData = [];
  listOfLinkForAssessment: Boolean;
  enableAttendence: Boolean = false;
  createAssessmentForm: FormGroup;
  trackGeoLocation: Boolean = false;
  candidateSnapshot: Boolean = false;
  assementDashboardShow = [];
  configMoveForwardOnly: Boolean;
  checkJobValue: Boolean = false;
  countryCode: any;
  errorMessage: Boolean;
  candidatesADD: Boolean;
  addprivate: Boolean = false;
  jobID = "";
  getAssementList = [];
  isLoading: Boolean;
  createdassessmentId = "";
  assessmentTypeForLoadMore;
  editId: any;
  assessmentList1: Boolean;
  assessmentListOfCandidates: Boolean;
  assessmentData: Boolean;
  asseementQuestion: Boolean;
  disableSaveExit: Boolean;
  showSectionQuestion: Boolean;
  assessmentConfigurationDash: Boolean;
  assessmentCandidatesDetails: Boolean;
  assessmentDashMessage: Boolean;
  checkForPsychometric: Boolean = false;
  companyType: any;
  publicerrorMsg: any;
  typeOfQuestion: any = [];
  levelOfQuestion: any = [];
  maximumQuestion: any;
  questionCount: any;
  obj2: any;
  nextErr: any;
  penalityList: any = [];
  questionAnswerSave: any = [];
  customeField = {
    parentCategoryId: "",
    categoryId: "",
    assessment: "",
    categoryName: "",
    subCategoryName: "",
    questionType: "",
    difficulty: "",
    numberOfQuestions: "",
  };
  customFields = [this.customeField];
  configDurationData = [{ data: 0 }];
  configCutOffData = [{ data: 0 }];
  configInstructionData = [{ data: "" }];
  sectionGridName = [{ data: false }];
  configQuestionViewPreference = [{ data: 'default' }];
  toggleChecked: boolean[] = new Array(this.configQuestionViewPreference.length).fill(false);
  zeroentered: Boolean;
  previewOfAssessmentList: any = [];
  languages: string;
  editedAssess: Boolean = false;
  editAssessmentthroughID = "";
  multipleDelete: boolean;
  skip: any = 0;
  listofLinkDetails: any = [];
  CandidateReportDetails: boolean;
  psychometerPreview: Boolean;
  listMessage: boolean;
  createdassessmentType = "";
  createdcreatedOn = "";
  categoryListLoaded: boolean = false;
  private = "private";
  easy = "EASY";
  medium = "MEDIUM";
  hard = "HARD";
  option1 = "1";
  option2 = "2";
  option3 = "3";
  option4 = "4";
  @ViewChild("TestCodeLink") TestCodeLink: ModalDirective;
  questionErr: Boolean = false;
  totalNumDoSelectQuestion: any;
  sampletestss: any = [];
  forOnlineCompiler: Boolean = false;
  testname: any;
  testlevel: any;
  durationOfTest: any;
  onlineDuration: any;
  onlineCutoff: any;
  onlineLevel: any;
  getDoSelectTest: any = [];
  getDoSelectEdit: any = [];
  selectTest: any = [];
  getDoSelectQuesName: any = [];
  onlineSection: any = [];
  getDoSelectCutoff: any = [];
  getDoSelectTestDuration: any = [];
  onlineTotalQues: any;
  onlinecomp: any;
  onlineSlug: any;
  ErrorFlag: boolean = false;
  optionalQueFlag: boolean = false;


  totalQuestionCountForSection: number = 0;
  questionsToBeAnsweredForSection: number

  //check weather there is assessment type in localstorage or not
  checkForType: boolean = false;

  sectionDetail: any = {
    id: "",
    sectionName: "",
    hasOptionalQuestions: false,
    questionsToBeAnswered: "",
    scorePerQuestion: "",
    duration: "",
    cutOff: "",
    addInstruction: "",
    position: "",
    categories: [
      {
        categoryId: "",
        questionType: [""],
        difficulty: "",
        numberOfQuestions: "",
      },
    ],

  };
  onlineEdit: boolean = false;
  masterQBAccess: boolean = false;
  scoreErrorFlag: boolean;

  constructor(
    private assessmentService: AssessmentService,
    private _location: Location,
    private router: Router,
    private route: ActivatedRoute,
    private toastrService: ToastrService
  ) {

    // localStorage.removeItem('assessmentType')
    this.accessToken = localStorage.getItem("accessToken");
    this.companyId = localStorage.getItem("companyId");
    this.companyType = localStorage.getItem("companyType");
    this.modalLink = new FormGroup({
      link: new FormControl("public", Validators.required),
    });
    this.getAssessments();
    this.getRouteParams();
  }

  ngOnInit() {

    this.proctor = localStorage.getItem("proctor");
    if (this.proctor === "proctor") {
      this.observerLanding();
      this.assessmentTypeForLoadMore = localStorage.getItem("assessmentType");
      localStorage.removeItem("proctor");
      localStorage.removeItem("assessmentType");
      this.isLoading = true;
    }
    // const linkforJob = localStorage.getItem('createLinkForJob');
    const assesmentforJob = localStorage.getItem("createAssessmentForJob");
    const assessmentID = localStorage.getItem("EditassessmentIdForJob");
    const jobId = localStorage.getItem("jobId");
    this.jobID = localStorage.getItem("jobId");
    const editJobAsses = localStorage.getItem("editAssess");
    if (assessmentID != null && editJobAsses === "edited" && jobId != null) {
      // this.editAssessmentForJob();
      this.checkJobValue = true;
    }
    localStorage.removeItem("createAssessmentForJob");
    if (jobId != null && assesmentforJob === "create") {
      this.createNewAssessment(); // Sets 'Basic Info' tab view
      this.checkJobValue = true;
    }
    this.assementDashboardShow = [];
    if (this.sectionGridName[0] && this.sectionGridName[0].data) {
      this.sectionGridName[0].data = false;
    }

    this.fetchActiveAssessmentTypes();
    this.getQuestionType();
    this.getLevelOfAssess();
    this.penality();
    this.getDoTest();

    if (this.assessmentType === "Psychometer") {
      this.checkForPsychometric = true;
      this.setPsychometricAssessmentViewValue();
      this.duration();
    } else {
      this.isValidPercentageMarks = false;
      this.duration();
    }

    if (this.assessmentType === "Online Compiler") {
      this.forOnlineCompiler = true;
      localStorage.setItem("forOnlineCompiler", this.assessmentType);
      this.getDoTest();
    } else {
      this.forOnlineCompiler = false;
      this.isValidPercentageMarks = false;
    }

    this.modalLink = new FormGroup({
      link: new FormControl("public", Validators.required),
    });
    this.modalResetCandidate = new FormGroup({
      reset: new FormControl("", Validators.required),
      Resetreason: new FormControl("", Validators.required),
    });
    this.createAssessmentForm = new FormGroup({
      assessName: new FormControl("", Validators.required),
    });

    var qbAccess = localStorage.getItem("masterQBAccess");
    if (qbAccess == "allowed") {
      this.masterQBAccess = true;
    } else {
      this.masterQBAccess = false;
    }
  }

  // ngAfterViewInit() {
  //   this.updateTotalQuestionCount();
  // }

  getRouteParams() {

    this.assessmentType = localStorage.getItem("_assessmentType");

    this.route.params.subscribe(
      (params) => {
        if (params["id"]) {
          this.getAssessmentDetails(params.id);
          this.editedAssess = true;
          this.checkForEdit = true;
          this.checkJobValue = true;
          if (this.assessmentType === "Psychometer") {
            this.psychometricEdit = true;
          }
          if (this.assessmentType === "Online Compiler") {
            this.onlineEdit = true;
          }
          this.editAssessmentthroughID = params.id;
        }
      },
      (error) => console.log(error)
    );

    if (this.assessmentType) {
      this.checkForType = true;

      this.assessmentType = this.assessmentType.replace(/(?:^|\s)\S/g, function (a) { return a.toUpperCase(); });
      // this.assessmentType[0].charAt[0].toUpperCase() +
      // this.assessmentType.substr(1).charAt[0].toUpperCase();
    }
    this.checkAssessmentType();
  }

  getCategoryList() {

    const payload = {
      talentBridgeQuestions: true,
      assessmentType: this.assessmentType,
    };
    this.assessmentService
      .categoryList(payload, this.companyId, this.skip, this.accessToken)
      .subscribe(
        (response) => {

          this.categoryListData = response.listOfCategory;
        },
        (error) => {
          console.log(error.error.message);
        }
      );
  }

  updateAssessmentNameError(errorState: boolean, errorMsg: string) {

    this.assesmentNameError = errorState;
    this.assesmentNameErr = errorMsg;
  }

  updateOptionalQueFlag(value: boolean, j: any) {
    this.scoreErrorFlag=false;
    this.boxes[j].hasOptionalQuestions = value
    if (!this.boxes[j].hasOptionalQuestions) {
      this.boxes[j].questionsToBeAnswered = 0;
      this.boxes[j].scorePerQuestion = 0;
    } else {
      this.updateTotalQuestionCount(false);
    }
  }

  validateAssessmentName() {
    /*
     * It checks 'Assessment Name' value
     * Use : getAssessmentDetails() - On edit assessment scenario
     */
    if (this.assessmentName !== "") {
      // Assessment-name is provided
      if (this.assessmentName.trim() !== "") {
        // Assessment-name has at least one non-space character
        if (this.assessmentName.charAt(0) !== " ") {
          // First Character is not space
          if (
            this.assessmentName.trim().length >= 3 &&
            this.assessmentName.trim().length <= 51
          ) {
            // Length is between 3 to 100
            this.updateAssessmentNameError(false, "");
          } else {
            // Length validation violation
            this.updateAssessmentNameError(true, "");
          }
        } else {
          // First Character is space
          this.updateAssessmentNameError(
            true,
            "Assessment name can not start with space"
          );
          $("#assessmentName").css("border-color", "red");
        }
      } else {
        // Assessment-name consists of all spaces
        this.updateAssessmentNameError(true, "Only space is not allowed");
        $("#assessmentName").css("border-color", "red");
      }
    } else {
      // Assessment-name is not provided
      this.updateAssessmentNameError(true, "");
    }
  }

  getAssessmentDetails(id: any) {

    const payload = {
      assessmentId: id,
    };
    this.assessmentService
      .editAssessment(payload, this.companyId, this.accessToken)
      .subscribe(
        (response) => {
          if (response.message[0].quickAssessmentTemplateId) {
            this.router.navigate(["assessment/list/" + response.message[0].assessmentType.toLowerCase()]);
            this.toastrService.error("Invalid process requested");

          }
          this.editAssesmentById = response.message;
          this.assessmentName = response.message[0].assessmentName;

          this.validateAssessmentName(); // Validate assessment name received from server, initialize required validation flags
          this.assessmentType = response.message[0].assessmentType;
          if (this.assessmentType === "Psychometer") {
            this.checkForPsychometric = true;
          }
          if (this.assessmentType === "Online Compiler") {
            this.forOnlineCompiler = true;
            this.assessmentService
              .getTest(this.companyId, this.accessToken)
              .subscribe(
                (res: any) => {
                  this.getDoSelectEdit = res.message.objects;
                  let sampletest = [];
                  for (let i = 0; i < this.getDoSelectEdit.length; i++) {
                    if (this.getDoSelectEdit[i].name == this.onlinecomp) {
                      sampletest.push(this.getDoSelectEdit[i]);
                      this.onlineSection = sampletest[0].sections;
                      this.onlineSlug = sampletest[0].slug;
                      this.sectionDetail.id = "";
                      this.sectionDetail.sectionName =
                        this.onlineSection[0].name;
                      this.sectionDetail.duration = "";
                      this.sectionDetail.cutOff = "";
                      this.sectionDetail.addInstruction = "";
                      this.sectionDetail.position = "";
                      this.sectionDetail.hasOptionalQuestions = false;
                      this.sectionDetail.questionsToBeAnswered = "";
                      this.sectionDetail.scorePerQuestion = "";
                    }
                  }
                },

                (error) => {
                  console.log(error.error.message);
                }
              );
          }

          this.onlinecomp = response.message[0].onlineCompiler.testName;
          this.onlineTotalQues = response.message[0].questionCount;
          this.durations = response.message[0].duration;
          this.onlineDuration = response.message[0].duration;
          this.onlineCutoff = response.message[0].cutOff;
          this.percentageMarks = response.message[0].cutOff;
          this.isValidPercentageMarks = true;
          this.showPassingScoreErr = false;
          this.assessmentDescription = response.message[0].description;
          this.shuffleQuestions =
            response.message[0].configuration.shuffleQuestions;
          this.disMaxMarks =
            response.message[0].configuration.showQuestionScore;
          this.enableAutoResume =
            response.message[0].configuration.allowedAutoResume;
          this.autoResumeAttemptCount =
            response.message[0].configuration.allowedAutoResumeValue;
          this.displayCatSubCat = response.message[0].configuration.showCategoryInfo;
          this.shuffleOptions =
            response.message[0].configuration.shuffleOptions;
          this.attemptAll = response.message[0].configuration.attemptAll;
          this.moveForwardOnly =
            response.message[0].configuration.moveForwardOnly;
          this.recordSessioninAudio =
            response.message[0].configuration.recordSession;
          this.negativeMarking =
            response.message[0].configuration.negativeMarking;
          this.Penalty = response.message[0].configuration.selectPenalty;
          this.allowSuspiciosEnable =
            response.message[0].configuration.allowedSuspiciousCount;
          this.allowsuspiciosCount =
            response.message[0].configuration.suspiciousCountValue;
          this.allowresetEnable =
            response.message[0].configuration.allowedReset;
          this.allowresetCount =
            response.message[0].configuration.allowedResetValue;
          this.mainHistoryEnable =
            response.message[0].configuration.maintainHistory;
          this.mainHistoryCount =
            response.message[0].configuration.maintainHistoryValue;
          this.instructForTest =
            response.message[0].configuration.instructionForTest;
          this.instructForTestValue =
            response.message[0].configuration.instructionForTestValue;
          this.afterTestMessage = response.message[0].configuration.addMessage;
          this.afterTestMessageValue =
            response.message[0].configuration.addMessageValue;
          this.redirectToWeb =
            response.message[0].configuration.redirectToWebsite;
          this.redirectToWebValue =
            response.message[0].configuration.redirectToWebsiteValue;
          this.createNewAssessment(); // Sets 'Basic Info' tab view
          this.totalDurationTest = this.durations.split(" ");
          this.totalDuration = Number(this.totalDurationTest[0]);
          let sum = 0;
          this.checkDuration = Number(this.totalDuration);
          this.boxes = [];
          this.subCatDetails = [];
          this.section = response.message[0].sections.length;
          this.index = this.section - 1;
          let obj,
            parentId,
            subCatId,
            subCatName,
            ParentName,
            level,
            type,
            questNum,
            questionsToBeAnswered;
          if (response.message[0].sections.length > 1) {
            this.deleteGrid = true;
          }
          for (let j = 0; j < response.message[0].sections.length; j++) {
            this.configDurationData[j] = {
              data: 0,
            };
            this.configCutOffData[j] = {
              data: 0,
            };
            this.configInstructionData[j] = {
              data: "",
            };
            this.configQuestionViewPreference[j] = {
              data: "",
            };
            this.sectionGridName[j] = {
              data: false,
            };
            this.data = response.message[0].sections[j].categories;
            this.configDurationData[j].data =
              response.message[0].sections[j].duration;
            this.configCutOffData[j].data =
              response.message[0].sections[j].cutOff;
            this.configInstructionData[j].data =
              response.message[0].sections[j].addInstruction;
            if (response.message[0].sections[j].questionViewPreference === 'all') {
              this.configQuestionViewPreference[j].data = 'all';
              this.toggleChecked[j] = true;
            } else {
              this.configQuestionViewPreference[j].data = 'default';
              this.toggleChecked[j] = false;
            }
            this.configQuestionViewPreference[j].data = response.message[0].sections[j].questionViewPreference;
            sum += Number(this.configDurationData[j].data);
            obj = {
              sectionName: response.message[0].sections[j].sectionName,
              id: response.message[0].sections[j]._id,
              hasOptionalQuestions: response.message[0].sections[j].hasOptionalQuestions || false,
              scorePerQuestion: response.message[0].sections[j].scorePerQuestion || 0,
              questionsToBeAnswered: response.message[0].sections[j].questionsToBeAnswered || 0,
              totalNumberOfQuestions: 0
            };
            this.boxes.push(obj);
            this.subCatDetails[j] = {
              categories: [],
            };
            this.boxes[j].data = [];
            if (this.data.length > 1) {
              this.removeSecData[j] = true;
            }
            for (let i = 0; i < this.data.length; i++) {
              this.boxes[j].data.push(
                response.message[0].sections[j].categories[i]
              );
              parentId =
                response.message[0].sections[j].categories[i].parentCategoryId;
              subCatId =
                response.message[0].sections[j].categories[i].categoryId;
              ParentName =
                response.message[0].sections[j].categories[i]
                  .parentCategoryName;
              subCatName =
                response.message[0].sections[j].categories[i].subCategoryName;
              level = response.message[0].sections[j].categories[i].difficulty;
              type = response.message[0].sections[j].categories[i].questionType;
              questNum =
                response.message[0].sections[j].categories[i].numberOfQuestions;
              questionsToBeAnswered = response.message[0].sections[j].questionsToBeAnswered || 0;
              this.getSubCategoryList(parentId, false, j, i);
              $("#name1-" + j + "-" + i).val(parentId);
              $("#subCat-" + j + "-" + i).val(subCatId);
              $("#value-" + j + "-" + i).val(type);
              $("#level-" + j + "-" + i).val(level);
              $("#question-" + j + "-" + i).val(questNum);
              $("#maxQuestionCount-" + j + "-" + i).val(0);
              $("#questionsToBeAnswered-" + j).val(questionsToBeAnswered);
            }


          }
          this.totalDuration -= Number(sum);
          this.updateTotalQuestionCount(true);
        },
        (err) => {
          console.log(err);
        }
      );
  }

  changeCategory(parentId, check, j, k) {
    $("#subCat" + "-" + j + "-" + k).val("");
    $("#value" + "-" + j + "-" + k).val("");
    $("#level" + "-" + j + "-" + k).val("");
    $("#question" + "-" + j + "-" + k).val("");
    $("#countDiv-" + j + "-" + k).hide();
    this.getSubCategoryList(parentId, check, j, k);
    // remove red border after category gets changed
    for (let m = 0; m < this.boxes.length; m++) {
      for (let n = 0; n < this.boxes[m].data.length; n++) {
        $("#name1-" + m + "-" + n).css("border-color", "");
        $("#subCat-" + m + "-" + n).css("border-color", "");
        $("#value-" + m + "-" + n).css("border-color", "");
        $("#level-" + m + "-" + n).css("border-color", "");
        $("#name1-" + j + "-" + k).css("border-color", "");
        $("#subCat-" + j + "-" + k).css("border-color", "");
        $("#value-" + j + "-" + k).css("border-color", "");
        $("#level-" + j + "-" + k).css("border-color", "");
      }
    }
    // end of logic
  }

  spaceValidation(e, i) {
    if (e.target.value.charAt(0) === " " && e.srcElement.nodeName === "INPUT") {
      $("#sectionNameErr-" + i).html("Only space is not allowed");
    } else {
      $("#sectionNameErr-" + i).html("");
    }
  }

  checkAssessmentType(event?) {

    if (event) {
      if (event.target.value === "Psychometer") {
        this.checkForPsychometric = true;
        this.setPsychometricAssessmentViewValue();
        this.duration();
      } else if (event.target.value === "Online Compiler") {
        this.forOnlineCompiler = true;
        this.checkForPsychometric = false;
        this.assessmentDescription = "";
        this.instructForTestValue = "<p>Instruction not included </p>";
      } else {
        this.checkForPsychometric = false;
        this.forOnlineCompiler = false;
        this.questionCount = "";
        this.instructForTestValue = "<p>Instruction not included </p>";
        this.assessmentDescription = "";
        this.psychometerAssessment = "";
        this.durationList = [];
        this.durations = "";
        this.shuffleQuestions = true;
        this.shuffleOptions = true;
        this.disMaxMarks = true;
        this.displayCatSubCat = false;

        if (
          this.percentageMarks !== "" &&
          parseInt(this.percentageMarks, 10) >= 0 &&
          parseInt(this.percentageMarks, 10) <= 100
        ) {
          this.isValidPercentageMarks = true;
        } else {
          this.isValidPercentageMarks = false;
        }
        this.duration();
      }

    }

    if (this.assessmentType === "Online Compiler") {

      this.forOnlineCompiler = true;
      localStorage.setItem("forOnlineCompiler", this.assessmentType);
      this.checkForPsychometric = false;
      this.assessmentDescription = "";
      this.instructForTestValue = "<p>Instruction not included </p>";
    } else {
      this.forOnlineCompiler = false;
    }
  }

  setPsychometricAssessmentViewValue() {
    this.checkForPsychometric = true;
    this.psychometerAssessment = "Psychometer";
    this.questionCount = "168";
    this.percentageMarks = "";
    this.showPassingScoreErr = false;
    this.durationList = [];
    this.shuffleQuestions = false;
    this.shuffleOptions = false;
    this.disMaxMarks = false;
    this.displayCatSubCat = false
    // this.durations = '5 min';
    this.isValidPercentageMarks = true;

    this.assessmentDescription =
      "The term Psychometric literally means “measurement of the mind”. Psychometric tests provide an objective way of assessing individual strengths and weaknesses. PsychoMeter is developed by experts using rigorous methods to ensure that the tests are unbiased. This is a ‘standardized’ assessment, meaning they have been tested on people of a similar age and background and performance is interpreted relative to that standard.\n" +
      "\n" +
      " \n" +
      "\n" +
      "Employers and careers services, amongst others, are increasingly getting aware of the relevance and value of psychometric tests. Since the tests provide a somewhat objective way of assessing candidate’s strengths and weaknesses with respect to a particular job, these are a quick way to prima facie determine candidate’s suitability/fitment to the job given the role and profile of the job.\n" +
      "\n" +
      " \n" +
      "\n" +
      "PsychoMeter test is an online Personality Measure test. PsychoMeter has 168 (14 x 12) questions and assesses the test-taker’s personality on 4 Mother Quotients themselves divided in 12 Daughter Quotients (14 questions each).";
    const instructForTestValue =
      "In Psychometric assessments, there are no right and wrong answers.\n" +
      "Follow below mentioned instructions while taking the assessment --\n" +
      "• Do not spend too much time thinking about a question\n" +
      "• Provide honest response, the assessment is designed in a way to detect deliberate distortion of responses\n" +
      "Once the assessment is done, your Psychometric profile will be judged from your responses. You will be profiled on how you see yourself, how you interact and \nmanage others, how you manage your emotions and how you are generally feeling in life.";
    if (instructForTestValue !== undefined) {
      if (instructForTestValue !== null) {
        this.instructForTestValue = instructForTestValue
          .split("\n")
          .join("<br>");
      }
    }
  }

  getSubCategoryList(parentId, check, j, k) {
    this.subCatErr = false;
    for (let i = 0; i < this.sectionGridName.length; i++) {
      this.sectionGridName[i].data = false;
    }
    const payload = {
      parentId: parentId,
    };

    this.assessmentService
      .subCategoryList(payload, this.companyId, this.accessToken)
      .subscribe(
        (response) => {
          this.subCategoryList = response;
          this.subCatLen = this.subCategoryList.length;
          this.subCatDetails[j].categories[k] = response;
          $("#countDiv-" + j + "-" + k).hide();
          $("#question-" + j + "-" + k).css("border-color", "");

          this.optionParentId("name1", k, j);
        },
        (error) => {
          console.log(error.error.message);
          this.subCatErr = true;
          this.subCatDetails[j].categories[k] = [];
          $("#subCat-" + j + "-" + k).val("");
          // this.optionParentId('name1', k, j);
        }
      );
  }

  editSectionname(j) {
    this.sectionGridName[j].data = true;
    setTimeout(() => {
      $("#editTestBox-" + j).focus();
    }, 0);
  }

  handleQuestionViewToggleChange(event: MatSlideToggleChange, index: number) {
    this.configQuestionViewPreference[index].data = event?.checked ? 'all' : 'default';
    this.toggleChecked[index] = event?.checked ?? false;
    if (!event) {
      this.configQuestionViewPreference[index].data = 'default';
    }
  }

  optionParentId(id, i, j) {
    let name,
      name2,
      value,
      level,
      cat,
      subCat = "",
      index;
    let name1, value2, level2, name4;
    this.customFieldErr = false;
    $("#error-" + j + "-" + i).html("");
    $("#question-" + j + "-" + i).css("border-color", "");
    for (let k = 0; k < this.sectionGridName.length; k++) {
      this.sectionGridName[k].data = false;
    }
    for (let z = 0; z < this.boxes.length; z++) {
      $("#sectionErr-" + z).html("");
      $("#gridERR-" + z).html("");
    }
    $("#question-" + j + "-" + i).val("");
    cat = $("#name1-" + j + "-" + i).val();
    subCat = $("#subCat-" + j + "-" + i).val();
    if (subCat === "") {
      index = $("#name1-" + j + "-" + i).val();
    } else {
      index = $("#subCat-" + j + "-" + i).val();
      this.name2 = index;
    }
    value = $("#value-" + j + "-" + i).val();
    level = $("#level-" + j + "-" + i).val();
    if (
      (index !== null &&
        this.subCatDetails[j].categories[i].length > 0 &&
        subCat !== "" &&
        value !== "" &&
        level !== "") ||
      (this.subCatDetails[j].categories[i].length === 0 &&
        value !== "" &&
        level !== "")
    ) {
      const payload = {
        categoryId: [index],
        questionType: [value],
        difficultyLevel: [level],
        isInbuiltQuestion: true,
      };

      const accesssToken = localStorage.getItem("accessToken");

      this.assessmentService
        .questionCountforCategory(payload, this.companyId, accesssToken)
        .subscribe(
          (response) => {
            $("#questioncount-" + j + "-" + i).val(response.questionCount);
            $("#questioncount-" + j + "-" + i).html(response.questionCount);
            $("#maxQuestionCount-" + j + "-" + i).val(response.questionCount);
            $("#countDiv-" + j + "-" + i).show();
            if (response.questionCount === 0) {
              $("#question-" + j + "-" + i).css("border-color", "red");
            } else {
              $("#question-" + j + "-" + i).css("border-color", "");
            }
            $("#countDiv-" + j + "-" + i).show();
            if (
              index !== "" &&
              index !== undefined &&
              value !== "" &&
              value !== undefined &&
              level !== "" &&
              level !== undefined
            ) {
              name1 = $("#name1-" + j + "-" + i).val();
              name4 = $("#subCat-" + j + "-" + i).val();
              value2 = $("#value-" + j + "-" + i).val();
              level2 = $("#level-" + j + "-" + i).val();
              for (let m = 0; m < this.boxes.length; m++) {
                for (let n = 0; n < this.boxes[m].data.length; n++) {
                  name = $("#name1-" + m + "-" + n).val();
                  name2 = $("#subCat-" + m + "-" + n).val();
                  value = $("#value-" + m + "-" + n).val();
                  level = $("#level-" + m + "-" + n).val();
                  if (j === m && i === n) {
                  } else {
                    if (
                      name === name1 &&
                      name2 === name4 &&
                      value === value2 &&
                      level === level2
                    ) {
                      $("#sectionErr-" + j).html(
                        "You added same data in row " +
                        (n + 1) +
                        " of Section " +
                        (m + 1)
                      );
                      $("#name1-" + m + "-" + n).css("border-color", "red");
                      $("#subCat-" + m + "-" + n).css("border-color", "red");
                      $("#value-" + m + "-" + n).css("border-color", "red");
                      $("#level-" + m + "-" + n).css("border-color", "red");
                      $("#name1-" + j + "-" + i).css("border-color", "red");
                      $("#subCat-" + j + "-" + i).css("border-color", "red");
                      $("#value-" + j + "-" + i).css("border-color", "red");
                      $("#level-" + j + "-" + i).css("border-color", "red");
                      this.index1 = i;
                      this.index2 = j;
                      this.index3 = n;
                      this.index4 = m;
                      this.customFieldErr = true;
                      this.dupQstSetSelectionErr = true;
                      return;
                    } else {
                      this.dupQstSetSelectionErr = false;
                      $("#name1-" + m + "-" + n).css("border-color", "");
                      $("#subCat-" + m + "-" + n).css("border-color", "");
                      $("#value-" + m + "-" + n).css("border-color", "");
                      $("#level-" + m + "-" + n).css("border-color", "");
                      $("#name1-" + j + "-" + i).css("border-color", "");
                      $("#subCat-" + j + "-" + i).css("border-color", "");
                      $("#value-" + j + "-" + i).css("border-color", "");
                      $("#level-" + j + "-" + i).css("border-color", "");
                    }
                  }
                }
              }
            }
          },
          (error) => {
            console.log(error.error.message);
            this.errorMsg = error.error.message;
          }
        );
    }
  }

  clearcreateAssementData() {
    this.assessmentName = "";
    this.assessmentType = "";
    this.durations = "";
    this.onlinecomp = "";
    this.percentageMarks = "";
    this.assessmentDescription = "";
    this.shuffleQuestions = true;
    this.shuffleOptions = true;
    this.disMaxMarks = true;
    this.displayCatSubCat = false;
    this.attemptAll = false;
    this.moveForwardOnly = false;
    this.recordSessioninAudio = false;
    this.negativeMarking = false;
    this.Penalty = "";
    this.enableAttendence = false;
    this.trackGeoLocation = false;
    this.candidateSnapshot = false;
    this.configMoveForwardOnly = false;
    this.allowSuspiciosEnable = false;
    this.allowsuspiciosCount = "";
    this.allowresetEnable = false;
    this.allowresetCount = "";
    this.mainHistoryEnable = false;
    this.mainHistoryCount = "";
    this.candidatesADD = false;
    this.configDurationData[0] = { data: 0 };
    this.configCutOffData[0] = { data: 0 };
    this.configInstructionData[0] = { data: "" };
    this.configQuestionViewPreference[0] = { data: "" }
    this.sectionGridName[0] = { data: false };
    this.subCatDetails[0] = { categories: [] };

    for (let i = this.boxes.length - 1; i > 0; i--) {
      this.removeWidget(i);
    }
    const obj = {
      parentCategoryId: "",
      categoryId: "",
      assessment: "",
      categoryName: "",
      subCategoryName: "",
      questionType: "",
      difficulty: "",
      numberOfQuestions: "",
      questionViewPreference: ""
    };
    this.boxes[0].sectionName = "Section 1";
    this.boxes[0].data = [];
    this.boxes[0].data.push(obj);
    localStorage.removeItem("editAssess");
    localStorage.removeItem("EditassessmentIdForJob");
    localStorage.removeItem("checkForEdit");
    localStorage.removeItem("jobId");
    localStorage.removeItem("createAssessmentForJob");
    this.modalLink.reset();
    this.modalResetCandidate.reset();
    this.privateInviteName = "";
    this.privateInviteStatus = "Active";
    this.privateBlockIpAddress = "";
    this.privateTags = "";
    this.privateStartDate = "";
    this.privateStartTime = "";
    this.privateEndDate = "";
    this.privateEndTime = "";
    this.privateAuthanticationOTP = false;
    this.privateMessage = "";
    this.privateSubject = "";
    this.publicInviteName = "";
    this.publicInviteStatus = "Active";
    this.publicBlockIpAddress = "";
    this.publicTags = "";
    this.publicStartDate = "";
    this.publicStartTime = "";
    this.publicEndDate = "";
    this.publicEndTime = "";
    this.publicAuthanticationOTP = false;
    this.inviteCheck = false;
    this.TrackLocationForLink = false;
    this.IDProofForLink = false;
    this.candidateSnapshotForLink = false;
    this.candidateAttendanceLink = false;
    this.candidateProctor = false;
    this.candidatesDetails = [this.candidatesDetail];
    this.byLink = false;
    this.step2Complete = false;
    this.checkForEdit = false;
    this.addprivate = false;
    this.setWizard1 = false;
    this.setWizard2 = false;
    this.setWizard3 = false;
    this.setWizard4 = false;
    this.linkWizard = false;
    this.getAssementList = [];
    this.instructForTest = true;
    this.instructForTestValue = "<p>Instruction not included </p>";
    this.afterTestMessageValue = "<p>Thank you for taking the Assessment.</p>";
    this.afterTestMessage = true;
    this.editCandidates = false;
    this.redirectToWebValue = "";
    this.redirectToWeb = false;
    this.section = 1;
    this.curNum = 2;
    this.deleteCount = [];
    this.errorMsg = "";
    this.linkPublic = true;
    this.linkPrivate = false;
  }

  createNewAssessment() {

    // Sets 'Basic Info' tab view
    this.privateInviteStatus = "Active";
    this.publicInviteStatus = "Active";
    window.scroll(0, 0);
    if (this.assessmentDescriptionDash) {
      this.clearcreateAssementData();
    }
    this.createdassessmentId = "";
    this.editId = "";
    this.setWizard1 = true;
    this.showAssessemt = false;
    this.step1 = true;
    this.step2 = false;
    this.assessmentList1 = false;
    this.assessmentDescriptionDash = false;
    this.assessmentListOfCandidates = false;
    this.assessmentData = false;
    this.asseementQuestion = false;
    this.showSectionQuestion = false;
    this.assessmentConfigurationDash = false;
    this.assessmentCandidatesDetails = false;
    this.assessmentDashMessage = false;
  }

  setDefSelectQuestionView() {
    const dashConf = this._generateDefaultDashConfig();
    for (let i = 0; i < dashConf.length; i++) {
      const conf = dashConf[i];
      conf.payload = 1 + i;
      const obj = {
        parentCategoryId: "",
        categoryId: "",
        assessment: "",
        categoryName: "",
        subCategoryName: "",
        questionType: "",
        difficulty: "",
        numberOfQuestions: "",

      };
      this.boxes[i] = { id: "", data: [], sectionName: "Section " + (i + 1), questionsToBeAnswered: 0, totalNumberOfQuestions: 0, scorePerQuestion: 0, hasOptionalQuestions: false };
      this.boxes[i].data.push(obj);
    }
    this.curNum = dashConf.length + 1;
  }

  getAssessments() {
    const payload = {
      type: "assessmentType",
      assessmentType: "",
    };
    this.assessmentService
      .getLevelOfAssess(payload, this.companyId, this.accessToken)
      .subscribe(
        (response) => {
          this.assessmentTypeList = response;
        },
        (error) => {
          console.log(error.error.message);
        }
      );
    this.setDefSelectQuestionView();
    this.editSectionData = "Section " + this.section;
    this.removeSecData[0] = false;
  }

  getDoTest() {
    this.assessmentService.getTest(this.companyId, this.accessToken).subscribe(
      (res: any) => {
        this.getDoSelectTest = res.message.objects;
        localStorage.setItem("getDoSelectTest", res);
      },

      (error) => {
        console.log(error.error.message);
      }
    );
  }

  selectedvalue() {
    this.assessmentService.getTest(this.companyId, this.accessToken).subscribe(
      (res: any) => {
        this.sampletestss = res.message.objects;
        // let sampletest = [];
        // for (let i = 0; i < this.sampletestss.length; i++) {
        //     if (this.sampletestss[i].name ==  this.onlinecomp.name ) {
        //       sampletest.push(this.sampletestss[i]);

        this.onlineDuration = this.onlinecomp.duration;
        this.onlineCutoff = this.onlinecomp.cutoff;
        this.onlineTotalQues = this.onlinecomp.total_problems;
        this.onlineSlug = this.onlinecomp.slug;
        this.onlineLevel = this.onlinecomp.level;
        this.onlineSection = this.onlinecomp.sections;

        this.sectionDetail.id = "";
        this.sectionDetail.sectionName = this.onlineSection[0].name;
        this.sectionDetail.duration = "";
        this.sectionDetail.cutOff = "";
        this.sectionDetail.addInstruction = "";
        this.sectionDetail.position = "";
        this.sectionDetail.hasOptionalQuestions = false;
        this.sectionDetail.questionsToBeAnswered = "";
        this.sectionDetail.scorePerQuestion = "";
        // }
        // }
      },

      (error) => {
        console.log(error.error.message);
      }
    );
  }

  penality() {
    const payload = {
      type: "penalty",
      assessmentType: "",
    };
    this.assessmentService
      .getLevelOfAssess(payload, this.companyId, this.accessToken)
      .subscribe(
        (response) => {
          this.penalityList = response;
        },
        (error) => {
          console.log(error.error.message);
        }
      );
  }

  checkForZero(event) {
    if (event.target.value !== "") {
      if (event.target.value.charAt(0) === 0 || event.target.value === 0) {
        this.zeroentered = true;
        $("#next").attr("disabled", true);
      } else {
        this.zeroentered = false;
        $("#next").attr("disabled", false);
      }
    } else {
      this.zeroentered = false;
    }
    if (event.target.value >= 0) {
      $("#suspicious").html("");
    }
  }

  updateSectionName(e, j) {
    if (e.srcElement.selectionStart === 0) {
      this.boxes[j].sectionName = "Section" + " " + String(j + 1);
    }
    if (e.target.value.charAt(0) === " " && e.srcElement.nodeName === "INPUT") {
      return;
    } else {
      this.sectionGridName[j].data = false;
    }
  }

  duration() {
    const payload = {
      type: "duration",
      assessmentType: this.psychometerAssessment,
    };
    this.assessmentService
      .getLevelOfAssess(payload, this.companyId, this.accessToken)
      .subscribe(
        (response) => {
          this.durationList = response;
          if (this.checkForPsychometric) {
            this.durations = response[0].name;
          }
        },
        (error) => {
          console.log(error.error.message);
        }
      );
  }

  step1Method() {
    this.step1 = false;
    let p;
    if (this.psychometricEdit || this.onlineEdit) {
      this.step3 = true;
      this.step2 = false;
      this.setWizard3 = true;
      p = 100;
      document.getElementById('prog').style.width = p + "%";

    } else if (this.checkForPsychometric || this.forOnlineCompiler) {
      this.step3 = true;
      this.step2 = false;
      this.setWizard3 = true;
      p = 100 / 2;
      document.getElementById('prog').style.width = p + "%";

    } else {
      this.step2 = true;
      if (this.checkForEdit) {
        p = 100 / 2;
      } else {
        if (
          this.boxes.length === 1 &&
          (this.boxes[0].data[0].parentCategoryId === "" ||
            this.boxes[0].data[0].parentCategoryId === undefined)
        ) {
          // if 'Select Questions' step was not configured during the ongoing flow, set 'Select Questions' view to default
          this.setDefSelectQuestionView();
        }
        p = 100 / 3;
      }
      document.getElementById('prog').style.width = p + "%";

    }
    this.setWizard2 = true;
    this.totalDurationTest = this.durations.split(" ");
    this.totalDuration = Number(this.totalDurationTest[0]);
    this.checkDuration = this.totalDuration;
    window.scroll(0, 0);
    if (!this.checkForPsychometric && !this.forOnlineCompiler && !this.categoryListLoaded) {
      this.getCategoryList();
      this.categoryListLoaded = true;
    }

  }

  step2Method() {
    this.totalNumQuestion = 0;
    this.totalNumDoSelectQuestion = 0;
    let name, value, level, question, name2, i, maxQuestionCnt;
    this.nodeValue = [];
    if (
      this.customFieldErr ||
      this.maxQuestErr === true ||
      this.dupQstSetSelectionErr
    ) {
      return;
    }
    if(this.scoreErrorFlag){
      return;
    }
    $(".errorMsgContainer").html("");
    for (i = 0; i < this.boxes.length; i++) {
      if (this.boxes[i].sectionName === "" || this.boxes[i].sectionName.trim() === "") {
        $("#gridERR-" + i).html("Section name cannot be blank");
        return;
      }
      if (this.boxes[i].sectionName !== "" && this.boxes[i].sectionName.length < 3) {
        $("#sectionErr-" + i).html("Section name must have at least 3 characters");
        return;
      }
      for (let j = 0; j < this.boxes[i].data.length; j++) {
        name = $("#name1-" + i + "-" + j).val();
        name2 = $("#subCat-" + i + "-" + j).val();
        value = $("#value-" + i + "-" + j).val();
        level = $("#level-" + i + "-" + j).val();
        question = $("#question-" + i + "-" + j).val();
        maxQuestionCnt = $("#maxQuestionCount-" + i + "-" + j).val();
        if (
          ((name === "" || name === undefined) &&
            (name === undefined || name2 === "")) ||
          value === "" ||
          value === undefined ||
          level === "" ||
          level === undefined ||
          question === "" ||
          question === undefined
        ) {
          $("#sectionErr-" + i).html("Select question(s).");
          this.customFieldErr = true;
          return;
        }
        if (Number(question) > Number(maxQuestionCnt)) {
          $("#error-" + i + "-" + j).html(
            "Cannot be 0 or greater than " + " " + maxQuestionCnt + "."
          );
          $("#question-" + i + "" + j).css("border-color", "red");
          this.maxQuestErr = true;
          return;
        }
      }
    }


    for (let i = 0; i < this.boxes.length; i++) {
      if (this.boxes[i].hasOptionalQuestions) {
        if (this.boxes[i].questionsToBeAnswered > this.boxes[i].totalNumberOfQuestions || this.boxes[i].questionsToBeAnswered == 0
        ) {

          $("#error-" + i).html(
            "Cannot be 0 or greater than " + " " + this.boxes[i].totalNumberOfQuestions + "."
          );

          return;

        } else if (this.boxes[i].scorePerQuestion == 0 || this.boxes[i].scorePerQuestion < 0 || this.boxes[i].scorePerQuestion == null) {

          $("#error1-" + i).html(
            " Score Per Question cannot be 0 or less than 0"
          );

          return;
        }

        else {
          $("#error1-" + i).html("");

        }
      }

    }


    window.scroll(0, 0);
    for (let j = 0; j < this.boxes.length; j++) {
      this.data = this.boxes[j].data;
      for (let i = 0; i < this.data.length; i++) {
        this.boxes[j].data[i].parentCategoryId = $(
          "#name1-" + j + "-" + i
        ).val();
        this.boxes[j].data[i].categoryId = $("#subCat-" + j + "-" + i).val();
        this.boxes[j].data[i].parentCategoryName = $(
          "#name1-" + j + "-" + i
        ).text();
        this.boxes[j].data[i].subCategoryName = $(
          "#subCat-" + j + "-" + i
        ).text();
        this.boxes[j].data[i].questionType = $("#value-" + j + "-" + i).val();
        this.boxes[j].data[i].difficulty = $("#level-" + j + "-" + i).val();

        this.boxes[j].data[i].numberOfQuestions = $(
          "#question-" + j + "-" + i
        ).val();
        this.boxes[j].data[i].maxNumberOfQuestions = $(
          "#maxQuestionCount-" + j + "-" + i
        ).val();
      }
    }
    this.step2Complete = true;
    let subCat, obj;
    let m: number;
    let questionCount = 0;
    for (let j = 0; j < this.boxes.length; j++) {
      this.data = this.boxes[j].data;
      m = j + 1;
      var score: any;
      var queTobeAns: any;
      if (this.boxes[j].hasOptionalQuestions) {
        score = this.boxes[j].scorePerQuestion + "";
        queTobeAns = this.boxes[j].questionsToBeAnswered + "";
      }
      else {
        score = "";
        queTobeAns = "";
      }
      this.obj2 = {
        _id: this.boxes[j].id,
        questionViewPreference: this.configQuestionViewPreference[j].data === 'all' ? 'all' : 'default',
        hasOptionalQuestions: this.boxes[j].hasOptionalQuestions,
        scorePerQuestion: score,
        questionsToBeAnswered: queTobeAns,
        sectionName: this.boxes[j].sectionName,
        duration: this.configDurationData[j].data + "",
        cutOff: this.configCutOffData[j].data + "",
        addInstruction: this.configInstructionData[j].data,
        position: j + 1 + "",
        categories: [],
      };
      for (let i = 0; i < this.data.length; i++) {
        name = $("#name1-" + j + "-" + i).val();
        subCat = $("#subCat-" + j + "-" + i).val();
        value = $("#value-" + j + "-" + i).val();
        level = $("#level-" + j + "-" + i).val();
        question = $("#question-" + j + "-" + i).val();
        questionCount += Number(question);
        this.totalNumQuestion += Number(question);
        this.totalNumDoSelectQuestion = localStorage.getItem("getDoSelectTest");
        let index;
        if (subCat === "") {
          index = name;
        } else {
          index = subCat;
        }
        obj = {
          categoryId: index,
          questionType: [value],
          difficulty: level,
          numberOfQuestions: question,
        };
        this.obj2.categories.push(obj);
      }
      this.nodeValue.push(this.obj2);
    }
    this.questionCount = questionCount + "";
    let p;
    if (this.checkForEdit) {
      p = 2 * (100 / 2);
    } else {
      p = 2 * (100 / 3);
    }
    document.getElementById('prog').style.width = p + "%";

    this.setWizard3 = true;
    this.step1 = false;
    this.step2 = false;
    this.step3 = true;
  }

  checkAssessmentName() {
    this.assesmentNameErr = "";
    this.assesmentNameError = false;
    $("#assessmentName").css("border-color", "");
    if (this.assessmentName.charAt(0) === " ") {
      if (this.assessmentName.trim().length > 0) {
        this.assesmentNameErr = "Assessment name can not start with space";
      } else {
        this.assesmentNameErr = "Only space is not allowed";
      }
      this.assesmentNameError = true;
      $("#assessmentName").css("border-color", "red");
      return;
    }
  }

  private _generateDefaultItemConfig(): NgGridItemConfig {
    return { dragHandle: ".handle", col: 1, row: 100, sizex: 100, sizey: 10 };
  }

  private _generateDefaultDashConfig(): NgGridItemConfig[] {
    return [{ dragHandle: ".handle", col: 1, row: 100, sizex: 100, sizey: 6 }];
  }

  addSections() {
    let name, value, level, question, name2;
    for (let k = 0; k < this.sectionGridName.length; k++) {
      this.sectionGridName[k].data = false;
    }
    if (this.customFieldErr || this.maxQuestErr === true) {
      return;
    }
    for (let z = 0; z < this.boxes.length; z++) {
      $("#sectionErr-" + z).html("");
      $("gridERR-" + z).html("");
    }
    if (
      this.boxes[this.section - 1].sectionName === "" ||
      this.boxes[this.section - 1].sectionName.trim() === ""
    ) {
      $("#gridERR-" + (this.section - 1)).html("Section name cannot be blank");
      return;
    }
    if (
      this.boxes[this.section - 1].sectionName !== "" &&
      this.boxes[this.section - 1].sectionName.length < 3
    ) {
      $("#sectionErr-" + (this.section - 1)).html("Section name must have at least 3 characters");
      return;
    }
    if (this.checkForEdit === true) {
      this.curNum = this.section + 1;
      this.index = this.section - 1;
    }
    for (let i = 0; i < this.boxes.length; i++) {
      for (let j = 0; j < this.boxes[i].data.length; j++) {
        name = $("#name1-" + i + "-" + j).val();
        name2 = $("#subCat-" + i + "-" + j).val();
        value = $("#value-" + i + "-" + j).val();
        level = $("#level-" + i + "-" + j).val();
        question = $("#question-" + i + "-" + j).val();
        if (
          ((name === "" || name === undefined) &&
            (name === undefined || name2 === "")) ||
          value === "" ||
          value === undefined ||
          level === "" ||
          level === undefined ||
          question === "" ||
          question === undefined
        ) {
          $("#sectionErr-" + i).html("Select question(s).");
          this.customFieldErr = true;
          return;
        }
      }
    }
    this.removeSecData[this.section] = false;
    this.deleteGrid = true;
    this.sectionGridName[this.section] = { data: false };
    this.configDurationData[this.section] = { data: 0 };
    this.configCutOffData[this.section] = { data: 0 };
    this.configQuestionViewPreference[this.section] = { data: "default" };
    this.toggleChecked[this.section] = false;
    this.configInstructionData[this.section] = { data: "" };
    this.handleQuestionViewToggleChange;
    this.index += 1;
    this.subCatDetails[this.section] = { categories: [] };
    this.section = this.section + 1;
    const obj = {
      parentCategoryId: "",
      categoryId: "",
      assessment: "",
      categoryName: "",
      subCategoryName: "",
      questionType: "",
      difficulty: "",
      numberOfQuestions: "",
    };
    if (this.deleteCount.length > 0) {
      this.curNum = this.deleteCount[this.deleteCount.length - 1];
      this.deleteCount.splice(this.deleteCount.length - 1, 1);
    } else {
      this.curNum = this.section;
    }
    const conf: NgGridItemConfig = this._generateDefaultItemConfig();
    conf.payload = this.curNum;
    this.tempArr2 = [];
    this.tempArr2.push(this.customeField);
    this.boxes.push({
      id: "",
      data: [obj],
      sectionName: "Section " + conf.payload,
      questionsToBeAnswered: 0,
      totalNumberOfQuestions: 0,
      scorePerQuestion: 0,
      hasOptionalQuestions: false,
    });
  }

  RidirectionMessage() {
    localStorage.setItem("assessmentPage", "HelloAssessment");
    this.router.navigate(["jobs"]);
  }

  dashboardRefresh() {
    localStorage.setItem("profileInfo", "show profileInfo");
    this.router.navigate(["dashboard"]);
  }

  backClicked() {
    this._location.back();
  }

  validateNumber(e) {
    if (
      $.inArray(e.keyCode, [46, 8, 9, 27, 13, 110]) !== -1 ||
      (e.keyCode === 65 && e.ctrlKey === true) ||
      (e.keyCode >= 35 && e.keyCode <= 39)
    ) {
      return;
    }
    // Ensure that it is a number and stop the keypress
    if (
      (e.shiftKey || e.keyCode < 48 || e.keyCode > 57) &&
      (e.keyCode < 96 || e.keyCode > 105)
    ) {
      e.preventDefault();
    }
  }

  stepWizard1() {
    this.setWizard2 = false;
    this.setWizard3 = false;
    this.setWizard4 = false;
    if (!this.setWizard1) {
      return;
    }
    this.listErrMessage = false;
    this.showAssessemt = false;
    this.assessmentDescriptionDash = false;
    const p = 0;
    document.getElementById('prog').style.width = p + "%";

    this.step1 = true;
    this.step2 = false;
    this.step3 = false;
    this.showPreviewAssessment = false;
    this.publicLinkCreation = false;
    this.privateLinkCreationStep1 = false;
    this.privateLinkCreationStep2 = false;
    if (this.step2Complete === false) {
      for (let j = 0; j < this.boxes.length; j++) {
        this.data = this.boxes[j].data;
        for (let i = 0; i < this.data.length; i++) {
          this.boxes[j].data[i].parentCategoryId = $(
            "#name1-" + j + "-" + i
          ).val();
          this.boxes[j].data[i].categoryId = $("#subCat-" + j + "-" + i).val();
          this.boxes[j].data[i].parentCategoryName = $(
            "#name1-" + j + "-" + i
          ).text();
          this.boxes[j].data[i].subCategoryName = $(
            "#subCat-" + j + "-" + i
          ).text();
          this.boxes[j].data[i].questionType = $("#value-" + j + "-" + i).val();
          this.boxes[j].data[i].difficulty = $("#level-" + j + "-" + i).val();
          this.boxes[j].data[i].numberOfQuestions = $(
            "#question-" + j + "-" + i
          ).val();
        }
      }
    }
    const size = this.candidatesDetails.length;
    this.candidatesDetails = [];
    for (let i = 0; i < size; i++) {
      setTimeout(function () {
        myExtObject.loadFlagId(size);
      });
      const obj = {
        candidatesName: $("#canname-" + i).val(),
        email: $("#email-" + i).val(),
        phoneNo: $("#phone-" + i).val(),
        countryCode: "+91",
      };
      this.candidatesDetails.push(obj);
    }
  }

  stepWizard2() {
    this.setWizard3 = false;
    this.setWizard4 = false;
    if (!this.setWizard2) {
      return;
    }

    let p;
    if (this.checkForEdit) {

      p = 100 / 2;
      console.log(p);
    } else {
      p = 100 / 3;

    }
    document.getElementById('prog').style.width = p + "%";
    // ($("#prog")as any).width(p + "%");
    this.step1 = false;
    this.step2 = true;
    this.step3 = false;
    this.showPreviewAssessment = false;
    this.publicLinkCreation = false;
    this.privateLinkCreationStep1 = false;
    this.privateLinkCreationStep2 = false;
    const size = this.candidatesDetails.length;
    this.candidatesDetails = [];
    for (let i = 0; i < size; i++) {
      setTimeout(function () {
        myExtObject.loadFlagId(size);
      });
      const obj = {
        candidatesName: $("#canname-" + i).val(),
        email: $("#email-" + i).val(),
        phoneNo: $("#phone-" + i).val(),
        countryCode: "+91",
      };
      this.candidatesDetails.push(obj);
    }
    for (let secIndx = 0; secIndx < this.boxes.length; secIndx++) {
      for (
        let catIndx = 0;
        catIndx < this.boxes[secIndx].data.length;
        catIndx++
      ) {
        $("#questioncount-" + secIndx + "-" + catIndx).val(
          this.boxes[secIndx].data[catIndx].maxNumberOfQuestions
        );
        $("#questioncount-" + secIndx + "-" + catIndx).html(
          this.boxes[secIndx].data[catIndx].maxNumberOfQuestions
        );
        $("#maxQuestionCount-" + secIndx + "-" + catIndx).html(
          this.boxes[secIndx].data[catIndx].maxNumberOfQuestions
        );
      }
    }
  }

  stepWizard3() {
    this.setWizard4 = false;
    if (!this.setWizard3) {
      return;
    }
    let p;
    if (this.checkForEdit) {
      p = 2 * (100 / 2);
    } else if (this.checkForPsychometric || this.forOnlineCompiler) {
    } else {
      p = 2 * (100 / 3);
    }
    document.getElementById('prog').style.width = p + "%";

    this.step1 = false;
    this.step2 = false;
    this.step3 = true;
    this.linkWizard = true;
    this.showPreviewAssessment = false;
    this.publicLinkCreation = false;
    this.privateLinkCreationStep1 = false;
    this.privateLinkCreationStep2 = false;
    const size = this.candidatesDetails.length;
    this.candidatesDetails = [];
    for (let i = 0; i < size; i++) {
      setTimeout(function () {
        myExtObject.loadFlagId(size);
      });
      const obj = {
        candidatesName: $("#canname-" + i).val(),
        email: $("#email-" + i).val(),
        phoneNo: $("#phone-" + i).val(),
        countryCode: "+91",
      };
      this.candidatesDetails.push(obj);
    }
  }

  stepWizard4() {
    if (!this.setWizard4) {
      return;
    }
    let p;
    if (this.checkForEdit) {
      p = 3 * (100 / 2);
    } else {
      p = 3 * (100 / 3);
    }
    document.getElementById('prog').style.width = p + "%";

    if (this.linkWizard === false) {
      this.privateLinkCreate();
    } else if (this.linkWizard === true) {
      this.publicLinkCreate();
    }
  }

  copyLink() {
    const copyText = document.getElementById("linkGener") as HTMLInputElement;
    copyText.select();
    document.execCommand("copy");
  }

  removeWidget(index: number): void {
    if (this.boxes.length === 1) {
      this.deleteGrid = false;
      return;
    }
    if (this.boxes[index]) {
      for (let z = 0; z < this.boxes.length; z++) {
        $("#sectionErr-" + z).html("");
        $("#gridERR-" + z).html("");
      }
      this.customFieldErr = false;
      if (this.index2 === index || this.index4 === index) {
        if (this.index2 === index) {
          $("#name1-" + this.index4 + "-" + this.index3).css(
            "border-color",
            ""
          );
          $("#subCat-" + this.index4 + "-" + this.index3).css(
            "border-color",
            ""
          );
          $("#value-" + this.index4 + "-" + this.index3).css(
            "border-color",
            ""
          );
          $("#level-" + this.index4 + "-" + this.index3).css(
            "border-color",
            ""
          );
        } else {
          $("#name1-" + this.index2 + "-" + this.index1).css(
            "border-color",
            ""
          );
          $("#subCat-" + this.index2 + "-" + this.index1).css(
            "border-color",
            ""
          );
          $("#value-" + this.index2 + "-" + this.index1).css(
            "border-color",
            ""
          );
          $("#level-" + this.index2 + "-" + this.index1).css(
            "border-color",
            ""
          );
        }
      }
      this.boxes.splice(index, 1);
      this.subCatDetails.splice(index, 1);
      this.configQuestionViewPreference.splice(index, 1);
      this.toggleChecked.splice(index, 1);
      this.index -= 1;
      this.section -= 1;
      if (this.boxes.length === 1) {
        this.deleteGrid = false;
      }
    }
    this.deleteCount.push(index + 1);
    this.dupQstSetSelectionErr = false;
  }

  privateLinkCreate() {
    window.scroll(0, 0);
    if (this.byLink === false) {
      this.setWizard4 = true;
      const p = 3 * (100 / 3);
      document.getElementById('prog').style.width = p + "%";


    }
    this.listErrMessage = false;
    this.publicLinkCreation = false;
    this.privateLinkCreationStep1 = true;
    this.showAssessemt = false;
    this.step1 = false;
    this.step2 = false;
    this.step3 = false;
    this.showPreviewAssessment = false;
    this.privateLinkCreationStep2 = false;
    this.assessmentDescriptionDash = false;
  }

  publicLinkCreate() {
    this.setWizard1 = false;
    this.setWizard2 = false;
    this.setWizard3 = false;
    if (this.byLink === false) {
      this.setWizard4 = true;
      const p = 3 * (100 / 3);
      document.getElementById('prog').style.width = p + "%";

    }
    window.scroll(0, 0);
    this.step1 = false;
    this.step2 = false;
    this.step3 = false;
    this.showPreviewAssessment = false;
    this.privateLinkCreationStep2 = false;
    this.assessmentDescriptionDash = false;
    this.publicLinkCreation = true;

    $("#main_Div").css("padding-top", "");
  }

  observerLanding() {
    const assessmentID = localStorage.getItem("assessmentId");
    const payload = { assessmentId: assessmentID };
    this.assessmentService
      .editAssessment(payload, this.companyId, this.accessToken)
      .subscribe(
        (response) => {
          // this.showAssessmentDetails(response.message[0]);
        },
        (error) => {
          this.publicerrorMsg = error.error.message;
        }
      );
  }

  getQuestionType() {
    const payload = {
      type: "questionType",
      assessmentType: "",
    };
    this.assessmentService
      .getLevelOfAssess(payload, this.companyId, this.accessToken)
      .subscribe(
        (response) => {
          this.typeOfQuestion = response;
        },
        (error) => {
          console.log(error.error.message);
        }
      );
  }

  getLevelOfAssess() {
    const payload = {
      type: "level",
      assessmentType: "",
    };
    this.assessmentService
      .getLevelOfAssess(payload, this.companyId, this.accessToken)
      .subscribe(
        (response) => {
          this.levelOfQuestion = response;
        },
        (error) => {
          console.log(error.error.message);
        }
      );
  }

  validateQuestionCount(question, i, j) {

    this.maxQuestErr = false;
    for (let z = 0; z < this.boxes.length; z++) {
      $("#gridERR-" + z).html("");
      $("#error-" + j + "-" + i).html("");
      $("#sectionErr-" + j).html("");
    }
    const subCat = $("#subCat-" + j + "-" + i).val();
    if (this.subCatDetails[j].categories[i].length > 0 && subCat === "") {
      $("#sectionErr-" + j).html("Select sub category");
      return;
    }
    let questionCount = $("#question-" + j + "-" + i).val();
    let maxQuestionCount = $("#maxQuestionCount-" + j + "-" + i).val();
    if (questionCount === "" || questionCount === undefined) {
      this.customFieldErr = false;
      this.questionErr = true;
      $("#sectionErr-" + j).html("Enter no. of question(s)");
    } else {
      this.questionErr = false;
    }
    questionCount = Number(questionCount);
    maxQuestionCount = Number(maxQuestionCount);
    this.maximumQuestion = maxQuestionCount;
    // Update total count of questions

    this.updateTotalQuestionCount(false);

    // new changes starts
    if (
      questionCount > maxQuestionCount ||
      questionCount <= 0 ||
      maxQuestionCount === 0
    ) {

      this.maxQuestErr = true;

      if ($("#question-" + j + "-" + i).val() === "") {
        $("#error-" + j + "-" + i).html("");
        if (maxQuestionCount > 0) {
          $("#question-" + j + "-" + i).css("border-color", "");
        } else {
          $("#question-" + j + "-" + i).css("border-color", "red");
        }
        return;
      } else if (maxQuestionCount === 0) {
        $("#error-" + j + "-" + i).html(
          "No question found for the selected configuration"
        );
        $("#question-" + j + "-" + i).css("border-color", "red");
        return;
      } else {
        $("#error-" + j + "-" + i).html(
          "Cannot be 0 or greater than " + " " + maxQuestionCount + "."
        );
        $("#question-" + j + "-" + i).css("border-color", "red");
        return;
      }
    } else {
      $("#error-" + j + "-" + i).html("");
      $("#question-" + j + "-" + i).css("border-color", "");
      this.customFieldErr = false;
      return;
    }
    // new changes ends here

  }


  updateTotalQuestionCount(editFlag: boolean) {
    let totalCount: number = 0;

    if (!editFlag) {
      for (let j = 0; j < this.boxes.length; j++) {
        totalCount = 0;
        for (let i = 0; i < this.boxes[j].data.length; i++) {
          const questionCount = $("#question-" + j + "-" + i).val();

          if (!isNaN(questionCount) && questionCount !== "") {
            totalCount += parseInt(questionCount);
          }
        }
        this.boxes[j].totalNumberOfQuestions = totalCount;


      }
    } else {
      for (let j = 0; j < this.boxes.length; j++) {
        totalCount = 0;
        for (let i = 0; i < this.boxes[j].data.length; i++) {

          totalCount += parseInt(this.boxes[j].data[i].numberOfQuestions);
        }
        this.boxes[j].totalNumberOfQuestions = totalCount;
        // this.boxes[j].questionsToBeAnswered=this.boxes[j].questionsToBeAnswered;


      }

    }

    // Set the total count of questions
  }




  validateQueToBeAnsCount(box: Box, j: number) {


    if (box.totalNumberOfQuestions < box.questionsToBeAnswered) {
      $("#error-" + j).html(
        "Cannot be 0 or greater than " + " " + box.totalNumberOfQuestions + "."
      );
      this.ErrorFlag = true;

    }
    else {
      $("#error-" + j).html("");
      this.ErrorFlag = false;
    }
  }

  fetchActiveAssessmentTypes() {
    const payload = {
      type: "assessmentType",
      assessmentType: "",
    };
    this.assessmentService
      .getLevelOfAssess(payload, this.companyId, this.accessToken)
      .subscribe(
        (response) => {
          this.assessmentTypeList = response;
        },
        (error) => {
          console.log(error.error.message);
        }
      );
  }

  addMoreField(index) {
    for (let k = 0; k < this.sectionGridName.length; k++) {
      this.sectionGridName[k].data = false;
    }
    let name, value, level, question, name2;
    const err = false;
    this.nextErr = false;
    this.customFieldErr = false;
    for (let z = 0; z < this.boxes.length; z++) {
      $("#sectionErr-" + z).html("");
      $("#gridERR-" + z).html("");
    }
    for (let k = 0; k < this.sectionGridName.length; k++) {
      this.sectionGridName[k].data = false;
    }
    this.index = index;
    if (this.maxQuestErr === true) {
      return;
    }
    for (let i = 0; i < this.boxes.length; i++) {
      for (let j = 0; j < this.boxes[i].data.length; j++) {
        name = $("#name1-" + i + "-" + j).val();
        name2 = $("#subCat-" + i + "-" + j).val();
        value = $("#value-" + i + "-" + j).val();
        level = $("#level-" + i + "-" + j).val();
        question = $("#question-" + i + "-" + j).val();
        if (
          ((name === "" || name === undefined) &&
            (name === undefined || name2 === "")) ||
          value === "" ||
          value === undefined ||
          level === "" ||
          level === undefined ||
          question === "" ||
          question === undefined
        ) {
          $("#sectionErr-" + i).html("Select question(s).");
          this.customFieldErr = true;
          return;
        }
      }
    }
    if (!err) {
      $("#sectionErr-" + index).html("");
      this.removeSecData[index] = true;
      const tempArr = {
        parentCategoryId: "",
        categoryId: "",
        assessment: "",
        categoryName: "",
        subCategoryName: "",
        questionType: "",
        difficulty: "",
        numberOfQuestions: "",
        maxNumberOfQuestions: "",
      };
      this.boxes[index].data.push(tempArr);
      this.customFields.push(this.customeField);
    }
  }

  redirectUrl(data) {
    window.open(data);
  }

  previewOfAssessment() {
    window.scroll(0, 0);
    this.listErrMessage = false;
    this.showAssessemt = false;
    this.step1 = false;
    this.step2 = false;
    this.step3 = false;
    this.showPreviewAssessment = true;
    this.assessmentDescriptionDash = false;
    this.questionAnswerSave = [];
    if (this.checkForPsychometric == true) {
      this.psychometerPreview = true;
    }
    if (this.showPreviewAssessment === true) {
      this.setWizard3 = true;
      let p;
      if (this.checkForEdit) {
        p = 2 * (100 / 2);
      } else if (this.checkForPsychometric || this.forOnlineCompiler) {
      } else {
        p = 2 * (100 / 3);
      }
      document.getElementById('prog').style.width = p + "%";

      this.byLink = false;
    }
    const payload = { sections: this.nodeValue };
    this.assessmentService
      .previewOfAssessmentInBuilt(payload, this.companyId, this.accessToken)
      .subscribe(
        (response) => {
          if (!this.checkForPsychometric && !this.forOnlineCompiler) {

            this.previewOfAssessmentList = response;
            for (let j = 0; j < this.previewOfAssessmentList.length; j++) {
              this.questionAnswerSave = [];
              for (
                let i = 0;
                i < this.previewOfAssessmentList[j].questions.length;
                i++
              ) {
                if (this.previewOfAssessmentList[j].questions[i].questionType !== 'Reading Comprehension') {
                  let obj = {};
                  if (
                    this.previewOfAssessmentList[j].questions[i].questionType !==
                    "Fill In The Blanks" &&
                    this.previewOfAssessmentList[j].questions[i].questionType !==
                    "Descriptive" &&
                    this.previewOfAssessmentList[j].questions[i].questionType !==
                    "Match The Following"
                  ) {
                    this.questionAnswerSave[i] =
                      this.previewOfAssessmentList[j].questions[i].answer.split(
                        ","
                      );
                    obj = {
                      a: this.questionAnswerSave[i][0],
                      b: this.questionAnswerSave[i][1],
                      c: this.questionAnswerSave[i][2],
                      d: this.questionAnswerSave[i][3],
                    };
                  }
                  // view for fill in the blank
                  if (
                    this.previewOfAssessmentList[j].questions[i].questionType ===
                    "Fill In The Blanks" &&
                    Object.keys(
                      this.previewOfAssessmentList[j].questions[i].answer
                    ).length !== 0
                  ) {
                    const regex = /#blank#/gi;
                    const arr =
                      this.previewOfAssessmentList[j].questions[i].question.split(
                        regex
                      );
                    Object.keys(
                      this.previewOfAssessmentList[j].questions[i].answer
                    ).forEach((key, k) => {
                      if (
                        this.previewOfAssessmentList[j].questions[i].answer[key]
                          .length > 1
                      ) {
                        this.previewOfAssessmentList[j].questions[i].answer[key] =
                          this.previewOfAssessmentList[j].questions[i].answer[
                            key
                          ].join(" / ");
                      }
                      arr[k] =
                        arr[k] +
                        '<span class="blank-ans">' +
                        this.previewOfAssessmentList[j].questions[i].answer[key] +
                        ' <img alt="right-tick" src="/assets/img/Upload completed.png"> </span>';
                    });
                    this.previewOfAssessmentList[j].questions[i].question = arr
                      .join("")
                      .split("?")
                      .join(".");
                  }
                  // end of view
                  if (
                    this.previewOfAssessmentList[j].questions[i].questionType !==
                    "Match The Following"
                  ) {
                    this.previewOfAssessmentList[j].questions[i].answer = obj;
                  }
                }
                else {
                  let obj = {};
                  const question = this.previewOfAssessmentList[j].questions[i];
                  const questionType = question.questionType;
                  question.subQuestions.forEach((subQue: any) => {

                    if (
                      subQue.questionType !== "Fill In The Blanks" &&
                      subQue.questionType !== "Descriptive" &&
                      subQue.questionType !== "Match The Following"
                    ) {

                      this.questionAnswerSave[i] = subQue.answer.split(",");
                      obj = {
                        a: this.questionAnswerSave[i][0],
                        b: this.questionAnswerSave[i][1],
                        c: this.questionAnswerSave[i][2],
                        d: this.questionAnswerSave[i][3],
                      };
                    }

                    // Handle "Fill In The Blanks" type
                    if ( subQue.questionType === "Fill In The Blanks" && Object.keys(subQue.answer).length !== 0) {
                      let tempArray = [];

                      tempArray = subQue.answer;

                      this.fillInTheBlanksQuesn = '';
                      this.fillInTheBlanksQuesn = subQue.question;
                      let arr = [];
                      arr = this.fillInTheBlanksQuesn.split(/#blank#/g);

                      // Process each key-value pair in tempArray
                      Object.keys(tempArray).forEach((key, j) => {
                        const valueArray = tempArray[key].Value; // Access the Value array

                        if (valueArray.length > 1) {
                          tempArray[key].Value = valueArray.join(' / ');
                        } else {
                          tempArray[key].Value = valueArray[0]; // Single element in the array
                        }

                        arr[j] = arr[j] + `<span class="blank-ans">${tempArray[key].Value} <img alt="right-tick" src="/assets/img/Upload completed.png"></span>`;
                      });

                      this.fillInTheBlanksQuesn = arr.join('').split('?').join('.');
                    }

                    if (subQue.questionType !== "Match The Following") {

                      subQue.answer = obj;
                    }
                  });

                }



              }
            }
          }
        },
        (error) => {
          console.log(error.error.message);
        }
      );
  }

  privateCandidateinvitationList(check) {
    this.showPreviewAssessment = false;
    this.privateLinkCreationStep1 = false;
    this.privateLinkCreationStep2 = false;
    this.publicLinkCreation = false;
    const jobId = localStorage.getItem("jobId");
    const assesmentforJob = localStorage.getItem("createAssessmentForJob");
    if (check === true && this.inviteCheck === false) {
      this.toastrService.success("Assessment created successfully", "", {
        timeOut: 5000,
      });
    }
    if (jobId === null || jobId === undefined) {
      if (this.inviteCheck === true || this.editCandidates) {
        this.publicLinkCreation = false;
        this.privateLinkCreationStep2 = false;
        $("#publicLink").modal("hide");
        this.listofInvitationLink(true);
        this.clearcreateAssementData();
        this.inviteCheck = false;
      } else if (this.checkForEdit) {
        this.backClicked();
      } else {
        $("#publicLink").modal("hide");
        this.clearcreateAssementData();
        this.assessmentDashboard();
        this.backClicked();
      }
    } else {
      localStorage.removeItem("jobId");
      localStorage.removeItem("createAssessmentForJob");
      this.privateLinkCreationStep2 = false;
      this.publicLinkCreation = false;
      this.router.navigate(["jobs"]);
      this.clearcreateAssementData();
    }
  }

  selectInviteSection() {
    if (document.getElementById("invites") != null) {
      document.getElementById("invites").style.color = "#0ec2d0";
      document.getElementById("Details").style.color = "";
      document.getElementById("Questio").style.color = "";
      document.getElementById("config").style.color = "";
      document.getElementById("Details").style.borderBottom = "";
      document.getElementById("invites").style.borderBottom = "2px solid";
      document.getElementById("Questio").style.borderBottom = "";
      document.getElementById("config").style.borderBottom = "";
    }
  }

  assessmentDashboard() {
    this.showAssessemt = true;
    this.privateLinkCreationStep1 = false;
    this.privateLinkCreationStep2 = false;
    this.publicLinkCreation = false;
    this.showPreviewAssessment = false;
    this.step1 = false;
    this.step2 = false;
    this.step3 = false;
    this.assessmentList1 = false;
    this.assessmentDescriptionDash = false;
    this.getAssementList = [];
    this.skip = 0;
    this.multipleDelete = false;
    this.assessmentService
      .assessmentDashboard(this.companyId, this.accessToken)
      .subscribe(
        (response) => {
          this.assementDashboardShow = response;
        },
        (error) => {
          console.log(error.error.message);
        }
      );
  }

  publishLink() {
    // $.when(this.createAssessmentTest(false)).then(this.publicLinkCreate());
    this.createAssessmentTest(false);
  }

  listofInvitationLink(check) {
    if (this.isRequestInProcess) {
      return;
    }
    if (check === false) {
      this.skipforLinkList++;
    } else {
      this.skipforLinkList = 0;
      this.listofLinkDetails = [];
    }
    window.scroll(0, 0);
    if (document.getElementById("invites") != null) {
      document.getElementById("invites").style.color = "#0ec2d0";
      document.getElementById("Details").style.color = "";
      document.getElementById("Question").style.color = "";
      document.getElementById("config").style.color = "";
      document.getElementById("Details").style.borderBottom = "";
      document.getElementById("invites").style.borderBottom = "2px solid";
      document.getElementById("Question").style.borderBottom = "";
      document.getElementById("config").style.borderBottom = "";
    }
    this.assessmentDescriptionDash = true;
    this.assessmentCandidatesDetails = false;
    this.CandidateReportDetails = false;
    this.assessmentData = false;
    this.showAssessemt = false;
    this.assessmentList1 = false;
    this.asseementQuestion = false;
    this.showSectionQuestion = false;
    this.assessmentConfigurationDash = false;
    this.assessmentListOfCandidates = true;
    this.privateLinkCreationStep1 = false;
    this.privateLinkCreationStep2 = false;
    this.publicLinkCreation = false;
    const payload = { referenceId: this.createdassessmentId };
    this.isRequestInProcess = true;
    this.assessmentService
      .listofInvitationLink(
        payload,
        this.companyId,
        this.accessToken,
        this.skipforLinkList
      )
      .subscribe(
        (response) => {
          this.isRequestInProcess = false;
          this.listOfLinkForAssessment = response.isMoreList;
          let listofLink = [];
          listofLink = response.message;
          this.listofLinkDetails = this.listofLinkDetails.concat(listofLink);
          this.publicLinkcreateion = response.linkUrl;
          this.listMessage = true;
          // this.skipforLinkList++;
          this.listErrMessage = false;
          this.selectInviteSection();
        },
        (error) => {
          this.isRequestInProcess = false;
          this.errorMsg = error.error.message;
          this.listMessage = false;
          this.listErrMessage = true;
        }
      );
  }

  checkForZeroValue(e) {
    /*
     * On key input, checks 'Passing Score' field value,
     * updated 'isValidPercentageMarks' value, which controls enabled/disabled state of 'Basic Info' tab next button
     */
    if (e.keyCode === 48 && Number(e.target.value.charAt(0)) === 0) {
      // Multiple 0s provided
      e.target.value = 0;
      this.isValidPercentageMarks = true;
    } else if (Number(e.target.value.charAt(0)) === 0) {
      if (e.target.value !== "") {
        // If the value has 0 as the first digit, remove it and update the value
        e.target.value = Math.round(e.target.value);
        this.isValidPercentageMarks = true;
      } else {
        // OK
        this.isValidPercentageMarks = false;
      }
    }
    if (e.target.value >= 0 && e.target.value <= 100) {
      // Valid value is between 0 to 100 range
      if (this.checkForPsychometric) {
        this.isValidPercentageMarks = true;
      } else if (this.percentageMarks === "") {
        this.isValidPercentageMarks = false;
      } else {
        this.isValidPercentageMarks = true;
      }
    } else {
      // Value out of range
      this.isValidPercentageMarks = false;
    }
    if (!this.isValidPercentageMarks && this.percentageMarks !== "") {
      this.showPassingScoreErr = true;
    } else {
      // do nothing
      this.showPassingScoreErr = false;
    }
  }

  checkPassingScore(e) {
    if (
      $.inArray(e.keyCode, [46, 8, 9, 27, 13, 110]) !== -1 ||
      (e.keyCode === 65 && e.ctrlKey === true) ||
      (e.keyCode >= 35 && e.keyCode <= 39)
    ) {
      return;
    }
    // Ensure that it is a number and stop the keypress
    if (
      (e.shiftKey || e.keyCode < 48 || e.keyCode > 57) &&
      (e.keyCode < 96 || e.keyCode > 105)
    ) {
      e.preventDefault();
    }
  }

  showAssessmentCreationErr(error: any) {
    this.disableSaveExit = false;
    this.errorMsg = error.error.message;
    this.toastrService.error(this.errorMsg, "", {
      timeOut: 5000,
    });
  }

  createAssessmentTest(check) {
    if (this.disableSaveExit) {
      return;
    }
    this.disableSaveExit = true;
    let afterMessage, instructionTest;
    if (!this.allowSuspiciosEnable) {
      this.allowsuspiciosCount = "";
    }
    if (this.instructForTest === false) {
      instructionTest = "";
    } else {
      instructionTest = this.instructForTestValue;
    }
    if (!this.afterTestMessage) {
      afterMessage = "";
    } else {
      afterMessage = this.afterTestMessageValue;
    }
    if (this.createdassessmentId !== "") {
      return;
    } else if (this.createdassessmentId == "" && this.forOnlineCompiler) {
      const create1 = localStorage.getItem("createAssessmentForJob");
      let min = "min";
      let space = " ";

      const requestBody = {
        assessment: {
          assessmentName: this.assessmentName.trim(), // assessment name
          assessmentType: this.assessmentType, // assessment type
          duration: this.onlineDuration.toString(), //duration
          cutOff: this.onlineCutoff.toString(), // cutoff
          description: "", //description
          questionCount: this.onlineTotalQues.toString(), //total question count

          onlineCompiler: {
            testName: this.onlinecomp, //test name
            slug: this.onlineSlug, // selected test slug
          },
          configuration: {
            shuffleQuestions: false,
            showQuestionScore: false,
            shuffleOptions: false,
            attemptAll: false,
            moveForwardOnly: false,
            recordSession: false,
            negativeMarking: false,
            selectPenalty: "",
            allowedSuspiciousCount: false,
            suspiciousCountValue: "",
            allowedReset: false,
            allowedResetValue: "",
            allowedAutoResume: false,
            allowedAutoResumeValue: "",
            maintainHistory: this.mainHistoryEnable, //
            maintainHistoryValue: this.mainHistoryCount, //
            instructionForTest: this.instructForTest, //
            instructionForTestValue: instructionTest, //
            addMessage: this.afterTestMessage, //
            addMessageValue: afterMessage, //
            redirectToWebsite: this.redirectToWeb, //
            redirectToWebsiteValue: this.redirectToWebValue, //
            isInbuiltQuestions: true, //
          },
          sections: [this.sectionDetail],
        },
        jobId: "",
      };

      const requestPay = {
        assessment: {
          assessmentName: this.assessmentName.trim(), // assessment name
          assessmentType: this.assessmentType, // assessment type
          duration: this.onlineDuration.toString().concat(space, min), //duration
          cutOff: this.onlineCutoff.toString(), // cutoff
          description: "", //description
          questionCount: this.onlineTotalQues.toString(), //total question count

          onlineCompiler: {
            testName: this.onlinecomp.name, //test name
            slug: this.onlineSlug, // selected test slug
          },
          configuration: {
            shuffleQuestions: false,
            showQuestionScore: false,
            shuffleOptions: false,
            attemptAll: false,
            moveForwardOnly: false,
            recordSession: false,
            negativeMarking: false,
            selectPenalty: "",
            allowedSuspiciousCount: false,
            suspiciousCountValue: "",
            allowedReset: false,
            allowedResetValue: "",
            allowedAutoResume: false,
            allowedAutoResumeValue: "",
            maintainHistory: this.mainHistoryEnable, //
            maintainHistoryValue: this.mainHistoryCount, //
            instructionForTest: this.instructForTest, //
            instructionForTestValue: instructionTest, //
            addMessage: this.afterTestMessage, //
            addMessageValue: afterMessage, //
            redirectToWebsite: this.redirectToWeb, //
            redirectToWebsiteValue: this.redirectToWebValue, //
            isInbuiltQuestions: true, //
          },
          sections: [this.sectionDetail],
        },
        jobId: "",
      };
      const jobId = localStorage.getItem("jobId");
      if (jobId === null || jobId === undefined) {
        requestBody["jobId"] = "";
      } else {
        requestBody["jobId"] = jobId + "";
      }
      if (jobId === null || jobId === undefined) {
        requestPay["jobId"] = "";
      } else {
        requestPay["jobId"] = jobId + "";
      }
      this.inviteCheck = false;
      if (this.checkForEdit === true) {
        if (this.editedAssess === true) {
          requestBody.assessment["_id"] = this.editAssessmentthroughID;
          this.assessmentService
            .editassessment(requestBody, this.companyId, this.accessToken)
            .subscribe(
              (response) => {
                this.showPreviewAssessment = false;
                this.showAssessemt = false;
                this.toastrService.success(
                  "Assessment edited successfully",
                  "",
                  {
                    timeOut: 5000,
                  }
                );
                this.checkForEdit = false;
                this.checkJobValue = false;
                this.disableSaveExit = false;

                this.router.navigate(["assessment/list/" + this.assessmentType]);
              },
              (error) => {
                this.showAssessmentCreationErr(error);
              }
            );
        } else if (this.editedAssess === false) {
          const editAssessIdForJoB = localStorage.getItem(
            "EditassessmentIdForJob"
          );
          requestBody.assessment["_id"] = editAssessIdForJoB;
          this.assessmentService
            .editassessment(requestBody, this.companyId, this.accessToken)
            .subscribe(
              (response) => {
                this.showPreviewAssessment = false;
                this.showAssessemt = false;
                this.toastrService.success(
                  "Assessment edited successfully",
                  "",
                  {
                    timeOut: 5000,
                  }
                );
                this.disableSaveExit = false;
                this.privateCandidateinvitationList(false);
                this.checkForEdit = false;
                this.checkJobValue = false;
              },
              (error) => {
                this.showAssessmentCreationErr(error);
              }
            );
        }
      } else {
        this.assessmentService
          .assessmentCreation(requestPay, this.companyId, this.accessToken)
          .subscribe(
            (response) => {
              if (response) {
                this.publicLinkCreate();
                this.createdassessmentId = response.assessmentId;
                this.createdassessmentType = response.assessmentType;
                this.createdcreatedOn = response.createdOn;
                localStorage.setItem(
                  "_createdassessmentId",
                  this.createdassessmentId
                );
                localStorage.setItem(
                  "_createdassessmentType",
                  this.createdassessmentType
                );
                localStorage.setItem(
                  "_createdcreatedOn",
                  this.createdcreatedOn
                );
              }
              this.setWizard4 = true;
              this.disableSaveExit = false;
              if (check === true && create1 !== "create") {
                this.toastrService.success(
                  "Assessment created successfully",
                  "",
                  {
                    timeOut: 5000,
                  }
                );
                this.router.navigate(["assessment/list"]);
              }
              if (check === true && create1 === "create") {
                // this.toastrService.success('Assessment created successfully', '',{
                //   timeOut : 5000,
                // });
                // this.clearcreateAssementData();
                // this.checkJobValue = false;
                // localStorage.removeItem('createAssessmentForJob');
                // this.disableSaveExit = false;
                // this.router.navigate(['jobs']);
              }
            },
            (error) => {
              this.showAssessmentCreationErr(error);
            }
          );
      }
    } else {
      const create = localStorage.getItem("createAssessmentForJob");
      const payLoad = {
        assessment: {
          assessmentName: this.assessmentName.trim(),
          assessmentType: this.assessmentType,
          language: this.languages,
          duration: this.durations,
          cutOff: this.percentageMarks,
          description: this.assessmentDescription,
          questionCount: this.questionCount,
          configuration: {
            shuffleQuestions: this.shuffleQuestions,
            showQuestionScore: this.disMaxMarks,
            shuffleOptions: this.shuffleOptions,
            attemptAll: this.attemptAll,
            moveForwardOnly: this.moveForwardOnly,
            recordSession: this.recordSessioninAudio,
            negativeMarking: this.negativeMarking,
            selectPenalty: this.Penalty,
            allowedSuspiciousCount: this.allowSuspiciosEnable,
            suspiciousCountValue: this.allowsuspiciosCount,
            allowedReset: this.allowresetEnable,
            allowedResetValue: this.allowresetCount,
            allowedAutoResume: this.enableAutoResume,
            allowedAutoResumeValue: this.autoResumeAttemptCount,
            maintainHistory: this.mainHistoryEnable,
            maintainHistoryValue: this.mainHistoryCount,
            instructionForTest: this.instructForTest,
            instructionForTestValue: instructionTest,
            addMessage: this.afterTestMessage,
            addMessageValue: afterMessage,
            redirectToWebsite: this.redirectToWeb,
            redirectToWebsiteValue: this.redirectToWebValue,
            isInbuiltQuestions: true,
            showCategoryInfo: this.displayCatSubCat
          },
          sections: this.nodeValue,
        },
      };
      const jobId = localStorage.getItem("jobId");
      if (jobId === null || jobId === undefined) {
        payLoad["jobId"] = "";
      } else {
        payLoad["jobId"] = jobId + "";
      }
      this.inviteCheck = false;
      if (this.checkForEdit === true) {
        if (this.editedAssess === true) {
          payLoad.assessment["_id"] = this.editAssessmentthroughID;
          this.assessmentService
            .editassessment(payLoad, this.companyId, this.accessToken)
            .subscribe(
              (response) => {
                this.showPreviewAssessment = false;
                this.showAssessemt = false;
                this.toastrService.success(
                  "Assessment edited successfully",
                  "",
                  {
                    timeOut: 5000,
                  }
                );
                this.checkForEdit = false;
                this.checkJobValue = false;
                this.disableSaveExit = false;
                this.router.navigate(["assessment/list/" + this.assessmentType.toLowerCase()]);
              },
              (error) => {
                this.showAssessmentCreationErr(error);
              }
            );
        } else if (this.editedAssess === false) {
          const editAssessIdForJoB = localStorage.getItem(
            "EditassessmentIdForJob"
          );
          payLoad.assessment["_id"] = editAssessIdForJoB;
          this.assessmentService
            .editassessment(payLoad, this.companyId, this.accessToken)
            .subscribe(
              (response) => {
                this.showPreviewAssessment = false;
                this.showAssessemt = false;
                this.toastrService.success(
                  "Assessment edited successfully",
                  "",
                  {
                    timeOut: 5000,
                  }
                );
                this.disableSaveExit = false;
                this.privateCandidateinvitationList(false);
                this.checkForEdit = false;
                this.checkJobValue = false;
              },
              (error) => {
                this.showAssessmentCreationErr(error);
              }
            );
        }
      } else {
        this.assessmentService
          .assessmentCreation(payLoad, this.companyId, this.accessToken)
          .subscribe(
            (response) => {
              if (response) {
                this.publicLinkCreate();
                this.createdassessmentId = response.assessmentId;
                this.createdassessmentType = response.assessmentType;
                this.createdcreatedOn = response.createdOn;
                localStorage.setItem(
                  "_createdassessmentId",
                  this.createdassessmentId
                );
                localStorage.setItem(
                  "_createdassessmentType",
                  this.createdassessmentType
                );
                localStorage.setItem(
                  "_createdcreatedOn",
                  this.createdcreatedOn
                );
                this.checkAndSetHasDescriptiveQuestionsFlag();
              }
              this.setWizard4 = true;
              this.disableSaveExit = false;
              if (check === true && create !== "create") {
                this.toastrService.success(
                  "Assessment created successfully",
                  "",
                  {
                    timeOut: 5000,
                  }
                );
                this.router.navigate(["assessment/list"]);
              }
              if (check === true && create === "create") {
                // this.toastrService.success('Assessment created successfully', '',{
                //   timeOut : 5000,
                // });
                // this.clearcreateAssementData();
                // this.checkJobValue = false;
                // localStorage.removeItem('createAssessmentForJob');
                // this.disableSaveExit = false;
                // this.router.navigate(['jobs']);
              }
            },
            (error) => {
              this.showAssessmentCreationErr(error);
            }
          );
      }
    }
  }

  checkConfigEnableOption() {
    if (!this.negativeMarking) {
      this.Penalty = "";
      $("#penality").html("");
    }
    if (!this.allowSuspiciosEnable) {
      this.allowsuspiciosCount = "";
      $("#suspicious").html("");
      this.zeroentered = false;
      $("#next").attr("disabled", false);
    }
    if (!this.allowresetEnable) {
      this.allowresetCount = "";
      $("#reset").html("");
    }
    if (!this.mainHistoryEnable) {
      this.mainHistoryCount = "";
      $("#history").html("");
    }
    // if (!this.instructForTest) {
    //   this.instructForTestValue = '';
    //   $('#instruction').html('');
    // } else {
    //   this.instructForTestValue = '<p>Instruction not included </p>';
    // }
    // if (!this.afterTestMessage) {
    //   this.afterTestMessageValue = '';
    //   $('#message').html('');
    // } else {
    //   this.afterTestMessageValue = '<p> Thank you for taking assessment </p>';
    // }
  }

  configurationMarking() {
    this.zeroentered = false;
    $("#penality").html("");
    $("#suspicious").html("");
    $("#reset").html("");
    $("#history").html("");
    $("#instruction").html("");
    $("#message").html("");
    if (this.negativeMarking) {
      if (this.Penalty === "") {
        $("#penality").html("Select penalty (%).");
        return;
      }
    } else {
      this.Penalty = "";
      $("#penality").html("");
    }
    if (this.allowSuspiciosEnable) {
      if (this.allowsuspiciosCount === "") {
        $("#suspicious").html("Enter violation(s) count limit.");
        return;
      }
    } else {
      this.allowsuspiciosCount = "";
      $("#suspicious").html("");
    }
    if (this.allowresetEnable) {
      if (this.allowresetCount === "") {
        $("#reset").html("Please select Reset");
        return;
      }
    } else {
      this.allowresetCount = "";
      $("#reset").html("");
    }

    if (this.mainHistoryEnable) {
      if (this.mainHistoryCount === "") {
        $("#history").html("Please select Maintain History");
        return;
      }
    } else {
      this.mainHistoryCount = "";
      $("#history").html("");
    }
    if (this.instructForTest) {
      if (this.instructForTestValue === "") {
        $("#instruction").html("Assessment instruction message is missing!");
        return;
      }
    } else {
      this.instructForTestValue = "";
      $("#instruction").html("");
    }
    if (this.afterTestMessage) {
      if (this.afterTestMessageValue === "") {
        $("#message").html("After test message is missing!");
        return;
      }
    } else {
      this.afterTestMessageValue = "";
      $("#message").html("");
    }
    this.previewOfAssessment();
  }

  checkForNegativeValue(e) {
    if (e.target.value > 0) {
      $("#penality").html("");
    }
  }

  // onDateChanged(event, key) {
  //   this.errorMsg = '';
  //   if (key === 'start') {
  //     this.publicStartDate = event.formatted;
  //     this.publicStartTime = '';
  //   } else {
  //     this.publicEndDate = event.formatted;
  //     this.publicEndTime = '';
  //   }
  // }

  onClock(id) {
    myExtObject.loadClock(id);
  }

  removeSectionData(j, i) {
    this.dupQstSetSelectionErr = false;
    if (this.boxes[j].data.length === 1) {
      this.removeSecData[j] = false;
      return;
    } else {
      for (let z = 0; z < this.boxes.length; z++) {
        $("#sectionErr-" + z).html("");
        $("#gridERR-" + z).html("");
      }
      this.customFieldErr = false;
      this.maxQuestErr = false;
      if (
        (this.index2 === j && this.index1 === i) ||
        (this.index4 === j && this.index3 === i)
      ) {
        if (this.index2 === j && this.index1 === i) {
          $("#name1-" + this.index4 + "-" + this.index3).css(
            "border-color",
            ""
          );
          $("#subCat-" + this.index4 + "-" + this.index3).css(
            "border-color",
            ""
          );
          $("#value-" + this.index4 + "-" + this.index3).css(
            "border-color",
            ""
          );
          $("#level-" + this.index4 + "-" + this.index3).css(
            "border-color",
            ""
          );
          this.boxes[j].data.splice(i, 1);
          this.subCatDetails[j].categories.splice(i, 1);
          if (this.boxes[j].data.length === 1) {
            this.removeSecData[j] = false;
          }
        } else {
          $("#name1-" + this.index2 + "-" + this.index1).css(
            "border-color",
            ""
          );
          $("#subCat-" + this.index2 + "-" + this.index1).css(
            "border-color",
            ""
          );
          $("#value-" + this.index2 + "-" + this.index1).css(
            "border-color",
            ""
          );
          $("#level-" + this.index2 + "-" + this.index1).css(
            "border-color",
            ""
          );
          this.boxes[j].data.splice(i, 1);
          this.subCatDetails[j].categories.splice(i, 1);
          if (this.boxes[j].data.length === 1) {
            this.removeSecData[j] = false;
          }
        }
      } else {
        this.boxes[j].data.splice(i, 1);
        this.subCatDetails[j].categories.splice(i, 1);
        if (this.boxes[j].data.length === 1) {
          this.removeSecData[j] = false;
        }
        this.updateTotalQuestionCount(false);
      }
    }
  }

  inBuildQuestion(event) {
    // this.isInbuiltQuestions = event.target.checked;
    // if (event === 'inBuilt') {
    //   this. inBuiltQuest =  true;
    //   this. inBuiltQuestUpload = false;
    // }
    // if (event === 'inBuiltQuestion') {
    //   this.inBuiltQuest = false;
    //   this.inBuiltQuestUpload = true;
    // }
  }

  backButton() {
    this.listErrMessage = false;
    this.showAssessemt = false;
    this.assessmentDescriptionDash = false;
    if (this.checkForPsychometric || this.forOnlineCompiler) {
      if (this.step3 == true) {
        this.step3 = false;
        this.step1 = true;
        this.setWizard3 = false;
        const p = 0;
        document.getElementById('prog').style.width = p + "%";

      } else {
        this.step3 = true;
        this.showPreviewAssessment = false;
        this.psychometerPreview = false;
        if (this.step1 == true) {
          this.step3 = false;
        }
      }
    } else {
      if (this.step2 === true) {
        this.step1 = true;
        this.step2 = false;
        this.setWizard2 = false;
        this.setWizard3 = false;
        this.setWizard4 = false;
        const p = 0;
        document.getElementById('prog').style.width = p + "%";

        window.scroll(0, 0);
        if (this.step2Complete === false) {
          for (let j = 0; j < this.boxes.length; j++) {
            this.data = this.boxes[j].data;
            for (let i = 0; i < this.data.length; i++) {
              this.boxes[j].data[i].parentCategoryId = $(
                "#name1-" + j + "-" + i
              ).val();
              this.boxes[j].data[i].categoryId = $(
                "#subCat-" + j + "-" + i
              ).val();
              this.boxes[j].data[i].parentCategoryName = $(
                "#name1-" + j + "-" + i
              ).text();
              this.boxes[j].data[i].subCategoryName = $(
                "#subCat-" + j + "-" + i
              ).text();
              this.boxes[j].data[i].questionType = $(
                "#value-" + j + "-" + i
              ).val();
              this.boxes[j].data[i].difficulty = $(
                "#level-" + j + "-" + i
              ).val();
              this.boxes[j].data[i].numberOfQuestions = $(
                "#question-" + j + "-" + i
              ).val();
            }
          }
        }
      } else if (this.step3 === true) {
        this.step2 = true;
        this.step3 = false;
        this.setWizard3 = false;
        this.setWizard4 = false;
        let p;
        if (this.checkForEdit) {
          p = 100 / 2;
        } else {
          p = 100 / 3;
        }
        document.getElementById('prog').style.width = p + "%";

        window.scroll(0, 0);
      } else if (this.showPreviewAssessment === true) {
        this.step3 = true;
        this.setWizard4 = false;
        this.showPreviewAssessment = false;
        let p;
        if (this.checkForEdit) {
          p = 2 * (100 / 2);
        } else {
          p = 2 * (100 / 3);
        }
        document.getElementById('prog').style.width = p + "%";

        window.scroll(0, 0);
      }
    }
    if (this.publicLinkCreation === true && this.byLink === false) {
      this.showPreviewAssessment = true;
      this.publicLinkCreation = false;
      this.setWizard4 = false;
      window.scroll(0, 0);
    } else if (
      this.privateLinkCreationStep1 === true &&
      this.byLink === false
    ) {
      this.privateLinkCreationStep1 = false;
      this.showPreviewAssessment = true;
      window.scroll(0, 0);
    } else if (this.privateLinkCreationStep2 === true) {
      this.privateLinkCreationStep1 = true;
      this.privateLinkCreationStep2 = false;
      window.scroll(0, 0);
      const size = this.candidatesDetails.length;
      this.candidatesDetails = [];
      for (var i = 0; i < size; i++) {
        setTimeout(function () {
          myExtObject.loadFlagId(size);
        });
        const obj = {
          candidatesName: $("#canname-" + i).val(),
          email: $("#email-" + i).val(),
          phoneNo: $("#phone-" + i).val(),
          countryCode: "+91",
        };
        this.candidatesDetails.push(obj);
      }
    }
  }

  keyPress(event) {
    return event.charCode === 8 || event.charCode === 0
      ? null
      : event.charCode >= 48 && event.charCode <= 57;
  }

  getOptionText(optionObj, optionType) {
    return myExtObject.getMTFOptionText(optionObj, optionType);
  }

  getOptionKey(optionObj, optionType) {
    return myExtObject.getMTFOptionKey(optionObj, optionType);
  }
  checkAndSetHasDescriptiveQuestionsFlag() {
    for (
      let sectionIndex = 0;
      sectionIndex < this.nodeValue.length;
      sectionIndex++
    ) {
      for (
        let categoryIndex = 0;
        categoryIndex < this.nodeValue[sectionIndex].categories.length;
        categoryIndex++
      ) {
        if (
          this.nodeValue[sectionIndex].categories[categoryIndex]
            .questionType[0] == "Descriptive"
        ) {
          localStorage.setItem("hasDescriptiveQuestions", "yes");
          return;
        }
      }
    }
  }

  validateScore(score){
    if(score > 100 || score < 0){
      this. scoreErrorFlag=true;
    } else{
      this. scoreErrorFlag=false;
    }
  }

}
