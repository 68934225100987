import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { catchError, map, tap } from 'rxjs/operators';
import { throwError} from 'rxjs';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import 'rxjs/add/observable/throw';
import {environment} from '../../environments/environment';

@Injectable()
export class JobService {

  private headers = new HttpHeaders({
    'Content-Type': 'application/json',
    'dataType': 'jsonp',
    'Access-Control-Allow-Origin': '*'
  });
  private url = '';
  private localhost = 'localhost';
  globalUrl = environment.serverUrl;

  constructor(private http: HttpClient) {}

  fetchSkillset(): Observable<any> {
    this.url = this.globalUrl + '/business/services/user/jobdescription/skillset';
    return this.http.post(this.url, {}, {headers: this.headers}).pipe(
      map(response => response),
      catchError(this._errorHandler)
    );
  }

  createJD(payLoad: any, companyId: string, accessToken: any): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'bearer ' + accessToken
    });
    this.url = this.globalUrl + '/business/services/user/jobdescription/create?companyId=' + companyId;

    return this.http.post(this.url, payLoad, {headers: headers}).pipe(
      map(response => response),
      catchError(this._errorHandler)
    );
  }

  listOfJD(skip, companyId: string, accessToken: any): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'bearer ' + accessToken
    });
    this.url = this.globalUrl + '/business/services/user/jobdescription/get/jobList?companyId=' + companyId + '&skip=' + skip;
    return this.http.post(this.url, {}, {headers: headers}).pipe(
      map(response => response),
      catchError(this._errorHandler)
    );
  }

  removeJob(payload, companyId, accessToken): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'bearer ' + accessToken
    });

    this.url = this.globalUrl + '/business/services/user/jobdescription/job/delete?companyId=' + companyId;
    return this.http.post(this.url, payload, {headers: headers}).pipe(
      map(response => response),
      catchError(this._errorHandler)
    );

  }

  addAssessment(companyId, accessToken): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'bearer ' + accessToken
    });
    this.url = this.globalUrl + '/business/services/assessment/assesstype/count?companyId=' + companyId;
    return this.http.post(this.url, {}, {headers: headers}).pipe(
      map(response => response),
      catchError(this._errorHandler)
    );

  }

  particularAssesmentType(payload, companyId, accessToken): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'bearer ' + accessToken
    });

    this.url = this.globalUrl + '/business/services/assessment/assesstype/list?companyId=' + companyId;
    return this.http.post(this.url, payload, {headers: headers}).pipe(
      map(response => response),
      catchError(this._errorHandler)
    );

  }

  assessmentDetailsById(payload, companyId, accessToken): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'bearer ' + accessToken
    });
    this.url = this.globalUrl + '/business/services/assessment/list/assessmentid?companyId=' + companyId;
    return this.http.post(this.url, payload, {headers: headers}).pipe(
      map(response => response),
      catchError(this._errorHandler)
    );

  }

  addAssessForJob(payload, companyId, accessToken): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'bearer ' + accessToken
    });
    this.url = this.globalUrl + '/business/services/assessment/job/assessment/add?companyId=' + companyId;
    return this.http.post(this.url, payload, {headers: headers}).pipe(
      map(response => response),
      catchError(this._errorHandler)
    );

  }

  listOfAssessForJob(payload, companyId, accessToken): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'bearer ' + accessToken
    });

    this.url = this.globalUrl + '/business/services/assessment/job/assessment/list?companyId=' + companyId;
    return this.http.post(this.url, payload, {headers: headers}).pipe(
      map(response => response),
      catchError(this._errorHandler)
    );

  }

  listOfLinkForJob(skip, payload, companyId, accessToken): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'bearer ' + accessToken
    });

    this.url = this.globalUrl + '/business/services/assessment/job/testlink/list?companyId=' + companyId + '&skip=' + skip;
    return this.http.post(this.url, payload, {headers: headers}).pipe(
      map(response => response),
      catchError(this._errorHandler)
    );

  }

  editedJob(payload, companyId, accessToken): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'bearer ' + accessToken
    });

    this.url = this.globalUrl + '/business/services/user/jobdescription/job/edit?companyId=' + companyId;
    return this.http.post(this.url, payload, {headers: headers}).pipe(
      map(response => response),
      catchError(this._errorHandler)
    );

  }

  getexperience(payload, companyId, accessToken): Observable<any> {

    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });

    this.url = this.globalUrl + '/business/services/company/get/masterdata';
    return this.http.post(this.url, payload, {headers: headers}).pipe(
      map(response => response),
      catchError(this._errorHandler)
    );

  }

  deleteLink(payload, companyId, accessToken): Observable<any> {

    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'bearer ' + accessToken
    });

    this.url = this.globalUrl + '/business/services/assessment/job/testlink/delete?companyId=' + companyId;
    return this.http.post(this.url, payload, {headers: headers}).pipe(
      map(response => response),
      catchError(this._errorHandler)
    );
  }

  assessmentDelete(payload, companyId, accessToken): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'bearer ' + accessToken
    });
    this.url = this.globalUrl + '/business/services/assessment/job/assessment/delete?companyId=' + companyId;
    return this.http.post(this.url, payload, {headers: headers}).pipe(
      map(response => response),
      catchError(this._errorHandler)
    );
  }

  jobIdDetails(payload, companyId, accessToken): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'bearer ' + accessToken
    });

    this.url = this.globalUrl + '/business/services/user/jobdescription/job/list/jobid?companyId=' + companyId;
    return this.http.post(this.url, payload, {headers: headers}).pipe(
      map(response => response),
      catchError(this._errorHandler)
    );
  }

  imageUploadActive(payload, companyId, accessToken): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'bearer ' + accessToken
    });
    this.url = this.globalUrl + '/business/services/assessment/link/candidate/upload/baseimage?companyId=' + companyId;
    return this.http.post(this.url, payload, {headers: headers}).pipe(
      map(response => response),
      catchError(this._errorHandler)
    );
  }

  _errorHandler(error: HttpErrorResponse) {
    console.error(error);
    return throwError(error || 'Server Error');
  }

}
