<div class="container-center">
  <div class="box-1">
    <app-carousel></app-carousel>
  </div>

  <div class="box-2">
    <div class="container">
      <form [formGroup]="registerForm" class="form-padding">
        <h5>Register Your Company</h5>
        <div class="row mtop30 height50">
          <div class="col form-group">
            <input
              type="text"
              class="form-control height50"
              id="firstName"
              name="firstName"
              aria-describedby="emailHelp"
              placeholder="First Name"
              formControlName="firstName"
              (input)="clearErrorMessage()"
            />
            <div
              class="text text-danger"
              *ngIf="firstName.touched && firstName.invalid"
            >
              <small *ngIf="firstName.errors.pattern">Enter first name.</small>
              <small *ngIf="firstName.errors.required">Enter first name.</small>
              <small *ngIf="firstName.errors.maxlength"
                >Maximum 101 characters are allowed.</small
              >
            </div>
          </div>
          <div class="col form-group">
            <input
              type="text"
              class="form-control height50"
              id="lastName"
              name="lastName"
              aria-describedby="emailHelp"
              placeholder="Last Name"
              formControlName="lastName"
              (input)="clearErrorMessage()"
            />
            <div
              class="text text-danger"
              *ngIf="lastName.touched && lastName.invalid"
            >
              <small *ngIf="lastName.errors.pattern">Enter last name.</small>
              <small *ngIf="lastName.errors.maxlength"
                >Maximum 101 characters are allowed.</small
              >
            </div>
          </div>
        </div>
        <div class="form-group mtop10">
          <input
            type="text"
            class="form-control height50"
            id="companyName"
            placeholder="Company Name"
            name="companyName"
            formControlName="companyName"
            (input)="clearErrorMessage(); checkforValue($event)"
          />
          <div
            class="text text-danger"
            *ngIf="companyName.touched && companyName.invalid"
          >
            <small *ngIf="companyName.errors.pattern"
              >Enter company name.</small
            >
            <small *ngIf="companyName.errors.required"
              >Enter company name.</small
            >
            <small *ngIf="companyName.errors.minlength && showMinimumError"
              >Minimum 3 characters are required.</small
            >
            <small *ngIf="companyName.errors.maxlength"
              >Maximum 265 characters are allowed.</small
            >
          </div>
        </div>
        <div class="form-group mtop15">
          <international-phone-number2
            (focus)="clearServerErr()"
            (keypress)="clearServerErr(); keyprees($event)"
            name="contactNumber"
            formControlName="contactNumber"
            [(ngModel)]="phoneData"
            (change)="checkCountryCode()"
            placeholder="Enter Phone Number"
            #myInput
            [defaultCountry]="'in'"
            [required]="true"
            [allowedCountries]="[]"
          ></international-phone-number2>
          <div
            class="text text-danger"
            *ngIf="contactNumber.touched && contactNumber.invalid"
          >
            <small>Enter valid mobile number.</small>
          </div>
        </div>
        <div class="form-group mtop15">
          <input
            type="text"
            class="form-control height50"
            id="email"
            name="email"
            aria-describedby="emailHelp"
            placeholder="Email ID"
            formControlName="email"
            (input)="clearErrorMessage()"
          />
          <div class="text text-danger" *ngIf="email.touched && email.invalid">
            <small *ngIf="email.errors.required">Enter valid email ID.</small>
            <small *ngIf="email.errors.pattern">Enter valid email ID.</small>
          </div>
        </div>
       
        <small class="text-danger">
          {{ serverErrMessage }}
        </small>
        
        <ngx-recaptcha2 #captchaElem 
         [siteKey]="site"
          (success)="handleSuccess($event)" [size]="size"
        [hl]="lang" [theme]="theme" [type]="type" formControlName="recaptcha">
        </ngx-recaptcha2>
        <div class="mtop15">
          <label class="form-check-label"
            >By clicking "Register", you agree to our
            <a routerLink="/terms-of-use" target="_blank">Terms of Use</a
            >.</label
          >
        </div>
        <button
          [disabled]="registerForm.invalid"
          type="button"
          class="btn btn-login mtop15"
          (click)="submitForm(registerForm.value); clearServerErr()"
        >
          Register
        </button>
        <p class="mtop30">
          Already have an account?
          <a routerLink="/login" (keypress)="clearServerErr()" class="text-info"
            >Login</a
          >
        </p>
      </form>
    </div>
  </div>
</div>
