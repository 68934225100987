import { Component, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { TabsetComponent } from "ngx-bootstrap/tabs";
import { NgxUiLoaderService } from "ngx-ui-loader";
import { combineLatest } from "rxjs";
import { SharedserviceService } from "../sharedservice.service";
interface dataLevel {
  parentId: any;
  _id: any;
  name: string;
  type: string;
  urlSlug: string;
  childAvailability: boolean;
}
interface dataLevel1 {
  _id: any;
  name: string;
  type: string;
  urlSlug: string;
  childAvailability: boolean;
}

@Component({
  selector: "app-skill-based-template",
  templateUrl: "./skill-based-template.component.html",
  styleUrls: ["./skill-based-template.component.css"],
})
export class SkillBasedTemplateComponent implements OnInit {
  @ViewChild("usesTabs") usesTabs: TabsetComponent;
  skillBasedTemplateList = [];
  parentFlagNum: number = 0;
  quickAssessmentFunctionLevel: dataLevel1[] = [];
  quickAssessmentFunctionLevel2: dataLevel[] = [];
  quickAssessmentFunctionLevel3: dataLevel[] = [];
  functionData: dataLevel1[];
  singleFunctionData: dataLevel1;
  singleFunctionData2: dataLevel;
  public isCollapsed = false;
  selectedFunctionLevel: any;
  selectedFunction1Hasexpanded: boolean = false;
  selectedFunction2Hasexpanded: boolean = false;
  industryList: any = [];
  receivedjobRoleList: any = [];
  selectedIndustry: string = "";
  selectedJobRole: string = "";
  templateAccessibility: string = "";
  searchText: string = "";
  type: string = "";
  urlSlug: string = "";
  forAssessmentType: string = "";
  accessibility: string = "";
  noTemplateFoundErr: string = null;
  extendFlag: boolean = false;
  searchTaglist: any[] = [];
  functionLevel1: string = "";
  functionLevel2: string = "";
  functionLevel3: string = "";
  functionLevel2Flag: boolean = false;
  functionLevel3Flag: boolean = false;
  searchText1: string = "";
  skip: number = 0;
  isMoreTemplateFlag: boolean = false;

  constructor(
    private sharedServiceObj: SharedserviceService,
    private router: Router,
    private route: ActivatedRoute,
    private ngxService: NgxUiLoaderService
  ) {}

  async ngOnInit() {
    // this.ngxService.start();
    await this.getFunctionData("");
    await this.getIndustryData();
    await this.getJobRoleData();

    await this.getRouteParams();
  }

  async getRouteParams() {

    this.skip = 0;
    combineLatest([this.route.params, this.route.queryParams]).subscribe(
      async ([params, queryParams]) => {

        this.searchTaglist = [];
        this.skillBasedTemplateList = [];
        this.skip=0;

        if (
          this.route.snapshot.params["type"] &&
          this.route.snapshot.params["urlSlug"]
        ) {
          this.type = this.route.snapshot.params["type"];
          this.urlSlug = this.route.snapshot.params["urlSlug"];
          if (!this.searchTaglist.includes(this.urlSlug)) {
            this.searchTaglist.push(this.urlSlug);
          }

          if (this.type == "functionalArea") {
            this.functionLevel1 = localStorage.getItem("functionLevel1");
            this.functionLevel2 = localStorage.getItem("functionLevel2");

            if (this.functionLevel2) {
              this.functionLevel2Flag = true;
            } else {
              this.functionLevel2 = "";
            }
            this.functionLevel3 = localStorage.getItem("functionLevel3");

            if (this.functionLevel3) {
              this.functionLevel3Flag = true;
            } else {
              this.functionLevel3 = "";
            }
          }
          if (this.type == "industry") {
            this.selectedIndustry = localStorage.getItem("industry");
          }
          if (this.type == "jobRoleTitle") {
            this.selectedJobRole = localStorage.getItem("jobRole");
          }
        }


        if (queryParams["forassessmenttype"]) {
          this.forAssessmentType = queryParams["forassessmenttype"];
        }
        else{
          this.forAssessmentType="";
        }

        if (this.forAssessmentType) {
          this.searchTaglist.push(this.forAssessmentType);
        }

        if (queryParams["accessibility"]) {
          this.templateAccessibility = queryParams["accessibility"];
        }
        else{
          this.templateAccessibility="";
        }
        if (this.templateAccessibility) {
          this.searchTaglist.push(this.templateAccessibility);
        }

        if (this.functionLevel1) {
          let obj = this.quickAssessmentFunctionLevel.find(
            (o) => o.name === this.functionLevel1
          );

          if (obj) {
            if (obj.childAvailability) {
              this.parentFlagNum = 1;

              if (this.quickAssessmentFunctionLevel2[0]?.parentId != obj._id) {
                await this.getFunctionData(obj._id);
              }
            }
          }
        }
        if (this.functionLevel2) {
          let obj1 = this.quickAssessmentFunctionLevel2.find(
            (o) => o.name === this.functionLevel2
          );

          if (obj1) {
            if (obj1.childAvailability) {
              this.parentFlagNum = 2;

              if (this.quickAssessmentFunctionLevel3[0]?.parentId != obj1._id) {
                await this.getFunctionData(obj1._id);
              }
            }
          }
        }
        await this.getSkillBasedData();
      },
      (error) => {
        console.log(error);
      }
    );
  }

  async getSkillBasedData() {
    const payload = {
      searchText: this.searchText1,
      urlSlug: {
        type: this.type,
        identifier: this.urlSlug,
      },
      forAssessmentType: this.forAssessmentType,
      accessibility: this.templateAccessibility,
    };

    await this.sharedServiceObj
      .getSkillBasedTemplateList(payload, this.skip)
      .toPromise()
      .then(
        (response: any) => {
          let list = response.response;

          this.skillBasedTemplateList =
            this.skillBasedTemplateList.concat(list);
          this.isMoreTemplateFlag = response.isMoreCategory;
          this.noTemplateFoundErr = "";
          this.ngxService.stop();
        },
        (error: any) => {
          this.skillBasedTemplateList = [];
          this.isMoreTemplateFlag = false;
          this.noTemplateFoundErr = error.error.message;
          this.ngxService.stop();

          console.log(error);
        }
      );
  }

  clearFilter() {
    this.searchTaglist = [];
    this.urlSlug = "";
    this.type = "";
    const url = "/skill-based-assessment/";

    this.router.navigate([url]);
  }

  searchTemplate() {}

  async getFunctionData(parentId: string) {

    if (parentId == "") {
      this.parentFlagNum = 0;
    }
    const Payload = {
      type: "functionalArea",
      parentId: parentId,
    };
    await this.sharedServiceObj
      .getMasterValuesForMetaData(Payload)
      .toPromise()
      .then(
        async (response: any) => {
          this.functionData = response.response;

          for (var i = 0; i < this.functionData.length; i++) {
            if (this.parentFlagNum == 0) {
              this.singleFunctionData = {
                _id: this.functionData[i]._id,
                name: this.functionData[i].name,
                type: this.functionData[i].type,
                urlSlug: this.functionData[i].urlSlug,
                childAvailability: this.functionData[i].childAvailability,
              };

              this.quickAssessmentFunctionLevel.push(this.singleFunctionData);
            }
            if (this.parentFlagNum == 1) {
              this.singleFunctionData2 = {
                parentId: parentId,
                _id: this.functionData[i]._id,
                name: this.functionData[i].name,
                type: this.functionData[i].type,
                urlSlug: this.functionData[i].urlSlug,
                childAvailability: this.functionData[i].childAvailability,
              };
              this.quickAssessmentFunctionLevel2.push(this.singleFunctionData2);
              this.functionLevel2Flag = true;
            }
            if (this.parentFlagNum == 2) {
              this.singleFunctionData2 = {
                parentId: parentId,
                _id: this.functionData[i]._id,
                name: this.functionData[i].name,
                type: this.functionData[i].type,
                urlSlug: this.functionData[i].urlSlug,
                childAvailability: this.functionData[i].childAvailability,
              };
              this.quickAssessmentFunctionLevel3.push(this.singleFunctionData2);
              this.functionLevel3Flag = true;
            }
          }
        },
        (error: any) => {
          console.log(error);
        }
      );
  }

  setAssessmentType() {
    localStorage.setItem("forAssessmentType", this.forAssessmentType);
    localStorage.removeItem("accessibility");
    this.setPayLoadData(this.forAssessmentType);
  }

  async setFunctionLevelOne() {
    localStorage.removeItem("accessibility");
    localStorage.removeItem("forAssessmentType");
    this.searchTaglist=[];
    this.selectedJobRole="";
    this.selectedIndustry="";
    this.selectedFunctionLevel = this.quickAssessmentFunctionLevel.find(
      (o) => o.name === this.functionLevel1
    );
    localStorage.setItem("functionLevel1", this.selectedFunctionLevel.name);
    localStorage.removeItem("functionLevel2");
    localStorage.removeItem("functionLevel3");
    this.functionLevel2Flag = false;
    this.functionLevel3Flag = false;
    this.quickAssessmentFunctionLevel2 = [];

    this.setPayLoadData(this.selectedFunctionLevel);
  }

  async setFunctionLevelTwo() {
    localStorage.removeItem("accessibility");
    localStorage.removeItem("forAssessmentType");
    this.selectedFunctionLevel = this.quickAssessmentFunctionLevel2.find(
      (o) => o.name === this.functionLevel2
    );
    localStorage.setItem("functionLevel2", this.selectedFunctionLevel.name);
    localStorage.removeItem("functionLevel3");
    this.quickAssessmentFunctionLevel3 = [];
    this.functionLevel3Flag = false;
    // this.functionLevel3=""

    this.setPayLoadData(this.selectedFunctionLevel);
  }

  setFunctionLevelThree() {
    localStorage.removeItem("accessibility");
    localStorage.removeItem("forAssessmentType");
    this.selectedFunctionLevel = this.quickAssessmentFunctionLevel3.find(
      (o) => o.name === this.functionLevel3
    );
    localStorage.setItem("functionLevel3", this.selectedFunctionLevel.name);
    this.setPayLoadData(this.selectedFunctionLevel);
  }

  getIndustryData() {
    const Payload = {
      type: "industry",
      parentId: "",
    };
    this.sharedServiceObj
      .getMasterValuesForMetaData(Payload)
      .toPromise()
      .then(
        (response: any) => {
          this.industryList = response.response;
        },
        (error: any) => {
          console.log(error);
        }
      );
  }

  async getJobRoleData() {
    const Payload = {
      type: "jobRoleTitle",
      parentId: "",
    };
    await this.sharedServiceObj
      .getMasterValuesForMetaData(Payload)
      .toPromise()
      .then(
        (response: any) => {
          this.receivedjobRoleList = response.response;
        },
        (error: any) => {
          console.log(error);
        }
      );
  }

  // extendLevel(functionval: any, state: boolean, level: number) {
  //
  //   if (level == 1) {
  //     let obj = this.quickAssessmentFunctionLevel.find(
  //       (o) => o._id === functionval._id
  //     );
  //     obj. = state;
  //     this.parentFlagNum = 1;
  //   }
  //   if (level == 2) {
  //     let obj = this.quickAssessmentFunctionLevel2.find(
  //       (o) => o._id === functionval._id
  //     );
  //     obj. = state;
  //     this.parentFlagNum = 2;
  //   }

  //   this.getFunctionData(functionval._id);
  // }

  setIndustry() {
    this.functionLevel1="";
    this.functionLevel2="";
    this.functionLevel3="";
    this.functionLevel2Flag=false;
    this.functionLevel3Flag=false;
    this.selectedJobRole="";

    localStorage.removeItem("functionLevel1");
    localStorage.removeItem("functionLevel2");
    localStorage.removeItem("functionLevel3");
    localStorage.removeItem("accessibility");
    localStorage.removeItem("forAssessmentType");
    this.searchTaglist=[];

    let obj = this.industryList.find((o) => o.name === this.selectedIndustry);
    localStorage.setItem("industry", this.selectedIndustry);

    this.setPayLoadData(obj);
  }

  setJobRole() {
    this.functionLevel1="";
    this.functionLevel2="";
    this.functionLevel3="";
    this.functionLevel2Flag=false;
    this.functionLevel3Flag=false;
    this.selectedIndustry="";

    localStorage.removeItem("functionLevel1");
    localStorage.removeItem("functionLevel2");
    localStorage.removeItem("functionLevel3");
    localStorage.removeItem("accessibility");
    localStorage.removeItem("forAssessmentType");
    this.searchTaglist=[];

    let obj = this.receivedjobRoleList.find(
      (o) => o.name === this.selectedJobRole
    );
    localStorage.setItem("jobRole", this.selectedJobRole);

    this.setPayLoadData(obj);
  }

  setAccessibility() {

    localStorage.removeItem("forAssessmentType");
    this.searchTaglist=[];

    if (this.templateAccessibility == "") {
      localStorage.removeItem("accessibility");
    } else {
      localStorage.setItem("accessibility", this.templateAccessibility);
    }
    this.setPayLoadData(this.templateAccessibility);
  }

  setPayLoadData(value: any) {
    let url: string;
    
    if (typeof value == "string") {
      let obj = this.searchTaglist.find(
        (o) =>
          o === "Aptitude" ||
          o === "Technical" ||
          o === "open" ||
          o === "restricted"
      );
      if(obj=== "Aptitude" || obj === "Technical"){
        if(value===obj){
        localStorage.removeItem("forAssessmentType");
        }
      }
      if( obj === "open" || obj === "restricted"){
        if(value===obj){
        localStorage.removeItem("accessibility");
        }
      }
      var index = this.searchTaglist.indexOf(obj);

      this.searchTaglist.splice(index, 1);
      if (this.type.length > 0) {
        url = "/skill-based-assessment/" + this.type + "/" + this.urlSlug;
      } else {
        url = "/skill-based-assessment";
      }
      console.log(localStorage.getItem("forAssessmentType"));
      console.log(url);
      
      this.router.navigate([url], {
        queryParams: {
          forassessmenttype: localStorage.getItem("forAssessmentType"),
          accessibility: localStorage.getItem("accessibility"),
        },
      });
    } else {
      this.searchTaglist = [];
      this.type = value.type;
      this.urlSlug = value.urlSlug;

      url = "/skill-based-assessment/" + this.type + "/" + this.urlSlug;

      this.router.navigate([url]);
    }
  }

  removeFilter(tag) {
    var index = this.searchTaglist.indexOf(tag);
    this.searchTaglist.splice(index, 1);

    if (
      this.searchTaglist[0] == "Aptitude" ||
      this.searchTaglist[0] == "Technical"
    ) {
      const url = "/skill-based-assessment";
      this.router.navigate([url], {
        queryParams: {
          forassessmenttype: this.searchTaglist[0],
        },
      });
    } else if (
      this.searchTaglist[0] == "open" ||
      this.searchTaglist[0] == "restricted"
    ) {
      const url = "/skill-based-assessment";
      this.router.navigate([url], {
        queryParams: {
          accessibility: this.searchTaglist[0],
        },
      });
    } else if (this.searchTaglist.length != 0) {
      const url = "/skill-based-assessment/" + this.type + "/" + this.urlSlug;
      location.assign(url);
    } else {
      const url = "/skill-based-assessment/";
      location.assign(url);
    }
  }
  getTemplateDetails(template) {
    this.router.navigate([
      "skill-based-assessment-template-detail/" + template.urlSlug,
    ]);
  }

  loadMore() {

    this.skip = this.skip+1;
    this.getSkillBasedData();
  }

  showTruncatedIndustryTitle(industryTitle: string) {
    return (industryTitle === "" || industryTitle.length < 20) ? industryTitle:industryTitle.slice(0, 16)+"..";
  }
}
