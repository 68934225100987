import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import { VerifyService } from '../verify-comp/verify-comp.service';
import {Form, NgForm} from '@angular/forms';
import { Router, ActivatedRoute, Params } from '@angular/router';
import {ToastrService} from 'ngx-toastr';
import * as CryptoJS from 'crypto-js';




@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.css'],
  providers: [VerifyService]
})

export class ForgotPasswordComponent implements OnInit, OnDestroy {
  companyId = '';
  password = '';
  hideLoading = false;
  confirmPassword = '';
  invalidPassword: boolean;
  passwordValidation: any;
  matchPassword = '';
  passwordChangeId = '';
  token1 = '';
  showFormField: boolean;
  showLoginButton: boolean;
  errorMessage = '';
  formErrorMsg = '';
  successMessage = '';
  pwdFormat = 'Password must contain minimum 8 and maximum 15 characters, including at least one uppercase alphabet, one lowercase alphabet, one numeric digit and one special character. Password must not contain spaces';
  missingLinkVerfTokenErr = 'This reset password link seems to be broken. Please reinitiate \'Forgot Password\' process or contact us at support@calibrat.com for assistance.';
  @ViewChild('feed') feed: any;
  constructor(public verifyservice: VerifyService, private toastrService: ToastrService, private router: Router,
              private route: ActivatedRoute) {
    this.route.queryParams.subscribe((params: Params) => {
      this.token1 = params['token'];
    });
  }
  ngOnInit() {
    document.body.classList.add('registration_login_bg_image');
    if (this.token1 === '' || this.token1 === undefined) {
      this.errorMessage = this.missingLinkVerfTokenErr;
      this.hideLoading = true;
    } else {
      this.passwordLnkVerification(this.token1);
    }
  }


  passwordMatch() {
    this.matchPassword = '';
    for (let i = 0; i < this.password.length; i++) {
      if (this.password.charAt(i) === ' ') {
        break;
      } else {
        this.invalidPassword = false;
      }
    }
    if (this.invalidPassword === false) {
      this.passwordValidation = '';
    }
  }
  confirmMatch() {
    if (this.password === '' || this.confirmPassword === '') {
      return;
    }
    if (this.password.length < this.confirmPassword.length) {
      this.matchPassword = 'Password Mismatch';
      return ;
    } else if ((this.confirmPassword !== this.password) && (this.password.length === this.confirmPassword.length)) {
      this.matchPassword = 'Password Mismatch';
      return;
    } else {
      this.matchPassword = '';
    }
  }

  matchPasswordFormat(e) {
    if (this.feed.controls.password !== undefined && e.target.value !== '' && !this.feed.controls.password.valid ) {
      this.invalidPassword = true;
      this.passwordValidation = 'Invalid Password';
    } else {
      this.invalidPassword = false;
      this.passwordValidation = '';
    }
  }
  passwordLnkVerification(token) {
    const payload = {
      'token': token,
    };
    this.verifyservice.passWordLinkVerification(payload).subscribe((response: any) => {
        this.passwordChangeId = response._id;
        this.hideLoading = true;
        this.showFormField = true;
      },
      error => {
        this.errorMessage = error.error.message;
        this.hideLoading = true;
    });

  }

  SubmitsEncry(password): string {
    const key = CryptoJS.enc.Utf8.parse('8080808080808080');
    const iv = CryptoJS.enc.Utf8.parse('8080808080808080');
    const encryptedpassword = CryptoJS.AES.encrypt(CryptoJS.enc.Utf8.parse(password), key,
      { keySize: 128 / 8,
        iv: iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7
      });
    return encryptedpassword;
  }
  submitPassword() {
    this.errorMessage = '';
    this.formErrorMsg = '';
    if (this.confirmPassword !== this.password) {
      this.matchPassword = 'Password mismatch';
      return false;
    }
    const password = this.SubmitsEncry(this.confirmPassword);
    this.confirmPassword = password.toString();
    const payLoad = {
      '_id' : this.passwordChangeId,
      'password': JSON.stringify(this.confirmPassword),
    };
    this.verifyservice.changePassword(payLoad).subscribe((response: any) => {
      this.showLoginButton = true;
      this.showFormField = false;
      this.successMessage = response.response;
    },
      error => {
        this.formErrorMsg = error.error.message;
      });
  }
  ngOnDestroy() {
    document.body.classList.remove('registration_login_bg_image');
  }
}
