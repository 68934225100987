import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { SimpleChanges } from '@angular/core';
import { AssessmentService } from '../assessment.service';
@Component({
  selector: 'app-mathjax',
  templateUrl: './mathjax.component.html',
  styleUrls: ['./mathjax.component.css']
})
export class MathjaxComponent implements OnInit {

  @Input() content: string;

  constructor(public qbs: AssessmentService) { }
  mathJaxObject;
  ngOnChanges(changes: SimpleChanges) {
    if (changes['content']) {
      this.renderMath();
    }
  }

  renderMath() {
    // MathJax.Hub.Queue(["Typeset",MathJax.Hub]);

    this.mathJaxObject  = this.qbs.nativeGlobal()['MathJax'] ;
    // setInterval(()=>{},1)
    let angObj = this;
    setTimeout(() => {
      angObj.mathJaxObject.Hub.Queue(
        ['Typeset', angObj.mathJaxObject.Hub],
        'mathContent');
    }, 1000);
  }
  loadMathConfig() {
    this.mathJaxObject  = this.qbs.nativeGlobal()['MathJax'] ;
    this.mathJaxObject.Hub.Config({
      showMathMenu: false,
      tex2jax: {inlineMath: [['$', '$'], ['\\(', '\\)']]},
      menuSettings: { zoom: 'Double-Click', zscale: '150%' },
      CommonHTML: { linebreaks: { automatic: true } },
      'HTML-CSS': { linebreaks: { automatic: true } },
      SVG: { linebreaks: { automatic: true } }
    });
  }

  ngOnInit() {

    this.loadMathConfig();
    this.renderMath();

  }

}

